import React from "react";

import * as Constants from "./Constants";
import * as Helper from "./Helper";
import numeral from "numeral";

class Monitoring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientuuid: "-1",
      interval: Constants.TIMEFRAME_MONTH,
      lastUpdated: new Date(),
      refreshRate: Constants.REFRESH_RATE_MINUTES_1,
    };
  }

  componentDidMount() {
    // Get the client list
    this.props.getClientList(Constants.ACTIVE, (clients) => {
      this.setState(
        {
          clients: clients
            .filter(
              (c) =>
                c.name.indexOf("Rainforest") === -1 &&
                c.name.indexOf("Demo ") === -1 &&
                c.name.indexOf("Playwright") === -1 &&
                c.name.indexOf("Template") === -1
            )
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
        () => {
          // Get the metrics for all clients
          Constants.METRICS.forEach((metric) => {
            this.getMetric(metric, this.state.interval);
          });
        }
      );
    });
    // Start a timer that runs every 10 seconds. If the last update was more than that update interval, then update the metrics
    this.timer = setInterval(() => {
      const now = new Date();
      if (now - this.state.lastUpdated > this.state.refreshRate) {
        this.setState({ lastUpdated: now }, () => {
          Constants.METRICS.forEach((metric) => {
            this.getMetric(metric, this.state.interval, this.state.clientuuid);
          });
        });
      }
    }, 10000);
  }

  componentWillUnmount() {
    // Clean up
  }

  render() {
    return (
      <div className="app-body">
        <div className="monitoring">
          <div className="monitoringWrapper">
            <div className="monitoringHeader">
              <h1 className="">Monitoring</h1>
              {this.renderRefreshSelect()}
              {this.renderClientSelect()}
            </div>
            {this.renderMetrics()}
          </div>
        </div>
      </div>
    );
  }

  renderRefreshSelect = () => {
    return (
      <div>
        <label className="marginRight1em" htmlFor="refresh-select">
          Refresh every:
        </label>
        <select id="refresh-select" className="refresh-select" onChange={this.handleChangeRefreshRate} value={this.state.refreshRate}>
          {Constants.REFRESH_RATES.map((definition) => {
            const label = definition[0];
            const rate = definition[1];
            return (
              <option key={rate} value={rate}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  renderClientSelect = () => {
    if (this.state.clients) {
      const all = { clientuuid: "-1", name: "All" };
      const clients = [all, ...this.state.clients];
      return (
        <div>
          <label className="marginRight1em" htmlFor="client-select">
            Client:
          </label>
          <select id="client-select" className="client-select" onChange={this.handleClientChange} value={this.state.clientuuid}>
            {clients.map((client) => (
              <option key={client.clientuuid} value={client.clientuuid}>
                {client.name}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  renderMetrics = () => {
    return (
      <div className="metrics">
        {Constants.METRICS.map((metric) => {
          return (
            <div key={metric} className="metric centerAligned">
              <h2>{this.renderTitle(metric)}</h2>
              {this.renderMetric(metric)}
            </div>
          );
        })}
      </div>
    );
  };

  renderMetric = (metric) => {
    // Make sure "metric" is a valid key to the this.state.metrics object
    if (!this.state.metrics?.hasOwnProperty(metric)) {
      return <div>Loading...</div>;
    }

    const data = this.state.metrics[metric];
    if (data) {
      return <h1>{numeral(data.total || data.count).format(Constants.DECIMAL_VALUE)}</h1>;
    } else if (data === null) {
      return <div>No data</div>;
    } else {
      return <div>Loading...</div>;
    }
  };

  getMetric = (metric, interval, clientuuid) => {
    const data = {
      action: Constants.ACTION_GET_METRICS,
      q: metric,
      t: interval,
    };
    if (clientuuid && clientuuid !== "-1") {
      data.uuid = clientuuid;
    }
    const url = Constants.URL_CLIENTS;
    Helper.getData(url, data).then((response) => {
      if (response.status === 200 && response.body) {
        this.setState((prevState) => ({
          metrics: {
            ...prevState.metrics,
            [metric]: response.body,
          },
        }));
      } else {
        this.setState((prevState) => ({
          metrics: {
            ...prevState.metrics,
            [metric]: null,
          },
        }));
      }
    });
  };

  handleClientChange = (event) => {
    this.setState({ clientuuid: event.target.value }, () => {
      Constants.METRICS.forEach((metric) => {
        this.getMetric(metric, this.state.interval, event.target.value);
      });
    });
  };

  handleChangeRefreshRate = (event) => {
    console.log("handleChangeRefreshRate", event.target.value);
    this.setState({ refreshRate: event.target.value });
  };

  renderTitle = (metric) => {
    switch (metric) {
      case Constants.METRIC_CREDIT_AUTH_APPROVALS:
        return "Credit Approvals";
      case Constants.METRIC_INVOICE_COUNT:
        return "Invoices Created";
      case Constants.METRIC_PAYMENTS:
        return "Payments Received";
      case Constants.METRIC_SALES_AMOUNT:
        return "Sales Amount";
      case Constants.METRIC_USER_COUNT:
        return "Users Created";
      default:
        break;
    }
    return "";
  };
}

export default Monitoring;
