import React from "react";
import BaseDetailViewHandler from "./BaseDetailViewHandler";

// Constants
import * as Constants from "./Constants";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";
import QRCode from "react-qr-code";

// Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCalculator,
  faCheck,
  faCircleMinus,
  faCopy,
  faExclamationTriangle,
  faFolderTree,
  faTag,
  faTrashCan,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";

import BillingsIcon from "./img/BillingsIcon";
import ButtonControls from "./ButtonControls";
import CirclePlusIcon from "./img/CirclePlusIcon.js";
import CircleXmarkIcon from "./img/CircleXmarkIcon";
import CloudIcon from "./CloudIcon";
import ContactCardDeck from "./ContactCardDeck";
import ErrorIcon from "./ErrorIcon";
import Folders from "./Folders";
import ListItemView from "./ListItemView";
import ProductCard from "./ProductCard";
import RepairItemCard from "./RepairItemCard";
import SearchBar from "./SearchBar";
import StockOrderIcon from "./img/StockOrderIcon.js";
import Switch from "./Switch";
import TextareaAutosize from "react-textarea-autosize";
import TagList from "./TagList";
import ContactCard from "./ContactCard.jsx";
import Tooltip from "./Tooltip";

// View for editing a contact
class BaseDetailViewComponent extends BaseDetailViewHandler {
  constructor(props) {
    super(props);
    const { addBreadcrumb, checkLogin } = this.props;
    addBreadcrumb();
    checkLogin();

    this.state = {
      displaySettings: this.props.appState.displaySettings,
      clientSettings: this.props.appState.clientSettings,
      downloading: false,
      enableActionButtons: true,
      error: null,
      expandedListItems: [],
      inflightRequests: 0,
      isNew: true,
      linkCopied: false,
      showCost: false,
      showHints: false,
      showInventory: false,
      showSelectStatus: false,
      showPurchaseItemStatus: false,
      showEditOrderDate: false,
      showEditDueDate: false,
      viewName: "",
      billingPlanSearchCount: 0,
      billingPlanSearchKey: "",
      billingPlanSearchResults: null,
      billingPlanSearchLimit: 5,
      billingPageNumber: 1,
      selectedInvoices: [],
      selectedOrderItems: [],
      suggestions: {},
      loading: true,
      reverbShippingProviders: [],
      isEditingBaseDetailName: false,
    };

    let returnView = false;
    let pickSalesperson = false;

    if (this.props.selectedItem?.type === Constants.PROSPECT) {
      this.state.prospect = this.props.selectedItem;
      this.state.company = Helper.getBlankProspectCompany(this.state.prospect);
      this.state.isNew = false;
      this.prospectStatusRef = React.createRef();
    } else if (this.props.appState.currentView === Constants.CAMPAIGN) {
      this.state.campaign = this.props.selectedItem;
      this.state.isNew = false;
    } else if (
      Helper.isOrderView(this.props.appState.currentView) ||
      (this.props.appState.currentView === Constants.BILLING && props.selectedItem?.type === Constants.BILLING_PLAN)
    ) {
      // creates cursor focus on search fields
      this.contactSearchInput = React.createRef();
      this.contactSearchWrapperRef = React.createRef();

      this.productSearchInput = React.createRef();
      this.productSearchWrapperRef = React.createRef();

      this.billingPlanSearchRef = React.createRef();
      this.billingPlanSearchWrapperRef = React.createRef();

      this.orderStatusRef = React.createRef();
      this.creationDateRef = React.createRef();
      this.dueDateRef = React.createRef();

      this.purchaseItemStatusRef = React.createRef();

      // Setup the order
      const sourceorder = Helper.deepCopy(props.selectedItem);
      let order = Helper.getBlankOrder(props.ordertype, props.appState.salesperson, props.selectedItem?.ordersubtype);
      let contactuuid = null;
      let possibleCompanies = [];
      if (sourceorder) {
        // Check for return invoice and set view flag
        if (this.isReturn(sourceorder)) {
          returnView = true;
        }

        contactuuid = sourceorder.contactuuid;
        order.orderuuid = sourceorder.orderuuid;
        if (sourceorder.sourceorderuuid) {
          order.sourceorderuuid = sourceorder.sourceorderuuid;
        }
        order.ordernumber = sourceorder.ordernumber;
        order.orderstatus = sourceorder.orderstatus;
        order.salesperson = sourceorder.salesperson;
        order.creationdatetime = sourceorder.creationdatetime;
        // Due date is only for repair views.
        order.duedatetime = sourceorder.duedatetime;
        order.ponumber = sourceorder.ponumber;
        order.company = sourceorder.company;
        // Build a temporary skeleton company to reduce screen bounce
        // But only for customer-based orders that don't have a company loaded already
        // and have a companyuuid to load down below
        if (!order.company && sourceorder.companyuuid && props.appState.currentView !== Constants.PURCHASE) {
          order.company = {
            companyuuid: sourceorder.companyuuid || "",
            companyname: sourceorder.companyname,
            contacts: [
              {
                contactuuid: sourceorder.contactuuid,
                firstname: sourceorder.contactname,
                lastname: "",
                mobilephone: sourceorder.mobilephone,
                otherphone: sourceorder.otherphone,
                email: sourceorder.email,
              },
            ],
          };
        }
        order.notes = sourceorder.notes;

        // Copy order totals
        order.totalcost = sourceorder.totalcost;
        order.totalpayments = sourceorder.totalpayments;
        order.totalprice = sourceorder.totalprice;
        order.totaltax = sourceorder.totaltax;

        // For Purchases, sort the order items by productuuid to combine like items
        if (this.props.appState.currentView === Constants.PURCHASE) {
          order.orderitems = this.sortAndCombineProducts(sourceorder.orderitems, props.ordertype, props.appState.salesperson);
        } else if (this.props.selectedItem?.orderitems) {
          order.orderitems = this.props.selectedItem.orderitems.map(item => {
            // Parent/root order item uuid's from source order item
            item.parentorderitemuuid = item.orderitemuuid ? item.orderitemuuid : null;
            item.parent_data = [];
            return item;
          });
        }

        // Copy any deposits/payments as a "payment advice" entry
        order.payments = [];
        if (sourceorder.payments) {
          let payments = sourceorder.payments.filter(p => p.paymenttype !== Constants.CHANGE);
          order.payments = payments.map(payment => {
            const parentpaymentuuid = payment.parentpaymentuuid ? payment.parentpaymentuuid : payment.paymentuuid;
            const amount = payment.amount - payment.amountRefunded;
            payment = {
              creationdatetime: payment.creationdatetime,
              amount: amount,
              paymenttype: Constants.DEPOSIT_APPLIED,
              paymentuuid: payment.paymentuuid,
              parentpaymentuuid: parentpaymentuuid,
              uuid: payment.paymentuuid,
              sourceorderuuid: props.selectedItem?.sourceorderuuid ?? "",
            };
            return payment;
          });
        }

        // Determine supplier (or possible supplier list) for this order
        if (!order.company && props.ordertype === Constants.PURCHASE) {
          pickSalesperson = true;
          possibleCompanies = this.getPossibleSuppliers(order);
          // If all items have the same supplier, then default in the supplier on the order
          if (possibleCompanies.length === 1) {
            order.company = {
              companyuuid: possibleCompanies[0].id,
              companyname: possibleCompanies[0].text,
              contacts: [
                {
                  contactuuid: null,
                  firstname: "",
                  lastname: "",
                  mobilephone: "",
                  otherphone: "",
                  email: "",
                },
              ],
            };
          }
        }
      }
      order = Helper.maybeAddDefaultRepairItem(order);

      this.state = {
        ...this.state,
        contactSearchKey: "",
        contactSearchResults: null,
        contactuuid: contactuuid, // Selected contactuuid from list
        downloading: false,
        error: null,
        families: this.props.appState.families,
        isNew: true,
        order: order,
        paymentView: Constants.PAYMENT_BUTTONS,
        pickSalesperson: pickSalesperson,
        possibleCompanies: possibleCompanies,
        product: null,
        productSearchKey: "",
        productSearchResults: null,
        productSearchCount: 0,
        prospect: null,
        // returnView: returnView,
        isReturn: returnView,
        technicians: this.props.appState.technicians,
      };
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener(Constants.EVENT_KEYDOWN, this.handleKeyDown);

    // If Product or Order view, load the product suggestions
    if (this.props.appState.currentView === Constants.PRODUCT || Helper.isOrderView(this.props.appState.currentView)) {
      this.getProductSuggestions();
    }

    if (this.state.order) {
      // TODO: Quick hack to always show order details before loading finishes
      this.setState({ loading: false });
      if (this.state.order?.orderuuid) {
        // Opening an existing order from the database
        this.setState({ isNew: false });
        this.getOrder(this.state.order?.orderuuid);
      } else if (this.state.order?.company && this.state.order?.company.companyuuid) {
        // Creating an order for a specific company (chosen earlier)
        this.getCompany(this.state.order?.company.companyuuid, () => {
          // Check to see if this is a subscription payment and if so, then show "payment required" disclaimer
          const item = this.state.order?.orderitems?.find(item => item?.uniqueidentifier?.startsWith(Constants.SUBSCRIPTION_PAYMENT + "|"));
          if (item) {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Please note:\nThe subscription overdue amount\nfor this customer will be updated\nonce this invoice is paid in full.",
            });
          }
        });
      } else if (this.state.possibleCompanies.length > 1) {
        // Creating an order from items (chosen somewhere else)
        // Show a prompt asking to use one of the companies
        this.props.showOverlay({
          type: Constants.OVERLAY_PICKER,
          items: this.state.possibleCompanies,
          text: "Pick a supplier",
          id: this.state.possibleCompanies[0].id,
          event: {},
          hideCreate: true,
          callback: this.handlePickCompany,
        });
      } else if (this.state.isNew && this.state.order?.orderitems?.find(item => item.isgiftcard && !item.giftCardNumber)) {
        // Creating a new order with a gift card item
        this.setState(prevState => ({
          order: {
            ...prevState.order,
            orderitems: prevState.order.orderitems.filter(item => !(item.isgiftcard && !item.giftCardNumber)),
          },
        }));
        // If a SALE invoice is being created with a gift card item, then show the gift card input
        this.handleShowGiftCardInput(this.state.order?.orderitems?.find(item => item.isgiftcard && !item.giftCardNumber));
      }
    } else if (this.props.selectedItem?.type === Constants.PROSPECT) {
      this.getProspect(this.props.selectedItem?.marketingprospectuuid);
    } else if (this.props.appState.currentView === Constants.CAMPAIGN && !this.props.selectedItem) {
      // Prompt for the campaign name
      this.handleCreateCampaign();
    } else if (this.props.appState.currentView === Constants.CAMPAIGN && this.props.selectedItem?.campaignuuid) {
      this.getCampaign(this.props.selectedItem?.campaignuuid);
    } else if (!this.isBillingDetailView()) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener(Constants.EVENT_KEYDOWN, this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    // If we are creating a RETURN from an INVOICE...
    if (
      this.props.selectedItem &&
      prevProps.selectedItem &&
      Helper.isOrderView(this.props.appState.currentView) &&
      !Helper.isReturn(prevProps.selectedItem) &&
      Helper.isReturn(this.props.selectedItem) &&
      this.props.selectedItem?.orderuuid !== prevProps.selectedItem.orderuuid &&
      this.props.selectedItem?.orderuuid === null
    ) {
      this.props.addBreadcrumb();
      // Setup the return
      let order = Helper.getBlankOrder(this.props.ordertype, this.props.appState.salesperson, Constants.RETURN);
      const contactuuid = this.props.selectedItem?.contactuuid;
      order.orderuuid = this.props.selectedItem?.orderuuid;
      if (this.props.selectedItem?.sourceorderuuid) {
        order.sourceorderuuid = this.props.selectedItem?.sourceorderuuid;
      }
      order.sourceorderuuid = prevProps.selectedItem.orderuuid;
      order.ordernumber = this.props.selectedItem?.ordernumber;
      order.orderstatus = this.props.selectedItem?.orderstatus;
      order.companyname = this.props.selectedItem?.companyname;
      order.contactuuid = this.props.selectedItem?.contactuuid;
      order.contactname = this.props.selectedItem?.contactname;
      order.salesperson = this.props.selectedItem?.salesperson;
      order.company = this.props.selectedItem?.company;
      order.orderitems =
        this.props.selectedItem?.orderitems?.map?.(item => {
          item.cost = numeral(item.cost).multiply(-1).format(Constants.CURRENCY);
          item.sellprice = numeral(item.sellprice).multiply(-1).format(Constants.CURRENCY);
          item.discount = numeral(item.discount).multiply(-1).format(Constants.CURRENCY);
          item.totalcost = "0.00";
          item.totalprice = "0.00";
          item.quantitySold = numeral(item.quantity).format(Constants.DECIMAL_VALUE);
          item.quantityreceived = numeral(item.quantityreceived).format(Constants.DECIMAL_VALUE);
          item.quantity = "0";
          item.orderitemuuid = null;
          return item;
        }) ?? [];
      // Default qty of 1 for single item returns with no prior returns
      if (
        order.orderitems.length === 1 &&
        numeral(order.orderitems[0].quantitySold).value() === 1 &&
        numeral(order.orderitems[0].quantityreceived).value() === 0
      ) {
        order.orderitems[0].quantity = 1;
      }
      this.setState(
        {
          downloading: false,
          error: null,
          isNew: true,
          order: order,
          contactuuid: contactuuid,
          isReturn: true,
          // returnView: true,
        },
        () => {
          this.props.persistState();
        }
      );
    } else if (Helper.isOrderView(this.props.appState.currentView) && this.props.selectedItem?.orderuuid !== prevProps.selectedItem?.orderuuid) {
      // We're opening a return invoice from the invoice screen
      // If the current order still has a message on it, then display the message before proceeding
      if (this.state.order?.message) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: this.state.order?.message,
          callback: () => {
            this.setState(
              prevState => ({ order: { ...prevState.order, message: null } }),
              () => {
                this.props.addBreadcrumb();
                this.getOrder(this.props.selectedItem?.orderuuid);
              }
            );
          },
        });
      } else {
        this.props.addBreadcrumb();
        this.getOrder(this.props.selectedItem?.orderuuid);
      }
    } else if (
      [Constants.SUPPLIER, Constants.CUSTOMER].includes(this.props.appState.currentView) &&
      this.props.selectedItem?.companyuuid !== prevProps.selectedItem?.companyuuid
    ) {
      this.getCompany(this.props.selectedItem?.companyuuid);
    }
  }

  render = () => {
    const storename = this.state.clientSettings?.NAME || "";
    let classes = "areaBaseDetailContainer areaDetail" + this.props.appState.currentView + " " + this.props.filtertype?.tab;
    if (this.props.appState.currentView === Constants.INVOICE && this.state.order?.ordersubtype === Constants.SUBSCRIPTION) {
      classes += " subscription";
    }
    let subject = "";
    let view = this.props.appState.currentView;
    let report = "";
    if (this.props.selectedItem?.reportname) {
      report = this.props.selectedItem?.reportname;
    }

    let subtypeView = (this.state.order?.ordersubtype ? this.state.order?.ordersubtype : " ").trim();
    let printOrderNumber = this.state.order?.ordernumber ? this.state.order?.ordernumber : "New";
    if (view === Constants.PURCHASE) {
      subject = storename + " " + subtypeView + view + " #" + printOrderNumber;
    } else if (view === Constants.QUOTE) {
      subject = storename + " " + subtypeView + view + " #" + printOrderNumber;
    } else if (view === Constants.ORDER) {
      subject = storename + " " + subtypeView + view + " #" + printOrderNumber;
    } else if (view === Constants.INVOICE && subtypeView === Constants.RETURN) {
      subject = storename + " " + subtypeView + view + " #" + printOrderNumber;
    } else if (view === Constants.INVOICE) {
      subject = storename + " " + subtypeView + view + " #" + printOrderNumber;
    } else if (view === Constants.REPAIR) {
      subject = storename + " " + subtypeView + view + " #" + printOrderNumber;
    } else if (view === Constants.REPORT) {
      subject = storename + " Report " + report;
    } else if (view === Constants.CUSTOMER) {
      let { desktopTitle } = this.renderScreenTitles();
      subject = storename + " - " + desktopTitle;
    } else if (view === Constants.SUPPLIER) {
      let { desktopTitle } = this.renderScreenTitles();
      subject = storename + " - " + desktopTitle;
    } else if (view === Constants.PRODUCT) {
      subject = storename + " - " + (this.state.product?.productname || "New Product");
    } else if (this.isBillingDetailView()) {
      if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
        subject = storename + " Billing Plan";
      } else {
        subject = storename + " Billing Subscription";
      }
    }
    document.title = subject;
    return this.renderDetailBody(classes);
  };

  renderDetailBody = classes => {
    return (
      <React.Fragment>
        <div className={classes}>
          {this.renderAreaClientLogo()}
          {/* {this.renderPageTitle()} */}
          {/* Render Letterhead where appropriate  */}
          {this.renderAreaClientTaxID()}
          {this.renderAreaHorizontalRule()}
          {this.renderAreaClientName()}
          {this.renderAreaClientAddress1()}
          {this.renderAreaClientCityStateZip()}
          {this.renderAreaClientPhone()}
          {this.renderAreaClientWeb()}
          {this.renderAreaClientEmail()}

          {/* Render appropriate Header for screen/print */}
          {this.renderAreaCustomerHeader()}
          {this.renderAreaProductHeader()}
          {this.renderAreaSupplierHeader()}
          {this.renderAreaReportHeader()}
          {this.renderAreaCampaignHeader()}

          {/* Render left part of screen based on object type */}

          {this.renderAreaCloseButton(false)}

          {/* Render top part of screen based on object type */}
          {this.renderAreaContactSearch()}
          {this.renderAreaContactCardDeck()}
          {this.renderAreaProspectCard()}
          {this.renderAreaRepairItems()}
          {this.renderAreaProspectAdditionalData()}
          {this.renderAreaProspectAgreement()}
          {this.renderAreaSwitchesCampaign()}

          {/* Render right part of screen based on object type */}
          <div className="areaIdPanel noReceipt">
            {this.renderAreaCloseButton(true)}
            {this.renderAreaOrderNumber()}
            {this.renderAreaProspectStatus()}
            {this.renderAreaOrderStatus()}
            {this.renderAreaCreationDate()}
            {this.renderAreaUpdateDate()}
            {this.renderAreaProspectAgreementDate()}
            {this.renderCampaignName()}
            {this.renderAreaProspectPaymentCard()}
            {this.renderAreaProspectMatch()}
            {this.renderAreaDueDate()}
            {this.renderAreaSalesperson()}
            {this.renderAreaPONumberInput()}
            {this.renderAreaPONumberLabel()}
            {this.renderAreaTrackingNumberInput()}
            {this.renderAreaTrackingNumberLabel()}
            {this.renderAreaSwitches()}
            {this.renderAreaStoreCredit()}
          </div>

          {/* Render areas for receipt printing */}
          {this.renderAreaOrderNumber("receiptOnly")}
          {this.renderAreaCreationDate("receiptOnly")}
          {this.renderAreaDueDate("receiptOnly")}
          {this.renderAreaSalesperson("receiptOnly")}
          {this.renderAreaPONumberInput("receiptOnly")}
          {this.renderAreaPONumberLabel("receiptOnly")}
          {this.renderAreaTrackingNumberInput("receiptOnly")}
          {this.renderAreaTrackingNumberLabel("receiptOnly")}

          {/* Render product list information */}
          {this.renderAreaProductSearch()}
          {this.renderAreaProductList()}
          {this.renderAreaProductListNarrow()}

          {/* Render Product Card or New Product based on object type */}
          {this.renderAreaProductCard()}
          {this.renderAreaNewProduct()}
          {this.renderProductElements()}
          {this.renderAreaManagerFields()}

          {/* Render Report based on selected type */}
          {this.renderReportBody()}

          {/* Render standard bottom part of the screen */}
          {this.renderAreaFolders()}
          {this.renderAreaPrintedNotes()}
          {this.renderAreaSubscribe()}
          {this.renderAreaButtonControls()}
          {this.renderAreaOrderTotal()}

          {/* Render the correct barcode based on print type */}
          {this.renderAreaBarcode()}
          {this.renderAreaBarcodeNarrow()}
          {this.renderAreaPay()}

          {/* Billing components */}
          {this.renderAreaSubscriptionStatus()}
          {this.renderAreaStoredPayments()}
          {this.renderAreaSubscriptions()}
          {this.renderAreaSubscriptionsNarrow()}
          {this.renderAreaBillingPlanSearch()}
          {this.renderAreaOverdueTotal()}

          {/* Render Campaign Components */}
          {this.renderCampaignLink()}
          {this.renderAreaCampaignDetails()}
        </div>
      </React.Fragment>
    );
  };

  renderAreaClientLogo() {
    let src = this.props.appState.clientSettings?.LOGO;
    let name = this.props.appState.clientSettings?.NAME;
    if (!src) {
      return <div className="clientDetail areaClientLogo">{name}</div>;
    }

    src = this.props.appState.uploadsUrl + "?uploaduuid=" + src;
    name += " Logo";
    return (
      <div className="clientDetail areaClientLogo">
        <img src={src} alt={name} />
      </div>
    );
  }

  renderAreaHorizontalRule() {
    return <hr className="clientDetail areaHorizontalRule" />;
  }

  renderAreaClientName() {
    if (this.state.clientSettings?.NAME) {
      return <div className="clientDetail areaClientName">{this.state.clientSettings.NAME}</div>;
    } else {
      return "";
    }
  }

  renderAreaClientAddress1() {
    if (this.state.clientSettings?.STREET_ADDRESS) {
      return <div className="clientDetail areaClientAddress1">{this.state.clientSettings.STREET_ADDRESS}</div>;
    } else {
      return "";
    }
  }

  renderAreaClientCityStateZip() {
    if (this.state.clientSettings?.CITY_STATE_ZIP) {
      return <div className="clientDetail areaClientCityStateZip">{this.state.clientSettings.CITY_STATE_ZIP}</div>;
    } else {
      return "";
    }
  }

  renderAreaClientPhone() {
    if (this.state.clientSettings?.PHONE) {
      return <div className="clientDetail areaClientPhone">{this.state.clientSettings.PHONE}</div>;
    } else {
      return "";
    }
  }

  renderAreaClientWeb() {
    if (this.state.clientSettings?.WEBSITE) {
      return <div className="clientDetail areaClientWeb">{this.state.clientSettings.WEBSITE}</div>;
    } else {
      return "";
    }
  }

  renderAreaClientEmail() {
    if (this.state.clientSettings?.EMAIL) {
      return <div className="clientDetail areaClientEmail">{this.state.clientSettings.EMAIL}</div>;
    } else {
      return "";
    }
  }

  renderAreaClientTaxID() {
    if (this.state.clientSettings?.TAX_ID) {
      return (
        <div className="clientDetail areaClientTaxID">
          {this.state.clientSettings?.NAME} Federal Tax ID: {this.state.clientSettings?.TAX_ID}
        </div>
      );
    } else {
      return "";
    }
  }

  renderPageTitle() {
    return <div className="areaPageTitle">{this.props.appState.currentView}</div>;
  }

  renderAreaCustomerHeader() {
    if (this.isBillingDetailView() || Helper.isOrderView(this.props.appState.currentView) || this.props.appState.currentView === Constants.CUSTOMER) {
      let { desktopTitle, mobileTitle } = this.renderScreenTitles();
      let viewCustomer = "";
      const customerName = Helper.getCustomerName(this.state);
      // Create a handler to add a new tag to the customer/product
      let addTagHandler = null;
      const isBillingView = this.isBillingDetailView();
      const showOnBilling = isBillingView && !this.isMaastOnlyCustomer();
      if ((Helper.isTaggedView(this.props.appState.currentView, this.props.filtertype?.tab) || showOnBilling) && !this.state.isNew) {
        if (isBillingView || !Helper.isOrderView(this.props.appState.currentView)) {
          addTagHandler = () => {
            this.setState(prevState => ({
              company: {
                ...prevState.company,
                tags: [...prevState.company.tags, Helper.getBlankTag(Constants.COMPANY, prevState.company.companyuuid, customerName)],
              },
            }));
          };
        } else {
          addTagHandler = () => {
            this.setState(prevState => ({
              order: {
                ...prevState.order,
                company: {
                  ...prevState.order.company,
                  tags: [...prevState.order.company.tags, Helper.getBlankTag(Constants.COMPANY, prevState.order.company.companyuuid, customerName)],
                },
              },
            }));
          };
        }
        const company = this.state.order?.company || this.state.company;
        if (this.props.appState.currentView !== Constants.CUSTOMER) {
          viewCustomer = (
            <span
              data-testid="View Customer"
              className="superScript"
              onClick={() => {
                this.handleViewCompany(company);
              }}
            >
              &nbsp;
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
          );
        }
      }
      // Create the "add tag" icon, if we have a handler for it
      let addTag =
        addTagHandler === null ? (
          ""
        ) : (
          <span className="superScript tagAdd" onClick={addTagHandler} data-testid="Add Customer Tag">
            &nbsp;
            <FontAwesomeIcon icon={faTag} />
          </span>
        );
      if (this.isMaastOnlyCustomer()) {
        addTag = "";
      }

      return (
        <div className="areaContactHeader">
          <div>
            <span className="desktop-inline" data-testid="Customer Header Desktop">
              {desktopTitle}
            </span>
            <span className="mobile" data-testid="Customer Header Mobile">
              {mobileTitle}
            </span>
            {viewCustomer}
            {addTag}
            <CloudIcon downloading={this.state.downloading} />
            <ErrorIcon error={this.state.error} />
            {this.renderAreaTagList()}
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  renderAreaTagList = () => {
    let tags = this.state.order?.company?.tags || this.state.company?.tags || this.state.product?.tags || [];
    if (tags.length === 0) {
      return "";
    }
    return (
      <div className="tagList">
        <TagList
          tags={tags}
          handleDeleteTag={this.handleDeleteTag}
          handleEditTag={this.handleEditTag}
          handleSaveTag={this.handleSaveTag}
          handleChangeTag={this.handleChangeTag}
          handleCancelTag={this.handleCancelTag}
        />
      </div>
    );
  };

  renderAreaOrderTagList = () => {
    let tags = this.state.order?.tags || [];
    if (tags.length === 0) {
      return "";
    }
    return (
      <div className="tagList">
        <TagList
          tags={tags}
          handleDeleteTag={this.handleDeleteOrderTag}
          handleEditTag={this.handleEditOrderTag}
          handleSaveTag={this.handleSaveOrderTag}
          handleChangeTag={this.handleChangeOrderTag}
          handleCancelTag={this.handleCancelOrderTag}
        />
      </div>
    );
  };

  renderAreaProductHeader = () => {
    if (this.props.appState.currentView === Constants.PRODUCT) {
      let productnameheader = <span className="desktop">New Product</span>;
      if (this.state.product?.productname !== null && this.state.product?.productname.trim().length > 0) {
        productnameheader = this.state.product.productname;
      }
      let addTag = this.state.isNew ? (
        ""
      ) : (
        <span
          className="superScript tagAdd"
          data-testid="Add Product Tag"
          onClick={() => {
            this.setState(prevState => ({
              product: {
                ...prevState.product,
                tags: [
                  ...prevState.product.tags,
                  Helper.getBlankTag(Constants.PRODUCT, prevState.product.productuuid, prevState.product.productname),
                ],
              },
            }));
          }}
        >
          &nbsp;
          <FontAwesomeIcon icon={faTag} />
        </span>
      );
      return (
        <div className="areaProductHeader" data-testid="Product Header">
          {productnameheader}
          {addTag}
          <CloudIcon downloading={this.state.downloading} />
          <ErrorIcon error={this.state.error} />
          {this.renderAreaTagList()}
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaSupplierHeader = () => {
    if (this.props.appState.currentView === Constants.SUPPLIER) {
      if (!this.state.isEditingCompanyName) {
        let companyname = this.state.company?.companyname;
        let addTag = (
          <span
            className="superScript tagAdd"
            data-testid="Add Supplier Tag"
            onClick={() => {
              this.setState(prevState => ({
                company: {
                  ...prevState.company,
                  tags: [
                    ...prevState.company.tags,
                    Helper.getBlankTag(Constants.COMPANY, prevState.company.companyuuid, prevState.company.companyname),
                  ],
                },
              }));
            }}
          >
            &nbsp;
            <FontAwesomeIcon icon={faTag} />
          </span>
        );
        return (
          <div className="areaContactHeader">
            <span
              onDoubleClick={this.enableSupplierNameEdit}
              onTouchStart={event => this.handleTouchStart(event)}
              onTouchEnd={event => this.handleTouchEnd(event)}
            >
              {companyname}
            </span>
            {addTag}
            <CloudIcon downloading={this.state.downloading} />
            <ErrorIcon error={this.state.error} />
            {this.renderAreaTagList()}
          </div>
        );
      } else {
        return (
          <div className="areaContactHeader">
            <input
              type="text"
              name="companyname"
              id="companyname"
              placeholder="Supplier name"
              maxLength={50}
              autoComplete="off"
              ref={this.baseDetailNameInput}
              onChange={event => this.handleChange(event, Constants.COMPANY)}
              onFocus={Helper.handleFocus}
              onBlur={event => {
                this.handleBlur(event, Constants.COMPANY, null, this.maybeDisableSupplierNameEdit);
              }}
              value={this.state.company.companyname}
            />
          </div>
        );
      }
    } else {
      return "";
    }
  };

  renderAreaCampaignHeader = () => {
    if (this.props.appState.currentView === Constants.CAMPAIGN) {
      let campaignname = this.state.campaign?.campaignname;
      if (!this.state.isEditingBaseDetailName) {
        return (
          <div className="areaContactHeader">
            <span
              onDoubleClick={this.handleBaseDetailNameEdit}
              onTouchStart={event => this.handleTouchStart(event)}
              onTouchEnd={event => this.handleTouchEnd(event)}
            >
              {campaignname}
            </span>
            <CloudIcon downloading={this.state.downloading} />
            <ErrorIcon error={this.state.error} />
          </div>
        );
      } else {
        return (
          <div className="areaContactHeader">
            <input
              type="text"
              name="campaignname"
              id="campaignname"
              placeholder="Campaign name"
              maxLength={100}
              autoComplete="off"
              ref={this.baseDetailNameInput}
              onChange={event => this.handleChange(event, Constants.CAMPAIGN)}
              onFocus={Helper.handleFocus}
              onBlur={event => {
                this.handleBlur(event, Constants.CAMPAIGN, null, event => {
                  this.maybeDisableBaseDetailNameEdit(event);
                });
              }}
              value={this.state.campaign.campaignname}
            />
          </div>
        );
      }
    } else {
      return "";
    }
  };

  renderAreaReportHeader = () => {
    return "";
  };

  renderAreaCloseButton = inIdPanel => {
    if (!this.state.isNew) {
      // Make icon appear disabled if there is an overlay or waiting flag
      let classNames = "areaCloseButton ";
      if (this.state.downloading) {
        classNames += "save-disabled";
      }
      // If we're not on the Pay view, show the close button in the panel
      // but add the appropriate view class
      if (this.props.appState.currentView !== Constants.PAY) {
        // If we're on another view on the desktop, show the close button in the panel
        // Otherwise, show it outside the panel
        if (inIdPanel) {
          classNames += " desktop ";
        } else {
          classNames += " mobile ";
        }
      } else {
        // Do not show the close button in the panel if we're on the Pay view
        // It will show up in the Pay view's panel
        if (inIdPanel) {
          return "";
        }
      }
      const testid = "Close Button " + (inIdPanel ? "In Panel" : "Outside Panel");
      return (
        <div className={classNames} onClick={this.props.followBreadcrumb} data-testid={testid}>
          <FontAwesomeIcon icon={faCircleMinus} />
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaContactSearch = () => {
    if ((Helper.isOrderView(this.props.appState.currentView) && !this.state.order?.company) || (this.isBillingDetailView() && !this.state.company)) {
      let view = Constants.CUSTOMER_SEARCH;
      let placeholder = "Search Customers";

      if (this.props.appState.currentView === Constants.PURCHASE) {
        view = Constants.SUPPLIER_SEARCH;
        placeholder = "Search Supplier";
      }

      // Only show the "Add Contact" button if a contact is not already selected
      let addContact = "";
      if (!this.state.order?.company) {
        let datatestid = "Add Customer Contact";
        let handler = this.handleAddNewCustomer;
        if (this.props.appState.currentView === Constants.PURCHASE && !this.state.order?.company) {
          datatestid = "Add Supplier Contact";
          handler = this.handleAddNewSupplier;
        }
        addContact = this.renderPlusContainer(handler, datatestid);
      }

      return (
        <div className="areaCustomerSearchContainer" ref={this.contactSearchWrapperRef}>
          <div className="popup">
            <SearchBar
              appState={this.props.appState}
              handleSearchChange={(id, value) => this.handleChange({ target: { id: id, value: value, type: "text" } }, view, null)}
              searchkey={this.state.contactSearchKey}
              handleClearSearch={this.handleClearCustomerSearch}
              searchPlaceholder={placeholder}
              fieldref={this.contactSearchInput}
            />
            {this.renderAreaContactSearchResults()}
          </div>
          {addContact}
          {this.renderWalkinCustomer()}
          {this.renderStockOrderButton()}
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaContactSearchResults = () => {
    if (this.state.contactSearchKey && this.state.contactSearchResults) {
      if (this.state.contactSearchResults.length > 0) {
        return (
          <div className="areaContactSearchResults popuplist">
            <ListItemView
              listitems={this.state.contactSearchResults}
              expandedListItems={null}
              selectedListItems={[]}
              renderItemToColumns={this.renderContactSearchResultsToColumns}
              toggleCollapsed={() => {}}
              selectListItem={this.selectCustomerListItem}
              handleEditItem={this.props.handleEditItem}
              handleTouchStart={this.handleTouchStart}
              handleTouchEnd={this.handleTouchEnd}
            />
          </div>
        );
      } else {
        return "No matches";
      }
    } else if (this.state.contactSearchKey && !this.state.contactSearchResults) {
      return "Searching...";
    } else {
      return "";
    }
  };

  renderAreaProspectCard() {
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS && this.state.prospect) {
      return (
        <div className="areaContactCardContainer">
          <ContactCard
            additionalContactsVisible={false}
            appState={this.props.appState}
            company={this.state.company}
            contact={this.state.prospect}
            handleAddContact={() => {}}
            handleBlur={this.handleBlur}
            handleCaretClick={() => {}}
            handleChange={this.handleChange}
            handleDeleteContact={() => {}}
            handleEditMode={this.handleEditMode}
            handleUnchainContact={() => {}}
            hasAdditionalContacts={false}
            hasNewUnsavedContact={false}
            isNewContact={false}
            isPrimaryContact={true}
            isProtectedCustomer={false}
            setDownloading={this.props.setDownloading}
            showOverlay={this.props.showOverlay}
          />
        </div>
      );
    }
  }

  renderAreaContactCardDeck() {
    let company = null;

    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      return "";
    }
    if (this.state.order?.company) {
      company = this.state.order?.company;
    } else if (this.state.company) {
      company = this.state.company;
    }
    if (company) {
      let additionalControls = "";
      const isInvoiceEligible =
        Helper.isOrderView(this.props.appState.currentView) &&
        !this.isReturn() &&
        this.props.appState.currentView !== Constants.PAY &&
        !this.state.order?.externalid;
      const isBillingEligible = this.props.appState.currentView === Constants.RECURRING && this.state.subscriptions?.length === 0;
      // Show the remove button on all order views except Return Invoice and Pay
      if (isInvoiceEligible || isBillingEligible) {
        additionalControls = (
          <div className="removeCompanyContainer">
            <span onClick={this.handleRemoveCustomer} title="Remove/Replace Customer">
              <CircleXmarkIcon />
            </span>
          </div>
        );
      }
      return (
        <ContactCardDeck
          additionalControls={additionalControls}
          appState={this.props.appState}
          company={company}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          handleEditMode={this.handleEditMode}
          handleDeleteContact={this.handleDeleteContact}
          handleAddContactToCompany={this.handleAddContactToCompany}
          isProtectedCustomer={this.isProtectedCustomer()}
          setDownloading={downloading => {
            this.setState({ downloading: downloading });
          }}
          showOverlay={this.props.showOverlay}
        />
      );
    } else {
      return "";
    }
  }

  renderAreaRepairItems = () => {
    if (
      this.props.appState.currentView === Constants.REPAIR ||
      (this.props.appState.currentView === Constants.INVOICE && this.state.order?.repairitems) ||
      (this.props.appState.currentView === Constants.PAY && this.state.order?.repairitems)
    ) {
      const repairitems = this.state.order?.repairitems.map((item, index) => {
        return (
          <React.Fragment key={index + "-" + item.uuid}>
            <RepairItemCard
              appState={this.props.appState}
              item={item}
              index={index}
              showadd={index === 0}
              // collapse has been turned off by default and will not collapse the repair card at this time.
              collapsed={false}
              handleChange={event => this.handleChange(event, Constants.REPAIR_ITEM, item.uuid, index)}
              handleBlur={event => this.handleBlur(event, Constants.REPAIR_ITEM, item.uuid, null, null, index)}
              handleDelete={event => this.handleDeleteRepairItem(item.uuid, index)}
              handleAdd={this.handleAddRepairItem}
              handleSelectTechnician={this.handleSelectTechnician}
              handleSelectFamily={this.handleSelectFamily}
              handleSelectStatus={this.handleSelectStatus}
              families={this.state.families}
              technicians={this.state.technicians}
            />
            <hr className="printDivider" />
          </React.Fragment>
        );
      });
      return (
        <div className="areaRepairCardDeck">
          <div className="repair-items">{repairitems}</div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaOrderNumber(classes = "") {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      let ordernumberlabel = "";
      let ordertype = this.props.selectedItem?.ordertype;
      let ordersubtype = this.props.selectedItem?.ordersubtype;
      if (this.state.order?.ordernumber) {
        ordernumberlabel = (
          <React.Fragment>
            {ordertype} #{this.state.order?.ordernumber}
          </React.Fragment>
        );
        if (ordertype === Constants.REPAIR) {
          ordernumberlabel = (
            <React.Fragment>
              Service <span className="printRepairLabel">Estimate</span> #{this.state.order?.ordernumber}
            </React.Fragment>
          );
        } else if (ordertype === Constants.PURCHASE) {
          ordernumberlabel = (
            <React.Fragment>
              Purchase <span className="printPurchaseLabel">Order</span> #{this.state.order?.ordernumber}
            </React.Fragment>
          );
        } else if (this.isReturn()) {
          ordernumberlabel = <React.Fragment>Return #{this.state.order?.ordernumber}</React.Fragment>;
        } else if (ordersubtype === Constants.SUBSCRIPTION) {
          ordernumberlabel = <React.Fragment>Subscription Invoice #{this.state.order?.ordernumber}</React.Fragment>;
        }
      }
      let source = " ";
      // Build the source order link if we have a source order
      if (
        (Helper.isOrderView(this.props.appState.currentView) &&
          this.state.order?.sourceordertype &&
          this.state.order?.sourceordernumber &&
          this.state.order?.sourceorderuuid &&
          this.state.order?.sourceorderuuid !== this.state.order?.uuid) ||
        this.props.selectedItem?.hasSourceOrder
      ) {
        let handleViewSourceOrder = () => {};
        let sourceInfo = "";
        if (this.state.order?.sourceordertype === Constants.REPAIR) {
          sourceInfo = "Service Estimate #" + this.state.order?.sourceordernumber;
          handleViewSourceOrder = () => {
            this.props.handleEditItem(Constants.REPAIR, Constants.REPAIRS, {
              orderuuid: this.state.order?.sourceorderuuid,
              ordertype: Constants.REPAIR,
            });
          };
        } else if (this.state.order?.sourceordertype === Constants.PURCHASE) {
          sourceInfo = "Purchase Order #" + this.state.order?.sourceordernumber;
          handleViewSourceOrder = () => {
            this.props.handleEditItem(Constants.PURCHASE, Constants.PURCHASES, {
              orderuuid: this.state.order?.sourceorderuuid,
              ordertype: Constants.PURCHASE,
            });
          };
        } else if (this.state.order?.sourceordertype === Constants.INVOICE) {
          sourceInfo = "Invoice #" + this.state.order?.sourceordernumber;
          handleViewSourceOrder = () => {
            this.props.handleEditItem(Constants.INVOICE, Constants.INVOICES, {
              orderuuid: this.state.order?.sourceorderuuid,
              ordertype: Constants.INVOICE,
            });
          };
        } else if (this.state.order?.sourceordertype === Constants.QUOTE) {
          sourceInfo = "Quote #" + this.state.order?.sourceordernumber;
          handleViewSourceOrder = () => {
            this.props.handleEditItem(Constants.QUOTE, Constants.QUOTES, {
              orderuuid: this.state.order?.sourceorderuuid,
              ordertype: Constants.QUOTE,
            });
          };
        } else if (this.state.order?.sourceordertype === Constants.ORDER) {
          sourceInfo = "Order #" + this.state.order?.sourceordernumber;
          handleViewSourceOrder = () => {
            this.props.handleEditItem(Constants.ORDER, Constants.ORDERS, {
              orderuuid: this.state.order?.sourceorderuuid,
              ordertype: Constants.ORDER,
            });
          };
        }

        source = (
          <span className="tooltip sourceorder">
            <span onClick={handleViewSourceOrder} className="superScript">
              <FontAwesomeIcon icon={faFolderTree} />
            </span>
            <span className="tooltiptext">{sourceInfo}</span>
          </span>
        );
      }
      // Build the subscription link if we have a subscription invoice
      else if (
        this.props.appState.currentView === Constants.INVOICE &&
        this.state.order?.ordersubtype === Constants.SUBSCRIPTION &&
        this.state.order?.subscriptionnumber &&
        this.state.order?.subscriptionuuid
      ) {
        const handleViewSubscription = () => {
          this.props.handleEditItem(Constants.RECURRING, Constants.RECURRINGS, this.state.order?.company);
        };
        const sourceInfo = (
          <span>
            Subscription #{this.state.order?.subscriptionnumber}
            <br />({this.state.order?.subscriptionstatus})
          </span>
        );
        source = (
          <span className="tooltip sourceorder">
            <span onClick={handleViewSubscription} className="superScript">
              <FontAwesomeIcon icon={faFolderTree} />
            </span>
            <span className="tooltiptext">{sourceInfo}</span>
          </span>
        );
      }

      // Create the "add tag" icon, if we have a handler for it
      let addTag = this.state.order?.ordernumber ? (
        <span
          className="superScript tagAdd"
          data-testid="Add Order Tag"
          onClick={() => {
            this.setState(prevState => ({
              order: {
                ...prevState.order,
                tags: [
                  ...prevState.order.tags,
                  Helper.getBlankTag(
                    this.state.order?.ordertype,
                    prevState.order.orderuuid,
                    Helper.getOrderNumber(this.state.order.ordertype, this.state.order.ordersubtype, this.state.order.ordernumber)
                  ),
                ],
              },
            }));
          }}
        >
          <FontAwesomeIcon icon={faTag} />
        </span>
      ) : (
        ""
      );
      return (
        <div className={classes + " creationDetail areaOrderNumber"} data-testid={"Order Number " + classes}>
          {this.renderAreaOrderTagList()}
          {ordernumberlabel} {source} {addTag}
        </div>
      );
    } else {
      return "";
    }
  }

  renderAreaProspectStatus = (classes = "") => {
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      // Get the list of possible statuses
      let statuses = this.renderSelectProspectStatus();
      // Determine if a title should show indicating why the status selection is not available
      let title = "";
      if (!statuses) {
        title = "No status options available";
      }
      // If we're not actively showing the possible statuses, then clear the order status list (so it's not visible)
      if (!this.state.showSelectStatus) {
        statuses = "";
      }

      return (
        <div className={classes + " areaProspectStatus"}>
          <span className="desktop">
            <span className="popup">
              <div title={title} onClick={this.handleMaybeShowSelectStatus}>
                {Helper.renderStatus(this.state.prospect?.prospectstatus)}
              </div>
              {statuses}
            </span>
          </span>
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaOrderStatus = (classes = "") => {
    if (Helper.isOrderView(this.props.appState.currentView) && this.props.appState.currentView !== Constants.PAY) {
      // Get the list of possible statuses
      let orderStatuses = this.renderSelectOrderStatus();
      // If we're not actively showing the possible statuses, then clear the order status list (so it's not visible)
      if (!this.state.showSelectStatus) {
        orderStatuses = "";
      }

      return (
        <div className={classes + " areaOrderStatus"}>
          <span className="desktop">
            <span className="popup">
              <div title="Order Status" onClick={this.handleMaybeShowSelectStatus}>
                {Helper.renderStatus(this.state.order?.orderstatus)}
              </div>
              {orderStatuses}
            </span>
          </span>
        </div>
      );
    } else if (this.props.appState.currentView === Constants.CAMPAIGN) {
      return (
        <div className={classes + " areaOrderStatus"}>
          <span className="desktop">
            <span className="popup">
              <div title="Campaign Status" onClick={this.handleMaybeShowSelectStatus}>
                {Helper.renderStatus(this.state.campaign?.campaignstatus)}
              </div>
            </span>
          </span>
        </div>
      );
    } else {
      return "";
    }
  };

  renderSelectProspectStatus = () => {
    let statuses = this.getPossibleProspectStatuses();
    if (statuses.length === 0) {
      return "";
    }
    const statuslist = statuses.map(status => {
      return (
        <div
          key={status.uuid}
          className="list-item nowrap"
          onClick={event => {
            this.handleSelectProspectStatus(status.uuid);
            this.setState({ showSelectStatus: false });
          }}
        >
          {status.statusname}
        </div>
      );
    });

    return (
      <span className="popuplist" ref={this.prospectStatusRef}>
        {statuslist}
      </span>
    );
  };

  renderSelectOrderStatus = () => {
    let statuses = this.getPossibleOrderStatuses();
    if (statuses.length === 0) {
      return "";
    }
    const statuslist = statuses.map(status => {
      return (
        <div
          key={status.uuid}
          className="list-item nowrap"
          onClick={event => {
            this.handleSelectOrderStatus(status.uuid);
            this.setState({ showSelectStatus: false });
          }}
        >
          {status.statusname}
        </div>
      );
    });

    return (
      <span className="popuplist" ref={this.orderStatusRef} title="List of available statuses">
        {statuslist}
      </span>
    );
  };

  renderAreaProspectAgreement = () => {
    if (
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab === Constants.TAB_PROSPECTS &&
      this.state.prospect?.agreement
    ) {
      return (
        <div className="creationDetail areaProspectAgreement printOnly" data-testid="Prospect Agreement" title="Prospect Agreement Container">
          <div className="headerStyle marginTop1em marginBottomhalfem" title="Prospect Agreement Header">
            Agreement
          </div>
          {Helper.renderTextAsHtml(this.state.prospect.agreement)}
        </div>
      );
    }
  };

  renderAreaProspectAdditionalData = () => {
    if (
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab === Constants.TAB_PROSPECTS &&
      this.state.prospect?.additionaldata
    ) {
      const checkboxes = Helper.getAdditionalDataPromptsByType(this.state.prospect?.campaign?.additionaldataprompt ?? "", "checkbox");
      const dateFields = Helper.getAdditionalDataPromptsByType(this.state.prospect?.campaign?.additionaldataprompt ?? "", "date");
      const timeFields = Helper.getAdditionalDataPromptsByType(this.state.prospect?.campaign?.additionaldataprompt ?? "", "time");
      const fields = Helper.getAdditionalDataPromptFieldNames(this.state.prospect?.campaign?.additionaldataprompt ?? "", false);
      let data = JSON.parse(this.state.prospect.additionaldata);
      let keys = Object.keys(data);
      if (keys.length === 0) {
        data = "No additional data collected";
      } else {
        data = keys.map(key => {
          let label = fields.find(field => Helper.fieldNameToID(field) === key) ?? key;
          let value = data[key];
          if (checkboxes.includes(key)) {
            value = value ? "Yes" : "No";
          }
          if (dateFields.includes(key)) {
            value = Helper.formatDate(value);
          }
          if (timeFields.includes(key)) {
            value = Helper.formatTimeString(value);
          }
          return (
            <React.Fragment key={key}>
              <span className="additionalDataKey">{label}:</span>
              <span className="additionalDataValue" data-testid={label}>
                {value}
              </span>
            </React.Fragment>
          );
        });
      }
      return (
        <div className="areaProspectAdditionalData">
          <div className="span2 headerStyle marginBottomqtrem">Additional Data</div>
          {data}
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaProspectPaymentCard = () => {
    if (
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab === Constants.TAB_PROSPECTS &&
      this.state.prospect?.card_number &&
      this.state.prospect?.exp_date
    ) {
      return (
        <div className="creationDetail areaCampaignCard" data-testid="Campaign Card Number">
          Payment Method: {this.state.prospect.card_number} ({this.state.prospect.exp_date})
        </div>
      );
    }
    return "";
  };

  renderAreaProspectMatch = () => {
    if (
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab === Constants.TAB_PROSPECTS &&
      this.state.prospect?.contact &&
      this.state.prospect?.company
    ) {
      return (
        <div className="creationDetail areaCampaignCard" data-testid="Matched Contact">
          Matched to: {this.state.prospect?.contact?.firstname} {this.state.prospect?.contact?.lastname}
          <span
            data-testid="View Customer"
            className=""
            onClick={() => {
              this.handleViewCompany(this.state.prospect.company);
            }}
          >
            &nbsp;
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </span>
        </div>
      );
    }
    return "";
  };

  renderCampaignName = () => {
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      return (
        <div className="creationDetail areaCampaignName" data-testid="Campaign Name">
          Campaign: {this.state.prospect?.campaign?.campaignname}
        </div>
      );
    }
    return "";
  };

  renderCampaignLink = () => {
    if (this.props.appState.currentView === Constants.CAMPAIGN) {
      const url = Helper.getCampaignLink(false, this.state.campaign.subdomain, this.state.campaign.campaignuuid);
      const icon = this.state.linkCopied ? (
        <FontAwesomeIcon
          icon={faCheck}
          onClick={() => {
            this.setState({ linkCopied: true }, () => {
              setTimeout(() => {
                this.setState({ linkCopied: false });
              }, 1500);
            });
            Helper.copyToClipboard(url);
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faCopy}
          onClick={() => {
            this.setState({ linkCopied: true }, () => {
              setTimeout(() => {
                this.setState({ linkCopied: false });
              }, 1500);
            });
            Helper.copyToClipboard(url);
          }}
        />
      );
      return (
        <div className="areaCampaignLink">
          <div className="campaignLink noPrint" title="Campaign Link" data-url={url}>
            Campaign Link:&nbsp;&nbsp;<span className="hoverPointer">{icon}</span>
          </div>

          <div
            id="campaignQRCode"
            title="Click to download QR Code"
            onClick={() => {
              this.handleDownloadQRCode("campaignQRCode");
            }}
          >
            <QRCode value={Helper.getCampaignLink(false, this.state.campaign.subdomain, this.state.campaign.campaignuuid)} size={128} />
          </div>
        </div>
      );
    }
  };

  renderAreaProspectAgreementDate = () => {
    if (
      this.props.appState.currentView === Constants.CUSTOMER &&
      this.props.filtertype?.tab === Constants.TAB_PROSPECTS &&
      this.state.prospect?.agreedatetime
    ) {
      let agreementDate = Helper.formatDate(this.state.prospect?.agreedatetime, false, true);
      return (
        <div className="creationDetail areaAgreementDate" data-testid={"Agreement Date"}>
          Agreement Date: {agreementDate}
        </div>
      );
    }
    return "";
  };

  renderAreaCreationDate(classes = "") {
    let creationDate = "";
    if (this.isBillingDetailView()) {
      return "";
    }
    // Order view: ORDER, QUOTE, REPAIR, INVOICE, PURCHASE, PAY
    if (this.state.showEditOrderDate && Helper.isOrderView(this.props.appState.currentView)) {
      return (
        <div className="creationDetail areaCreationDate" ref={this.creationDateRef}>
          <input
            type="datetime-local"
            id="creationdatetime"
            onChange={this.handleChangeOrderDate}
            value={this.getDetailObjectRef()?.creationdatetime}
          />
          <FontAwesomeIcon
            icon={faCircleMinus}
            onClick={() => {
              this.setState({ showEditOrderDate: false });
            }}
          />
        </div>
      );
    } else {
      creationDate = Helper.formatDate(this.getDetailObjectRef()?.creationdatetime, false, true);
      // Do not show the time on reports or campaigns
      if (this.props.appState.currentView === Constants.REPORT || this.props.appState.currentView === Constants.CAMPAIGN) {
        creationDate = creationDate.split(",")[0];
      }
      return (
        <div
          className={classes + " creationDetail areaCreationDate"}
          onClick={this.handleEditOrderDate}
          data-testid={("Creation Date " + classes).trim()}
        >
          Creation Date: {creationDate}
        </div>
      );
    }
  }

  renderAreaUpdateDate(classes = "") {
    let updatedDate = "";
    if (this.props.appState.currentView === Constants.CAMPAIGN) {
      updatedDate = Helper.formatDate(this.state.campaign?.lastupdated, false, true);
      updatedDate = updatedDate.split(",")[0];
      return (
        <div className={classes + " creationDetail areaCreationDate"} title={"Date Last Updated"}>
          Maintenance Date: {updatedDate}
        </div>
      );
    } else {
      return "";
    }
  }

  renderAreaDueDate(classes = "") {
    if (this.props.appState.currentView !== Constants.REPAIR) {
      return "";
    }

    if (this.state.showEditDueDate && Helper.isOrderView(this.props.appState.currentView)) {
      return (
        <div className="creationDetail areaDueDate" ref={this.dueDateRef}>
          <input
            type="datetime-local"
            id="duedatetime"
            className="dueDatetime"
            onChange={this.handleChangeDueDate}
            value={this.getDetailObjectRef()?.duedatetime}
          />
          <span className="leftMarginSmall">
            <FontAwesomeIcon
              icon={faCircleMinus}
              onClick={() => {
                this.setState({ showEditDueDate: false });
              }}
            />
          </span>
          <span className="leftMarginSmall">
            <FontAwesomeIcon
              icon={faTrashCan}
              onClick={() => {
                this.setState({ showEditDueDate: false }, () => {
                  this.handleChangeDueDate({ target: { id: "duedatetime", value: "" } });
                });
              }}
            />
          </span>
        </div>
      );
    } else {
      let dueDate = Helper.formatDate(this.getDetailObjectRef()?.duedatetime, false, true);
      let dueDateElement;
      let emptyDueDateClass = "";
      if (dueDate === "") {
        emptyDueDateClass = "empty";
        dueDateElement = <span className="highlight noPrint "> Due Date: Click to set due date</span>;
      } else {
        dueDateElement = <span> Due Date: {dueDate}</span>;
      }

      return (
        <div className={classes + " creationDetail areaDueDate " + emptyDueDateClass} onClick={this.handleEditDueDate}>
          {dueDateElement}
        </div>
      );
    }
  }

  renderAreaSalesperson(classes = "") {
    if (this.isBillingDetailView()) {
      return "";
    }
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab !== Constants.TAB_CUSTOMER) {
      return "";
    }
    let salesperson = this.props.appState.currentView !== Constants.CAMPAIGN ? "Salesperson" : "Created By";
    let externalID = "";
    if (this.state.order?.externalid) {
      externalID = `(${this.state.order?.externalid})`;
    }
    return (
      <div className={classes + " creationDetail areaSalesperson"} data-testid={"Salesperson " + classes}>
        {salesperson}: &nbsp;
        {this.getDetailObjectRef()?.salesperson} {externalID}
      </div>
    );
  }

  renderAreaStoreCredit() {
    if (this.props.appState.currentView === Constants.CUSTOMER && this.state.company?.storecredit) {
      return (
        <div className="areaStoreCredit" data-testid="Customer Store Credit Balance">
          Store Credit: &nbsp;
          {numeral(this.state.company.storecredit).format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
      );
    } else {
      return "";
    }
  }

  renderAreaPONumberInput(classes = "") {
    if (
      Helper.isOrderView(this.props.appState.currentView) &&
      this.props.appState.currentView !== Constants.PURCHASE &&
      this.props.appState.currentView !== Constants.PAY &&
      !this.state.order?.externalid // Not an order retrieved from an external system
    ) {
      return (
        <div className={classes + " areaPONumber areaInputItem"}>
          <input
            type="text"
            name="ponumber"
            id="ponumber"
            data-testid="PO Number"
            placeholder="Customer PO Number"
            maxLength={32}
            className="ponumber right-aligned"
            autoComplete="off"
            onChange={event => this.handleChange(event, Constants.ORDER)}
            onFocus={Helper.handleFocus}
            onBlur={event => this.handleBlur(event, Constants.ORDER, this.state.order?.orderuuid)}
            value={this.state.order?.ponumber}
          />
        </div>
      );
    } else {
      return "";
    }
  }

  renderAreaPONumberLabel(classes = "") {
    if (
      Helper.isOrderView(this.props.appState.currentView) &&
      this.props.appState.currentView !== Constants.PURCHASE &&
      this.state.order?.ponumber &&
      !this.state.order?.externalid // Not an order retrieved from an external system
    ) {
      return (
        <div className={classes + " areaPONumberLabel"}>
          <label htmlFor="ponumber" className="poNumberLabel">
            Customer PO Number: &nbsp;
          </label>
          <span>{this.state.order?.ponumber}</span>
        </div>
      );
    } else {
      return "";
    }
  }

  renderAreaTrackingNumberInput(classes = "") {
    // Include Purchases and orders retrieved from an external system
    if (this.props.appState.currentView === Constants.PURCHASE || this.state.order?.externalid) {
      return (
        <div className={classes + " areaTrackingNumber areaInputItem trackingnbrcontainer"}>
          <label htmlFor="trackingnumber" className="trackingnbrlabel">
            Tracking number(s) &nbsp;{" "}
            <span className="trackShipment" onClick={this.handleTrackShipment}>
              <FontAwesomeIcon icon={faTruck} />
            </span>
          </label>
          <TextareaAutosize
            className="trackingnumber right-aligned"
            name="trackingnumber"
            id="trackingnumber"
            title="Tracking number(s)"
            rows="3"
            autoComplete="off"
            maxLength={500}
            onChange={event => this.handleChange(event, Constants.ORDER)}
            onFocus={Helper.handleFocus}
            onBlur={event => this.handleBlur(event, Constants.ORDER, this.state.order?.orderuuid)}
            value={this.state.order?.trackingnumber}
          />
        </div>
      );
    } else {
      return "";
    }
  }

  renderAreaTrackingNumberLabel(classes = "") {
    // Include Purchases and orders retrieved from an external system, if they have a tracking number
    if ((this.props.appState.currentView === Constants.PURCHASE || this.state.order?.externalid) && this.state.order?.trackingnumber) {
      return (
        <div className={classes + " areaTrackingNumberLabel"}>
          <label htmlFor="trackingnumber" className="trackingNumberLabel">
            Tracking Number: &nbsp;
          </label>
          <span>{this.state.order?.trackingnumber}</span>
        </div>
      );
    } else {
      return "";
    }
  }

  // Pass True for the embedded flag to render this area outside the "normal" location
  renderAreaManagerFields = embedded => {
    if (this.props.appState.currentView === Constants.PRODUCT || embedded) {
      return (
        <div className="areaProductManagerFields">
          <div className="productManagerFieldsWrapper areaInputItem">
            <div className="productManagerItem">
              <label htmlFor="commission" className="product-header">
                Commission
              </label>
              <input
                type="text"
                name="commission"
                id="commission"
                data-testid="Commission"
                readOnly={!Helper.authorize(Constants.ACTION_EDIT_COMMISSION, this.props.appState.usertype)}
                placeholder='ex. "10%" or "Standard"'
                autoComplete="off"
                maxLength={255}
                onFocus={Helper.handleFocus}
                onChange={event => {
                  this.handleChangeProduct(event);
                }}
                onBlur={event => this.handleBlur(event, Constants.PRODUCT, this.state.product?.productuuid)}
                value={this.state.product?.commission ?? ""}
              />
            </div>
            <div className="productManagerItem">
              <label htmlFor="maxdiscount" className="product-header">
                Max Discount
              </label>
              <input
                type="text"
                name="maxdiscount"
                id="maxdiscount"
                data-testid="Max Discount"
                placeholder='ex. "10%"'
                readOnly={!Helper.authorize(Constants.ACTION_EDIT_COMMISSION, this.props.appState.usertype)}
                autoComplete="off"
                maxLength={4}
                onFocus={Helper.handleFocus}
                onChange={this.handleChangeProduct}
                onBlur={event => this.handleBlur(event, Constants.PRODUCT, this.state.product?.productuuid, null, "percent")}
                value={this.state.product.maxdiscount}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaSwitches = () => {
    if (this.props.appState.currentView === Constants.SUPPLIER) {
      return this.renderAreaSwitchesSupplier();
    } else if (this.props.appState.currentView === Constants.PRODUCT) {
      return this.renderAreaSwitchesProduct();
    } else if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_CUSTOMER) {
      return this.renderAreaSwitchesCustomer();
    }
  };

  renderAreaSwitchesProduct = () => {
    // Only show the publish to Cartloom switch if the feature is enabled
    let tooltipPID = this.state.product?.externalid ? (
      <Tooltip explanationClass="cartloomPID" explanation={true} text={`Cartloom Product ID (PID) is ${this.state.product?.externalid}`} />
    ) : (
      ""
    );

    let publishToCartloom = "";
    if (this.props.appState.features.includes(Constants.FEATURE_CARTLOOM) && this.props.appState?.thirdparty?.cartloomsellerid) {
      const cartloomDisabled =
        !this.state.product?.storesku ||
        !this.state.product?.photos ||
        this.state.product?.photos?.length === 0 ||
        !this.state.product?.sellprice ||
        !this.state.product?.productname ||
        !this.state.product?.longdescription;
      if (cartloomDisabled) {
        tooltipPID = (
          <Tooltip
            explanationClass="cartloomPID"
            explanation={true}
            text="Product name, description, store SKU, sell price, and at least one photo are required."
          />
        );
      }
      let cartloomLabel = <span>Publish Product to Cartloom? {tooltipPID}</span>;
      publishToCartloom = (
        <Switch
          label={cartloomLabel}
          fieldname="publishToCartloom"
          fielddisabled={cartloomDisabled}
          // disabledClass={cartloomDisabled ? "save-disabled" : ""}
          handleChange={this.handlePublishCartloom}
          checked={this.state.product?.externalid}
          elementid="switch-publishToCartloom"
        />
      );
    }

    return (
      <div className="areaSwitchesProduct">
        <div className="product-toggles">
          <Switch
            label="Count in inventory?"
            fieldname="affectinventory"
            handleChange={event => {
              this.handleChangeProduct(event, () => {
                // If the affects inventory flag is set to false,
                // then ask to zero the inventory count
                if (!this.state.product?.affectinventory) {
                  this.maybeZeroInventoryCount();
                }
              });
            }}
            checked={this.state.product?.affectinventory}
            elementid="switch-affectinventory"
          />
          <Switch
            label="Item subject to sales tax?"
            fieldname="taxable"
            handleChange={this.handleChangeProduct}
            checked={this.state.product?.taxable}
            elementid="switch-taxable"
          />
          <Switch
            label="Is this a current product?"
            fieldname={Constants.FILTER_ACTIVE}
            handleChange={this.handleChangeProduct}
            checked={this.state.product?.active}
            elementid="switch-active"
          />
          {publishToCartloom}
        </div>
      </div>
    );
  };

  renderAreaSwitchesSupplier = () => {
    return (
      <div className="areaSwitchesSupplier">
        <div className="contact-toggles active">
          <Switch
            label="Is this an active supplier?"
            fieldname={Constants.FILTER_ACTIVE}
            handleChange={event => this.handleChange(event, Constants.COMPANY)}
            checked={this.state.company?.active}
            elementid="switch-active"
          />
        </div>
      </div>
    );
  };

  renderAreaSwitchesCustomer = () => {
    return (
      <div className="areaSwitchesCustomer">
        <div className="contact-toggles ">
          <Switch
            label="Customer subject to sales tax?"
            fieldname="taxable"
            handleChange={event => this.handleChange(event, Constants.COMPANY)}
            checked={this.state.company?.taxable}
            elementid="switch-taxable"
          />
          {/* <Switch
            label="Customer on credit hold?"
            fieldname="creditHold"
            handleChange={event => this.handleChange(event, Constants.COMPANY)}
            checked={this.state.company.creditHold}
            elementid="switch-creditHold"
          /> */}
          <div className="discountWrapper">
            <span>Customer Discount:</span>
            <input
              type="text"
              inputMode="numeric"
              name="discount"
              id="discount"
              data-testid="Customer Discount"
              placeholder="10%"
              autoComplete="off"
              maxLength={4}
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.handleChange(event, Constants.COMPANY, this.state.company?.companyuuid);
              }}
              onBlur={event => this.handleBlur(event, Constants.COMPANY, null, null, "percent")}
              value={this.state.company?.discount ?? ""}
            />
          </div>
        </div>
      </div>
    );
  };

  renderAreaSwitchesCampaign = () => {
    if (this.props.appState.currentView === Constants.CAMPAIGN && this.state.campaign) {
      let requireInfo = "First Name, Last Name, & Email Required";
      let requireCard = this.state.campaign?.requirecard ? "Card Required" : "Card Not Required";
      let requireAddress = this.state.campaign?.requireaddress ? "Address Required" : "Address Not Required";
      let requiremobilephone = this.state.campaign?.requiremobilephone ? "Mobile Phone Required" : "Mobile Phone Not Required";
      let requireotherphone = this.state.campaign?.requireotherphone ? "Address Required" : "Address Not Required";
      let requirenameinvitation = this.state.campaign?.requirenameinvitation ? "Name Required" : "Name Not Required";
      let requiremobilephoneinvitation = this.state.campaign?.requiremobilephoneinvitation ? "Mobile Phone Required" : "Mobile Phone Not Required";

      let tooltipInvite = (
        <Tooltip
          explanation={true}
          text="When customers click on your campaign link, they will first encounter the invitation page. Here, they must enter their email address. You can also choose to require their name and/or phone number."
        />
      );
      let tooltipForm = (
        <Tooltip
          explanation={true}
          text="When customers click the link in their email, they will be directed to the form page. Here, they must provide the required information (selected below) and complete any additional fields defined in the campaign questions section."
        />
      );
      const emailTooltip = <Tooltip explanationClass="" explanation={true} text="Email is always required on the invitation page." />;
      const emailText = <span>Email {emailTooltip}</span>;
      const nameTooltip = <Tooltip explanationClass="" explanation={true} text="Name is always required on the form page." />;
      const nameText = <span>Name {nameTooltip}</span>;
      return (
        <div className="areaSwitchesCampaign">
          <div>
            <div className="printOnly bold">Additional Elements</div>
            <div className="white marginBottomhalfem">Require on Invitation Page? {tooltipInvite}</div>
            <div className="contact-toggles">
              <Switch
                label={emailText}
                fieldname="requireemail"
                fielddisabled={true}
                switchClass={"save-disabled"}
                handleChange={() => {}}
                checked={true}
                elementid="switch-requireEmail"
              />
            </div>
            <div className="printOnly">Email is always required</div>
            <div className="contact-toggles">
              <Switch
                label="Name"
                title="Require First and Last Name on invitation page"
                fieldname="requirenameinvitation"
                handleChange={event => this.handleChange(event, Constants.CAMPAIGN)}
                checked={this.state.campaign?.requirenameinvitation}
                elementid="switch-requirenameinvitation"
              />
            </div>
            <div className="printOnly">{requirenameinvitation}</div>
            <div className="contact-toggles">
              <Switch
                label="Mobile Phone"
                title="Require Mobile Phone on invitation page"
                fieldname="requiremobilephoneinvitation"
                handleChange={event => this.handleChange(event, Constants.CAMPAIGN)}
                checked={this.state.campaign?.requiremobilephoneinvitation}
                elementid="switch-requiremobilephoneinvitation"
              />
            </div>
            <div className="printOnly">{requiremobilephoneinvitation}</div>
          </div>
          <div>
            <div className="white marginBottomhalfem">Require on Form Page? {tooltipForm}</div>
            <div className="contact-toggles">
              <Switch
                label={nameText}
                fieldname="requireinfo"
                fielddisabled={true}
                switchClass={"save-disabled"}
                handleChange={() => {}}
                checked={true}
                elementid="switch-requireInfo"
              />
            </div>
            <div className="printOnly">{requireInfo}</div>
            <div className="contact-toggles">
              <Switch
                label="Address"
                title="Require Address on Form Page"
                fieldname="requireaddress"
                handleChange={event => this.handleChange(event, Constants.CAMPAIGN)}
                checked={this.state.campaign?.requireaddress}
                elementid="switch-requireAddress"
              />
            </div>
            <div className="printOnly">{requireAddress}</div>
            <div className="contact-toggles">
              <Switch
                label="Mobile Phone"
                title="Require Mobile Phone on Form Page"
                fieldname="requiremobilephone"
                handleChange={event => this.handleChange(event, Constants.CAMPAIGN)}
                checked={this.state.campaign?.requiremobilephone}
                elementid="switch-requireMobilePhone"
              />
            </div>
            <div className="printOnly">{requiremobilephone}</div>

            <div className="contact-toggles">
              <Switch
                label="Other Phone"
                title="Require Other Phone on Form Page"
                fieldname="requireotherphone"
                handleChange={event => this.handleChange(event, Constants.CAMPAIGN)}
                checked={this.state.campaign?.requireotherphone}
                elementid="switch-requireOtherPhone"
              />
            </div>
            <div className="printOnly">{requireotherphone}</div>
            <div className="contact-toggles">
              <Switch
                label="Credit Card"
                title="Require Credit Card on Form Page"
                fieldname="requirecard"
                handleChange={event => this.handleChange(event, Constants.CAMPAIGN)}
                checked={this.state.campaign?.requirecard}
                elementid="switch-requireCard"
              />
            </div>
            <div className="printOnly">{requireCard}</div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaProductSearch = () => {
    if (
      Helper.isOrderView(this.props.appState.currentView) &&
      this.props.appState.currentView !== Constants.PAY &&
      !Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, this.state.order?.orderstatus) &&
      !this.isReturn() &&
      !this.state.order?.externalid &&
      !this.state.product
    ) {
      return (
        <div className="areaProductSearchContainer" ref={this.productSearchWrapperRef}>
          <div className="popup">
            <SearchBar
              appState={this.props.appState}
              handleSearchChange={(id, value) =>
                this.handleChange({ target: { id: id, value: value, type: "text" } }, Constants.PRODUCT_SEARCH, null)
              }
              searchkey={this.state.productSearchKey}
              handleClearSearch={this.handleClearProductSearch}
              searchPlaceholder={"Search Products"}
              fieldref={this.productSearchInput}
            />
            {this.renderProductSearchResults()}
          </div>
          {this.renderPlusContainer(this.handleAddNewProduct, "Add New Product")}
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaProductList = () => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      return <div className="areaProductList">{this.renderProductList()}</div>;
    } else {
      return "";
    }
  };

  renderAreaProductListNarrow = () => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      return <div className="areaProductListNarrow">{this.renderProductListNarrow()}</div>;
    } else {
      return "";
    }
  };

  renderAreaProductCard = () => {
    if (this.props.appState.currentView === Constants.PRODUCT) {
      return (
        <div className="areaProductCard">
          <ProductCard
            product={this.state.product}
            handleChange={this.handleChangeProduct}
            handleBlur={this.handleBlur}
            companyNameInput={this.companyNameInput}
            skuInput={this.skuInput}
            handleBlurCompany={this.handleBlurSupplier}
            handleClickAddSupplier={this.handleAddSupplier}
            handleClickDeleteSupplier={this.handleDeleteSupplier}
            handleChangeBridge={this.handleChangeBridge}
            handleBlurBridge={this.handleBlurBridge}
            handleBlurCompanyBridge={this.handleBlurCompanyBridge}
            handleClickDeleteSupplierBridge={this.handleDeleteSupplierBridge}
            productSuggestions={this.state.productSuggestions}
          />
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaNewProduct = () => {
    if (Helper.isOrderView(this.props.appState.currentView) && this.state.product) {
      let saveButtonClass = "action-button green-button";
      if (!this.isReadyToSubmitNewProduct()) {
        saveButtonClass += " save-disabled";
      }
      return (
        <div className="areaAddProductContainer">
          <ProductCard
            embedded={true}
            product={this.state.product}
            handleChange={event => this.handleChange(event, Constants.PRODUCT, null)}
            handleBlur={event => this.handleBlur(event, Constants.PRODUCT, null)}
            companyNameInput={this.companyNameInput}
            skuInput={this.skuInput}
            handleBlurCompany={event => this.handleBlurSupplier(event)}
            handleClickAddSupplier={this.handleAddSupplier}
            handleClickDeleteSupplier={this.handleDeleteSupplier}
            handleChangeBridge={null}
            handleBlurBridge={null}
            handleBlurCompanyBridge={null}
            handleClickDeleteSupplierBridge={null}
            handleCancel={() => {
              this.setState({ product: null });
            }}
            handleSave={this.saveAddProduct}
            saveButtonClass={saveButtonClass}
            productSuggestions={this.state.productSuggestions}
          />
          <div>
            {this.renderAreaSwitchesProduct()}
            {this.renderAreaManagerFields(true)}
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderProductElements = () => {
    if (this.props.appState.currentView === Constants.PRODUCT) {
      return (
        <React.Fragment>
          <div className="productElement areaProductName">{this.state.product?.productname}</div>

          <div className="productLabel areaProductDescLabel">Long Product Description:</div>
          <div className="productElement areaProductDesc">{this.state.product?.longdescription}</div>

          <div className="productLabel areaProductSellPriceLabel">Sell Price: </div>
          <div className="productElement areaProductSellPrice">{numeral(this.state.product?.sellprice).format(Constants.CURRENCY_WITH_SYMBOL)}</div>

          <div className="productLabel areaProductInventoryLabel">Inventory Total: </div>
          <div className="productElement areaProductInventory">{this.state.product?.inventory}</div>

          <div className="productLabel areaProductStoreSkuLabel">Store SKU: </div>
          <div className="productElement areaProductStoreSku">{this.state.product?.storesku}</div>

          <div className="productLabel areaProductUPCLabel">{this.state.product?.upc ? "" : "System Assigned"} UPC:</div>
          <div className="productElement areaProductUPC">{this.state.product?.upc || this.state.product?.altupc}</div>

          <div className="productLabel areaProductEANLabel">EAN: </div>
          <div className="productElement areaProductEAN">{this.state.product?.ean}</div>

          <div className="productLabel areaProductFamilyLabel">Category: </div>
          <div className="productElement areaProductFamily">{this.state.product?.family}</div>

          <div className="productLabel areaProductMakeLabel">Make: </div>
          <div className="productElement areaProductMake">{this.state.product?.make}</div>

          <div className="productLabel areaProductModelLabel">Model: </div>
          <div className="productElement areaProductModel">{this.state.product?.model}</div>

          <div className="productLabel areaProductSupplierNameLabel">Supplier: </div>
          <div className="productElement areaProductSupplierName">{this.state.product?.companyname}</div>

          <div className="productLabel areaProductSupplierSkuLabel">SKU: </div>
          <div className="productElement areaProductSupplierSku">{this.state.product?.sku}</div>

          <div className="productLabel areaProductSupplierCostLabel">Cost: </div>
          <div className="productElement areaProductSupplierCost">{numeral(this.state.product?.cost).format(Constants.CURRENCY_WITH_SYMBOL)}</div>

          <div className="productLabel areaProductMaxDiscountLabel">Max Discount: </div>
          <div className="productElement areaProductMaxDiscount">{Helper.formatPercent(numeral(this.state.product?.maxdiscount).value())}</div>

          <div className="productLabel areaProductCommissionLabel">Commission: </div>
          <div className="productElement areaProductCommission">{this.state.product?.commission}</div>

          {this.renderareaProductUPCBarcode()}
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  renderReportBody = () => {
    return "";
  };

  renderAreaFolders = () => {
    // Do not show folders for:
    //   Protected customers on Customer screen
    //   New (unsaved) customers
    //   Report or Campaign screens
    if (
      (this.isProtectedCustomer() && this.props.appState?.currentView === Constants.CUSTOMER) ||
      this.state.isNew ||
      this.state.loading ||
      // TODO: Refactor to use "FOLDER_VIEWS" list in Constants
      [Constants.REPORT, Constants.CAMPAIGN].includes(this.props.appState?.currentView)
    ) {
      return "";
    }
    let refuuid = null;
    let companyuuid = null;
    if (this.props.appState.currentView === Constants.SUPPLIER || this.isBillingDetailView()) {
      refuuid = this.state.company?.companyuuid;
    } else if (this.props.appState.currentView === Constants.CUSTOMER) {
      if (this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
        refuuid = this.state.prospect?.marketingprospectuuid;
      } else {
        refuuid = this.state.company?.companyuuid;
      }
    } else if (this.props.appState.currentView === Constants.PRODUCT) {
      refuuid = this.state.product?.productuuid;
    } else if (Helper.isOrderView(this.props.appState.currentView) || this.props.appState.currentView === Constants.PAY) {
      refuuid = this.state.order?.orderuuid;
      companyuuid = this.state.order?.company?.companyuuid;
    }
    return (
      <Folders
        appState={this.props.appState}
        clearSubscriptions={this.clearSubscriptions}
        company={this.state.company}
        companyuuid={companyuuid}
        deletePhoto={this.deletePhoto}
        editingPayments={this.state.editingPayments}
        filtertype={this.props.filtertype}
        getCustomerFromVault={this.getCustomerFromVault}
        getEmail={this.props.getEmail}
        getListingCategories={this.getListingCategories}
        getListingConditions={this.getListingConditions}
        getMaastInvoices={this.getMaastInvoices}
        getReverbListing={this.getReverbListing}
        getShippingProfiles={this.getShippingProfiles}
        getSMSContacts={this.getSMSContacts}
        getSubscriptions={this.getSubscriptions}
        getText={this.props.getText}
        handleAddPaymentMethod={this.handleAddPaymentMethod}
        handleAfterAddPhoto={this.handleAfterAddPhoto}
        handleAfterAddPhotoBinary={this.handleAfterAddPhotoBinary}
        handleBlur={this.handleBlur}
        handleBlurCustomField={this.handleBlurCustomField}
        handleBlurPrintedNotes={event => {
          this.handleBlur(event, Constants.ORDER, this.state.order?.orderuuid);
        }}
        handleCancelMaastInvoice={this.handleCancelMaastInvoice}
        handleChange={this.handleChange}
        handleChangeCustomField={this.handleChangeCustomField}
        handleChangePrintedNotes={this.handleChangeOrder}
        handleChangeReverbAttribute={this.handleChangeReverbAttribute}
        handleDeletePaymentRequestLink={this.handleDeletePaymentRequestLink}
        handleDeleteStoredPayment={this.handleDeleteStoredPayment}
        handleEditInvoicePayments={this.handleEditInvoicePayments}
        handleEditItem={this.props.handleEditItem}
        handleEditStoredPayment={this.handleEditStoredPayment}
        handleExpandSubscription={this.handleExpandSubscription}
        handleEmail={this.handleEmail}
        handleEmailMessagePrompt={this.handleEmailMessagePrompt}
        handleMarkInvoicesPaid={this.handleMarkInvoicesPaid}
        handleOpenEmailClient={this.handleOpenEmailClient}
        handlePayInvoices={this.handlePayInvoices}
        handleRequestPaymentMethod={this.handleRequestPaymentMethod}
        handleRetrieveResult={this.handleRetrieveResult}
        handleSelectInvoice={this.handleSelectInvoice}
        handleSendInvoices={this.handleSendInvoices}
        handleTrackShipment={this.handleTrackShipment}
        handleViewCampaign={this.handleViewCampaign}
        handleViewProspect={this.handleViewProspect}
        hideInactiveSubscriptions={this.state.hideInactiveSubscriptions ?? false}
        hideOverlay={this.props.hideOverlay}
        isMaastOnlyCustomer={this.isMaastOnlyCustomer}
        isProtectedCustomer={this.isProtectedCustomer()}
        listingCategories={this.state.listingCategories}
        listingConditions={this.state.listingConditions}
        maastCards={this.state.maastCustomer?.billing_cards ?? []}
        maastCustomer={this.state.maastCustomer}
        maastInvoices={this.state.maastInvoices ?? []}
        maastInvoicesPagination={this.state.maastInvoicesPagination}
        maastTransactions={this.state.maastTransactions ?? []}
        maybePublishOrPutToReverb={this.maybePublishOrPutToReverb}
        maybeTagCustomer={this.maybeTagCustomer}
        order={this.state.order ?? null}
        photos={this.state.product?.photos ?? []}
        prospect={this.state.prospect}
        postPhoto={this.postPhoto}
        postPhotoBinary={this.props.postPhotoBinary}
        postRefundTransaction={this.postRefundTransaction}
        printedNotes={this.state.order?.notes ?? ""}
        product={this.state.product ?? null}
        putCompany={this.putCompany}
        putPhoto={this.putPhoto}
        refuuid={refuuid}
        renderCustomFieldsPrint={this.renderCustomFieldsPrint}
        selectedInvoices={this.state.selectedInvoices}
        setRefreshFoldersCallback={this.setRefreshFoldersCallback}
        shippingProfiles={this.state.shippingProfiles}
        showOverlay={this.props.showOverlay}
        subscriptions={this.state.subscriptions}
        updateMarketingProspect={this.updateMarketingProspect}
      />
    );
  };

  renderCustomFieldsPrint = () => {
    return "";
  };

  renderAreaPrintedNotes = () => {
    if (Helper.isOrderView(this.props.appState.currentView) && this.state.order?.notes) {
      return (
        <div className="areaPrintedNotes ">
          <span className="noteLabel">Note:</span> {Helper.ln2br(this.state.order?.notes)}
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaBillingPlanSearch = () => {
    const editingSub = (this.state.subscriptions?.find(sub => sub.editMode) ?? null) !== null;
    // Only ONE new subscription can be added/edited at a time
    if (this.isBillingDetailView() && !editingSub) {
      const placeholder = this.props.appState.currentView === Constants.BILLING ? "Search billing plans" : "Search subscription templates";
      return (
        <div className="areaBillingPlanSearchContainer" ref={this.billingPlanSearchWrapperRef}>
          <div className="popup">
            <SearchBar
              appState={this.props.appState}
              handleSearchChange={this.handleChangeBillingPlanSearch}
              searchkey={this.state.billingPlanSearchKey}
              handleClearSearch={this.handleClearBillingPlanSearch}
              searchPlaceholder={placeholder}
              fieldref={this.billingPlanSearchRef}
            />
            {this.renderBillingPlanSearchResults()}
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaSubscribe = () => {
    if (
      !this.isProtectedCustomer() &&
      this.props.appState.maast.merchant_id &&
      Helper.isBillingFeatureEnabled(this.props.appState.features) &&
      !this.state.isNew &&
      ((this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab !== Constants.TAB_PROSPECTS) ||
        (this.isBillingDetailView() && this.props.filtertype?.tab === Constants.TAB_PLANS)) &&
      Helper.authorize(Constants.ACTION_VIEW_BILLING, this.props.appState.usertype)
    ) {
      const classnames = "action-button blue-button";
      return (
        <div className="areaSubscribeContainer action-buttons">
          <span className="action-button-cell subscribe">
            <span data-testid="Customer Subscribe" onClick={this.handleSubscribe} className={classnames}>
              <BillingsIcon /> Subscribe
            </span>
          </span>
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaButtonControls = () => {
    // TODO: Added Order Object, refactor order attributes.
    if (this.state.loading) {
      return "";
    }
    if (this.props.appState.currentView !== Constants.PAY) {
      return (
        <div className="areaButtonControlsContainer">
          <ButtonControls
            appState={this.props.appState}
            campaign={this.state.campaign}
            datatestidcancel={this.props.appState.currentView + " Cancel"}
            datatestidsave={this.props.appState.currentView + " Save"}
            enableActionButtons={this.state.enableActionButtons}
            filtertype={this.props.filtertype}
            followBreadcrumb={this.props.followBreadcrumb}
            handleArchive={this.handleArchive}
            handleCreateGenericOrder={this.handleCreateGenericOrder}
            handleDelete={this.handleDelete}
            handleDeleteBillingPlan={this.handleDeleteBillingPlan}
            handleDraft={this.handleDraft}
            handleEmail={this.handleEmail}
            handleEmailMessagePrompt={this.handleEmailMessagePrompt}
            handleMarkAsPaid={this.handleMarkAsPaid}
            handlePay={this.handlePay}
            handlePreview={this.handlePreview}
            handlePrint={this.handlePrint}
            handleProspectToCustomer={this.handleProspectToCustomer}
            handlePublish={this.handlePublish}
            handleRefund={this.handleRefund}
            handleResendCampaignLinkToProspect={this.handleResendCampaignLinkToProspect}
            handleReturn={this.handleReturn}
            handleSMS={this.handleSMS}
            isProtectedCustomer={this.isProtectedCustomer()}
            isReadyToSubmit={this.isReadyToSubmit}
            order={this.state.order}
            orderstatus={this.state.order ? this.state.order?.orderstatus : null}
            ordertype={this.state.order ? this.state.order?.ordertype : null}
            product={this.state.product}
            prospect={this.state.prospect}
            save={this.save}
            showOverlay={this.props.showOverlay}
            showSaveCancel={this.state.isNew}
            totalpayments={this.getTotalPayments()}
            totalprice={this.getTotalPrice()}
          />
        </div>
      );
    }
  };

  renderAreaOrderTotal = () => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      return (
        <div className="areaOrderTotalContainer">
          <span className="action-button-cell total">
            <span className="totalcontainer">
              {this.renderSubtotal()}
              {this.renderTaxDetail()}
              {this.renderTaxSummary()}
              {this.renderTotal()}
              {this.renderPayments()}
              {this.renderBalance()}
            </span>
          </span>
        </div>
      );
    } else {
      return "";
    }
  };

  renderSubtotal = () => {
    if (this.state.order?.ordertype !== Constants.PURCHASE) {
      const totalPrice = numeral(this.state.order?.totalprice).format(Constants.CURRENCY);
      const totalCost = numeral(this.state.order?.totalcost).format(Constants.CURRENCY);
      const label = this.state.showCost ? "Pre Tax Cost" : "Pre Tax Total";

      return (
        <React.Fragment>
          <div className="subTotalLabel totalLabels"></div>
          <div className="subTotalLabel totalLabels">{label}</div>
          <div className="subTotalValue right-aligned" data-testid="Subtotal">
            {this.state.showCost ? totalCost : totalPrice}
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  renderTaxDetail = () => {
    // Show tax detail if not a purchase and there are taxes for print view.
    if (this.state.order?.ordertype !== Constants.PURCHASE && this.state.order?.taxes?.length > 0 && this.state.order.totaltax > 0) {
      const prefix = this.state.order?.ordertype === Constants.INVOICE ? "" : "Estimated ";
      return this.state.order?.taxes.map(tax => {
        return (
          <React.Fragment key={tax.description}>
            <div className="taxTotalLabel totalLabels"></div>
            <div className="taxTotalLabel totalLabels">
              {prefix} {tax.description + " (" + tax.displayRate + ")"}
            </div>
            <div className="taxTotalValue right-aligned">{numeral(tax.amount).format(Constants.CURRENCY)}</div>
          </React.Fragment>
        );
      });
    } else if (this.state.order?.ordertype !== Constants.PURCHASE) {
      let taxLabel = "Total Tax";
      if (this.state.order?.company?.taxable === false && numeral(this.state.order?.totaltax ?? 0).value() === 0) {
        taxLabel = "Tax Exempt";
      } else if (this.state.order?.ordertype !== Constants.INVOICE && this.props.selectedItem?.ordertype !== Constants.INVOICE) {
        taxLabel = "Estimated Tax";
      }
      return (
        <React.Fragment>
          <div className="taxTotalLabel totalLabels"></div>
          <div className="taxTotalLabel totalLabels">{taxLabel}</div>
          <div className="taxTotalValue right-aligned">0.00</div>
        </React.Fragment>
      );
    }
  };

  renderTaxSummary = () => {
    // Show tax summary if not a purchase and there are taxes for screen view
    if (this.state.order?.ordertype !== Constants.PURCHASE && this.state.order?.totaltax) {
      let taxLabel = "Total Tax";
      if (this.state.order?.company?.taxable === false && numeral(this.state.order?.totaltax ?? 0).value() === 0) {
        taxLabel = "Tax Exempt";
      } else if (this.state.order?.ordertype !== Constants.INVOICE && this.props.selectedItem?.ordertype !== Constants.INVOICE) {
        taxLabel = "Estimated Tax";
      }
      return (
        <React.Fragment>
          <div className="taxSummaryLabel totalLabels"></div>
          <div className="taxSummaryLabel totalLabels">{taxLabel}</div>
          <div className="taxSummaryValue right-aligned" data-testid="Total Tax Amount">
            {numeral(this.state.order?.totaltax).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  renderPayments = () => {
    if (this.state.order?.payments?.length > 0) {
      return this.state.order?.payments.map(item => {
        return this.renderSelectedPaymentToColumns(item);
      });
    } else {
      return "";
    }
  };

  // Render the total including all items and taxes (or total cost)
  renderTotal = () => {
    let total;
    let totalLabel = "Total";
    // If the eye icon is pressed, then show the net profit in the total column
    if (this.state.showCost) {
      totalLabel = "Net Profit";
      total = numeral(this.state.order?.totalprice)
        .subtract(numeral(this.state.order?.totalcost ?? this.props.selectedItem?.totalcost).value())
        .format(Constants.CURRENCY);
    } else if (this.state.order?.ordertype === Constants.PURCHASE) {
      // If the screen is the purchase screen, then display the total cost
      totalLabel = "Total Cost";
      total = this.state.order?.totalcost ?? this.props.selectedItem?.totalcost;
    } else {
      // If the screen in Invoices or Pay, display the total with taxes
      total = this.state.order?.totalwithtax ?? this.props.selectedItem?.totalwithtax;
    }
    if (total) {
      return (
        <React.Fragment>
          <div></div>
          <div title="Total Including Tax" className="totalLabel totalLabels">
            {totalLabel}
          </div>
          <div className="totalValue right-aligned" data-testid="Order Total">
            {numeral(total).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  renderScreenTitles() {
    let desktopTitle = "";
    let mobileTitle = "";
    if (this.props.selectedItem) {
      if (this.props.selectedItem?.customercompanyname) {
        desktopTitle = mobileTitle = this.props.selectedItem.customercompanyname;
      } else if (this.props.selectedItem?.companyname) {
        desktopTitle = mobileTitle = this.props.selectedItem.companyname;
      } else if (this.props.selectedItem?.company?.companyname) {
        desktopTitle = mobileTitle = this.props.selectedItem.company.companyname;
      } else if (this.props.selectedItem?.contactname) {
        desktopTitle = mobileTitle = this.props.selectedItem.contactname;
      } else if (
        this.props.selectedItem?.contacts &&
        this.props.selectedItem?.contacts.length > 0 &&
        (this.props.selectedItem?.contacts[0].firstname || this.props.selectedItem?.contacts[0].lastname)
      ) {
        desktopTitle = mobileTitle = this.props.selectedItem?.contacts[0].firstname + " " + this.props.selectedItem?.contacts[0].lastname;
      } else if (this.props.selectedItem?.firstname || this.props.selectedItem?.lastname) {
        desktopTitle = mobileTitle = this.props.selectedItem?.firstname + " " + this.props.selectedItem?.lastname;
      }
    }

    // Only desktop will display New "View" here. Mobile displays it in App Header.
    if (this.state.isNew) {
      desktopTitle = "New " + this.props.appState.currentView;
      if (this.props.appState.currentView === Constants.REPAIR) {
        desktopTitle = "New Service Request";
      }
    }
    if (this.state.order?.company?.companyname) {
      desktopTitle = mobileTitle = this.state.order?.company.companyname;
    } else if (this.state.order?.contactname) {
      desktopTitle = mobileTitle = this.state.order?.contactname;
    } else if (this.state.company?.companyname) {
      desktopTitle = mobileTitle = this.state.company.companyname;
    } else if (
      this.state.company?.contacts &&
      this.state.company.contacts.length > 0 &&
      (this.state.company.contacts[0].firstname || this.state.company.contacts[0].lastname)
    ) {
      desktopTitle = mobileTitle = this.state.company.contacts[0].firstname + " " + this.state.company.contacts[0].lastname;
    }

    // For Billing, as additional title text
    if (this.isBillingDetailView()) {
      desktopTitle = mobileTitle = desktopTitle + " - Subscription Account";
    }

    // New Prospects will only have an email address and should be labelled as Not Submitted
    if (this.state.prospect?.prospectstatus === Constants.PROSPECT_STATUS_NEW) {
      desktopTitle = mobileTitle = "Form Not Submitted";
    }
    return { desktopTitle, mobileTitle };
  }

  // Renders the remaining balance after payments/deposits
  renderBalance = () => {
    if (!this.state.isNew && this.state.order?.payments?.length > 0) {
      return (
        <React.Fragment>
          <div></div>
          <div className="balanceLabel totalLabels">Balance</div>
          <div className="balanceValue right-aligned" data-testid="Balance Due">
            {numeral(this.state.order?.balancedue).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  renderAreaBarcode = () => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      return (
        <div className="areaBarCode">
          <svg id="barcode"></svg>
        </div>
      );
    } else {
      return "";
    }
  };

  renderAreaBarcodeNarrow = () => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      return (
        <div className="creationDetail areaBarCodeNarrow">
          <svg id="barcodeNarrow"></svg>
        </div>
      );
    } else {
      return "";
    }
  };

  renderareaProductUPCBarcode = () => {
    if (this.props.appState.currentView === Constants.PRODUCT && (this.state.product?.upc || this.state.product?.altupc)) {
      return (
        <div className="productElement areaProductUPCBarcode">
          <svg id="barcodeUPC"></svg>
        </div>
      );
    }
  };

  renderAreaPay = () => {
    if (this.props.appState.currentView === Constants.PAY) {
      let paymentmethods = "";
      if (this.state.paymentView === Constants.PAYMENT_BUTTONS) {
        paymentmethods = <div className="payment-action-buttons">{this.renderPaymentButtons()}</div>;
      } else if (this.state.paymentView === Constants.CREDIT) {
        paymentmethods = <div className="payment-credit">{this.renderCredit()}</div>;
      } else if (this.state.paymentView === Constants.CREDIT_REFUND) {
        paymentmethods = <div className="payment-credit">{this.renderCredit()}</div>;
      } else if (this.state.paymentView === Constants.STORED_PAYMENT) {
        paymentmethods = <div className="payment-credit">{this.renderCredit()}</div>;
      } else if (this.state.paymentView === Constants.GIFTCARD) {
        paymentmethods = <div className="payment-giftcard">{this.renderGiftcard()}</div>;
      } else if (this.state.paymentView === Constants.CASH) {
        paymentmethods = <div className="payment-cash">{this.renderCash()}</div>;
      } else if (this.state.paymentView === Constants.CHECK) {
        paymentmethods = <div className="payment-check">{this.renderCheck()}</div>;
      } else if (this.state.paymentView === Constants.PAYPAL) {
        paymentmethods = <div className="payment-paypal">{this.renderPaypal()}</div>;
      } else if (this.state.paymentView === Constants.OTHER) {
        paymentmethods = <div className="payment-other">{this.renderOther()}</div>;
      } else if (this.state.paymentView === Constants.STORECREDIT) {
        paymentmethods = <div className="payment-storecredit">{this.renderStoreCredit()}</div>;
      } else if (this.state.paymentView === Constants.APPROVED) {
        paymentmethods = <div className="payment-approved gridCenter">{this.renderApproval()}</div>;
      } else if (this.state.paymentView === Constants.DECLINED) {
        paymentmethods = <div className="payment-declined gridCenter">{this.renderDecline()}</div>;
      }
      let originalPaymentMethods = "";
      // If this is a return, the render the original (parent) payment methods
      return (
        <div className="areaPaymentMethods">
          {paymentmethods}
          {originalPaymentMethods}
        </div>
      );
    } else {
      return "";
    }
  };

  renderPlusContainer = (handler, datatestid) => {
    return (
      <div className="action-icons">
        <div className="plus" onClick={handler} data-testid={datatestid}>
          <CirclePlusIcon />
        </div>
      </div>
    );
  };

  renderWalkinCustomer = () => {
    if (
      Helper.inList([Constants.INVOICE], this.props.appState.currentView) &&
      this.state.clientSettings &&
      this.state.clientSettings[Constants.SETTINGS_WALKIN_CUSTOMER] &&
      !this.state.contactuuid
    ) {
      return (
        <div className="action-button-cell">
          <div className="actionButtonWalkIn brown-button" onClick={this.handleWalkInCustomer}>
            <span className="gridCenter">Walk-In</span>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderStockOrderButton = () => {
    if (
      Helper.inList([Constants.ORDER], this.props.appState.currentView) &&
      this.state.clientSettings &&
      this.state.clientSettings[Constants.SETTINGS_STOCK_ORDER_CUSTOMER] &&
      !this.state.contactuuid
    ) {
      return (
        <div className="action-button-cell">
          <span className="actionButtonStockOrder brown-button" onClick={this.handleStockOrder}>
            <span className="gridCenter desktop">
              <StockOrderIcon />
            </span>
            <span className="gridCenter desktop">Stock Order</span>
            <span className="gridCenter mobile">Stock</span>
          </span>
        </div>
      );
    } else {
      return "";
    }
  };

  renderBillingPlanSearchResults = () => {
    if (this.state.billingPlanSearchKey && this.state.billingPlanSearchResults) {
      if (this.state.billingPlanSearchResults.length > 0) {
        const results = this.state.billingPlanSearchResults.slice(0, this.state.billingPlanSearchLimit);
        let loadmore = "";
        if (this.state.billingPlanSearchLimit < 25 && this.state.billingPlanSearchLimit < this.state.billingPlanSearchCount) {
          loadmore = (
            <React.Fragment>
              <div data-testid="Show More Items" className="list-item selected cursorPointer" onClick={this.handleLoadMoreBillingPlans}>
                Show more items
              </div>
              <div className="list-item" onClick={this.handleLoadBillingPlans}></div>
              <div className="list-item" onClick={this.handleLoadBillingPlans}></div>
            </React.Fragment>
          );
        }
        const handler = this.props.appState.currentView === Constants.BILLING ? this.selectMaastBillingPlanListItem : this.selectBillingPlanListItem;
        return (
          <div className="billingplanssearchresults popuplist">
            <ListItemView
              listitems={results}
              expandedListItems={null}
              selectedListItems={[]}
              renderItemToColumns={this.renderBillingPlanSearchResultsToColumns}
              toggleCollapsed={() => {}}
              selectListItem={handler}
              handleEditItem={() => {}}
              handleTouchStart={this.handleTouchStart}
              handleTouchEnd={this.handleTouchEnd}
            />
            {loadmore}
          </div>
        );
      } else {
        return "No matches";
      }
    } else if (this.state.billingPlanSearchKey && !this.state.billingPlanSearchResults) {
      return "Searching...";
    } else {
      return "";
    }
  };

  renderProductSearchResults = () => {
    if (this.state.productSearchKey && this.state.productSearchResults) {
      if (this.state.productSearchResults.length > 0) {
        const results = this.state.productSearchResults.slice(0, this.state.productSearchLimit);
        let loadmore = "";
        if (this.state.productSearchLimit < 25 && this.state.productSearchLimit < this.state.productSearchCount) {
          loadmore = (
            <React.Fragment>
              <div data-testid="Show More Items" className="list-item selected cursorPointer" onClick={this.handleLoadMoreProducts}>
                Show more items
              </div>
              <div className="list-item" onClick={this.handleLoadMoreProducts}></div>
            </React.Fragment>
          );
        }
        return (
          <div className="orderproductsearchresults popuplist">
            <ListItemView
              listitems={results}
              expandedListItems={null}
              selectedListItems={[]}
              renderItemToColumns={this.renderProductSearchResultsToColumns}
              toggleCollapsed={() => {}}
              selectListItem={this.selectProductListItem}
              handleEditItem={() => {}}
              handleTouchStart={this.handleTouchStart}
              handleTouchEnd={this.handleTouchEnd}
            />
            {loadmore}
          </div>
        );
      } else {
        return "No matches";
      }
    } else if (this.state.productSearchKey && !this.state.productSearchResults) {
      return "Searching...";
    } else {
      return "";
    }
  };

  renderProductSearchResultsToColumns = item => {
    let productname = item.productname;
    if (item.companyname) {
      productname += " (" + item.companyname + ")";
    }
    if (item.location) {
      productname += " - " + item.location;
    }
    return [
      { rowvalue: productname },
      { rowvalue: item.affectinventory ? item.inventory : "-" },
      {
        rowvalue: item.sellprice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
        classes: "right-aligned",
      },
    ];
  };

  renderBillingPlanSearchResultsToColumns = item => {
    // TODO: Remove empty columns after fully converting
    if (this.props.appState.currentView === Constants.BILLING) {
      return [
        { rowvalue: item.plan_desc || item.plan_name, classes: "desktop" },
        { rowvalue: item.plan_desc || item.plan_name, classes: "mobile span3" },
        { rowvalue: item.is_individual ? "Individual" : "Group", classes: "plan_type" },
        { rowvalue: Helper.renderMaastFrequency(item.plan_frequency), classes: "plan_frequency" },
        { rowvalue: numeral(item.amt_tran).format(Constants.CURRENCY), classes: "right-aligned amt_tran" },
      ];
    } else if (this.props.appState.currentView === Constants.RECURRING) {
      return [
        { rowvalue: item.plan_name, classes: "desktop" },
        { rowvalue: item.plan_name, classes: "mobile span3" },
        { rowvalue: item.plan_frequency, classes: "plan_frequency" },
        { rowvalue: numeral(item.recur_amt).format(Constants.CURRENCY), classes: "right-aligned recur_amt" },
        { rowvalue: "" },
      ];
    }
  };

  renderPaymentList = () => {
    return (
      <div className="orderpaymentlist">
        <ListItemView
          listitems={this.state.order?.payments}
          expandedListItems={null}
          selectedListItems={[]}
          renderItemToColumns={this.renderSelectedPaymentToColumns}
          toggleCollapsed={() => {}}
          selectListItem={() => {}}
          handleEditItem={() => {}}
          handleTouchStart={() => {}}
          handleTouchEnd={() => {}}
        />
      </div>
    );
  };

  renderSelectedPaymentToColumns = item => {
    // Check for credit payments
    let cardNumber = "";
    if (item.paymenttype === Constants.CREDIT && item.authresponse) {
      const authResponse = JSON.parse(item.authresponse);
      // Maast
      if (authResponse?.tokenization_response?.card_number) {
        cardNumber = authResponse.tokenization_response.card_number;
        cardNumber = cardNumber.substring(cardNumber.length - 5);
        cardNumber = cardNumber.replace("x", "*");
      } else if (authResponse?.maskedCardNumber) {
        cardNumber = authResponse.maskedCardNumber;
        cardNumber = cardNumber.substring(cardNumber.length - 5);
        cardNumber = cardNumber.replace("x", "*");
      } else if (authResponse?.MASKED_PAN) {
        cardNumber = authResponse.MASKED_PAN;
        cardNumber = cardNumber.substring(cardNumber.length - 5);
      }
    }

    const amount = numeral(item.amount).format(Constants.CURRENCY);
    let description;
    let refundDescription;
    let datatestid = "";

    if (item.paymenttype === Constants.CHANGE) {
      datatestid = description = "Change Due";
    } else {
      if (item.paymenttype === Constants.CASH) {
        datatestid = description = refundDescription = "Cash";
      } else if (item.paymenttype === Constants.CREDIT) {
        datatestid = description = refundDescription = "Credit";
      } else if (item.paymenttype === Constants.CREDIT_REFUND) {
        datatestid = description = refundDescription = "Credit Refund";
      } else if (item.paymenttype === Constants.GIFTCARD) {
        datatestid = description = refundDescription = "Gift Card";
      } else if (item.paymenttype === Constants.PAYPAL) {
        datatestid = description = refundDescription = "PayPal";
      } else if (item.paymenttype === Constants.DEPOSIT_APPLIED) {
        datatestid = description = refundDescription = "Deposit";
      } else if (item.paymenttype === Constants.CHECK) {
        datatestid = description = refundDescription = "Check";
      } else if (item.paymenttype === Constants.OTHER) {
        datatestid = description = refundDescription = item.responsecode ? item.responsecode : "OTHER";
      } else if (item.paymenttype === Constants.STORECREDIT) {
        datatestid = description = refundDescription = item.responsecode ? item.responsecode : "Store Credit";
      }
      if (item.confirmationcode) {
        description += " #" + item.confirmationcode;
      }
      if (cardNumber) {
        description += " (" + cardNumber + ")";
      }
      description += " - " + Helper.formatDate(item.creationdatetime);
    }
    let refund = "";

    if (Constants.DEPOSIT_ENABLED_ORDER_TYPES.includes(this.state.order?.ordertype) && (item.amountRefunded || item.parentAmountRefunded)) {
      let description = refundDescription + " Refund";
      let amount = numeral(item.amountRefunded || item.parentAmountRefunded)
        .multiply(-1)
        .format(Constants.CURRENCY);
      refund = (
        <React.Fragment key={item.paymentuuid}>
          <div></div>
          <div className="totalLabels italic" data-testid="Payment Label">
            {description}
          </div>
          <div className="right-aligned paymentAmount italic" data-testid={datatestid}>
            {amount}
          </div>
        </React.Fragment>
      );
    }

    // Render a Delete button, but not on refunds or subscription invoices
    let deleteButton = "";
    const isReturn = this.state.order?.ordertype === Constants.INVOICE && this.state.order?.ordersubtype === Constants.RETURN;
    const isRefunded =
      this.state.order?.ordertype === Constants.INVOICE &&
      [Constants.ORDER_STATUS_REFUNDED, Constants.ORDER_STATUS_PARTIALLY_REFUNDED].includes(this.state.order?.orderstatus);
    if (Constants.CASHLIKE_PAYMENT_METHODS.includes(item.paymenttype) && !isReturn && !isRefunded) {
      const orderuuid = this.state.order?.orderuuid;
      deleteButton = (
        <span data-testid="Delete Payment" className="ghost noPrint" onClick={() => this.handleDeletePayment(orderuuid, item, description, amount)}>
          <FontAwesomeIcon icon={faTrashCan} />
        </span>
      );
    }

    return (
      <React.Fragment key={item.paymentuuid}>
        <div></div>
        <div className="totalLabels" data-testid="Payment Label">
          {description} {deleteButton}
        </div>
        <div className="right-aligned paymentAmount" data-testid={datatestid}>
          {amount}
        </div>
        {refund}
      </React.Fragment>
    );
  };

  renderDiscountColumnHeaderLabel = () => {
    let classes = "noPrint";
    if (this.state.order?.orderitems.length === 0) {
      classes += " save-disabled";
    }
    const apply = Constants.CLOSED_INVOICE_ORDER_STATUSES.includes(this.state.order?.orderstatus) ? (
      ""
    ) : (
      <FontAwesomeIcon icon={faCalculator} className={classes} />
    );
    return (
      <span onClick={this.handleApplyDiscount} data-testid="Apply Discount To Order">
        Discount {apply}
      </span>
    );
  };

  renderAreaCampaignDetails = () => {
    if (this.props.appState.currentView === Constants.CAMPAIGN) {
      let hints = (
        <span className="highlight" onClick={this.handleToggleHints}>
          Show Campaign Question formatting hints
        </span>
      );
      let formattingHelp = "";
      if (this.state.showHints) {
        hints = <span onClick={this.handleToggleHints}>Hide formatting hints</span>;
        formattingHelp = (
          <div className="white noPrint">
            <div>
              The "Campaign Questions" area of the form is composed of plain text and field definitions.
              <ul>
                <li>Plain text is displayed as is, including line breaks.</li>
                <li>
                  The plain text can be enhanced with bold and italic emphasis.
                  <ul>
                    <li>
                      To define a section as <span className="bold">bold</span>, enclose the section with two asterics like in the following example,
                      <span className="monospace">&nbsp;**Name:**</span>
                    </li>
                    <li>
                      Use ^^ to define a section as <span className="italic">italic</span>:<span className="monospace">&nbsp;^^Don't Forget!^^</span>
                    </li>
                  </ul>
                </li>
                <li>Each field definition is converted to a form field when the form is displayed to the prospect.</li>
              </ul>
              <br />
              Field definitions must be enclosed in double curly braces with their attributes separated by a pipe character, "|". The pipe character
              can be found on the keyboard above the Enter key. You do not have to include First Name, Last Name, or Email fields in the form; these
              fields are automatically included.
              <br />
              <ol>
                <li>
                  The <em>Type</em> attribute is used to determine what type of field is displayed on the form. Valid options are text, radio, select,
                  checkbox, date, time, textarea, and number.
                </li>
                <li>
                  The <em>Fieldname</em> in a field definition is paired with the prospect's answer when reviewing their form submission later. This
                  should be a word or short phrase that describes the field. Good examples include "Name", "Email", "Phone", etc. If the fieldname
                  contains spaces, that value will be replaced with an underscore when the is displayed on the Propsect's detail page. For example. a
                  fieldname of "Pickup Date" will be displayed as "Pickup_Date" on the Prospect's detail page.
                </li>
                <li>
                  The third attribute, when required, is used to provide additional information for rendering the field. For example, the placeholder
                  text for a text box, the options for a radio button, etc. If your a defining options, the list must be separated by a comma. You
                  cannot include a comma in the list of options.
                </li>
                <li>
                  The last attribute indicates if a response is required or optional. If the field is required, the attribute should be "Required". If
                  the field is not required, the attribute should be "Optional". To assist your customers, we recommend that you place an asterisk (*)
                  or other indicator in the form text to indicate that which fields are required.
                </li>
              </ol>
            </div>
            <br />
            <div className="monospace">
              <div>
                <span className="bold">Text box: </span> {"{{text|Fieldname|Placeholder|Required}}"}{" "}
                {this.renderCopy("textHintCopied", "{{text|Fieldname|Placeholder|Required}}")}
              </div>
              <div>&nbsp;&nbsp;&nbsp;Ex. - {"{{text|Name|Enter your full name|Required}}"}</div>
              <br />
              <div>
                Text area: {"{{textarea|Fieldname|Placeholder|Optional}}"}{" "}
                {this.renderCopy("textareaHintCopied", "{{textarea|Fieldname|Placeholder|Optional}}")}
              </div>
              <div>&nbsp;&nbsp;&nbsp;Ex. - {"{{textarea|Comments|Add any additional comments here|Optional}}"}</div>
              <br />
              <div>
                Radio button: {"{{radio|Fieldname|option1,option2,option3|Required}}"}{" "}
                {this.renderCopy("radioHintCopied", "{{radio|Fieldname|option1,option2,option3|Required}}")}
              </div>
              <div>&nbsp;&nbsp;&nbsp;Ex. - {"{{radio|Color|red,blue,green|Required}}"}</div>
              <br />
              <div>
                Select list: {"{{select|Fieldname|option1,option2,option3|Optional}}"}{" "}
                {this.renderCopy("selectHintCopied", "{{select|Fieldname|option1,option2,option3|Optional}}")}
              </div>
              <div>&nbsp;&nbsp;&nbsp;Ex. - {"{{select|State|Alabama,Alaska,Arizona,Arkansas,California,Colorado|Optional}}"}</div>
              <br />
              <div>
                Checkbox: {"{{checkbox|Fieldname|Question|Optional}}"}{" "}
                {this.renderCopy("checkboxHintCopied", "{{checkbox|Fieldname|Question|Optional}}")}
              </div>
              <div>&nbsp;&nbsp;&nbsp;Ex. - {"{{checkbox|Over Eighteen|Over eighteen?|Optional}}"}</div>
              <br />
              <div>
                Date: {"{{date|Fieldname|Required}}"} {this.renderCopy("dateHintCopied", "{{date|Fieldname|Required}}")}
              </div>
              <div>&nbsp;&nbsp;&nbsp;Ex. - {"{{date|Pickup date|Required}}"}</div>
              <br />
              <div>
                Time: {"{{time|Fieldname|Optional}}"} {this.renderCopy("timeHintCopied", "{{time|Fieldname|Optional}}")}
              </div>
              <div>&nbsp;&nbsp;&nbsp;Ex. - {"{{time|Pickup time|Optional}}"}</div>
              <br />
              <div>
                Number: {"{{number|Fieldname|Required}}"} {this.renderCopy("numberHintCopied", "{{number|Fieldname|Required}}")}
              </div>
              <div>&nbsp;&nbsp;&nbsp;Ex. - {"{{number|Quantity Needed|Required}}"}</div>
              <br />
            </div>
          </div>
        );
      }
      let duplicateWarning = "";
      if (this.state.campaign?.additionaldataprompt) {
        const { fields } = Helper.renderAdditionalDataPrompt(
          this.state.campaign?.additionaldataprompt,
          () => {},
          () => {}
        );
        // Look for duplicate field names
        const hasDuplicates = fields.some((field, index) => fields.indexOf(field) !== index);
        if (hasDuplicates) {
          duplicateWarning = (
            <span className="highlight">
              {" "}
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faExclamationTriangle} /> Some field names are duplicated. This will cause issues with the form.
            </span>
          );
        }
      }
      let tooltipAgreement = (
        <Tooltip
          explanation={true}
          text="If you provide text in the Agreement Text section, the prospect will be required to agree to the terms before being allowed to submit the form."
        />
      );

      return (
        <div className="areaCampaignDetails areaInputItem">
          <label title="Introduction/Description Heading" htmlFor="description">
            Introduction/Description
          </label>
          <textarea
            name="description"
            id="description"
            className="noPrint"
            title="Introduction/Description"
            placeholder="This is the first thing the prospect will see when they open the form. This is also the first sentence of the email that is sent to the customer. This is required."
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChange(event, Constants.CAMPAIGN);
            }}
            onBlur={event => this.handleBlur(event, Constants.CAMPAIGN, null, null, "text")}
            value={this.state.campaign?.description}
            autoComplete="off"
            rows="3"
            maxLength={20000}
          />
          <div className="printOnly" title="Print Only Description">
            {this.state.campaign?.description}
          </div>
          <label htmlFor="additionaldataprompt" title="Campaign Heading">
            Campaign Questions {duplicateWarning}
          </label>
          <textarea
            name="additionaldataprompt"
            id="additionaldataprompt"
            className="noPrint"
            title="Campaign Questions"
            placeholder="These are the questions that the prospect will answer when they open the form. Each question must be answered by the prospect before the form can be submitted."
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChange(event, Constants.CAMPAIGN);
            }}
            onBlur={event => this.handleBlur(event, Constants.CAMPAIGN, null, null, "text")}
            value={this.state.campaign?.additionaldataprompt}
            autoComplete="off"
            rows="10"
            maxLength={20000}
          />
          <div className="right-aligned noPrint" title="Helpful Formatting Hints">
            {hints}
          </div>
          {formattingHelp}
          <div className="printOnly" title="Print Only Campaign Questions">
            {Helper.ln2br(this.state.campaign?.additionaldataprompt ?? "")}
          </div>

          <label htmlFor="agreementtext" title="Agreement Text Heading">
            Agreement Text {tooltipAgreement}
          </label>
          <textarea
            name="agreementtext"
            id="agreementtext"
            className="noPrint"
            title="Agreement Text"
            placeholder="This is the Terms and Conditions the prospect will need to agree to before the form can be submitted. This is optional."
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChange(event, Constants.CAMPAIGN);
            }}
            onBlur={event => this.handleBlur(event, Constants.CAMPAIGN, null, null, "text")}
            value={this.state.campaign?.agreementtext}
            autoComplete="off"
            rows="7"
            maxLength={20000}
          />
          <div className="printOnly" title="Print Only Agreement Text">
            {this.state.campaign?.agreementtext}
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderReportSettings = () => {
    return "";
  };

  renderAreaSubscriptionStatus = () => {
    return "";
  };

  renderAreaStoredPayments = () => {
    return "";
  };

  renderAreaSubscriptions = () => {
    return "";
  };

  renderAreaSubscriptionsNarrow = () => {
    return "";
  };

  renderAreaOverdueTotal = () => {
    return "";
  };

  renderCopy = (name, value) => {
    return this.state[name] ? (
      <span>
        <FontAwesomeIcon
          icon={faCheck}
          title="Copied to clipboard"
          onClick={() => {
            Helper.copyToClipboard(value);
          }}
        />
      </span>
    ) : (
      <span className="copyToClipboard">
        <FontAwesomeIcon
          icon={faCopy}
          title="Copy to clipboard"
          onClick={() => {
            this.setState({ [name]: true }, () => {
              setTimeout(() => {
                this.setState({ [name]: false });
              }, 1500);
            });
            Helper.copyToClipboard(value);
          }}
        />
      </span>
    );
  };
}
export default BaseDetailViewComponent;
