import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

class Tooltip extends React.Component {
  render = () => {
    let explanationClass = "explanation " + (this.props.explanationClass ?? "");
    let wrapperClasses = " desktop newTooltip " + (this.props.wrapperClasses ?? "");
    let text = this.props.text;
    let tooltip = this.props.tooltip;
    if (this.props.explanation === true) {
      text = this.props.error ? <FontAwesomeIcon icon={faTriangleExclamation} /> : <FontAwesomeIcon icon={faCircleQuestion} />;
      tooltip = <div className={explanationClass}>{this.props.text}</div>;
      wrapperClasses += " tagListParent";
    }
    let newTooltipDisplayTextClasses = this.props.noGhost ? "" : "ghost";
    if (this.props.error) {
      newTooltipDisplayTextClasses += " highlight";
    }
    return (
      <span className={wrapperClasses}>
        <span className={newTooltipDisplayTextClasses}>{text}</span>
        <span className="newTooltipHoverText">{tooltip}</span>
      </span>
    );
  };
}

export default Tooltip;
