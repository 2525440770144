import React from "react";

// Functions
import { faTag, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNull } from "./Helper";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import FlexInput from "./FlexInput";
import TagList from "./TagList";
import Tooltip from "./Tooltip";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

class ProductList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hideFilter: [Constants.TAB_SUMMARY, Constants.TAB_INVENTORY].includes(this.props.filtertype?.tab),
      downloadState: null,
      inventoryStats: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const isInventoryTab = this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_INVENTORY;
    const isSummaryTab = this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_SUMMARY;
    if (isInventoryTab) {
      this.loadListItemsFromLocalStorage(this.state.pagenumber ?? 1);
      if (!this.state.products || this.state.products?.length === 0) {
        this.loadProductsFromLocalStorage();
      }
    } else if (isSummaryTab) {
      this.setState({ listItems: [] }, () => {
        this.handleFilterList({ active: null, disposition: Constants.DISPOSITION_INVENTORIED, tab: Constants.TAB_SUMMARY });
        this.getInventoryStats();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filtertype?.tab !== Constants.TAB_SUMMARY && this.props.filtertype?.tab === Constants.TAB_SUMMARY) {
      this.setState({ listItems: [] }, () => {
        this.handleFilterList({ active: null, disposition: Constants.DISPOSITION_INVENTORIED, tab: Constants.TAB_SUMMARY });
        this.getInventoryStats();
      });
    }
  }

  renderListView = listItems => {
    const isInventoryTab = this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_INVENTORY;
    const isSummaryTab = this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_SUMMARY;
    if (isInventoryTab && (!this.state.products || this.state.products?.length === 0)) {
      return (
        <div className="list-grid">
          <div className="inventoryMessage">
            <span className="highlight">To begin inventory reconciliation, you must first initialize your device.</span>
            <br />
            <br />
            Click the Initialize Device button above.
            <br />
            This may take a few minutes. Please wait until it is complete.
          </div>
        </div>
      );
    } else if (isInventoryTab && this.state.listItems?.length === 0) {
      return (
        <div className="list-grid">
          <div className="inventoryMessage">
            <span className="white">IMPORTANT! Read the following instructions carefully before beginning.</span>
            <br />
            <br />
            To inventory a product, scan or enter its Name, UPC, or SKU
            <br />
            in the search field above and press Enter.
            <br />
            <em>Your barcode scanner should be set to send an Enter key at the end of each scan.</em>
            <br />
            <em>Refer to your scanner's user manual for instructions on changing this setting.</em>
            <br />
            <br />
            Repeat for each physical item in your store to count items individually.
            <br />
            Or, scan a single item and enter the total quantity of items in the Quantity column.
            <br />
            <br />
            To save the items to the server, click the Save Inventory button above.
            <br />
            This will clear the list and allow you to scan or enter additional items.
            <br />
            <br />
            <span className="highlight">If you log out before selecting Save Inventory, your inventory counts will be lost.</span>
            <br />
            <br />
            If changes to products are made after inventory has started,
            <br />
            please re-initialize your device by{" "}
            <span
              className="white italic hoverPointer"
              onClick={() => {
                this.getProductsForInventory();
              }}
            >
              clicking here.
            </span>
          </div>
        </div>
      );
    } else if (isSummaryTab && this.state.inventoryStats?.dispositioninventoried === 0 && this.state.listItems?.length === 0) {
      return (
        <div className="list-grid">
          <div className="inventoryMessage">
            To perform inventory reconciliation, click the Inventory tab above,
            <br />
            initialize each device (computer, tablet, phone, etc.) used for counting inventory,
            <br />
            and begin scanning.
            <br />
            <br />
            If changes to products are made after inventory has started, <br />
            you will need to re-initialize each device by clicking the link on the Inventory tab.
            <br />
            <br />
            Only products marked as affecting inventory can be inventoried.
            <br />
            <br />
            Inventoried items will not appear on this tab until the data is saved to the server
            <br />
            by selecting the Save Inventory button on the Inventory tab.
          </div>
        </div>
      );
    } else {
      return this.doRenderListView(listItems);
    }
  };

  getHeaderRowItems = () => {
    if (this.props.filtertype.tab === Constants.TAB_PRODUCTS) {
      return this.getHeaderRowItemsProducts();
    } else if (this.props.filtertype.tab === Constants.TAB_SUMMARY) {
      return this.getHeaderRowItemsSummary();
    } else {
      return this.getHeaderRowItemsInventory();
    }
  };

  renderItemToColumns = item => {
    if (this.props.filtertype.tab === Constants.TAB_PRODUCTS) {
      return this.renderItemToColumnsProducts(item);
    } else if (this.props.filtertype.tab === Constants.TAB_SUMMARY) {
      return this.renderItemToColumnsSummary(item);
    } else {
      return this.renderItemToColumnsInventory(item);
    }
  };

  getHeaderRowItemsProducts = () => {
    return [
      {
        classes: "header list-header1 prodname firstLeft",
        sortable: true,
        columnheading: "Description",
        sortkey: "products.productname",
      },
      { classes: "header desktop", sortable: true, columnheading: "SKU", sortkey: "products.sku" },
      {
        classes: "header desktop sku",
        sortable: true,
        columnheading: "Store SKU",
        sortkey: "products.storesku",
      },
      {
        classes: "header desktop supplier",
        sortable: true,
        columnheading: "Supplier",
        sortkey: "companies.companyname",
      },
      {
        classes: "header desktop productfamily",
        sortable: true,
        columnheading: "Category",
        sortkey: "products.family",
      },
      {
        classes: "header right-aligned price",
        sortable: true,
        columnheading: "Price",
        sortkey: "products.sellprice",
      },
      {
        classes: "header desktop right-aligned inventory",
        sortable: true,
        columnheading: "Inv",
        sortkey: "products.inventory",
      },
      {
        classes: "header desktop location lastRight",
        sortable: true,
        columnheading: "Location",
        sortkey: "products.location",
      },
    ];
  };

  getHeaderRowItemsSummary = () => {
    return [
      {
        classes: "header list-header1 prodname",
        sortable: true,
        columnheading: "Description",
        sortkey: "products.productname",
      },
      {
        classes: "header right-aligned inventory",
        sortable: true,
        columnheading: "Inventory",
        sortkey: "products.inventory",
      },
      {
        classes: "header right-aligned onhand",
        sortable: true,
        columnheading: "On Hand",
        sortkey: "products.onhand",
      },
      {
        classes: "header desktop spacer",
        sortable: false,
        columnheading: "",
      },
      {
        classes: "header desktop disposition",
        sortable: false,
        columnheading: "Disposition",
        sortkey: "products.disposition",
      },
      {
        classes: "header desktop lastupdated",
        sortable: true,
        columnheading: "Date Last Updated",
        sortkey: "products.lastupdated",
      },
    ];
  };

  getHeaderRowItemsInventory = () => {
    return [
      { classes: "header desktop entry", columnheading: "Entry" },
      { classes: "header storesku", columnheading: "Store SKU" },
      { classes: "header prodname", columnheading: "Product Name" },
      { classes: "header right-aligned inventory desktop", columnheading: "Inventory" },
      { classes: "header right-aligned inventory mobile", columnheading: "Inv" },
      { classes: "header right-aligned onhand desktop", columnheading: "Quantity" },
      { classes: "header right-aligned onhand mobile", columnheading: "Qty" },
      { classes: "header desktop spacer", columnheading: "" },
      { classes: "header desktop disposition", columnheading: "Status" },
      { classes: "header desktop spacer", columnheading: "" },
    ];
  };

  renderItemToColumnsProducts = item => {
    let tagListElement = "";
    let fullname = item.productname;
    const icon = <FontAwesomeIcon icon={faTag} />;
    let tooltip = "";
    if (item.tags?.length > 0) {
      tagListElement = <TagList tags={item.tags} readonly={true} />;
      tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
      fullname = (
        <span>
          {item.productname} {tooltip}
        </span>
      );
    }
    const inventory = item.affectinventory ? formatNull(item.inventory) : "-";
    return [
      { rowvalue: item.recordnumber, classes: "right-aligned recordnumber" },
      { rowvalue: fullname, classes: "product" },
      { rowvalue: formatNull(item.sku), classes: "desktop" },
      { rowvalue: formatNull(item.storesku), classes: "desktop" },
      { rowvalue: item.companyname, classes: "desktop" },
      { rowvalue: item.family, classes: "desktop" },
      {
        rowvalue: item.sellprice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
        classes: "right-aligned",
      },
      { rowvalue: inventory, classes: "desktop right-aligned" },
      { rowvalue: item.location, classes: "desktop" },
    ];
  };

  renderItemToColumnsSummary = item => {
    let disposition = "";
    let tagListElement = "";
    let fullname = item.productname;
    if (item.disposition === Constants.DISPOSITION_PENDING) {
      disposition = "Uncounted Inventory";
    } else if (item.disposition === Constants.DISPOSITION_MATCHED) {
      disposition = "Matched Inventory";
    } else if (item.disposition === Constants.DISPOSITION_OVERAGE) {
      disposition = "Excess Inventory";
    } else if (item.disposition === Constants.DISPOSITION_MISSING) {
      disposition = "Missing Inventory";
    }
    const icon = <FontAwesomeIcon icon={faTag} />;
    let tooltip = "";
    if (item.tags?.length > 0) {
      tagListElement = <TagList tags={item.tags} readonly={true} />;
      tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
      fullname = (
        <span data-testid="Product Name">
          {item.productname} {tooltip}
        </span>
      );
    } else {
      fullname = <span data-testid="Product Name">{item.productname}</span>;
    }
    const onhand = (
      <div className="areaInputItem">
        <FlexInput
          type="text"
          inputMode="numeric"
          name="onhand"
          id="onhand"
          datatestid="On Hand"
          autoComplete="off"
          maxLength={13}
          onChange={event => this.handleChange(event, Constants.PRODUCT, item.productuuid)}
          onFocus={Helper.handleFocus}
          onBlur={event => this.handleBlur(event, Constants.PRODUCT, item.productuuid)}
          value={item.onhand}
        />
      </div>
    );
    const inventory = <div data-testid="Inventory Count">{formatNull(item.inventory)}</div>;
    disposition = <div data-testid="Disposition">{disposition}</div>;
    return [
      { rowvalue: item.recordnumber, classes: "right-aligned recordnumber" },
      { rowvalue: fullname, classes: "product" },
      { rowvalue: inventory, classes: "right-aligned" },
      { rowvalue: onhand, classes: "right-aligned" },
      { rowvalue: "", classes: "desktop spacer" },
      { rowvalue: disposition, classes: "desktop" },
      { rowvalue: Helper.formatDateTime(item.lastupdated), classes: "desktop" },
    ];
  };

  renderItemToColumnsInventory = item => {
    let tagListElement = "";
    let fullname = item.productname;
    const icon = <FontAwesomeIcon icon={faTag} />;
    let tooltip = "";
    if (item.tags?.length > 0) {
      tagListElement = <TagList tags={item.tags} readonly={true} />;
      tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
      fullname = (
        <span data-testid="Product Name">
          {item.productname} {tooltip}
        </span>
      );
    } else {
      fullname = <span data-testid="Product Name">{item.productname}</span>;
    }
    const deletedClasses = [
      Constants.INVENTORY_STATUS_DELETED,
      Constants.INVENTORY_STATUS_NOT_INVENTORIED,
      Constants.INVENTORY_STATUS_NOT_MATCHED,
    ].includes(item.status)
      ? " deleted"
      : "";
    const editable = item.status === Constants.INVENTORY_STATUS_MATCHED;
    const quantity = editable ? (
      <div className="areaInputItem">
        <FlexInput
          type="text"
          inputMode="numeric"
          name="quantity"
          id="quantity"
          datatestid="Quantity"
          autoComplete="off"
          maxLength={13}
          onChange={event => this.handleChange(event, Constants.INVENTORY, item.uuid)}
          onFocus={Helper.handleFocus}
          onBlur={event => this.handleBlur(event, Constants.INVENTORY, item.uuid)}
          value={item.quantity}
        />
      </div>
    ) : (
      <div data-testid="Quantity">{item.quantity}</div>
    );
    const deleteButton = editable ? (
      <span
        className="deleteButton"
        data-testid="Delete Button"
        onClick={() => {
          this.handleDeleteInventoryItem(item);
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </span>
    ) : (
      ""
    );
    const searchkey = <div data-testid="Search Key">{item.searchkey}</div>;
    const storesku = <div data-testid="Store SKU">{item.storesku}</div>;
    const inventory = <div data-testid="Inventory Count">{formatNull(item.inventory)}</div>;
    const status = <div data-testid="Status">{Helper.renderInventoryStatusLabel(item.status)}</div>;
    return [
      { rowvalue: searchkey, classes: "searchkey desktop" + deletedClasses },
      { rowvalue: storesku, classes: "product" + deletedClasses },
      { rowvalue: fullname, classes: "product" + deletedClasses },
      { rowvalue: inventory, classes: "right-aligned" + deletedClasses },
      { rowvalue: quantity, classes: "right-aligned" + deletedClasses },
      { rowvalue: "", classes: "desktop spacer" },
      { rowvalue: status, classes: "desktop" },
      { rowvalue: deleteButton, classes: "desktop" },
    ];
  };

  getListGridClassName = () => {
    let classNames = "productlist";
    if (this.props.appState.inventory) {
      if (this.props.filtertype.tab === Constants.TAB_INVENTORY) {
        classNames += " inventory";
      } else if (this.props.filtertype.tab === Constants.TAB_PRODUCTS) {
        classNames += " products";
      } else if (this.props.filtertype.tab === Constants.TAB_SUMMARY) {
        classNames += " summary";
      }
      if ((this.props.filtertype.tab === Constants.TAB_INVENTORY && !this.state.products) || this.state.products?.length === 0) {
        classNames += " noProducts";
      } else if (this.props.filtertype.tab === Constants.TAB_INVENTORY && this.state.listItems?.length === 0) {
        classNames += " noProducts";
      } else if (
        this.props.filtertype.tab === Constants.TAB_SUMMARY &&
        this.state.inventoryStats?.dispositioninventoried === 0 &&
        this.state.listItems?.length === 0
      ) {
        classNames += " noProducts";
      }
    }
    return classNames;
  };

  renderTopControlButtons = () => {
    // If we're not in inventory mode, then do not display tabs
    if (!this.props.appState.inventory) {
      return <div></div>;
    }

    // Products, Inventory, Summary
    let productsTabClassName = "action-tab";
    let inventoryTabClassName = "action-tab";
    let summaryTabClassName = "action-tab";

    if (this.props.filtertype.tab === Constants.TAB_PRODUCTS) {
      productsTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.TAB_INVENTORY) {
      inventoryTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.TAB_SUMMARY) {
      summaryTabClassName += " selected ";
    }

    // If this is a manager, then display the summary tab too
    let summaryTab = "";
    if (this.props.appState.usertype >= Constants.USER_TYPE_MANAGER) {
      summaryTab = (
        <span
          data-testid="Summary Tab"
          className={summaryTabClassName}
          onClick={() => {
            this.setState({ hideFilter: true, preventSelection: false, disableDetailView: false }, () => {
              this.props.handleChangeTabView({ active: null, disposition: Constants.DISPOSITION_INVENTORIED, tab: Constants.TAB_SUMMARY });
              this.handleFilterList({ active: null, disposition: Constants.DISPOSITION_INVENTORIED, tab: Constants.TAB_SUMMARY });
              // If the user clicks on the Summary tab while already on the Summary tab, then refresh the inventory stats
              // If the user clicks on the Summary tab while on another tab, the componentDidUpdate will handle the refresh
              if (this.props.filtertype?.tab === Constants.TAB_SUMMARY) {
                this.getInventoryStats();
              }
            });
          }}
        >
          Summary
        </span>
      );
    }

    let inventoryButtonsMobile = "";
    let inventoryButtonsDesktop = "";
    if (this.props.filtertype?.tab === Constants.TAB_INVENTORY) {
      inventoryButtonsMobile = <div className="mobile">{this.renderInventoryButtons()}</div>;
      inventoryButtonsDesktop = <div className="desktop">{this.renderInventoryButtons()}</div>;
    } else if (this.props.filtertype?.tab === Constants.TAB_SUMMARY) {
      inventoryButtonsMobile = <div className="mobile">{this.renderInventoryStats("Mobile")}</div>;
      inventoryButtonsDesktop = <div className="desktop statsContainer">{this.renderInventoryStats("Desktop")}</div>;
    }

    return (
      <React.Fragment>
        {inventoryButtonsMobile}
        <div className="productlisttopcontrols">
          <span
            data-testid="Products Tab"
            className={productsTabClassName}
            onClick={() => {
              this.setState({ hideFilter: false, preventSelection: false, disableDetailView: false }, () => {
                this.props.handleChangeTabView({ active: Constants.FILTER_ACTIVE, disposition: null, tab: Constants.TAB_PRODUCTS });
                this.handleFilterList({ active: Constants.FILTER_ACTIVE, disposition: null, tab: Constants.TAB_PRODUCTS });
              });
            }}
          >
            Products
          </span>
          <span
            data-testid="Inventory Tab"
            className={inventoryTabClassName}
            onClick={() => {
              this.setState({ hideFilter: true, preventSelection: true, disableDetailView: true }, () => {
                this.props.handleChangeTabView({ tab: Constants.TAB_INVENTORY });
                // Data on the inventory tab is maintained locally
                this.handleSelectInventoryTab();
              });
            }}
          >
            Inventory
          </span>
          {summaryTab}
        </div>
        {inventoryButtonsDesktop}
        <span className="action-tab-balance"></span>
      </React.Fragment>
    );
  };

  renderInventoryStats = view => {
    if (!this.state.inventoryStats) {
      // Display a loading message
      return <div className="inventoryStats">Loading...</div>;
    } else if (Object.keys(this.state.inventoryStats).length === 0) {
      // Display a message that there are no stats. Please refresh
      return <div className="inventoryStats">No stats available. Please refresh.</div>;
    } else {
      // Display the stats
      const stats = this.state.inventoryStats;
      const percentInventoried = ((100 * stats[Constants.DISPOSITION_INVENTORIED]) / stats[Constants.DISPOSITION_ALL]).toFixed(1);
      const percentPending = ((100 * stats[Constants.DISPOSITION_PENDING]) / stats[Constants.DISPOSITION_ALL]).toFixed(1);
      const percentMatched = ((100 * stats[Constants.DISPOSITION_MATCHED]) / stats[Constants.DISPOSITION_ALL]).toFixed(1);
      const percentOverage = ((100 * stats[Constants.DISPOSITION_OVERAGE]) / stats[Constants.DISPOSITION_ALL]).toFixed(1);
      const percentMissing = ((100 * stats[Constants.DISPOSITION_MISSING]) / stats[Constants.DISPOSITION_ALL]).toFixed(1);

      return (
        <div className="inventoryStats">
          <span className="white" data-testid={view + " Counted"}>
            Counted: {percentInventoried}%
          </span>
          <span data-testid={view + " Matched"}>Matched: {percentMatched}%</span>
          <span data-testid={view + " Excess"}>Excess: {percentOverage}%</span>
          <span data-testid={view + " Missing"}>Missing: {percentMissing}%</span>
          <span className="highlight" data-testid={view + " Uncounted"}>
            Uncounted: {percentPending}%
          </span>
        </div>
      );
    }
  };

  renderInventoryButtons = () => {
    if (this.state.products?.length > 0) {
      let saveClasses = "saveProducts";
      if (this.isSaveDisabled()) {
        saveClasses += " save-disabled";
      }
      return (
        <div className="inventoryButtons">
          <span
            className={saveClasses}
            onClick={() => {
              this.handleSaveInventoryData();
            }}
          >
            Save Inventory
          </span>
        </div>
      );
    } else {
      return (
        <div className="inventoryButtons">
          <span
            className="downloadProducts"
            onClick={() => {
              this.getProductsForInventory();
            }}
          >
            Initialize Device
          </span>
        </div>
      );
    }
  };

  handleDeleteInventoryItem = item => {
    // Update the item status in local storage to INVENTORY_STATUS_DELETED
    item.status = Constants.INVENTORY_STATUS_DELETED;
    localStorage.setItem(item.key, JSON.stringify(item));
    // Update the item status in state
    this.setState(prevState => ({
      listItems: prevState.listItems.map(i => {
        if (i.uuid === item.uuid) {
          i.status = Constants.INVENTORY_STATUS_DELETED;
        }
        return i;
      }),
    }));
  };

  handleSelectInventoryTab = () => {
    this.setState(
      prevState => ({
        searchkey: "",
        listItems: [],
        selectedListItems: [],
        pagenumber: 1,
        pagenumberentry: 1,
        pagecount: 1,
      }),
      () => {
        Helper.abortGetData(Constants.PREEMPT_GET_LIST_ITEMS);
        this.props.updateFilterType({ tab: Constants.TAB_INVENTORY }, () => {
          this.props.updateBreadcrumb({ searchkey: "" });
          // Load any list items from local storage into state
          this.loadListItemsFromLocalStorage(1);
          this.loadProductsFromLocalStorage();
        });
      }
    );
    // Clear the list items array in state
    this.setState({ listItems: [] }, () => {});
  };

  handleChangePageNumber = event => {
    let callback = null;
    if (this.props.filtertype?.tab === Constants.TAB_INVENTORY) {
      callback = () => {
        this.loadListItemsFromLocalStorage(this.state.pagenumber ?? 1);
      };
    }
    this.doHandleChangePageNumber(event, callback);
  };

  handleNextPage = event => {
    let callback = null;
    if (this.props.filtertype?.tab === Constants.TAB_INVENTORY) {
      callback = () => {
        this.loadListItemsFromLocalStorage(this.state.pagenumber ?? 1);
      };
    }
    this.doHandleNextPage(event, callback);
  };

  handlePrevPage = event => {
    let callback = null;
    if (this.props.filtertype?.tab === Constants.TAB_INVENTORY) {
      callback = () => {
        this.loadListItemsFromLocalStorage(this.state.pagenumber ?? 1);
      };
    }
    this.doHandlePrevPage(event, callback);
  };

  handleHome = event => {
    let callback = null;
    if (this.props.filtertype?.tab === Constants.TAB_INVENTORY) {
      callback = () => {
        this.loadListItemsFromLocalStorage(this.state.pagenumber ?? 1);
      };
    }
    this.doHandleHome(event, callback);
  };

  handleEnd = event => {
    let callback = null;
    if (this.props.filtertype?.tab === Constants.TAB_INVENTORY) {
      callback = () => {
        this.loadListItemsFromLocalStorage(this.state.pagenumber ?? 1);
      };
    }
    this.doHandleEnd(event, callback);
  };

  handleClearSearch = () => {
    this.props.updateBreadcrumb({ searchkey: "" });
    if (this.props.filtertype?.tab === Constants.TAB_INVENTORY) {
      this.setState({
        searchkey: "",
      });
    } else {
      // Duplicate code from BaseListViewComponent
      this.setState(
        {
          searchkey: "",
          sortkey: this.props.defaultSortKey,
          pagenumber: 1,
          pagenumberentry: 1,
          listItems: [],
          selectListItem: [],
          expandedListItems: [],
        },
        this.getListItems
      );
    }
  };

  loadItemsForSaveFromLocalStorage = () => {
    let matchedItems = [];
    let index = 0;
    while (true) {
      const key = `${Constants.LOCAL_STORAGE_INVENTORY}${index}`;
      const item = localStorage.getItem(key);
      if (!item) {
        break;
      }
      const itemObj = JSON.parse(item);
      if (Constants.SAVABLE_INVENTORY_STATUSES.includes(itemObj.status)) {
        itemObj.key = key;
        matchedItems.push(itemObj);
      }
      index++;
    }
    return matchedItems;
  };

  loadProductsFromLocalStorage = () => {
    const products = localStorage.getItem(Constants.LOCAL_STORAGE_PRODUCTS);
    this.setState({ products: JSON.parse(products) });
  };

  getProductsForInventory = (searchstart = 0) => {
    if (searchstart === 0) {
      this.setState({ products: [], productCount: 0, downloadState: Constants.DOWNLOAD_STATE_DOWNLOADING }, () => {
        localStorage.setItem(Constants.LOCAL_STORAGE_PRODUCTS, "[]");
      });
      this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Initializing device...", cancelCallback: this.cancelDownload });
    } // Check for cancel action
    else if (this.state.downloadState === Constants.DOWNLOAD_STATE_CANCELLED) {
      return;
    } else {
      this.props.showOverlay({
        type: Constants.OVERLAY_PROGRESS,
        text: `Initializing ${searchstart} of ${this.state.productCount}...`,
        cancelCallback: this.cancelDownload,
      });
    }
    const url = Constants.URL_PRODUCTS;
    const params = { action: Constants.ACTION_INVENTORY, searchlimit: Constants.LIST_DOWNLOAD_BLOCK_SIZE, searchstart: searchstart };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        // Store the records in state
        this.setState(
          prevState => ({
            products: [...prevState.products, ...response.body.records],
            productCount: response.body.count,
          }),
          () => {
            // Save this block of products to local storage
            let localStorageProducts = localStorage.getItem(Constants.LOCAL_STORAGE_PRODUCTS) ?? "[]";
            localStorageProducts = JSON.parse(localStorageProducts);
            localStorageProducts = [...localStorageProducts, ...response.body.records];
            localStorage.setItem(Constants.LOCAL_STORAGE_PRODUCTS, JSON.stringify(localStorageProducts));

            // Keep loading blocks until we have all the records
            if (response.body.records.length > 0 && response.body.count > this.state.products.length) {
              this.getProductsForInventory(searchstart + Constants.LIST_DOWNLOAD_BLOCK_SIZE);
            } else {
              // Save the records to local storage upon completion of the download
              localStorage.setItem(Constants.LOCAL_STORAGE_PRODUCTS, JSON.stringify(this.state.products));
              this.props.hideOverlay();
            }
          }
        );
      } else {
        this.setState({ messages: [] }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the products.",
          });
        });
      }
    });
  };

  isSaveDisabled = () => {
    const unsaved = this.state.listItems.filter(item => Constants.SAVABLE_INVENTORY_STATUSES.includes(item.status));
    if (unsaved.length > 0) {
      return false;
    }
    let hasUnsavedItems = false;
    let index = 0;
    let noItemsInLocalStorage = true;
    while (true) {
      const key = `${Constants.LOCAL_STORAGE_INVENTORY}${index}`;
      const item = localStorage.getItem(key);
      if (!item) {
        break;
      }
      noItemsInLocalStorage = false;
      const itemObj = JSON.parse(item);
      if (Constants.SAVABLE_INVENTORY_STATUSES.includes(itemObj.status)) {
        hasUnsavedItems = true;
        break;
      }
      index++;
    }
    if (!hasUnsavedItems || noItemsInLocalStorage) {
      return true;
    }
    return false;
  };

  handleSaveInventoryData = () => {
    if (this.isSaveDisabled()) {
      return;
    }
    // Show a progress overlay
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Sending inventory data to server..." });
    let matchedItems = this.loadItemsForSaveFromLocalStorage();

    matchedItems.forEach(item => {
      // Change the status to pending in local storage
      item.status = Constants.INVENTORY_STATUS_PENDING;
      item.quantity = parseInt(item.quantity);
      localStorage.setItem(item.key, JSON.stringify(item));
      // Update the status to pending in state
      this.setState(prevState => ({
        listItems: prevState.listItems.map(i => {
          if (i.uuid === item.uuid && Constants.SAVABLE_INVENTORY_STATUSES.includes(i.status)) {
            i.status = Constants.INVENTORY_STATUS_PENDING;
          }
          return i;
        }),
      }));
    });

    // Consolidate the pending item quantities
    matchedItems = matchedItems.reduce((accumulator, currentItem) => {
      const existingItem = accumulator.find(item => item.productuuid === currentItem.productuuid);

      if (existingItem) {
        // If an item with the same productuuid exists, update its quantity
        existingItem.quantity += currentItem.quantity;
      } else {
        // If not, add the current item to the accumulator
        accumulator.push(currentItem);
      }

      return accumulator;
    }, []);

    // Send the pending items to the server
    this.putInventoryProducts(matchedItems);
  };

  putInventoryProducts = products => {
    const url = Constants.URL_PRODUCTS;
    const params = { action: Constants.ACTION_INVENTORY_SAVE, products: products };
    Helper.putData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update the status to saved in local storage
        let index = 0;
        while (true) {
          const key = `${Constants.LOCAL_STORAGE_INVENTORY}${index}`;
          const item = localStorage.getItem(key);
          if (!item) {
            break;
          }
          localStorage.removeItem(key);
          index++;
        }
        // State only contains a subset of the items, so update any matching ones
        this.setState({ listItems: [] });
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Inventory data saved.\nScan additional items to continue.",
        });
      } else {
        this.setState({ messages: [] }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error saving the inventory data.",
          });
        });
      }
    });
  };

  cancelDownload = () => {
    this.setState({ downloadState: Constants.DOWNLOAD_STATE_CANCELLED }, () => {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Initialization cancelled.",
      });
    });
  };
}
export default ProductList;
