import React from "react";

import { animated, useSpring } from "@react-spring/web";
import * as Helper from "./Helper";

function BillingCustomFields(props) {
  const [springs] = useSpring(() => ({
    from: { x: -1000 },
    to: { x: 0 },
  }));
  const companyuuid = props.subscription.customer_id || props.subscription.companyuuid;
  return props.subscription.custom_fields
    ?.filter(cf => !cf.archived) // filter out archived custom fields
    .map((field, i) => {
      const classNames = "noPrint customFields list-item " + props.className;
      return (
        <animated.div
          className={classNames}
          key={i}
          style={{
            ...springs,
          }}
        >
          <input
            type="text"
            name={field.uuid}
            id={field.uuid}
            value={field.customfieldvalue}
            placeholder={field.description}
            onFocus={Helper.handleFocus}
            onChange={event => {
              props.handleChangeCustomField(event, field.subscriptionid, field.uuid);
            }}
            onBlur={event => {
              props.handleBlurCustomField(event, companyuuid, field.subscriptionid, field.customfieldlabeluuid, props.subscription.isNew);
            }}
          />
        </animated.div>
      );
    });
}

export default BillingCustomFields;
