import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faInfinity, faPencil, faTrashCan, faXmark, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import * as Constants from "./Constants";
import * as Helper from "./Helper.jsx";
import BillingCustomFields from "./BillingCustomFields.jsx";
import numeral from "numeral";
import React from "react";
import SaveCancelButtons from "./SaveCancelButtons.jsx";
import Tooltip from "./Tooltip";

class SubscriptionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllPaymentDates: false,
    };
  }

  render = () => {
    if (this.props.subscription.isNew) {
      return this.renderNewView();
    } else if (this.props.subscription.editMode) {
      return this.renderEditView();
    } else {
      return this.renderReadonlyView();
    }
  };

  // This view is only for mobile.
  // There is no "readonly view" for desktop, which uses a columnar view when not editing.
  renderReadonlyView = () => {
    const subscription = this.props.subscription;

    // Day of cycle and month of cycle elements
    const { dayOfCycleLabel, dayOfCycleElement } = this.renderCycleElementView(subscription);

    // Custom fields for print view
    const customFieldsRowPrint = this.props.renderCustomFieldsPrint(subscription, " span2 printOnly leftMarginSmall marginTophalfem");

    // Custom fields
    const customFieldsRow = subscription.expanded ? (
      <BillingCustomFields
        subscription={subscription}
        appState={this.props.appState}
        handleChangeCustomField={this.handleChangeCustomField}
        handleBlurCustomField={this.handleBlurCustomField}
        className="span2"
      />
    ) : (
      ""
    );

    // Highlight Overdue balance if it is greater than 0
    const overdueBalanceClasses = subscription.overdue_balance > 0 ? "subscriptionCardItem highlight" : "subscriptionCardItem";

    let nextBillWarning = this.renderNextBillWarning(subscription);
    let paymentMethod = "";
    if ([Constants.CH_SUBSCRIPTION_ACTIVE, Constants.CH_SUBSCRIPTION_SUSPENDED].includes(subscription.status)) {
      paymentMethod = (
        <React.Fragment>
          <label data-testid="Readonly Mobile Payment Method Label">Payment</label>
          <div data-testid="Readonly Mobile Payment Method Container" className="subscriptionCardItem">
            {subscription.masked_card_number || "Card not found"}
          </div>
        </React.Fragment>
      );
    }

    return (
      // Readonly Mobile view
      <div className="recurringSubscriptionCard" key={"Narrow-" + subscription.subscription_id}>
        <div className="viewSubscriptionDetails">
          <div data-testid="Readonly Mobile Subscription Buttons Container" className="subscriptionCardHeader span2">
            {this.renderSubscriptionButtonsReadonly(
              subscription,
              subscription.customFields,
              this.props.isMaastOnlyCustomer,
              this.props.handleExpandSubscription
            )}
          </div>

          <div data-testid="Readonly Mobile Status Container" className="subscriptionCardItem span2 noPrint">
            {Helper.renderStatus(subscription.status)}
          </div>

          <label data-testid="Readonly Mobile Subscription Number Label">Subscription</label>
          <div data-testid="Readonly Mobile Subscription Number" className="subscriptionCardItem">
            {subscription.subscriptionnumber}
          </div>

          <label data-testid="Readonly Mobile Subscription Name Label">Subscription Name</label>
          <div data-testid="Readonly Mobile Subscription Name" className="subscriptionCardItem">
            {subscription.plan_name}
          </div>

          <label data-testid="Readonly Mobile Recur Amount Label">Amount</label>
          <div data-testid="Readonly Mobile Recur Amount" className="subscriptionCardItem recurAmount">
            {numeral(subscription.recur_amt).format(Constants.CURRENCY)}
          </div>

          <label data-testid="Readonly Mobile Frequency Label">Frequency</label>
          <div data-testid="Readonly Mobile Frequency" className="subscriptionCardItem">
            {subscription.plan_frequency}
          </div>

          <label data-testid="Readonly Mobile Duration Label">Duration</label>
          <div data-testid="Readonly Mobile Duration" className="subscriptionCardItem duration">
            {subscription.plan_duration === -1 ? "Until Cancelled" : subscription.plan_duration}
          </div>

          {dayOfCycleLabel}
          {dayOfCycleElement}

          <label data-testid="Readonly Mobile Start Date Label">Start Date</label>
          <div data-testid="Readonly Mobile Start Date" className="subscriptionCardItem startDate">
            {Helper.formatDate(subscription.date_start)}
          </div>

          <label data-testid="Readonly Mobile End Date Label">End Date</label>
          <div data-testid="Readonly Mobile End Date" className="subscriptionCardItem">
            {Helper.renderDateEndOrInfinity(subscription)}
          </div>

          <label data-testid="Readonly Mobile Next Bill Label">Next Bill</label>
          <div data-testid="Readonly Mobile Next Bill" className="subscriptionCardItem dateNext">
            {Helper.formatDate(subscription.date_next)}
          </div>

          {nextBillWarning}

          <label data-testid="Readonly Mobile Invoice Count Label">Invoices</label>
          <div data-testid="Readonly Mobile Invoice Count" className="subscriptionCardItem">
            {subscription.plan_invoice_count}
          </div>

          <label data-testid="Readonly Mobile Paid Invoice Count Label">Paid</label>
          <div data-testid="Readonly Mobile Paid Invoice Count" className="subscriptionCardItem">
            {this.renderPaymentCountElement(subscription)}
          </div>

          <label data-testid="Readonly Mobile Overdue Amount Label">Overdue</label>
          <div data-testid="Readonly Mobile Overdue Amount" className={overdueBalanceClasses}>
            {numeral(subscription.overdue_balance).format(Constants.CURRENCY)}
          </div>

          {/* PRINT ONLY STATUS - The mobile card view already has a status badge, so we don't need a label/value also */}
          <label data-testid="Readonly Mobile Subscription Number Label" className="subscriptionCardItem printOnly">
            Status
          </label>
          <div data-testid="Readonly Mobile Subscription Number" className="subscriptionCardItem printOnly">
            {Helper.renderStatus(subscription.status)}
          </div>

          {paymentMethod}

          {customFieldsRow}
          {customFieldsRowPrint}
        </div>
      </div>
    );
  };

  renderNewView = () => {
    const subscription = this.props.subscription;
    const index = this.props.index;
    const { dayOfCycleLabel, dayOfCycleElement } = this.renderCycleElementView(subscription, "paddingLefthalfem");
    const customfields = this.renderCustomFields(subscription);

    let tooltipPaymentSchedule = (
      <Tooltip
        explanation={true}
        text="If the duration is until cancelled, only the first four projected payments will be displayed. Otherwise, the list of projected payments will display."
      />
    );
    let tooltipStartDate = (
      <Tooltip
        explanation={true}
        text="First Bill Date of the subscription. Valid start dates are 1 through 28. The 29th, 30th, 31st are not allowed."
      />
    );
    const saveButtonClasses = this.props.isReadyToSubmitSubscription(subscription)
      ? "action-button green-button"
      : "action-button green-button save-disabled";
    const saveCancel = this.props.isNewSubscriber ? (
      ""
    ) : (
      <SaveCancelButtons
        containerClasses="control-buttons-center no-top-padding"
        handleCancel={event => {
          this.props.handleCancelAddSubscription(subscription);
        }}
        handleSave={event => {
          this.props.handleSaveNewSubscription(subscription);
        }}
        saveButtonClass={saveButtonClasses}
        datatestidcancel="Subscription Cancel"
        datatestidsave="Subscription Save"
      />
    );

    return (
      // New view
      <div className="subscriptionCardContainer">
        <div className="recurringSubscriptionCard" key={subscription.subscription_id}>
          <div className="newSubscriptionDetails">
            <div className="headerStyle span2 centerAligned marginBottom1em">New Subscription Details</div>

            <label data-testid="New Subscription Name Label" className="marginTophalfem">
              Subscription Name
            </label>
            <div data-testid="New Subscription Name Container" className="subscriptionCardItem subscriptionName">
              {this.renderPlanDescription(subscription, index)}
            </div>

            <label data-testid="New Subscription Amount Mobile Label" className="mobile marginTophalfem">
              Amount
            </label>
            <label data-testid="New Subscription Amount Desktop Label" className="desktop marginTophalfem">
              Recurring Amount
            </label>
            <div data-testid="New Subscription Amount Container" className="subscriptionCardItem recurAmount">
              {this.renderRecurAmt(subscription, index)}
            </div>

            <label data-testid="New Subscription Frequency Label" className="marginTophalfem">
              Frequency
            </label>
            <div data-testid="New Subscription Frequency Container" className="subscriptionCardItem frequency">
              {this.renderPlanFrequency(subscription)}
            </div>

            <label data-testid="New Subscription Duration Label" className="marginTophalfem">
              Duration
            </label>
            <div data-testid="New Subscription Duration Container" className="subscriptionCardItem duration">
              {this.renderDuration(subscription, index)}
            </div>

            {dayOfCycleLabel}
            {dayOfCycleElement}

            <label data-testid="New Subscription Start Date Label" className="marginTophalfem">
              Start Date {tooltipStartDate}
            </label>
            <div data-testid="New Subscription Start Date Container" className="subscriptionCardItem startDate">
              {this.renderStartDate(subscription, index)}
            </div>

            <label data-testid="New Subscription Payment Mobile Label" className="mobile marginTophalfem">
              Payment
            </label>
            <label data-testid="New Subscription Payment Desktop Label" className="desktop marginTophalfem">
              Payment Method
            </label>
            <div data-testid="New Subscription Payment Container" className="subscriptionCardItem paymentMethod">
              {this.renderPaymentSelectElement(subscription, this.props.index)}
            </div>

            <label data-testid="New Subscription Payment Schedule Label">Payment Schedule {tooltipPaymentSchedule}</label>
            <div
              data-testid="New Subscription Payment Schedule Container"
              className="subscriptionCardItem paymentSchedule paddingLeftOneEm marginBottom1em"
            >
              {this.renderPaymentSchedule(subscription)}
            </div>
          </div>

          <div className="subscriptionCustomDetails">
            <div data-testid="New Custom Data Label" className="headerStyle span2 centerAligned marginBottom1em">
              New Custom Data
            </div>
            <div data-testid="New Custom Data Container" className="subscriptionCardHeader">
              {this.renderSubscriptionButtonsEdit(subscription)}
            </div>
            {customfields}
            <div>&nbsp;</div>
          </div>
        </div>
        {saveCancel}
      </div>
    );
  };

  renderEditView = () => {
    const subscription = this.props.subscription;
    const index = this.props.index;

    const { dayOfCycleLabel, dayOfCycleElement } = Constants.CH_SUBSCRIPTION_NO_EDIT_STATUSES.includes(subscription.status)
      ? this.renderCycleElementView(subscription, "paddingLefthalfem")
      : this.renderCycleElementEdit(subscription, index);

    const customfields = this.renderCustomFields(subscription);

    const tooltipExpires = (
      <Tooltip
        explanation={true}
        text="The projected end date is determined by subtracting the number of successful payments from the subscription’s full duration."
      />
    );
    const tooltipDateNext = (
      <Tooltip
        explanation={true}
        text="Adjusting the Bill Date will only affect this current billing cycle. If this change results in a duplicate charge, review the projected billing schedule to assess whether the subsequent payment should be skipped."
      />
    );

    // Show the eye icon to toggle the payment schedule if the subscription has a fixed number of payments.
    // If unlimited payments, show the tooltip explaining.
    const eye =
      subscription.plan_duration === -1 ? (
        <Tooltip
          explanation={true}
          text="This subscription has an unlimited number of payments. The payment schedule will continue until the subscription is cancelled."
        />
      ) : this.state.showAllPaymentDates ? (
        <span onClick={this.handleToggleShowAllPaymentDates} title="Hide all payment dates">
          <FontAwesomeIcon icon={faEyeSlash} />
        </span>
      ) : (
        <span onClick={this.handleToggleShowAllPaymentDates} title="Show all payment dates">
          <FontAwesomeIcon icon={faEye} />
        </span>
      );

    // Highlight Overdue balance if it is greater than 0
    const overdueBalanceClasses =
      subscription.overdue_balance > 0 ? "subscriptionCardItem paddingLefthalfem highlight" : "subscriptionCardItem paddingLefthalfem";

    const deleteButton = subscription.can_delete ? (
      <span className="gridCenter span2 ">
        <span className="action-button-cell delete  ">
          <span
            className="action-button red-button"
            onClick={() => {
              this.props.handleDeleteSubscription(subscription);
            }}
            data-testid="Delete From Other"
          >
            <FontAwesomeIcon icon={faTrashCan} /> Delete
          </span>
        </span>
      </span>
    ) : (
      ""
    );

    let projectedSchedule = "";
    if (subscription.date_next_payments?.length > 1) {
      projectedSchedule = (
        <React.Fragment>
          <label data-testid="Edit View Project Schedule Label">Projected Schedule {eye}</label>
          <div data-testid="Edit View Project Schedule Container" className="subscriptionCardItem paymentSchedule paddingLefthalfem">
            {this.renderPaymentSchedule(subscription)}
          </div>
        </React.Fragment>
      );
    }

    let nextBillDate = "";
    if (subscription.status === Constants.CH_SUBSCRIPTION_ACTIVE) {
      nextBillDate = (
        <React.Fragment>
          <label data-testid="Edit View Next Bill Mobile Label" className="mobile marginTophalfem">
            {subscription.date_next_payments?.length === 1 ? "Last Bill" : "Next Bill"}
          </label>
          <label data-testid="Edit View Next Bill Desktop Label" className="desktop marginTophalfem">
            {subscription.date_next_payments?.length === 1 ? "Last Bill Date" : "Next Bill Date"} {tooltipDateNext}
          </label>
          <div data-testid="Edit View Next Bill Date Container" className="subscriptionCardItem dateNext">
            {this.renderDateNext(subscription, index)}
          </div>{" "}
        </React.Fragment>
      );
    }

    let endDate = "";
    if (subscription.status === Constants.CH_SUBSCRIPTION_ACTIVE && subscription.plan_duration !== -1) {
      endDate = (
        <React.Fragment>
          <label data-testid="Edit View End Date Mobile Label" className="mobile">
            End Date
          </label>
          <label data-testid="Edit View End Date Desktop Label" className="desktop">
            Projected End Date {tooltipExpires}
          </label>
          <div data-testid="Edit View End Date Container" className="subscriptionCardItem paddingLefthalfem">
            {Helper.renderDateEndOrInfinity(subscription)}
          </div>
        </React.Fragment>
      );
    }

    let nextBillWarning = this.renderNextBillWarning(subscription);

    let paymentMethod = "";
    if ([Constants.CH_SUBSCRIPTION_ACTIVE, Constants.CH_SUBSCRIPTION_SUSPENDED].includes(subscription.status)) {
      paymentMethod = (
        <React.Fragment>
          <label data-testid="Edit View Payment Method Mobile Label" className="mobile marginTophalfem">
            Payment
          </label>
          <label data-testid="Edit View Payment Method Desktop Label" className="desktop marginTophalfem">
            Payment Method
          </label>
          <div data-testid="Edit View Payment Method Container" className="subscriptionCardItem paymentMethod marginBottom1em">
            {this.renderPaymentSelectElement(subscription, this.props.index)}
          </div>
        </React.Fragment>
      );
    }

    return (
      // Edit view
      <div className="recurringSubscriptionCard" key={subscription.subscription_id}>
        <div className="editSubscriptionDetails">
          <div className="subscriptionCardHeader span2 mobile">{this.renderSubscriptionButtonsEdit(subscription)}</div>
          <div className="headerStyle headerText span2 marginBottom1em">Edit Subscription Details</div>

          <label data-testid="Edit View Subscription Number Label">Subscription</label>
          <div data-testid="Edit View Subscription Number" className="subscriptionCardItem subscriptionnumber paddingLefthalfem">
            {subscription.subscriptionnumber}
          </div>

          <label data-testid="Edit View Subscription Name Label" className="marginTophalfem">
            Subscription Name
          </label>
          <div data-testid="Edit View Subscription Name Container" className="subscriptionCardItem subscriptionName">
            {this.renderPlanDescription(subscription, index)}
          </div>

          <label data-testid="Edit View Recurring Amount Mobile Label" className="mobile marginTophalfem">
            Amount
          </label>
          <label data-testid="Edit View Recurring Amount Desktop Label" className="desktop marginTophalfem">
            Recurring Amount
          </label>
          <div data-testid="Edit View Recurring Amount Container" className="subscriptionCardItem recurAmount">
            {this.renderRecurAmt(subscription, index)}
          </div>

          <label data-testid="Edit View Frequency Label">Frequency</label>
          <div data-testid="Edit View Frequency" className="subscriptionCardItem frenquency paddingLefthalfem">
            {subscription.plan_frequency}
          </div>

          <label data-testid="Edit View Duration Label">Duration</label>
          <div data-testid="Edit View Duration" className="subscriptionCardItem duration paddingLefthalfem">
            {subscription.plan_duration === -1 ? "Until Cancelled" : subscription.plan_duration}
          </div>

          {dayOfCycleLabel}
          {dayOfCycleElement}

          <label data-testid="Edit View Start Date Label">Start Date</label>
          <div data-testid="Edit View Start Date" className="subscriptionCardItem startDate paddingLefthalfem">
            {Helper.formatDate(subscription.date_start)}
          </div>

          {endDate}
          {nextBillDate}
          {nextBillWarning}
          {projectedSchedule}

          <label data-testid="Edit View Number Invoices Mobile Label" className="mobile">
            Invoices
          </label>
          <label data-testid="Edit View Number Invoices Desktop Label" className="desktop">
            Number of Invoices
          </label>
          <div data-testid="Edit View Number Invoices" className="subscriptionCardItem paddingLefthalfem">
            {subscription.plan_invoice_count}
          </div>

          <label data-testid="Edit View Payment Count Mobile Label" className="mobile">
            Paid
          </label>
          <label data-testid="Edit View Payment Count Desktop Label" className="desktop">
            Invoices Paid
          </label>
          <div data-testid="Edit View Payment Count Container" className="subscriptionCardItem paddingLefthalfem">
            {this.renderPaymentCountElement(subscription)}
          </div>

          <label data-testid="Edit View Overdue Mobile Label" className="mobile">
            Overdue
          </label>
          <label data-testid="Edit View Amount Overdue Desktop Label" className="desktop">
            Overdue Amount
          </label>
          <div data-testid="Edit View Overdue Balance" className={overdueBalanceClasses}>
            {numeral(subscription.overdue_balance).format(Constants.CURRENCY)}
          </div>

          <label data-testid="Edit View Status Label" className="marginTophalfem">
            Status
          </label>
          <div data-testid="Edit View Status Select Container" className="subscriptionCardItem subscriptionStatus">
            {this.renderStatusSelect(subscription, index)}
          </div>

          {paymentMethod}
        </div>

        <div className="subscriptionCustomDetails">
          <div data-testid="Edit View Custom Data Label" className="headerStyle headerText span2 marginBottom1em">
            Edit Custom Data
          </div>
          <div data-testid="Edit View Custom Data Container" className="subscriptionCardHeader desktop">
            {this.renderSubscriptionButtonsEdit(subscription)}
          </div>
          {customfields}
          <div>&nbsp;</div>
        </div>
        {deleteButton}
      </div>
    );
  };

  renderPlanDescription = (subscription, index) => {
    if (Constants.CH_SUBSCRIPTION_NO_EDIT_STATUSES.includes(subscription.status)) {
      return (
        <div data-testid="View Subscription Description" className="subscriptionCardItem paddingLefthalfem marginTophalfem">
          {subscription.plan_name}
        </div>
      );
    } else {
      return (
        <input
          type="text"
          name="plan_name"
          id="plan_name"
          data-testid="Edit Subscription Name Input"
          className="subscriptionCardItem subscriptionName"
          value={subscription.plan_name}
          onFocus={Helper.handleFocus}
          onChange={event => {
            this.props.handleChangePlanDescription(index, event);
          }}
          onBlur={event => {
            this.props.handleBlurSubscription(subscription.subscriptionuuid, "string", event, subscription.isNew);
          }}
        />
      );
    }
  };

  renderRecurAmt = (subscription, index) => {
    if (Constants.CH_SUBSCRIPTION_NO_EDIT_STATUSES.includes(subscription.status)) {
      return (
        <div data-testid="View Subscription Amount" className="subscriptionCardItem paddingLefthalfem marginTophalfem">
          {subscription.recur_amt}
        </div>
      );
    } else {
      return (
        <input
          type="text"
          name="recur_amt"
          id="recur_amt"
          data-testid="Edit Subscription Amount Input"
          maxLength={7}
          value={subscription.recur_amt}
          onFocus={Helper.handleFocus}
          onChange={event => {
            this.props.handleChangeRecurAmt(index, event);
          }}
          onBlur={event => {
            this.props.handleBlurSubscription(subscription.subscriptionuuid, "float", event, subscription.isNew);
          }}
        />
      );
    }
  };

  renderPlanFrequency = (subscription, index) => {
    return (
      <select
        name="plan_frequency"
        id="plan_frequency"
        data-testid="Edit Subscription Frequency Select"
        value={subscription.plan_frequency}
        onChange={event => {
          this.props.handleChangeSubscription(subscription, "string", event);
        }}
      >
        <option value={Constants.CH_RECURRING_PLAN_FREQUENCY_DAILY}>Daily</option>
        <option value={Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY}>Weekly</option>
        <option value={Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY}>Monthly</option>
        <option value={Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY}>Annually</option>
      </select>
    );
  };

  renderStartDate = (subscription, index) => {
    const violations = this.props.checkSubscriptionValidationRules(subscription);
    let errorTooltip = "";
    if (violations.some(v => v.failure === "date_start")) {
      errorTooltip = <Tooltip explanation={true} error={true} noGhost={true} text={violations.find(v => v.failure === "date_start").message} />;
    }
    return (
      <React.Fragment>
        <input
          type="date"
          name="date_start"
          id="date_start"
          data-testid="Edit Start Date Input"
          value={subscription.date_start}
          onChange={event => {
            this.props.handleChangeStartDate(index, event);
          }}
        />
        &nbsp; &nbsp;
        <span data-testid="Error Tooltip"> {errorTooltip}</span>
      </React.Fragment>
    );
  };

  renderDateNext = (subscription, index) => {
    if (Constants.CH_SUBSCRIPTION_NO_EDIT_STATUSES.includes(subscription.status)) {
      return (
        <div data-testid="View Subscription Next Bill" className="subscriptionCardItem paddingLefthalfem marginTophalfem">
          {Helper.formatDate(subscription.date_next)}
        </div>
      );
    } else {
      return (
        <input
          type="date"
          name="date_next"
          id="date_next"
          data-testid="Edit Subscription Next Bill Input"
          value={subscription.date_next}
          onFocus={event => {
            Helper.handleFocus(event, false);
          }}
          onChange={event => {
            this.props.handleChangeDateNext(index, event);
          }}
          onBlur={event => {
            this.props.handleBlurSubscription(subscription.subscriptionuuid, "date", event, subscription.isNew);
          }}
        />
      );
    }
  };

  renderDuration = (subscription, index) => {
    let plan_duration = subscription.plan_duration === -1 ? "Until Cancelled" : subscription.plan_duration;
    const durationError = this.renderDurationWarning(subscription);
    return (
      <div className="durationContainer">
        <input
          type="text"
          name="plan_duration"
          id="plan_duration"
          data-testid="Edit Subscription Duration or Next Date Input"
          placeholder="# of Payments"
          maxLength={4}
          disabled={subscription.plan_duration === -1}
          value={plan_duration}
          onChange={event => {
            this.props.handleChangeDuration(index, event);
          }}
        />
        <label className="checkboxContainer" htmlFor="plan_duration_unlimited">
          <input
            type="checkbox"
            name="plan_duration_unlimited"
            id="plan_duration_unlimited"
            onChange={event => {
              this.props.handleChangeUnlimitedDuration(index, event);
            }}
            checked={subscription.plan_duration_unlimited}
          />
          <span data-testid="Edit Unlimited Duration Checkmark" className="checkmark"></span>
        </label>
        <FontAwesomeIcon icon={faInfinity} />
        {durationError}
      </div>
    );
  };

  renderStatusSelect = (subscription, index) => {
    if (subscription.isNew) {
      return Helper.renderStatus(Constants.SUBSCRIPTION_NEW);
    }
    // Cancelled or complete subscriptions cannot be edited
    else if ([Constants.CH_SUBSCRIPTION_CANCELLED, Constants.CH_SUBSCRIPTION_COMPLETE].includes(subscription.status)) {
      return Helper.renderStatus(subscription.status);
    } else {
      return (
        <select
          name="status"
          id="status"
          data-testid="Edit Subscription Status Select"
          onChange={event => {
            this.props.handleChangeSubscriptionStatus(event, index);
          }}
          value={subscription.status}
        >
          {this.renderStatusSelectOptions(subscription)}
        </select>
      );
    }
  };

  renderStatusSelectOptions = subscription => {
    let options = [
      { key: Constants.CH_SUBSCRIPTION_ACTIVE, label: "Active" },
      { key: Constants.CH_SUBSCRIPTION_CANCELLED, label: "Cancel" },
      { key: Constants.CH_SUBSCRIPTION_PAUSED, label: "Paused" },
    ];
    if (subscription.status === Constants.CH_SUBSCRIPTION_SUSPENDED) {
      options.push({ key: Constants.CH_SUBSCRIPTION_SUSPENDED, label: "Suspend" });
    }

    return options.map(option => {
      return (
        <option data-testid="Status Select Options" value={option.key} key={option.key}>
          {option.label}
        </option>
      );
    });
  };

  renderPaymentSelectElement = (subscription, index) => {
    const cards = this.props.maastCustomer?.billing_cards ?? [];
    if (Constants.CH_SUBSCRIPTION_NO_EDIT_STATUSES.includes(subscription.status) || (!subscription.editMode && !subscription.isNew)) {
      return (
        <div data-testid="Render Payment Method" className="subscriptionLabel">
          {subscription.masked_card_number}
        </div>
      );
    }

    // If no card is selected but there are cards on file, show a "Please select" option
    let pleaseSelect = null;
    let card_id = subscription.card_id;
    if (!card_id && cards.length > 0) {
      pleaseSelect = (
        <option key="pleaseSelect" value="">
          Please select
        </option>
      );
    }

    // Check for no cards on file
    if (cards.length === 0) {
      return (
        <div data-testid="No Payment Method on file" className="subscriptionLabel">
          No Payment Method on file
        </div>
      );
    }

    // Render different <select>'s for MOBILE and DESKTOP
    return (
      <React.Fragment>
        <select
          name="storedPayment"
          id="storedPayment"
          data-testid="Edit Desktop Stored Payment"
          className="desktop"
          onFocus={Helper.handleFocus}
          onChange={event => this.props.handleChangePaymentOption(event, this.props.index)}
          value={subscription.card_id}
        >
          {this.renderPaymentSelectOptions(pleaseSelect)}
        </select>
        <select
          name="storedPayment"
          id="storedPayment"
          data-testid="Edit Mobile Stored Payment"
          className="mobile"
          onFocus={Helper.handleFocus}
          onChange={event => this.props.handleChangePaymentOption(event, this.props.index)}
          value={subscription.card_id}
        >
          {this.renderPaymentSelectOptionsNarrow(pleaseSelect)}
        </select>
      </React.Fragment>
    );
  };

  renderPaymentSelectOptionsNarrow = pleaseSelect => {
    const cards = this.props.maastCustomer?.billing_cards ?? [];
    let cardsElements = cards.map(card => {
      const key = card.card_id;
      return (
        <option key={key} value={card.card_id}>
          {card.card_number}
        </option>
      );
    });
    if (pleaseSelect) {
      cardsElements.unshift(pleaseSelect);
    }
    return cardsElements;
  };

  renderPaymentSelectOptions = pleaseSelect => {
    const cards = this.props.maastCustomer?.billing_cards ?? [];
    let cardsElements = cards.map(card => {
      const key = card.card_id;
      const name = card.billing_first_name + " " + card.billing_last_name;
      const desc = name + " " + card.card_number.slice(-6);
      return (
        <option key={key} value={card.card_id}>
          {desc}
        </option>
      );
    });
    if (pleaseSelect) {
      cardsElements.unshift(pleaseSelect);
    }
    return cardsElements;
  };

  renderSubscriptionButtonsReadonly = (subscription, customFields, isMaastOnlyCustomer, handleExpandSubscription) => {
    let leftButton = <span>&nbsp; &nbsp;</span>;
    let rightButton = Helper.renderSubscriptionExpandButton(subscription, customFields, isMaastOnlyCustomer, handleExpandSubscription);

    if (Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(subscription.status)) {
      // Show Save/Cancel when editing
      if (subscription.editMode && (!subscription.isNew || this.props.subscriptions.length > 1)) {
        leftButton = "";
        rightButton = (
          <span data-testid="Cancel or Edit Subscription" className="cancelIcon" onClick={this.props.handleCancelEditSubscription}>
            <FontAwesomeIcon icon={faXmark} />
          </span>
        );
      } else if (subscription.expanded) {
        leftButton = <span>&nbsp; &nbsp;</span>;
      } else if (this.props.subscriptions.filter(sub => sub.editMode).length > 0) {
        // If any subscription is in edit mode, don't show edit button
        leftButton = <span>&nbsp; &nbsp;</span>;
      } else {
        leftButton = (
          <span
            data-testid="Edit Subscription Button"
            className="noPrint"
            title="Edit Subscription"
            onClick={() => {
              this.props.handleToggleEditMode(subscription, this.props.index);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </span>
        );
      }
    }

    return (
      <div className="subscriptionLabel">
        {leftButton} &nbsp; &nbsp; {rightButton}
      </div>
    );
  };

  renderSubscriptionButtonsEdit = subscription => {
    if (!subscription.isNew && subscription.editMode) {
      return (
        <div data-testid="Close Subscription Button" className="subscriptionLabel" onClick={this.props.handleCancelEditSubscription}>
          <FontAwesomeIcon icon={faCircleMinus} />
        </div>
      );
    } else {
      return "";
    }
  };

  renderNextBillWarning(subscription) {
    let nextBillWarning = "";
    // If the next bill date is off-cycle, show a warning
    if (Helper.isDateNextOffCycle(subscription)) {
      nextBillWarning = (
        <div data-testid="Off Cycle Warning" className="subscriptionCardItem offCycleWarning span2">
          <span className="highlight">
            <FontAwesomeIcon icon={faExclamationTriangle} /> Next bill date is off-cycle and could result in a duplicate charge.
          </span>
        </div>
      );
    }
    return nextBillWarning;
  }

  renderDurationWarning(subscription) {
    let durationWarning = "";
    // If the next bill date is off-cycle, show a warning
    if (subscription.plan_duration === "") {
      durationWarning = (
        <div data-testid="No Duration Warning" className="subscriptionCardItem noDurationWarning span2">
          <span className="highlight">
            <FontAwesomeIcon icon={faExclamationTriangle} /> Enter a duration or select 'Until Cancelled'.
          </span>
        </div>
      );
    }
    return durationWarning;
  }

  renderPaymentCountElement(subscription) {
    let failedPaymentsCount = subscription.plan_invoice_count - subscription.plan_payment_count;
    let paid =
      failedPaymentsCount > 0 ? (
        <span data-testid="Payment Counts" className="highlight">
          {subscription.plan_payment_count} ({failedPaymentsCount} failed)
        </span>
      ) : (
        <span>{subscription.plan_payment_count}</span>
      );
    return paid;
  }

  renderNextBillDate(subscription) {
    let nextBill = [Helper.formatDate(subscription.date_next)];
    // Next bill date(s)
    if (subscription.date_next_payments?.length > 0) {
      nextBill = subscription.date_next_payments
        .slice(0, 3)
        .map(date => Helper.formatDateUTC(date))
        .map((date, index) => {
          return <div key={index}>{date}</div>;
        });
    }
    return nextBill;
  }

  renderPaymentSchedule(subscription) {
    if (this.props.checkSubscriptionValidationRules(subscription).some(v => v.failure === "date_start")) {
      return "";
    }

    if (Constants.CH_SUBSCRIPTION_NO_EDIT_STATUSES.includes(subscription.status)) {
      return <div data-testid="No Payment Schedule"> No payments scheduled</div>;
    }

    if (subscription.isNew) {
      //
      const dates = subscription.date_next_payments;
      return dates.map(date => {
        const d = Helper.formatDate(date);
        return (
          <div data-testid="Payment Schedule" key={d}>
            {d}
          </div>
        );
      });
    } else {
      let nextBill = <div data-testid="No Payment Schedule">No payments scheduled</div>;
      const last = 4 + (subscription.skip_oxt_payment ? 1 : 0);
      if (subscription.date_next_payments?.length > 0) {
        // Build a list of dates
        const dates = this.state.showAllPaymentDates
          ? subscription.date_next_payments.slice(1).map(date => Helper.formatDate(date))
          : subscription.date_next_payments.slice(1, last).map(date => Helper.formatDate(date));
        const more = subscription.date_next_payments.length - last;
        // Add a "more" if there are more than 4 dates
        if (subscription.plan_duration !== -1 && !this.state.showAllPaymentDates && subscription.date_next_payments.length > last) {
          dates.push(<span className="italic fontSizeSmall" onClick={this.handleToggleShowAllPaymentDates}>{`(and ${more} more)`}</span>);
        }
        nextBill = dates.map((date, index) => {
          // The first date is the next bill date which is the only one that can be skipped (oxt'd)
          if (index === 0) {
            const classes = subscription.skip_oxt_payment ? "skippingPayment" : "";
            const title = subscription.skip_oxt_payment ? "Skipping this payment" : "Scheduled payment";
            return (
              <div key={index}>
                <span data-testid="Payment Schedule" className={classes} title={title}>
                  {date}
                </span>
                <label className="skipOxtPayment checkboxContainer leftMarginSmall" htmlFor="skipOxtPayment">
                  <span data-testid="Skip Oxt Payment Desktop Label" className="desktop-inline">
                    Check to skip this payment
                  </span>
                  <span data-testid="Skip Oxt Payment Mobile Label" className="mobile">
                    Skip
                  </span>
                  <input
                    type="checkbox"
                    name="skipOxtPayment"
                    id="skipOxtPayment"
                    data-testid="Skip Oxt Payment"
                    onChange={event => {
                      this.props.handleSkipOxtPayment(this.props.index, event);
                    }}
                    checked={subscription.skip_oxt_payment}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            );
          } else {
            return (
              <div data-testid="Payment Schedule" key={index}>
                {date}
              </div>
            );
          }
        });
      }
      return nextBill;
    }
  }

  renderCycleElementView(subscription, labelClasses = "") {
    let dayOfCycleLabel = <label>Bills on</label>;
    let dayOfCycleElement = "";
    switch (subscription.plan_frequency) {
      case Constants.CH_RECURRING_PLAN_FREQUENCY_DAILY:
        dayOfCycleElement = (
          <div data-testid="View Day of Cycle" className={"subscriptionCardItem dayOfCycle " + labelClasses}>
            Every Day
          </div>
        );
        break;
      case Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY:
        dayOfCycleElement = (
          <div data-testid="View Day of Cycle" className={"subscriptionCardItem dayOfCycle " + labelClasses}>
            {Helper.renderDayOfCycle(subscription)}
          </div>
        );
        break;
      case Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY:
        dayOfCycleElement = (
          <div data-testid="View Day of Cycle" className={"subscriptionCardItem dayOfCycle " + labelClasses}>
            {Helper.renderDayOfCycle(subscription)}
          </div>
        );
        break;
      case Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY:
        dayOfCycleElement = (
          <div data-testid="View Day of Cycle" className={"subscriptionCardItem dayOfCycle " + labelClasses}>
            {Constants.MONTHS[subscription.month_of_cycle]} {Helper.renderDayOfCycle(subscription)}
          </div>
        );
        break;
      default:
        dayOfCycleLabel = "";
    }
    return { dayOfCycleLabel, dayOfCycleElement };
  }

  renderCycleElementEdit(subscription) {
    // Day of cycle and month of cycle elements
    let billsOnTipText = "";
    if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY) {
      billsOnTipText = "Subscription Month and Day of Cycle. Changes to this field will affect all future payment dates.";
    } else {
      billsOnTipText = "Subscription Day of Cycle. Changes to this field will affect all future payment dates.";
    }

    let tooltipBillsOn = <Tooltip explanation={true} text={billsOnTipText} />;
    let dayOfCycleLabel = "";
    let dayOfCycleElement = "";
    switch (subscription.plan_frequency) {
      case Constants.CH_RECURRING_PLAN_FREQUENCY_DAILY:
        dayOfCycleLabel = <label>Bills on {tooltipBillsOn}</label>;
        dayOfCycleElement = (
          <div data-testid="View Day of Cycle" className="subscriptionCardItem dayOfCycle paddingLefthalfem">
            Every Day
          </div>
        );
        break;
      case Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY:
      case Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY:
        dayOfCycleLabel = <label className="marginTophalfem">Bills on {tooltipBillsOn}</label>;
        dayOfCycleElement = (
          <div className="subscriptionCardItem dayOfCycle">
            {Helper.renderDayOfCycleSelect(subscription.plan_frequency, subscription.day_of_cycle, event => {
              this.props.handleChangeSubscription(subscription, "integer", event);
            })}
          </div>
        );
        break;
      case Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY:
        dayOfCycleLabel = <label className="marginTophalfem">Bills on {tooltipBillsOn}</label>;
        dayOfCycleElement = (
          <div className="subscriptionCardItem dayOfCycle">
            {Helper.renderMonthOfCycle(subscription, event => {
              this.props.handleChangeSubscription(subscription, "integer", event);
            })}
            <span className="desktop-inline">&nbsp; &nbsp;</span>
            {Helper.renderDayOfCycleSelect(
              subscription.plan_frequency,
              subscription.day_of_cycle,
              event => {
                this.props.handleChangeSubscription(subscription, "integer", event);
              },
              subscription.month_of_cycle
            )}
          </div>
        );
        break;
      default:
        console.warn("Unsupported Frequency");
        dayOfCycleLabel = "";
        dayOfCycleElement = "";
    }
    return { dayOfCycleLabel, dayOfCycleElement };
  }

  renderCustomFields(subscription) {
    return !subscription.custom_fields || subscription.custom_fields?.length === 0 ? (
      <div data-testid="No custom fields defined" className="italic centerAligned span2">
        No custom fields defined. See settings to add custom fields.
      </div>
    ) : (
      subscription.custom_fields
        ?.filter(cf => !cf.archived) // filter out archived custom fields
        .map((field, i) => {
          // If the subscription is inactive, don't allow changes to custom fields
          // TODO: We may need to revisit this if we want to allow changes to custom fields on inactive subscriptions
          let onChange = Constants.CH_SUBSCRIPTION_NO_EDIT_STATUSES.includes(subscription.status)
            ? () => {}
            : event => {
                this.props.handleChangeCustomField(event, field.subscriptionid, field.uuid);
              };
          let onBlur = Constants.CH_SUBSCRIPTION_NO_EDIT_STATUSES.includes(subscription.status)
            ? () => {}
            : event => {
                this.props.handleBlurCustomField(
                  event,
                  subscription.companyuuid,
                  field.subscriptionid,
                  field.customfieldlabeluuid,
                  subscription.isNew
                );
              };
          return (
            <React.Fragment key={i}>
              <label className="customLabel" htmlFor={field.uuid}>
                {field.description}
              </label>
              <div className="noPrint customFields list-item" key={i}>
                <input
                  type="text"
                  name={field.uuid}
                  id={field.uuid}
                  data-testid="Custom Field Input"
                  value={field.customfieldvalue}
                  placeholder={field.description}
                  onFocus={Helper.handleFocus}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </div>
              <div>&nbsp;</div>
            </React.Fragment>
          );
        })
    );
  }

  handleToggleShowAllPaymentDates = () => {
    this.setState(prevState => ({ showAllPaymentDates: !prevState.showAllPaymentDates }));
  };
}

export default SubscriptionCard;
