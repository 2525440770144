import React from "react";

import { animated, useSpring } from "@react-spring/web";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Capo from "../img/Capo.js";

function NewsWidget(props) {
  const [expanded, setExpanded] = React.useState(false);
  const openAnimation = useSpring({
    from: { maxHeight: "6em" },
    to: { maxHeight: expanded ? "100em" : "6em" },
    config: { duration: "400" },
  });
  let title = "Latest Updates";
  if (props.appState?.clientSettings?.NAME) {
    title = "Updates for " + props.appState?.clientSettings?.NAME;
  }
  const expanderIcon = expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />;
  const expander = (
    <div
      className="dbwExpander"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      {expanderIcon}
    </div>
  );
  return (
    <animated.div
      className="dashboardWidget dbwNews"
      onClick={() => {
        setExpanded(!expanded);
      }}
      style={openAnimation}
    >
      <div className="dbwTitle">
        <span className="newsLogo">
          <Capo />
        </span>
        <span data-testid="Updates for">{title}</span>
      </div>
      <hr />
      <h3 className="white">Latest Enhancements</h3>
      <ul>
        <li>Cash and cash-like payments can now be deleted with manager authorization. [1/1/2025]</li>
        <li>The Invoice tabs in the folder view now include "Balance Due" amounts. [1/1/2025]</li>
        <li>You can now search invoices and other order types using the UPC, EAN, or SKU of one of the items in the order. [1/1/2025]</li>
        <li>
          Totals are now available at the bottom of all list screens (orders, quotes, purchases, etc.) for managers and above. Don't forget that
          clicking on "Total Price" will toggle between price and cost, giving you a clearer picture of your margins. [11/20/2024]
        </li>
        <li>
          <span className="highlight">Pro tip:</span> To save time with quicker product scans, add all your items to an invoice before you hit save!
        </li>
      </ul>
      <h3 className="white">Bug Fixes</h3>
      <ul>
        <li>The Online tab for Invoices list now shows both Reverb and CartLoom orders. [1/6/2025]</li>
        <li>Gracefully handle errors when order item attributes (such as sales associate) cannot be updated due to a data conflict. [12/4/2024]</li>
        <li>Correctly setting cost on order items when scanning a barcode to add a product to an existing order/invoice. [11/8/2024]</li>
        <li>Purchase orders can now be emailed to supplier contacts with just a first OR last name (not both). [11/1/2024]</li>
      </ul>
      {expander}
    </animated.div>
  );
}

export default NewsWidget;
