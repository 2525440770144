import React from "react";

import { faAngleDown, faCheck, faPrint, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import numeral from "numeral";
import Tooltip from "./Tooltip";
import Switch from "./Switch";

// View for editing a contact
class Report extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    this.filterOptionsRef = React.createRef();
    const start = Helper.dateAsString();
    const end = start;

    this.state = {
      ...this.state,
      viewName: Constants.REPORT,
      reporttype: props.selectedItem.reporttype,
      start: start,
      end: end,
      includeSubscriptionInvoices: Helper.isFalse(this.props.appState?.displaySettings?.EXCLUDE_SUBSCRIPTIONS),
      isNew: false,
      reportDownloadCancelled: false,
      csv: "",
      csv_count: 0,
      record_count: null,
      showFilterOptions: false,
      dateFilterType: Constants.FILTER_DATE_TODAY,
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    super.componentDidMount();
    this.props.setExportCallback(this.handleExportReport);
    // Check the setting for including subscriptions in the UI
  }

  renderAreaReportHeader = () => {
    if (this.props.selectedItem.reportname) {
      return <div className="areaReportHeader">{this.props.selectedItem.reportname}</div>;
    } else return "";
  };

  renderReportBody = () => {
    return <div className="areaReportBody">{this.renderReportDetail()}</div>;
  };

  renderReportDetail = () => {
    let report = "";
    if (this.state.reporttype === Constants.REPORT_TIMESHEETS) {
      if (!this.state[Constants.REPORT_TIMESHEET_TOTALS] && !this.state.timesheetexceptions) {
        report = <div className="noData white">Select dates for report and click Run Report</div>;
      } else {
        report = (
          <React.Fragment>
            {this.renderTimesheetTotals()}
            {this.renderTimesheetExceptions()}
          </React.Fragment>
        );
      }
    } else if (this.state.reporttype === Constants.REPORT_SALES) {
      if (
        (this.state[Constants.REPORT_TAX_SUMMARY] === undefined || this.state[Constants.REPORT_TAX_DETAIL] === undefined) &&
        this.state[Constants.REPORT_PAYMENT_DETAIL] === undefined &&
        this.state[Constants.REPORT_DEPOSIT_DETAIL] === undefined &&
        this.state[Constants.REPORT_SALES_DATA] === undefined &&
        this.state.maastTransactions === undefined
      ) {
        report = <div className="noData white">Select dates for report and click Run Report</div>;
      } else if (
        (!this.state[Constants.REPORT_TAX_SUMMARY] || !this.state[Constants.REPORT_TAX_DETAIL]) &&
        !this.state[Constants.REPORT_PAYMENT_DETAIL] &&
        !this.state[Constants.REPORT_DEPOSIT_DETAIL] &&
        !this.state[Constants.REPORT_SALES_DATA] &&
        !this.state.maastTransactions
      ) {
        report = (
          <div className="noData white span2" data-testid="No data available">
            No data available
          </div>
        );
      } else {
        report = (
          <React.Fragment>
            {this.renderSalesData()}
            {this.renderTaxSummary()}
            {this.renderPaymentSummary()}
            {this.renderDepositSummary()}
            {this.renderRecurringBilling()}
          </React.Fragment>
        );
      }
    } else if (this.state.reporttype === Constants.REPORT_KEY_METRICS) {
      const keymetrics = this.state[Constants.REPORT_KEY_METRICS];
      if (!keymetrics) {
        report = <div className="noData white">Select dates for report and click Run Report</div>;
      } else {
        report = <React.Fragment>{this.renderKeyMetrics(keymetrics)}</React.Fragment>;
      }
    } else if (this.state.reporttype === Constants.REPORT_ORDER_ITEMS) {
      const detail = this.state[Constants.REPORT_ORDER_ITEMS];
      if (!detail) {
        report = <div className="noData white">Select dates for report and click Run Report</div>;
      } else {
        report = this.renderOrderItems();
      }
    } else if (this.state.reporttype === Constants.REPORT_BILLING_TRANSACTIONS) {
      const detail = this.state.maastTransactions;
      if (!detail) {
        report = <div className="noData white">Select dates for report and click Run Report</div>;
      } else {
        report = this.renderBillingTransactions();
      }
    } else if (this.state.reporttype === Constants.REPORT_BILLING_INVOICES) {
      const detail = this.state.maastInvoices;
      if (!detail) {
        report = <div className="noData white">Select dates for report and click Run Report</div>;
      } else {
        report = this.renderBillingInvoices();
      }
    } else if (this.state.reporttype === Constants.REPORT_PAYMENT_ORDER_DETAIL) {
      const detail = this.state[Constants.REPORT_PAYMENT_ORDER_DETAIL];
      if (!detail) {
        report = <div className="noData white">Select dates for report and click Run Report</div>;
      } else {
        report = this.renderPaymentOrderDetail();
      }
    }
    return (
      <div className="salesReport">
        <div className="reportSettings">
          {this.renderFilterComponent()}
          <div className="reportStartDate">
            <label htmlFor="start">Begin Date: </label>
            <input type="date" id="start" onChange={this.handleChangeStart} value={this.state.start} />
          </div>
          <div className="reportEndDate">
            <label htmlFor="end">End Date:</label>
            <input type="date" id="end" onChange={this.handleChangeEnd} value={this.state.end} />
          </div>
          {this.renderSubscriptionFilter()}
          {this.renderRunReport()}
          {this.renderPrintReport()}
        </div>
        {report}
      </div>
    );
  };

  renderSubscriptionFilter = () => {
    if (
      [Constants.REPORT_ORDER_ITEMS, Constants.REPORT_PAYMENT_ORDER_DETAIL].includes(this.state.reporttype) &&
      this.props.appState?.features.includes(Constants.FEATURE_RECURRINGS) &&
      this.props.appState?.maast?.merchant_id
    ) {
      return (
        <div className="reportIncludeSubscriptions">
          <Switch
            elementid="includeSubscriptionInvoices"
            label="Include Subscription Invoices"
            switchClass=""
            handleChange={this.handleIncludeRecurringBilling}
            fieldname="includeSubscriptionInvoices"
            title="Include subscription invoices in report data"
            checked={this.state.includeSubscriptionInvoices}
          />
        </div>
      );
    } else {
      return "";
    }
  };

  handleIncludeRecurringBilling = () => {
    this.setState({ includeSubscriptionInvoices: !this.state.includeSubscriptionInvoices });
  };

  renderFilterComponent = () => {
    let filterLabel = Helper.renderFilterLabel(this.state.dateFilterType);
    const filterListClasses = this.state.showFilterOptions ? " open " : "";
    return (
      <div className="reportFilterType">
        <label htmlFor="filter-list">Filter by:</label>
        <span className="filterReport" onClick={this.handleShowFilterOptions}>
          {filterLabel} <FontAwesomeIcon icon={faAngleDown} />
          <div id="filter-list" ref={this.filterOptionsRef} className={filterListClasses}>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_TODAY)}>Today</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_YESTERDAY)}>Yesterday</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_THIS_WEEK)}>This Week</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_THIS_MONTH)}>This Month</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_THIS_QUARTER)}>This Quarter</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_THIS_YEAR)}>This Year</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_LAST_WEEK)}>Last Week</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_LAST_MONTH)}>Last Month</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_LAST_QUARTER)}>Last Quarter</div>
            <div onClick={() => this.handleFilterSelection(Constants.FILTER_DATE_LAST_YEAR)}>Last Year</div>
          </div>
        </span>
      </div>
    );
  };

  renderRunReport = () => {
    let handler = this.handleLoadData;
    let classNames = "action-button green-button";
    if (!this.state.start || !this.state.end || this.state.start > this.state.end) {
      classNames += " save-disabled";
      handler = () => {};
    }
    return (
      <div className="reportButton noPrint">
        <span data-testid="Run Report" className={classNames} onClick={handler}>
          Run Report
        </span>
      </div>
    );
  };

  renderPrintReport = () => {
    return (
      <div className="reportButton noPrint">
        <span className="action-button brown-button" onClick={window.print}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </span>
      </div>
    );
  };

  renderTimesheetTotals = () => {
    if (!this.state[Constants.REPORT_TIMESHEET_TOTALS] || this.state[Constants.REPORT_TIMESHEET_TOTALS].length === 0) {
      return <div className="noData white">No timesheet entries for date range</div>;
    }
    let timesheets = this.state[Constants.REPORT_TIMESHEET_TOTALS].map(item => {
      return (
        <React.Fragment key={item.fullname}>
          <div className="timesheetListValue">{item.fullname}</div>
          <div className="timesheetListValue right-aligned">{numeral(item.totalTime).format(Constants.CURRENCY)}</div>
        </React.Fragment>
      );
    });
    return (
      <div className="reportTable">
        <div className="timesheetHeaderLabel">Employee Name</div>
        <div className="timesheetHeaderLabel right-aligned">Hours</div>
        {timesheets}
      </div>
    );
  };

  renderTimesheetExceptions = () => {
    if (!this.state.timesheetexceptions || this.state.timesheetexceptions.length === 0) {
      return "";
    }
    const exceptionitems = this.state.timesheetexceptions.map(item => {
      const clockin = Helper.formatDateTime(item.clockin);
      const clockout = Helper.formatDateTime(item.clockout);
      return (
        <React.Fragment key={item.fullname}>
          <div className="timesheetListValue">{item.fullname}</div>
          <div className="timesheetListValue">{clockin}</div>
          <div className="timesheetListValue">{clockout}</div>
          <div className="timesheetListValue right-aligned">{numeral(item.totalTime).format(Constants.CURRENCY)}</div>
        </React.Fragment>
      );
    });
    return (
      <React.Fragment>
        <hr />
        <div>These timesheet entries partially fell into the date range of the report and are not included in the hours reported above.</div>
        <div className="areaReportTimesheetExceptions">
          <div className="timesheetHeaderLabel">Employee Name</div>
          <div className="timesheetHeaderLabel">Clock In</div>
          <div className="timesheetHeaderLabel">Clock Out</div>
          <div className="timesheetHeaderLabel right-aligned">Hours</div>
          {exceptionitems}
        </div>
      </React.Fragment>
    );
  };

  renderSalesData = () => {
    if (!this.state[Constants.REPORT_SALES_DATA]) {
      return (
        <div className="reportTable">
          <h2 className="span2">Sales Before Tax</h2>
          <div className="noData white span2">No sales data available</div>
        </div>
      );
    }
    const salesData = this.state[Constants.REPORT_SALES_DATA];
    let netSales = numeral(salesData.net_sales);
    const taxableSales = numeral(salesData.taxable_sales);
    const nonTaxableSales = numeral(salesData.non_taxable_sales);
    let nonTaxableSalesTooltip = (
      <Tooltip explanation={true} text="The non-taxable sales figure includes closed invoices for non-taxable customers and non-taxable line items" />
    );
    let taxdetail = (
      <React.Fragment>
        <div className="metricListLabel">Taxable Sales</div>
        <div className="metricListValue right-aligned" data-testid="Taxable Sales">
          {taxableSales.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div className="metricListLabel">Non-Taxable Sales {nonTaxableSalesTooltip}</div>
        <div className="metricListValue right-aligned" data-testid="Non-Taxable Sales">
          {nonTaxableSales.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
      </React.Fragment>
    );
    // No point in showing tax detail if there are no sales (taxable or otherwise)
    if (!netSales.value() && !taxableSales.value() && !nonTaxableSales.value()) {
      taxdetail = "";
    }

    let salesTooltip = <Tooltip explanation={true} text="The net sales figure includes all closed sales less discounts and refunds" />;
    return (
      <div className="reportTable">
        <h2 className="span2">Sales Before Tax</h2>
        <div className="metricListLabel">Net Sales {salesTooltip}</div>
        <div className="metricListValue right-aligned" data-testid="Net Sales">
          {netSales.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        {taxdetail}
      </div>
    );
  };

  renderRecurringBilling = () => {
    if (this.props.appState?.features.includes(Constants.FEATURE_RECURRINGS) && this.props.appState?.maast?.merchant_id) {
      return this.renderRecurringBillingClerkhound();
    } else if (this.props.appState?.features.includes(Constants.FEATURE_BILLING) && this.props.appState?.maast?.merchant_id) {
      return this.renderRecurringBillingMaast();
    } else {
      return "";
    }
  };

  renderRecurringBillingClerkhound = () => {
    let billingSales;
    if (this.state.loading) {
      billingSales = (
        <React.Fragment>
          <div className="metricListLabel">Recurring Billing Revenue</div>
          <div className="metricListValue right-aligned">
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
          </div>
        </React.Fragment>
      );
    } else if (!this.state.reportrecurringrevenue || this.state.reportrecurringrevenue?.length === 0) {
      billingSales = <div className="noData white span2">No recurring billing data available</div>;
    } else {
      const reportrecurringrevenue = this.state.reportrecurringrevenue[0];
      let amount = numeral(reportrecurringrevenue?.recurring_sales || 0).format(Constants.CURRENCY_WITH_SYMBOL);
      billingSales = (
        <React.Fragment>
          <div data-testid="Recurring Billing Revenue Label" className="metricListLabel">
            Recurring Billing Revenue ({reportrecurringrevenue?.ordercount || "0"})
          </div>
          <div data-testid="Recurring Billing Revenue Amount" className="metricListValue right-aligned">
            {amount}
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className="reportTable">
        <h2 className="span2">Recurring Billing</h2>
        {billingSales}
      </div>
    );
  };

  renderRecurringBillingMaast = () => {
    let billingSales;
    if (this.state.loading) {
      billingSales = (
        <React.Fragment>
          <div className="metricListLabel">Recurring Billing Revenue</div>
          <div className="metricListValue right-aligned">
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
          </div>
        </React.Fragment>
      );
    } else if (this.state.maastTransactions?.length === 0) {
      billingSales = <div className="noData white span2">No recurring billing data available</div>;
    } else {
      const billingSalesAmount = numeral(
        (this.state.maastTransactions || []).reduce((acc, item) => {
          return numeral(item.amt_tran).subtract(item.amt_refunded).add(acc).value();
        }, 0)
      );
      //netSales = netSales.add(billingSalesAmount.value());
      billingSales = (
        <React.Fragment>
          <div className="metricListLabel">Recurring Billing Revenue ({this.state.maastTransactions?.length || "0"})</div>
          <div className="metricListValue right-aligned" data-testid="Billing Sales Amount">
            {billingSalesAmount.format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className="reportTable">
        <h2 className="span2">Recurring Billing</h2>
        {billingSales}
      </div>
    );
  };

  renderTaxSummary = () => {
    if (
      !this.state[Constants.REPORT_TAX_SUMMARY] ||
      !this.state[Constants.REPORT_TAX_DETAIL] ||
      this.state[Constants.REPORT_TAX_DETAIL].length === 0
    ) {
      return (
        <div className="reportTable">
          <h2 className="span2">Tax Summary</h2>
          <div className="noData white span2">No tax data available</div>
        </div>
      );
    }
    //Total Taxes accumulated in Orders Database
    let taxCollectedSummary = numeral(this.state[Constants.REPORT_TAX_SUMMARY][0].nettaxcollected ?? 0);

    //Total Taxes accumulated in Taxes Database
    let calcTaxTotal = numeral(0);
    const taxes = this.state[Constants.REPORT_TAX_DETAIL].map(item => {
      calcTaxTotal.add(numeral(item.nettaxcollected ?? 0).value());
      return (
        <React.Fragment key={item.description}>
          <div className="taxListLabel">{item.description}</div>
          <div className="taxListValue right-aligned" data-testid="Net Tax Collected">
            {numeral(item.nettaxcollected).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    });
    // Compare tax database total taxes to the orders database total taxes
    let auditMessage = "";
    if (calcTaxTotal.format(Constants.CURRENCY) !== taxCollectedSummary.format(Constants.CURRENCY)) {
      let taxDifference = calcTaxTotal.subtract(taxCollectedSummary.value());
      auditMessage = (
        <div>
          <div className="error"> Calculated Taxes do not match audit check</div>
          <div>{taxDifference.format(Constants.CURRENCY_WITH_SYMBOL)}</div>
          <div>Calculated Tax Total (Taxes) </div>
          {calcTaxTotal.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
      );
    }

    return (
      <div className="reportTable">
        <h2 className="span2">Tax Summary</h2>
        {taxes}
        <div className="totalTaxListLabel">Total Tax Collected</div>
        <div className="totalTaxListValue right-aligned" data-testid="Total Tax Collected">
          {taxCollectedSummary.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        {auditMessage}
      </div>
    );
  };

  renderPaymentSummary = () => {
    if (!this.state[Constants.REPORT_PAYMENT_DETAIL] || this.state[Constants.REPORT_PAYMENT_DETAIL].length === 0) {
      return (
        <div className="reportTable">
          <h2 className="span2">Payments Summary</h2>
          <div className="noData white span2">No payment data available</div>
        </div>
      );
    }
    //Total Payments accumulated in Payments Database
    let payments = this.state[Constants.REPORT_PAYMENT_DETAIL].filter(item => item.paymenttype !== Constants.DEPOSIT_APPLIED);
    let calcPaymentTotal = numeral(0);
    payments = payments.map(item => {
      let subtype = "";
      if ([Constants.CREDIT, Constants.CREDIT_REFUND].includes(item.paymenttype)) {
        subtype = item.subtype;
      }
      calcPaymentTotal.add(numeral(item.netpaymentscollected ?? 0).value());
      let paymentcount = "";
      if (item.paymentcount) {
        paymentcount = "(" + item.paymentcount + ")";
      }
      return (
        <React.Fragment key={item.paymenttype + "-" + item.refund + +"-" + item.processor + "-" + item.subtype}>
          <div className="metricListLabel" data-testid="Payment Label">
            {Helper.renderPaymentType(item.paymenttype, subtype, item.refund)} {paymentcount}
          </div>
          <div className="metricListValue right-aligned" data-testid="Payment Amount">
            {numeral(item.netpaymentscollected).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    });

    return (
      <div className="reportTable">
        <h2 className="span2">Payments Summary</h2>
        {payments}
        <div className="paymentTotalLabel">Total Payments</div>
        <div className="paymentTotalValue right-aligned" data-testid="Total Payments">
          {calcPaymentTotal.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
      </div>
    );
  };

  renderDepositSummary = () => {
    let deposits;
    let depositAppliedTooltip = (
      <Tooltip
        explanation={true}
        text="The applied deposit amount represents a deposit payment received from a Quote, Order, or Service, which has been allocated to an invoice within the selected timeframe. "
      />
    );
    if (!this.state[Constants.REPORT_DEPOSIT_DETAIL] || this.state[Constants.REPORT_DEPOSIT_DETAIL].length === 0) {
      deposits = <div className="noData white span2">No applied deposit data available</div>;
    } else {
      //Total Deposits accumulated in Payments Database
      deposits = this.state[Constants.REPORT_DEPOSIT_DETAIL].map(item => {
        return (
          <React.Fragment key={item.ordertype}>
            <div className="metricListLabel">
              To {item.ordertype}s {depositAppliedTooltip}
            </div>
            <div className="metricListValue right-aligned" data-testid="Deposit Amount">
              {numeral(item.netpaymentscollected).format(Constants.CURRENCY_WITH_SYMBOL)}
            </div>
          </React.Fragment>
        );
      });
    }

    return (
      <div className="reportTable">
        <h2 className="span2">Deposits Applied</h2>
        {deposits}
      </div>
    );
  };

  renderBillingInvoices = () => {
    if (!this.state.maastInvoices || this.state.maastInvoices.length === 0) {
      return (
        <div className="noData white" data-testid="No data available">
          No data available
        </div>
      );
    }
    let totalAmount = numeral(0);
    let totalPaid = numeral(0);
    let totalBalance = numeral(0);
    let invoices = this.state.maastInvoices.map(item => {
      const invoiceDateTime = Helper.formatDate(item.date_invoice, false, false);
      const invoiceDate = Helper.formatDate(item.date_invoice, true, false);
      const invoiceID = item.invoice_id;
      const invoiceName = `${item.billing_contact.first_name} ${item.billing_contact.last_name}`;
      const dateSent = Helper.formatDate(item.date_sent, false, false);
      const status = item.status;
      const statusIcon =
        item.amt_balance === 0 ? (
          <FontAwesomeIcon icon={faCheck} className="approvedCheck" />
        ) : (
          <FontAwesomeIcon icon={faXmark} className="declinedX" />
        );
      const invoiceTotal = numeral(item.amt_tran);
      const amountPaid = numeral(item.amt_paid);
      const datePaid = Helper.formatDate(item.date_payment, false, false);
      const balance = numeral(item.amt_balance);
      // Add to the totals
      totalAmount.add(invoiceTotal.value());
      totalPaid.add(amountPaid.value());
      totalBalance.add(balance.value());
      return (
        <React.Fragment key={item.invoice_id + item.invoice_number}>
          <div className="reporttableitem desktop">{invoiceDateTime}</div>
          <div className="reporttableitem mobile">{invoiceDate}</div>
          <div className="reporttableitem desktop">{invoiceID}</div>
          <div className="reporttableitem desktop">{dateSent}</div>
          <div className="reporttableitem">{invoiceName}</div>
          <div className="reporttableitem desktop">{status}</div>
          <div className="reporttableitem right-aligned desktop">{invoiceTotal.format(Constants.CURRENCY)}</div>
          <div className="reporttableitem right-aligned">{amountPaid.format(Constants.CURRENCY)}</div>
          <div className="reporttableitem desktop">{datePaid}</div>
          <div className="reporttableitem right-aligned desktop">{balance.format(Constants.CURRENCY)}</div>
          <div className="reporttableitem mobile">{statusIcon}</div>
          <div>&nbsp;</div>
        </React.Fragment>
      );
    });
    const totals = (
      <React.Fragment key="reportOrderItems">
        <div className="reporttabletotal span5 desktop">&nbsp;</div>
        <div className="reporttabletotal span2 mobile">&nbsp;</div>
        <div className="reporttabletotal right-aligned desktop">{totalAmount.format(Constants.CURRENCY_WITH_SYMBOL)}</div>
        <div className="reporttabletotal right-aligned">{totalPaid.format(Constants.CURRENCY_WITH_SYMBOL)}</div>
        <div className="reporttabletotal desktop">&nbsp;</div>
        <div className="reporttabletotal right-aligned desktop">{totalBalance.format(Constants.CURRENCY_WITH_SYMBOL)}</div>
      </React.Fragment>
    );
    return (
      <div className="reportTable reportTable10">
        <div className="span10 noPrint desktop">&nbsp;</div>
        <div className="span5 noPrint mobile">&nbsp;</div>
        <div className="reporttabletotal">Date</div>
        <div className="reporttabletotal desktop">ID</div>
        <div className="reporttabletotal desktop">Sent</div>
        <div className="reporttabletotal">Name</div>
        <div className="reporttabletotal desktop">Status</div>
        <div className="reporttabletotal right-aligned desktop">Total</div>
        <div className="reporttabletotal right-aligned">Paid</div>
        <div className="reporttabletotal mobile">&nbsp;</div>
        <div className="reporttabletotal desktop">Date Paid</div>
        <div className="reporttabletotal right-aligned desktop">Balance</div>
        <div>&nbsp;</div>
        {invoices}
        {totals}
      </div>
    );
  };

  renderBillingTransactions = () => {
    if (!this.state.maastTransactions || this.state.maastTransactions.length === 0) {
      return (
        <div className="noData white" data-testid="No data available">
          No data available
        </div>
      );
    }
    let totalAmount = numeral(0);
    let totalRefunded = numeral(0);
    let transactions = this.state.maastTransactions.map(item => {
      const transactionDate = Helper.formatDate(item.tran_time, false, false);
      const transactionDateShort = Helper.formatDate(item.tran_time, true, false);
      const cardholderName = `${item.cardholder_first_name} ${item.cardholder_last_name}`;
      const cardNumber = item.card_number;
      const rmsg = item.rmsg;
      const result = Constants.SALE_APPROVAL_CODES.includes(item.rcode) ? (
        <FontAwesomeIcon icon={faCheck} className="approvedCheck" />
      ) : (
        <FontAwesomeIcon icon={faXmark} className="declinedX" />
      );
      let transactionAmount = numeral(item.amt_tran);
      const transactionRefunded = numeral(item.amt_refunded);
      // Sum the transaction amount for totals only if approved.
      if (Constants.SALE_APPROVAL_CODES.includes(item.rcode)) {
        totalAmount.add(transactionAmount.value());
        transactionAmount = transactionAmount.format(Constants.CURRENCY);
      }
      //if declined, then strikethrough the amount so that it implies that the amount was not included in the totals
      else {
        transactionAmount = <span className="strikethrough">{transactionAmount.format(Constants.CURRENCY)}</span>;
      }

      totalRefunded.add(transactionRefunded.value());

      return (
        <React.Fragment key={transactionDate + item.pg_id}>
          <div className="reporttableitem desktop">{transactionDate}</div>
          <div className="reporttableitem mobile">{transactionDateShort}</div>
          <div className="reporttableitem">{cardholderName}</div>
          <div className="reporttableitem desktop">{cardNumber}</div>
          <div className="reporttableitem desktop">{rmsg}</div>
          <div className="reporttableitem right-aligned">{transactionAmount}</div>
          <div className="reporttableitem mobile">{result}</div>
          <div className="reporttableitem right-aligned desktop">{transactionRefunded.format(Constants.CURRENCY)}</div>
          <div>&nbsp;</div>
        </React.Fragment>
      );
    });
    const totals = (
      <React.Fragment key="reportOrderItems">
        <div className="reporttabletotal span4 desktop">&nbsp;</div>
        <div className="reporttabletotal span2 mobile">&nbsp;</div>
        <div className="reporttabletotal right-aligned">{totalAmount.format(Constants.CURRENCY_WITH_SYMBOL)}</div>
        <div className="reporttabletotal right-aligned desktop">{totalRefunded.format(Constants.CURRENCY_WITH_SYMBOL)}</div>
      </React.Fragment>
    );
    return (
      <div className="reportTable reportTable6">
        <div className="span7 noPrint desktop">&nbsp;</div>
        <div className="span5 noPrint mobile">&nbsp;</div>
        <div className="reporttabletotal">Date</div>
        <div className="reporttabletotal">Name</div>
        <div className="reporttabletotal desktop">Card Number</div>
        <div className="reporttabletotal desktop">Result</div>
        <div className="reporttabletotal right-aligned">Amount</div>
        <div className="reporttabletotal mobile">&nbsp;</div>
        <div className="reporttabletotal right-aligned desktop">Refunded</div>
        <div>&nbsp;</div>
        {transactions}
        {totals}
      </div>
    );
  };

  renderOrderItems = () => {
    if (!this.state[Constants.REPORT_ORDER_ITEMS] || this.state[Constants.REPORT_ORDER_ITEMS].length === 0) {
      return (
        <div className="noData white" data-testid="No data available">
          No data available
        </div>
      );
    }
    let totalCostOfGoodsSold = numeral(0);
    let totalPriceOfGoodsSold = numeral(0);
    let totalDiscountOfGoodsSold = numeral(0);
    let totalEstimatedTax = numeral(0);
    let saleInvoices = this.state[Constants.REPORT_ORDER_ITEMS]
      // .filter(item => item.ordersubtype !== Constants.RETURN)
      .map(item => {
        const quantity = numeral(item.quantity);
        const cost = numeral(item.cost);
        const totalcost = numeral(item.cost).multiply(quantity.value());
        const price = numeral(item.sellprice);
        const discount = numeral(item.discount).multiply(quantity.value());
        const totalprice = numeral(item.sellprice).multiply(quantity.value());
        const estimateditemtax = numeral(item.estimateditemtax ?? 0);
        const ordernumber = Helper.getOrderNumber(item.ordertype, item.ordersubtype, item.ordernumber);
        const creationdate = Helper.formatDate(item.creationdatetime, false, false);
        const closedate = Helper.formatDate(item.closedate, false, false);
        const associate = item.associate;

        // Add to the totals
        totalCostOfGoodsSold.add(totalcost.value());
        totalPriceOfGoodsSold.add(totalprice.value());
        totalDiscountOfGoodsSold.add(discount.value());
        totalEstimatedTax.add(estimateditemtax.value());
        return (
          <React.Fragment key={item.orderitemuuid}>
            <div className="reporttableitem desktop">{ordernumber}</div>
            <div className="reporttableitem desktop">{creationdate}</div>
            <div className="reporttableitem desktop">{closedate}</div>
            <div className="reporttableitem desktop">{associate}</div>
            <div className="reporttableitem desktop">{item.sku}</div>
            <div className="reporttableitem ">{item.productname}</div>
            <div className="reporttableitem desktop right-aligned">{quantity.format(Constants.DECIMAL_VALUE)}</div>
            <div className="reporttableitem desktop right-aligned">{cost.format(Constants.CURRENCY)}</div>
            <div className="reporttableitem desktop right-aligned">{price.format(Constants.CURRENCY)}</div>
            <div className="reporttableitem desktop right-aligned">{totalcost.format(Constants.CURRENCY)}</div>
            <div className="reporttableitem right-aligned">{totalprice.format(Constants.CURRENCY)}</div>
            <div className="reporttableitem desktop right-aligned">{discount.format(Constants.CURRENCY)}</div>
            <div className="reporttableitem right-aligned">{estimateditemtax.format(Constants.CURRENCY)}</div>
            <div className="reporttableitem desktop">&nbsp;</div>
          </React.Fragment>
        );
      });

    const totals = (
      <React.Fragment key="reportOrderItems">
        {/* Cost of goods sold */}
        <div className="reporttabletotal desktop right-aligned span9">&nbsp;</div>
        <div className="reporttabletotal mobile right-aligned">&nbsp;</div>
        <div data-testid="Total Cost Amount" className="reporttabletotal desktop right-aligned">
          {totalCostOfGoodsSold.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div data-testid="Total Price Amount" className="reporttabletotal right-aligned">
          {totalPriceOfGoodsSold.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div data-testid="Total Discount Amount" className="reporttabletotal desktop right-aligned">
          {totalDiscountOfGoodsSold.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div data-testid="Total Estimated Tax Amount" className="reporttabletotal right-aligned">
          {totalEstimatedTax.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div className="reporttabletotal desktop">&nbsp;</div>
      </React.Fragment>
    );

    return (
      <div className="reportTable reportTable12">
        <div className="reporttableitem desktop span14 italic">&nbsp;</div>
        <div className="reporttableitem span14 italic noPrint desktop">
          This reports lists the line item details for all invoices closed within the specified time period. Full line item details available via
          export in the gear menu. Please note that large timeframes may require a significant amount of time to process. For best results, it is
          recommended that this report be printed in landscape orientation, using legal-sized paper.
          <br /> <span className="white">*</span> Sales taxes are computed at the invoice level, resulting in tax estimations at the line item level
          that may not precisely align with the total tax amount for the invoice.
        </div>
        <div className="mobile span3 italic noPrint">Full line item details available via export in the gear menu.</div>
        <div className="mobile span3 noPrint">&nbsp;</div>
        <div className="reporttableitem desktop span14 italic">&nbsp;</div>
        <div className="reporttabletotal desktop">Invoice</div>
        <div className="reporttabletotal desktop">Created</div>
        <div className="reporttabletotal desktop">Closed</div>
        <div className="reporttabletotal desktop">Sales Associate</div>
        <div className="reporttabletotal desktop">SKU</div>
        <div className="reporttabletotal ">Product Name</div>
        <div className="reporttabletotal desktop right-aligned">Qty</div>
        <div className="reporttabletotal desktop right-aligned">Cost</div>
        <div className="reporttabletotal desktop right-aligned">Price</div>
        <div className="reporttabletotal desktop right-aligned">Total Cost</div>
        <div className="reporttabletotal right-aligned">Total Price</div>
        <div className="reporttabletotal desktop right-aligned">Total Disc</div>
        <div className="reporttabletotal right-aligned">Tax*</div>
        <div className="reporttabletotal desktop">&nbsp;</div>
        {saleInvoices}
        {totals}
      </div>
    );
  };

  renderKeyMetrics = keymetrics => {
    let gross_sales = keymetrics.gross_sales || 0;
    let gross_sales_including_returns = keymetrics.gross_sales_including_returns || 0;
    let gross_returns = keymetrics.gross_returns || 0;
    let gross_margin = keymetrics.gross_margin || 0;
    let net_sales = keymetrics.net_sales || 0;
    let net_revenue = keymetrics.net_revenue || 0;
    let recurring = "";
    let recurringCalculation = "";
    let recurringRevenue = 0;
    let averageRecurringRevenue = 0;
    if (this.state.maastTransactions?.length > 0) {
      let billingSalesAmount = 0;
      let billingRefundAmount = 0;
      billingSalesAmount = numeral(
        (this.state.maastTransactions || []).reduce((acc, item) => {
          return numeral(item.amt_tran).add(acc).value();
        }, 0)
      ).value();
      billingRefundAmount = numeral(
        (this.state.maastTransactions || []).reduce((acc, item) => {
          return numeral(item.amt_refunded).add(acc).value();
        }, 0)
      ).value();

      // Calculate the recurring revenue metric
      recurringRevenue = numeral(billingSalesAmount).subtract(billingRefundAmount).value();
      averageRecurringRevenue = numeral(recurringRevenue).divide(this.state.maastTransactions.length).value();
      recurring = (
        <React.Fragment>
          <div className="metricListLabel">Average Recurring Revenue</div>
          <div className="metricListValue right-aligned">{numeral(averageRecurringRevenue).format(Constants.CURRENCY_WITH_SYMBOL)}</div>
          <div>&nbsp;</div>
        </React.Fragment>
      );
      recurringCalculation = (
        <React.Fragment>
          <h3 className="span3 metricReportHeaders">Average Recurring Revenue Calculation</h3>

          <div className="span3 italic">
            Knowing the average recurring revenue for a certain time period helps you see how much money you can reliably expect to make regularly.
            <br />
            This information lets you make smart choices about how to spend your money, where to put your resources, and how to grow your business.
            <br />
            <br />
          </div>

          <div className="metricListLabel span3 formula">Average Recurring Revenue = Recurring Revenue / Number of Transactions</div>
          <div className="metricListLabel span3 formula">
            {numeral(averageRecurringRevenue).format(Constants.CURRENCY_WITH_SYMBOL)} ={" "}
            {numeral(recurringRevenue).format(Constants.CURRENCY_WITH_SYMBOL)} /{" "}
            {numeral(this.state.maastTransactions.length).format(Constants.DECIMAL_VALUE)}
          </div>
        </React.Fragment>
      );
    } else if (this.state[Constants.REPORT_RECURRING_REVENUE]?.length > 0) {
      const recurringRevenue = this.state[Constants.REPORT_RECURRING_REVENUE][0]?.recurring_sales || 0;

      // Calculate the recurring revenue metric
      averageRecurringRevenue = numeral(recurringRevenue).divide(this.state[Constants.REPORT_RECURRING_REVENUE][0]?.ordercount).value();
      recurring = (
        <React.Fragment>
          <div data-testid="Average Recurring Revenue Label" className="metricListLabel">
            Average Recurring Revenue
          </div>
          <div data-testid="Average Recurring Revenue Amount" className="metricListValue right-aligned">
            {numeral(averageRecurringRevenue).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
          <div>&nbsp;</div>
        </React.Fragment>
      );
      recurringCalculation = (
        <React.Fragment>
          <h3 className="span3 metricReportHeaders">Average Recurring Revenue Calculation</h3>

          <div className="span3 italic">
            Knowing the average recurring revenue for a certain time period helps you see how much money you can reliably expect to make regularly.
            <br />
            This information lets you make smart choices about how to spend your money, where to put your resources, and how to grow your business.
            <br />
            <br />
          </div>

          <div data-testid="Average Recurring Revenue Calculation Label" className="metricListLabel span3 formula">
            Average Recurring Revenue = Recurring Revenue / Number of Transactions
          </div>
          <div data-testid="Average Recurring Revenue Calculation Amount" className="metricListLabel span3 formula">
            {numeral(averageRecurringRevenue).format(Constants.CURRENCY_WITH_SYMBOL)} ={" "}
            {numeral(recurringRevenue).format(Constants.CURRENCY_WITH_SYMBOL)} /{" "}
            {numeral(this.state[Constants.REPORT_RECURRING_REVENUE][0]?.ordercount).format(Constants.DECIMAL_VALUE)}
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="reportTable reportTable3">
        <h2 className="span3 metricReportHeaders">Average Performance Metrics</h2>
        <div className="metricListLabel">Gross Margin</div>
        <div className="metricListValue right-aligned">{numeral(gross_margin).multiply(100).format(Constants.DECIMAL_VALUE)} %</div>
        <div>&nbsp;</div>
        <div className="metricListLabel">New Customers</div>
        <div className="metricListValue right-aligned">
          {numeral(keymetrics.customer_acquisition).multiply(100).format(Constants.DECIMAL_VALUE)} %
        </div>
        <div>&nbsp;</div>
        <div className="metricListLabel">Repeat Purchases</div>
        <div className="metricListValue right-aligned">{numeral(keymetrics.repeat_purchases).multiply(100).format(Constants.DECIMAL_VALUE)} %</div>
        <div>&nbsp;</div>
        <div className="metricListLabel">Average Order Value</div>
        <div className="metricListValue right-aligned">{numeral(keymetrics.order_value).format(Constants.CURRENCY_WITH_SYMBOL)}</div>
        <div>&nbsp;</div>
        <div className="metricListLabel">Inventory Cash Flow</div>
        <div className="metricListValue right-aligned">{numeral(keymetrics.inventory_cash_flow).format(Constants.CURRENCY_WITH_SYMBOL)}</div>
        <div>&nbsp;</div>
        {recurring}
        <div className="span3">&nbsp;</div>
        <h3 className="span3 metricReportHeaders">Gross Margin Calculations</h3>

        <div className="span3 italic">
          Gross margin helps you measure the profitability of your business. <br />
          It helps you identify areas of improvement in operations related to sales and pricing. <br />
          The higher the gross margin, the more profitable your business is. <br />
          Note, this data does not reflect expenses such as rent, utilities, payroll, etc.
          <br />
          <br />
        </div>

        {/* Gross Margin */}
        <div className="metricListLabel span3 formula">Gross Margin = Net Revenue / Net Sales</div>
        <div className="metricListLabel span3 formula">
          {numeral(keymetrics.gross_margin).multiply(100).format(Constants.DECIMAL_VALUE)}% ={" "}
          {numeral(net_revenue).format(Constants.CURRENCY_WITH_SYMBOL)} / {numeral(net_sales).format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div className="span3">&nbsp;</div>

        {/* Net Revenue */}
        <div className="metricListLabel span3 formula">Net Revenue = Net Sales - Cost of Goods Sold</div>
        <div className="metricListLabel span3 formula">
          {numeral(net_revenue).format(Constants.CURRENCY_WITH_SYMBOL)} = {numeral(net_sales).format(Constants.CURRENCY_WITH_SYMBOL)} -{" "}
          {numeral(keymetrics.cost_of_goods_sold).format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div className="span3">&nbsp;</div>

        {/* Net Sales */}
        <div className="metricListLabel span3 formula">Net Sales = Gross Sales - Discounts</div>
        <div className="metricListLabel span3 formula">
          {numeral(net_sales).format(Constants.CURRENCY_WITH_SYMBOL)} = {numeral(gross_sales).format(Constants.CURRENCY_WITH_SYMBOL)} -{" "}
          {numeral(keymetrics.gross_discounts).format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div className="span3">&nbsp;</div>

        {/* Gross Sales */}
        <div className="metricListLabel span3 formula">Gross Sales = Gross Sales including Returns - Returns</div>
        <div className="metricListLabel span3 formula">
          {numeral(gross_sales).format(Constants.CURRENCY_WITH_SYMBOL)} ={" "}
          {numeral(gross_sales_including_returns).format(Constants.CURRENCY_WITH_SYMBOL)} -{" "}
          {numeral(gross_returns).format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div className="span3">&nbsp;</div>

        <h3 className="span3 metricReportHeaders">New Customer Calculation</h3>

        <div className="span3 italic">
          New customer acquisition measures your success in getting new customers. <br />
          Use this to set realistic goals, inform marketing and sales strategies, and track progress over time.
          <br />
          <br />
        </div>

        <div className="metricListLabel span3 formula">New Customer Acquisition Rate = New Customers / Total Customers * 100</div>
        <div className="metricListLabel span3 formula">
          {numeral(keymetrics.customer_acquisition).multiply(100).format(Constants.DECIMAL_VALUE)}% ={" "}
          {numeral(keymetrics.new_customers).format(Constants.INTEGER_WITH_COMMA)} /{" "}
          {numeral(keymetrics.total_customers).format(Constants.INTEGER_WITH_COMMA)} * 100
        </div>
        <div className="span3">&nbsp;</div>

        <h3 className="span3 metricReportHeaders">Repeat Customer Calculation</h3>

        <div className="span3 italic">
          Are your customers coming back again and again for more? <br />
          Repeat customer rate measures customer loyalty and helps you identify opportunities for customer retention.
          <br />
          <br />
        </div>

        <div className="metricListLabel span3 formula">Repeat Customer Rate = Customers with Multiple Purchases / Total Customers * 100</div>
        <div className="metricListLabel span3 formula">
          {numeral(keymetrics.repeat_purchases).multiply(100).format(Constants.DECIMAL_VALUE)}% ={" "}
          {numeral(keymetrics.repeat_customers).format(Constants.INTEGER_WITH_COMMA)} /{" "}
          {numeral(keymetrics.total_customers).format(Constants.INTEGER_WITH_COMMA)} * 100
        </div>
        <div className="span3">&nbsp;</div>

        <h3 className="span3 metricReportHeaders">Average Order Value Calculation</h3>

        <div className="span3 italic">
          Use average invoice value to gain a better understanding of your customers' spending habits. <br />
          This metric captures the average amount of money your customers spend on each transaction at your store.
          <br />
          <br />
        </div>

        <div className="metricListLabel span3 formula">Average Invoice Value = Net Sales / Total Number of Invoices</div>
        <div className="metricListLabel span3 formula">
          {numeral(keymetrics.order_value).format(Constants.CURRENCY_WITH_SYMBOL)} = {numeral(net_sales).format(Constants.CURRENCY_WITH_SYMBOL)} /{" "}
          {numeral(keymetrics.total_orders).format(Constants.INTEGER_WITH_COMMA)}
        </div>
        <div className="span3">&nbsp;</div>

        <h3 className="span3 metricReportHeaders">Inventory Cash Flow Calculation</h3>

        <div className="span3 italic">
          The heart of your business's success is its cash flow. <br />
          This metric helps you feel confident your store's sales are running ahead of your purchases.
          <br />
          <br />
        </div>

        <div className="metricListLabel span3 formula">Inventory Cash Flow = Net Sales - Total Purchases Received</div>
        <div className="metricListLabel span3 formula">
          {numeral(keymetrics.inventory_cash_flow).format(Constants.CURRENCY_WITH_SYMBOL)} ={" "}
          {numeral(net_sales).format(Constants.CURRENCY_WITH_SYMBOL)} -{" "}
          {numeral(keymetrics.total_purchase_amount).format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div className="span3">&nbsp;</div>
        {recurringCalculation}
      </div>
    );
  };

  renderPaymentOrderDetail = () => {
    if (!this.state[Constants.REPORT_PAYMENT_ORDER_DETAIL] || this.state[Constants.REPORT_PAYMENT_ORDER_DETAIL].length === 0) {
      return (
        <div className="noData white" data-testid="No data available">
          No data available
        </div>
      );
    }
    let totalPaymentsCollected = numeral(0);
    let payments = this.state[Constants.REPORT_PAYMENT_ORDER_DETAIL].map(item => {
      const amount = numeral(item.amount);
      const orderTotal =
        item.paymenttype === Constants.CHANGE
          ? ""
          : numeral(item.totalprice ?? 0)
              .add(numeral(item.totaltax ?? 0).value())
              .format(Constants.CURRENCY_WITH_SYMBOL);
      const ordernumber =
        item.paymenttype === Constants.CHANGE ? "" : item.ordersubtype.slice(0, 1) + item.ordertype.slice(0, 1) + "-" + item.ordernumber;
      const creationdate = item.paymenttype === Constants.CHANGE ? "" : Helper.formatDate(item.creationdatetime, false, false);
      const paymentcreationdate = item.paymenttype === Constants.CHANGE ? "" : Helper.formatDate(item.paymentcreationdatetime, false, false);
      const closedate = item.paymenttype === Constants.CHANGE ? "" : Helper.formatDate(item.closedate, false, false);
      const salesperson = item.paymenttype === Constants.CHANGE ? "" : item.salesperson;
      const responsecode = item.responsecode;
      const paymentType = Helper.renderPaymentType(item.paymenttype, item.subtype, item.refund);
      const status = item.paymenttype === Constants.CHANGE ? "" : Helper.renderStatus(item.orderstatus);
      const contactname = item.paymenttype === Constants.CHANGE ? "" : item.contactname;

      // Add to the totals (except applied deposits)
      totalPaymentsCollected.add(amount.value());
      return (
        <React.Fragment key={item.paymentuuid}>
          <div className="reporttableitem">{ordernumber}</div>
          <div className="reporttableitem desktop">{contactname}</div>
          <div className="reporttableitem desktop">{salesperson}</div>
          <div className="reporttableitem desktop">{creationdate}</div>
          <div className="reporttableitem desktop">{closedate}</div>
          <div className="reporttableitem desktop">{paymentcreationdate}</div>
          <div className="reporttableitem desktop">{responsecode}</div>
          <div className="reporttableitem" data-testid="Payment Type">
            {paymentType}
          </div>
          <div className="reporttableitem right-aligned">{amount.format(Constants.CURRENCY_WITH_SYMBOL)}</div>
          <div className="reporttableitem desktop right-aligned">{orderTotal}</div>
          <div className="reporttableitem desktop">{status}</div>
          <div className="reporttableitem desktop">&nbsp;</div>
        </React.Fragment>
      );
    });

    const totals = (
      <React.Fragment key="reportOrderItems">
        <div className="reporttabletotal desktop right-aligned span8">Total</div>
        <div className="reporttabletotal mobile right-aligned span2">Total</div>
        <div className="reporttabletotal right-aligned" data-testid="Total Payments Collected">
          {totalPaymentsCollected.format(Constants.CURRENCY_WITH_SYMBOL)}
        </div>
        <div className="reporttabletotal desktop">&nbsp;</div>
      </React.Fragment>
    );

    return (
      <div className="reportTable reportTable12b">
        <div className="reporttableitem desktop span12 noPrint">&nbsp;</div>
        <div className="reporttableitem span12 italic noPrint desktop">
          This report provides a comprehensive overview of all payments made within the specified time period. Payments initially made as deposits for
          Quotes/Orders/Repairs are included. However, to avoid duplicating payment amounts, any deposit applied is excluded. Full payment details
          available via export in the gear menu. Please note that large timeframes may require a significant amount of time to process. For best
          results, it is recommended that this report be printed in landscape orientation.
        </div>
        <div className="reporttableitem desktop span12 italic">&nbsp;</div>
        <div className="reporttabletotal ">Number</div>
        <div className="reporttabletotal desktop">Customer</div>
        <div className="reporttabletotal desktop">Salesperson</div>
        <div className="reporttabletotal desktop">Created</div>
        <div className="reporttabletotal desktop">Closed</div>
        <div className="reporttabletotal desktop">Payment</div>
        <div className="reporttabletotal desktop">Response Code</div>
        <div className="reporttabletotal ">Payment Type</div>
        <div className="reporttabletotal right-aligned">Amount</div>
        <div className="reporttabletotal desktop right-aligned">Order Total</div>
        <div className="reporttabletotal desktop centerAligned">Order Status</div>
        <div className="reporttabletotal desktop">&nbsp;</div>

        {payments}
        {totals}
      </div>
    );
  };

  handleChangeStart = event => {
    this.setState({ start: event.target.value, dateFilterType: Constants.FILTER_DATE_CUSTOM });
  };

  handleChangeEnd = event => {
    this.setState({ end: event.target.value, dateFilterType: Constants.FILTER_DATE_CUSTOM });
  };

  handleExportReport = () => {
    if (this.state.reporttype === Constants.REPORT_ORDER_ITEMS) {
      this.handleExportInvoiceLineItemDetailReport();
    } else if (this.state.reporttype === Constants.REPORT_PAYMENT_ORDER_DETAIL) {
      this.handleExportPaymentOrderDetailReport();
    } else {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "This report cannot be exported (yet)",
      });
    }
  };

  handleExportInvoiceLineItemDetailReport = () => {
    // Last param indicates the report is being exported to CSV format
    this.getReport(Constants.REPORT_ORDER_ITEMS, this.state.start, this.state.end, true, true, 0, Constants.REPORT_DOWNLOAD_BLOCK_SIZE_MIN);
  };

  handleExportPaymentOrderDetailReport = () => {
    // Last param indicates the report is being exported to CSV format
    this.getReport(Constants.REPORT_PAYMENT_ORDER_DETAIL, this.state.start, this.state.end, true, true, 0, Constants.REPORT_DOWNLOAD_BLOCK_SIZE_MIN);
  };

  handleLoadData = () => {
    // Make sure the start and end dates are selected for time-based reports
    if (
      [Constants.REPORT_SALES, Constants.REPORT_TIMESHEETS, Constants.REPORT_KEY_METRICS].includes(this.state.reporttype) &&
      (!this.state.start || !this.state.end)
    ) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Please select a start and end date",
      });
    } else if (this.state.reporttype === Constants.REPORT_TIMESHEETS) {
      this.getReport(Constants.REPORT_TIMESHEET_TOTALS, this.state.start, this.state.end);
      this.getReport(Constants.REPORT_TIMESHEET_EXCEPTIONS, this.state.start, this.state.end);
    } else if (this.state.reporttype === Constants.REPORT_SALES) {
      this.getReport(Constants.REPORT_TAX_SUMMARY, this.state.start, this.state.end);
      this.getReport(Constants.REPORT_TAX_DETAIL, this.state.start, this.state.end);
      this.getReport(Constants.REPORT_PAYMENT_DETAIL, this.state.start, this.state.end);
      this.getReport(Constants.REPORT_DEPOSIT_DETAIL, this.state.start, this.state.end);
      this.getReport(Constants.REPORT_SALES_DATA, this.state.start, this.state.end);
      if (this.props.appState?.features.includes(Constants.FEATURE_RECURRINGS) && this.props.appState?.maast?.merchant_id) {
        this.getReport(Constants.REPORT_RECURRING_REVENUE, this.state.start, this.state.end);
      } else if (this.props.appState?.features.includes(Constants.FEATURE_BILLING) && this.props.appState?.maast?.merchant_id) {
        this.getTransactionsForDates(this.state.start, this.state.end);
      }
    } else if (this.state.reporttype === Constants.REPORT_KEY_METRICS) {
      this.getReport(Constants.REPORT_KEY_METRICS, this.state.start, this.state.end);
      if (
        (this.props.appState?.features.includes(Constants.FEATURE_BILLING) || this.props.appState?.features.includes(Constants.FEATURE_RECURRINGS)) &&
        this.props.appState?.maast?.merchant_id
      ) {
        this.getTransactionsForDates(this.state.start, this.state.end);
      }
    } else if (this.state.reporttype === Constants.REPORT_ORDER_ITEMS) {
      this.getReport(Constants.REPORT_ORDER_ITEMS, this.state.start, this.state.end, true, false, 0, Constants.REPORT_DOWNLOAD_BLOCK_SIZE_MIN);
    } else if (this.state.reporttype === Constants.REPORT_BILLING_TRANSACTIONS) {
      if (this.props.appState?.features.includes(Constants.FEATURE_BILLING) && this.props.appState?.maast?.merchant_id) {
        this.getAllTransactionsForDates(this.state.start, this.state.end);
      }
    } else if (this.state.reporttype === Constants.REPORT_PAYMENT_ORDER_DETAIL) {
      this.getReport(
        Constants.REPORT_PAYMENT_ORDER_DETAIL,
        this.state.start,
        this.state.end,
        true,
        false,
        0,
        Constants.REPORT_DOWNLOAD_BLOCK_SIZE_MIN
      );
    } else if (this.state.reporttype === Constants.REPORT_BILLING_INVOICES) {
      if (this.props.appState?.features.includes(Constants.FEATURE_BILLING) && this.props.appState?.maast?.merchant_id) {
        this.getMaastInvoicesForDates(this.state.start, this.state.end);
      }
    }
  };

  handleShowFilterOptions = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };

  handleFilterSelection = dateFilterType => {
    const { start, end } = Helper.getDatesForFilter(dateFilterType);
    this.setState({ start: start, end: end, dateFilterType: dateFilterType, showFilterOptions: false }, () => {
      if (dateFilterType !== Constants.FILTER_DATE_CUSTOM) {
        this.handleLoadData();
      }
    });
  };

  isReadyToSubmit = () => {
    return true;
  };

  // Get the transactions for the specified date range
  // The "data" param is used to accumulate the downloaded data without updating the state
  getReport = (reporttype, start, end, detail = false, csv_download = false, searchstart = null, searchlimit = null) => {
    //Protect screen during downloading data
    let text = "Downloading data...";

    // Having a "searchstart" value indicates block downloading
    if (searchstart !== null) {
      // Clear the report data if this is the first block
      if (searchstart === 0 && !csv_download) {
        this.setState({ [reporttype]: [] });
      } else if (searchstart === 0 && csv_download) {
        this.setState({ csv: "", csv_count: 0 });
      }

      // Update the progress text on all blocks after the first block
      if (searchstart !== 0 && this.state.record_count) {
        // CSV record count is tracked using csv_count, but the report record count is tracked using the length of the report array
        if (csv_download) {
          text = `Downloaded ${this.state.csv_count} of ${this.state.record_count}...`;
        } else {
          text = `Downloaded ${this.state[reporttype].length} of ${this.state.record_count}...`;
        }
      }
    }

    // Update the progress text
    this.setState({ downloading: true, reportDownloadCancelled: false }, () => {
      this.props.showOverlay({
        type: Constants.OVERLAY_PROGRESS,
        text: text,
        cancelCallback: () => {
          this.setState({ reportDownloadCancelled: true }, () => {
            this.props.hideOverlay();
          });
        },
      });
    });

    //set up to make database call
    const url = Constants.URL_REPORTS;
    let params = {
      reporttype: reporttype,
      start: start,
      end: end,
      exclude_subscriptions: !this.state.includeSubscriptionInvoices,
    };
    if (detail) {
      params.detail = true;
    }
    if (csv_download) {
      params.csv_download = true;
    }
    // Include blocking parameters if this is a block download
    if (searchstart !== null) {
      params.searchstart = searchstart;
      params.searchlimit = searchlimit;
    }

    // Make the call
    Helper.getData(url, params, null, null, Constants.PREEMPT_GET_REPORT + reporttype).then(response => {
      // If we're downloading in blocks then, after the first block,
      // calculate an optimal search limit for the subsequent block(s)
      if (response.status === 200 && !this.state.reportDownloadCancelled) {
        if (searchstart !== null && searchstart === 0 && (response.body?.record_count > 0 || response.body?.csv?.length > 0)) {
          const record_count = response.body.record_count;
          searchlimit = Math.max(
            Constants.REPORT_DOWNLOAD_BLOCK_SIZE_MIN,
            Math.min(Math.round(record_count / Constants.REPORT_DOWNLOAD_IDEAL_BLOCK_COUNT), Constants.REPORT_DOWNLOAD_BLOCK_SIZE_MAX)
          );
        }
      }

      // Report generation
      if (response.status === 200 && !csv_download && !this.state.reportDownloadCancelled) {
        // Downloading data in blocks
        if (searchstart !== null) {
          //Update state with response, turn off downloading
          this.setState(
            prevState => ({
              error: null,
              downloading: false,
              isNew: false,
              [reporttype]: [...prevState[reporttype], ...response.body?.records],
              record_count: response.body.record_count,
            }),
            () => {
              // Hide the overlay when there are no more records to download
              if (this.state[reporttype].length >= response.body.record_count || response.body?.records?.length === 0) {
                //Hide overlay after database action is complete
                this.props.hideOverlay();
              } else {
                this.getReport(reporttype, start, end, detail, csv_download, this.state[reporttype].length, searchlimit);
              }
            }
          );
        } else {
          //Update state with response, turn off downloading
          this.setState(
            {
              error: null,
              downloading: false,
              isNew: false,
              // Some idiot decided to return report data either in "records" or just the body
              [reporttype]: response.body?.records ?? response.body,
            },
            () => {
              //Hide overlay after database action is complete
              this.props.hideOverlay();
            }
          );
        }
      }
      // CSV generation
      else if (response.status === 200 && csv_download && !this.state.reportDownloadCancelled) {
        //Update state with response, turn off downloading
        this.setState(
          prevState => ({
            error: null,
            downloading: false,
            isNew: false,
            record_count: response.body.record_count,
            csv_count: prevState.csv_count + response.body.csv_count,
          }),
          () => {
            // If there is more data, then download the next block
            if (response.body.csv.length > 0) {
              this.setState(
                prevState => ({
                  csv: prevState.csv + response.body.csv,
                }),
                () => {
                  this.getReport(reporttype, start, end, detail, csv_download, this.state.csv_count, searchlimit);
                }
              );
            } else {
              if (this.state.csv.length === 0) {
                this.setState({ error: null, downloading: false }, () => {
                  this.props.showOverlay({
                    type: Constants.OVERLAY_MESSAGE,
                    text: "No data.",
                  });
                });
              } else {
                //Hide overlay after database action is complete
                this.props.hideOverlay();
                let filename = null;
                if (this.state.reporttype === Constants.REPORT_PAYMENT_ORDER_DETAIL) {
                  filename = "PaymentOrderDetail";
                } else if (this.state.reporttype === Constants.REPORT_ORDER_ITEMS) {
                  filename = "Invoice_Line_Item_Detail";
                }
                console.log("filename", filename);
                // Trigger download
                const element = document.createElement("a");
                const file = new Blob([this.state.csv], { type: "text/csv" });
                element.href = URL.createObjectURL(file);
                element.download = filename + ".csv";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                this.props.showOverlay({
                  type: Constants.OVERLAY_MESSAGE,
                  text: "Export complete.",
                });
              }
            }
          }
        );
      } else if (response.status === 503 && !this.state.reportDownloadCancelled) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else if (!this.state.reportDownloadCancelled) {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the data.",
          });
        });
      } else if (this.state.reportDownloadCancelled) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false, reportDownloadCancelled: false });
        this.props.hideOverlay();
      }
    });
  };
}
export default Report;
