import React from "react";

// Constants
import * as Constants from "./Constants";

// Components
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfinity } from "@fortawesome/free-solid-svg-icons";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";

// View for editing billing plans
class RecurringPlan extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    // creates cursor focus
    this.planNameInput = React.createRef();

    const billingplan = props.selectedItem ?? Helper.getBlankBillingPlan();

    billingplan.plan_duration_unlimited = billingplan.plan_duration === -1 ? true : false;

    this.state = {
      ...this.state,
      billingplan: billingplan,
      viewName: Constants.BILLING,
      isNew: props.selectedItem?.billingplanuuid ? false : true,
      showInfoPlanType: false,
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    super.componentDidMount();
    this.setState({ loading: false });
    if (this.state.billingplan.billingplanuuid) {
      this.setState({ isNew: false });
      this.getBillingPlan(this.state.billingplan.billingplanuuid);
    }
  }

  renderDetailBody = (classes) => {
    return (
      <div className={classes}>
        <div className="areaIdPanel">{this.renderAreaCloseButton(true)}</div>
        {this.renderHeader()}
        {this.renderPlanDetails()}
        {this.renderAreaButtonControls()}
        {this.renderAreaSubscribe()}
      </div>
    );
  };

  renderHeader = () => {
    return <h1 className="component-title areaBillingHeader">Subscription Template</h1>;
  };

  handleChangeUnlimitedDuration = (event) => {
    let duration = event.target.checked ? -1 : 1;
    this.setState(
      {
        billingplan: {
          ...this.state.billingplan,
          plan_duration: duration,
          plan_duration_unlimited: event.target.checked,
        },
      },
      () => {
        const durationEvent = {
          target: {
            name: "plan_duration",
            id: "plan_duration",
            value: this.state.billingplan.plan_duration,
            getAttribute: () => {
              return null;
            },
            removeAttribute: () => {},
          },
        };
        this.handleBlur(durationEvent, Constants.BILLING_PLAN);
      }
    );
  };

  handleDelete = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete?",
      callback: this.maybeDeleteBillingPlan,
      key: this.state.billingplan.billingplanuuid,
    });
  };

  maybeDeleteBillingPlan = (response, billingplanuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteBillingPlan(billingplanuuid);
    }
  };

  // Required fields include plan name, plan description, and plan price
  isReadyToSubmit = () => {
    return (
      this.state.billingplan.plan_name &&
      this.state.billingplan.plan_frequency &&
      this.state.billingplan.plan_duration &&
      this.state.billingplan.recur_amt &&
      numeral(this.state.billingplan.recur_amt).value() > 0
    );
  };

  save = () => {
    // TODO: Run data validation on the input fields
    if (this.isReadyToSubmit()) {
      if (!this.state.billingplan.billingplanuuid) {
        this.postBillingPlan();
      }
    }
  };

  renderPlanDetails = () => {
    return (
      <div className="areaTemplateSettings">
        <div className="templateInputItem">
          <label data-testid="Plan Template Name Label" className="inputLabel" htmlFor="plan_name">
            Name
          </label>
          <input
            type="text"
            name="plan_name"
            id="plan_name"
            data-testid="Plan Template Name Input"
            className="templateName"
            maxLength={255}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={(event) => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            onBlur={(event) => {
              this.handleBlur(event, Constants.BILLING_PLAN);
            }}
            value={this.state.billingplan.plan_name}
          />
          <span className="printOnlyInline">{this.state.billingplan.plan_name}</span>
        </div>
        <div className="templateInputItem planAmount">
          <label data-testid="Plan Template Recurring Amount Label" className="inputLabel" htmlFor="recur_amt">
            Amount
          </label>
          <input
            type="text"
            inputMode="numeric"
            name="recur_amt"
            id="recur_amt"
            data-testid="Plan Template Recurring Amount Input"
            className="templateAmount"
            maxLength={15}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={(event) => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            onBlur={(event) => {
              this.handleBlur(event, Constants.BILLING_PLAN, null, null, "float");
            }}
            value={this.state.billingplan.recur_amt}
          />
          <span className="printOnlyInline">{this.state.billingplan.recur_amt}</span>
        </div>
        <div className="templateInputItem">
          <label data-testid="Plan Template Frequency Label" className="inputLabel" htmlFor="plan_frequency">
            Frequency
          </label>
          <select
            name="plan_frequency"
            id="plan_frequency"
            data-testid="Plan Template Frequency Select"
            className="templateFrequency"
            onChange={(event) => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            value={this.state.billingplan.plan_frequency}
          >
            <option value={Constants.CH_RECURRING_PLAN_FREQUENCY_DAILY}>Daily</option>
            <option value={Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY}>Weekly</option>
            <option value={Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY}>Monthly</option>
            <option value={Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY}>Annually</option>
          </select>
          <div className="printOnlyInline">{this.state.billingplan.plan_frequency || ""}</div>
        </div>
        <div className="templateInputItem planDuration">
          <label data-testid="Plan Template Duration Label" className="inputLabel" htmlFor="plan_duration">
            Duration
          </label>
          <input
            type="text"
            name="plan_duration"
            id="plan_duration"
            data-testid="Plan Template Duration Input"
            className="templateDuration"
            maxLength={4}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={(event) => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            onBlur={(event) => {
              this.handleBlur(event, Constants.BILLING_PLAN, null, null, "int");
            }}
            value={this.state.billingplan.plan_duration_unlimited ? "Until cancelled" : this.state.billingplan.plan_duration}
          />
          <span className="printOnlyInline">
            {this.state.billingplan.plan_duration_unlimited ? "Until cancelled" : this.state.billingplan.plan_duration}
          </span>

          <label data-testid="Plan Template Unlimited Label" className="checkboxContainer" htmlFor="plan_duration_unlimited">
            <FontAwesomeIcon icon={faInfinity} />
            <input
              type="checkbox"
              name="plan_duration_unlimited"
              id="plan_duration_unlimited"
              data-testid="Plan Template Unlimited Checkbox"
              onChange={this.handleChangeUnlimitedDuration}
              checked={this.state.billingplan.plan_duration_unlimited}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
    );
  };
}
export default RecurringPlan;
