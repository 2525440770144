import React from "react";

// Constants
import * as Constants from "./Constants";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";

// Components
import ListControls from "./ListControls";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import Pagination from "./Pagination";
import FilterFunnel from "./img/FilterFunnel";

class BaseListViewComponent extends React.Component {
  constructor(props) {
    super(props);
    // Bind functions
    this.getListItems = this.getListItems.bind(this);

    this.searchRef = React.createRef();
    this.searchRef2 = React.createRef();

    this.props.addBreadcrumb();
    this.props.checkLogin();

    this.state = {
      allowDeselect: props.allowDeselect ?? true,
      disableDetailView: props.disableDetailView ?? false,
      downloading: false,
      error: props.error,
      expandedListItems: [],
      employee: "0",
      employees: [],
      end: "",
      hideFilter: false,
      hideFilterDates: true,
      listItems: [],
      pagecount: 1,
      pagenumber: props.page ?? 1,
      pagenumberentry: props.page ?? 1,
      preventSelection: props?.preventSelection ? true : false,
      reverblastretrieved: props.appState?.thirdparty?.reverblastretrieved,
      searchkey: props.searchkey,
      searchkeyCustomFields: "",
      selectedItem: null,
      selectedListItems: [],
      showpagination: true,
      // Don't show search bar on Timesheets or Reports. Billings will eventually be removed.
      showsearch: !Helper.inList([Constants.TIMESHEETS, Constants.REPORTS, Constants.IMPORT], this.props.appState.currentView),
      showemployeesearch: this.props.appState.currentView === Constants.TIMESHEETS,
      sortdirection: props.defaultSortKey ? "A" : null,
      sortkey: props.defaultSortKey ? props.defaultSortKey : null,
      showProfit: false,
      start: "",
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    window.addEventListener(Constants.EVENT_SCROLL, this.handleScroll);
    document.addEventListener(Constants.EVENT_KEYDOWN, this.handleKeyDown);
    // Pass the App component a reference to the refreshList function
    //    which can be called by App when it knows the database has been updated
    this.props.setListUpdateCallback(this.refreshList);
    this.props.setExportCallback(this.getListItemsForExport);
    const isInventoryTab = this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_INVENTORY;
    const isSummaryTab = this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_SUMMARY;
    if (!isInventoryTab && !isSummaryTab) {
      this.getListItems();
      if (this.props.appState.currentView === Constants.TIMESHEETS) {
        this.getEmployees();
      }
    } else {
      this.setState({ preventSelection: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener(Constants.EVENT_SCROLL, this.handleScroll);
    document.removeEventListener(Constants.EVENT_KEYDOWN, this.handleKeyDown);
  }

  render = () => {
    const listItems = this.renderListItems();
    let searchPlaceholder = "Search ";

    if (this.props.appState.currentView === Constants.BILLINGS) {
      if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
        searchPlaceholder += "Plans";
      } else {
        searchPlaceholder += "Customers";
      }
    } else {
      if (this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
        searchPlaceholder += "Prospects";
      } else {
        searchPlaceholder += this.props.title;
      }
    }
    let list_view = this.renderListView(listItems);
    const isProductSummaryView = this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_SUMMARY;
    return (
      <React.Fragment>
        <div id="list-container" className={this.getListGridClassName()}>
          <ListControls
            appState={this.props.appState}
            downloading={this.state.downloading}
            disabled={this.isSearchDisabled()}
            employee={this.state.employee}
            employees={this.state.employees}
            end={this.state.end}
            error={this.state.error}
            filtertype={this.props.filtertype}
            handleChangeEmployee={this.handleChangeEmployee}
            handleChangeEnd={this.handleChangeEnd}
            handleChangeLastRetrieved={this.handleChangeLastRetrieved}
            handleChangeStart={this.handleChangeStart}
            handleClearSearch={this.handleClearSearch}
            handleClearSearchCustomFields={this.handleClearSearchCustomFields}
            handleGetOnlineOrders={this.handleGetOnlineOrders}
            handleFilterList={this.handleFilterList}
            handleInventory={this.props.handleInventory}
            handleKeyDown={this.handleKeyDown}
            handleNew={this.props.handleEditItem}
            handleSearchChange={this.handleSearchChange}
            handleSearchChangeCustomFields={this.handleSearchChangeCustomFields}
            handleSearchKeyDown={this.handleSearchKeyDown}
            hideFilter={this.state.hideFilter}
            hideFilterDates={this.state.hideFilterDates}
            hideFilterDisposition={!isProductSummaryView}
            hideNew={this.props.hideNew ?? false}
            inventoryStats={this.state.inventoryStats}
            newicon={this.props.newIcon}
            renderControlButtons={this.renderTopControlButtons}
            renderCreatePurchaseButton={this.renderCreatePurchaseButton}
            reverblastretrieved={this.state.reverblastretrieved}
            searchkey={this.state.searchkey}
            searchkeyCustomFields={this.state.searchkeyCustomFields}
            searchPlaceholder={searchPlaceholder}
            searchRef={this.searchRef}
            searchRef2={this.searchRef2}
            showemployeesearch={this.state.showemployeesearch}
            showOverlay={this.props.showOverlay}
            showsearch={this.state.showsearch}
            start={this.state.start}
            title={this.props.title}
          />
          {list_view}
        </div>
        {this.renderPaginationOrTotals()}
      </React.Fragment>
    );
  };

  renderListView = listItems => {
    return this.doRenderListView(listItems);
  };

  doRenderListView = listItems => {
    let list_view = (
      <div className="list-grid" data-testid="List Grid Container">
        <ListHeaderView headerRowItems={this.getHeaderRowItems()} handleApplySort={this.handleApplySort} defaultSortKey={this.state.sortkey} />
        {listItems}
      </div>
    );
    if (this.props.two_panel) {
      list_view = (
        <div className="list-grid">
          <ListHeaderView headerRowItems={this.getHeaderRowItems()} handleApplySort={this.handleApplySort} defaultSortKey={this.state.sortkey} />
          <div className="panel_left">{listItems}</div>
          <div className="panel_right">
            {this.renderMobileDetailSelector()}
            {this.renderDetailPanel()}
          </div>
        </div>
      );
    }
    return list_view;
  };

  renderMobileDetailSelector = () => {
    return "";
  };

  renderDetailPanel = () => {
    return <div></div>;
  };

  renderListItems = () => {
    if (this.state.listItems) {
      return (
        <ListItemView
          disableDetailView={this.state.disableDetailView}
          listitems={this.state.listItems}
          expandedListItems={this.state.expandedListItems}
          selectedListItems={this.state.selectedListItems}
          renderItemToColumns={this.renderItemToColumns}
          toggleCollapsed={this.toggleCollapsed}
          selectListItem={this.selectListItem}
          handleEditItem={this.props.handleEditItem}
          handleTouchStart={this.handleTouchStart}
          handleTouchEnd={this.handleTouchEnd}
        />
      );
    } else {
      return "";
    }
  };

  renderCreatePurchaseButton = wrapperclass => {
    if (this.props.appState.currentView === Constants.ORDERS) {
      let title = "New PO from selected";
      let buttonClass = "new-po";

      if (this.state.selectedListItems?.length > 0) {
        title += " (" + this.state.selectedListItems.length + ")";
      } else {
        buttonClass += " save-disabled";
      }

      return (
        <span className={wrapperclass}>
          <span className={buttonClass} onClick={() => this.handleCreatePurchaseFromSelected(this.state.selectedListItems)}>
            {title}
          </span>
        </span>
      );
    } else {
      return <span className={wrapperclass}></span>;
    }
  };

  isSearchDisabled = () => {
    return this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype.tab === Constants.TAB_INVENTORY && !this.state.products;
  };

  handleSearchChangeCustomFields = (id, value) => {
    let sortkey = this.props.defaultSortKey;
    if (value) {
      sortkey = null;
    }
    const logicallyDifferent = value.trim() !== this.state.searchkeyCustomFields.trim();
    this.setState({ [id]: value, sortkey: sortkey, searchkey: "" }, () => {
      if (logicallyDifferent) {
        // Wait half a second
        setTimeout(() => {
          // If the value has not changed, run a search
          if (value === this.state.searchkeyCustomFields.trim()) {
            this.props.updateBreadcrumb({ searchkeyCustomFields: this.state.searchkeyCustomFields });
            this.setState({ pagenumber: 1, pagenumberentry: 1 }, this.getListItems);
          }
        }, Constants.SEARCH_DELAY);
      }
    });
  };

  handleSearchChange = (id, value) => {
    if (this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_INVENTORY) {
      this.setState({ searchkey: value });
    } else {
      let sortkey = this.props.defaultSortKey;
      if (value) {
        sortkey = null;
      }
      const logicallyDifferent = value.trim() !== this.state.searchkey.trim();
      this.setState({ [id]: value, sortkey: sortkey, searchkeyCustomFields: "" }, () => {
        // If the search key has actually changed, then run a search (after a delay)
        if (logicallyDifferent) {
          // Wait half a second
          setTimeout(() => {
            // If the value has not changed, run a search
            if (value.trim() === this.state.searchkey.trim()) {
              this.props.updateBreadcrumb({ searchkey: this.state.searchkey });
              this.setState({ pagenumber: 1, pagenumberentry: 1 }, this.getListItems);
            }
          }, Constants.SEARCH_DELAY);
        }
      });
    }
  };

  handleSearchKeyDown = event => {
    // Look for enter key to know to run the search
    const isInventoryTab = this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_INVENTORY;
    if (event.keyCode === Constants.ENTER && isInventoryTab) {
      const searchkey = this.state.searchkey.trim();
      if (searchkey) {
        const item = Helper.getBlankInventoryItem(searchkey);
        // Inline function to add the item to the list/local storage
        // Called below either directly or after moving to the first page
        const doAddItem = item => {
          // Go find the first item in the products list that matches the UPC/SKU/StoreSKU/EAN
          const product = this.state.products?.find(product =>
            [product.altupc, product.upc, product.sku, product.storesku, product.ean].includes(item.searchkey)
          );
          if (product) {
            item.productuuid = product.productuuid;
            item.productname = product.productname;
            item.storesku = product.storesku;
            item.inventory = product.inventory;
            if (product.affectinventory) {
              item.status = Constants.INVENTORY_STATUS_MATCHED;
            } else {
              item.status = Constants.INVENTORY_STATUS_NOT_INVENTORIED;
            }
          }
          // Get the item count in local storage
          let itemCount = 0;
          while (localStorage.getItem(`${Constants.LOCAL_STORAGE_INVENTORY}${itemCount}`)) {
            itemCount++;
          }
          this.setState(
            prevState => ({
              searchkey: "",
              listItems: [item, ...prevState.listItems.slice(0, this.props.appState.displaySettings.LIST_ROWS - 1)],
              pagecount: Math.ceil(itemCount / this.props.appState.displaySettings.LIST_ROWS),
            }),
            () => {
              // Get the next item index
              let index = 0;
              while (localStorage.getItem(`${Constants.LOCAL_STORAGE_INVENTORY}${index}`)) {
                index++;
              }
              // Write the new item to local storage
              const key = `${Constants.LOCAL_STORAGE_INVENTORY}${index}`;
              item.key = key;
              localStorage.setItem(key, JSON.stringify(item));
              /// If the product was not found locally, then fire off a get request to the server
              if (item.status === Constants.INVENTORY_STATUS_NEW) {
                this.getProduct(item, index);
              }
            }
          );
        };
        // We have to move back to the first page before adding the item, if required
        if (this.state.pagenumber !== 1) {
          this.loadListItemsFromLocalStorage(1, item => doAddItem(item));
        } else {
          doAddItem(item);
        }
      }
    }
  };

  handleClearSearchCustomFields = () => {
    this.props.updateBreadcrumb({ searchkeyCustomFields: "" });
    this.setState(
      {
        searchkeyCustomFields: "",
        sortkey: this.props.defaultSortKey,
        pagenumber: 1,
        pagenumberentry: 1,
        listItems: [],
        selectedListItems: [],
        expandedListItems: [],
      },
      this.getListItems
    );
  };

  handleClearSearch = () => {
    this.props.updateBreadcrumb({ searchkey: "" });
    this.setState(
      {
        searchkey: "",
        sortkey: this.props.defaultSortKey,
        pagenumber: 1,
        pagenumberentry: 1,
        listItems: [],
        selectedListItems: [],
        expandedListItems: [],
      },
      this.getListItems
    );
  };

  handleChangeEmployee = event => {
    this.setState({ employee: event.target.value }, () => {
      this.getListItems();
    });
  };

  handleGetOnlineOrders = () => {
    this.getOnlineOrders();
  };

  handleTouchStart = (event, item) => {
    const touchEvent = {
      time: Date.now(),
      item: item,
      event: event,
    };
    this.touchEvent = touchEvent;
    setTimeout(() => {
      this.maybeSelectItem(event, item.uuid);
    }, Constants.SEARCH_DELAY);
  };

  handleTouchEnd = event => {
    if (event.cancelable) {
      event.preventDefault();
    }

    // If the touch event was already handled by the timer event, then ignore the "touch end" event
    if (!this.touchEvent) {
      return;
    }

    // Grab the current touch event and clear it out from this
    const touchEvent = this.touchEvent;
    this.touchEvent = null;

    // If the touch moved a significant distance, then ignore it
    const start = touchEvent.event.changedTouches[0];
    const end = event.changedTouches[0];
    const distance = Math.sqrt(start.clientX - end.clientX + (start.clientY - end.clientY));
    if (distance > 20) {
      return;
    }

    // If the duration of the touch is less than 500ms and we're not in selection mode,
    //     then treat it as a "click"
    const duration = Date.now() - touchEvent.time;
    if (duration < 500 && this.state.selectedListItems?.length === 0) {
      if (!this.state.disableDetailView) {
        this.props.handleEditItem(touchEvent.item);
      }
    } else if (this.state.selectedListItems?.length > 0) {
      this.selectListItem(touchEvent.item);
    }
  };

  handleKeyDown = event => {
    const isLetter = event.keyCode >= 65 && event.keyCode <= 90;
    const isNumber = (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode >= 48 && event.keyCode <= 57);
    const isBackspace = event.keyCode === 8;
    // Ignore Ctrl+? and Alt+? events
    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }
    // Handle keypresses not directed at a specific input
    if ((isLetter || isNumber || isBackspace) && event.target === document.body) {
      if (this.searchRef.current) {
        this.searchRef.current.focus();
      }
    } else if (event.keyCode === Constants.PGUP) {
      this.handlePrevPage(event);
    } else if (event.keyCode === Constants.PGDN) {
      this.handleNextPage(event);
    } else if (event.keyCode === Constants.END) {
      this.handleEnd(event);
    } else if (event.keyCode === Constants.HOME) {
      this.handleHome(event);
    }
  };

  // Scrolling cancels touch events to select/open list items
  handleScroll = event => {
    this.touchEvent = null;
  };

  // Filter list based on active/inactive state
  handleFilterList = filtertype => {
    this.setState(
      prevState => ({
        listItems: [],
        selectedListItems: [],
        pagenumber: 1,
        pagenumberentry: 1,
        sortkey: this.props.defaultSortKey,
        sortdirection: null,
        totals: null,
      }),
      () => {
        this.props.updateFilterType(filtertype, () => {
          this.getListItems();
        });
      }
    );
  };

  handleChange = (event, type, uuid = null) => {
    this.setState(prevState => ({
      listItems: prevState.listItems.map(item => {
        if (item.uuid === uuid) {
          item[event.target.id] = Helper.getTargetValue(event);
        }
        return item;
      }),
    }));
    // NOTE: There are currently no checkboxes on the list screens
    // so this is dead code (but we're keeping it!)
    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      if (type === Constants.PRODUCT) {
        this.handleBlurProduct(event, uuid);
      } else if (type === Constants.TIMESHEET) {
        this.handleBlurTimesheet(event, uuid);
      }
    }
  };

  handleBlur = (event, type, uuid = null) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);

    if (type === Constants.PRODUCT) {
      this.handleBlurProduct(event, uuid, prev);
    } else if (type === Constants.INVENTORY) {
      this.handleBlurInventory(event, uuid, prev);
    } else if (type === Constants.TIMESHEET) {
      this.handleBlurTimesheet(event, uuid, prev);
    }
  };

  handleBlurInventory = (event, uuid, prev) => {
    let value = Helper.getTargetValue(event);
    // Make sure the field value changed
    if (prev !== value) {
      // Make sure we have a valid value
      if (event.target.id === "quantity") {
        value = parseInt(value);
        if (isNaN(value) || value <= 0) {
          // Reset the value back to the previous value
          this.setState(prevState => ({
            listItems: prevState.listItems.map(item => {
              if (item.uuid === uuid) {
                item[event.target.id] = prev;
              }
              return item;
            }),
          }));
          // Display error message
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Quantity must be a number greater than zero.",
          });
          return;
        }
      }
      // Update inventory product in local storage
      let index = 0;
      while (true) {
        const key = `${Constants.LOCAL_STORAGE_INVENTORY}${index}`;
        const item = localStorage.getItem(key);
        if (item) {
          const itemObj = JSON.parse(item);
          if (itemObj && itemObj.uuid === uuid) {
            itemObj.quantity = value;
            localStorage.setItem(itemObj.key, JSON.stringify(itemObj));
            break;
          }
        } else {
          break;
        }
        index++;
      }
    }
  };

  handleBlurProduct = (event, uuid, prev) => {
    let value = Helper.getTargetValue(event);
    // Make sure the field value changed
    if (prev !== value) {
      this.putProduct(uuid, event.target.id, value, () => {
        // If we're on the Summary tab, then refresh the stats
        if (this.props.appState.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_SUMMARY) {
          this.getInventoryStats();
        }
      });
    }
  };

  handleBlurTimesheet = (event, uuid, prev) => {
    let value = Helper.getTargetValue(event);
    value = String(value).replace("T", " ");
    prev = String(prev).replace("T", " ");
    if (prev !== value) {
      // Make sure the clockin is before the clockout
      let error = null;
      if (event.target.id === "clockin") {
        const clockout = this.state.listItems.find(i => i.uuid === uuid)?.clockout;
        if (clockout && value > clockout) {
          error = "Clockin must be before clockout.";
        }
      } else if (event.target.id === "clockout") {
        const clockin = this.state.listItems.find(i => i.uuid === uuid)?.clockin;
        if (clockin && value < clockin) {
          error = "Clockout must be after clockin.";
        }
      }
      if (error) {
        this.setState(
          prevState => ({
            listItems: prevState.listItems.map(i => {
              if (i.uuid === uuid) {
                i[event.target.id] = prev;
                i.editingIn = false;
                i.editingOut = false;
              }
              return i;
            }),
          }),
          () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: error,
            });
          }
        );
        return;
      }

      // Get an auth token
      this.props.showOverlay({
        type: Constants.OVERLAY_AUTH_PROMPT,
        prompt: "Enter your credentials\nto continue",
        user: "",
        callback: (response, authtoken = null) => {
          if (response === Constants.OVERLAY_RESPONSE_NO) {
            this.setState(prevState => ({
              listItems: prevState.listItems.map(i => {
                if (i.uuid === uuid) {
                  i[event.target.id] = prev;
                  i.editingIn = false;
                  i.editingOut = false;
                }
                return i;
              }),
            }));
          } else if (response === Constants.OVERLAY_RESPONSE_YES) {
            const clockin = event.target.id === "clockin" ? String(value).replace("T", " ") : null;
            const clockout = event.target.id === "clockout" ? value : null;
            const failureCallback = () => {
              this.setState(prevState => ({
                listItems: prevState.listItems.map(i => {
                  if (i.uuid === uuid) {
                    i[event.target.id] = prev;
                    i.editingIn = false;
                    i.editingOut = false;
                  }
                  return i;
                }),
              }));
            };
            this.props.putTimesheet(authtoken, { timesheetuuid: uuid }, clockin, clockout, failureCallback);
          }
        },
      });
    } else {
      this.setState(prevState => ({
        listItems: prevState.listItems.map(i => {
          if (i.uuid === uuid) {
            i.editingIn = false;
            i.editingOut = false;
          }
          return i;
        }),
      }));
    }
  };

  handleChangePageNumber = event => {
    this.doHandleChangePageNumber(event);
  };

  doHandleChangePageNumber = (event, callback = null) => {
    let pagenumber = parseInt(event.target.value);
    const logicallyDifferent = pagenumber !== this.state.pagenumber;
    this.setState({ pagenumberentry: event.target.value }, () => {
      if (event.target.value === "" || isNaN(pagenumber)) {
        return;
      }
      if (logicallyDifferent) {
        // If the pagenumber has not changed in the last half second, run a search
        // Wait half a second
        setTimeout(() => {
          // If the pagenumber has not changed in the last half second, run a search
          if (pagenumber === parseInt(this.state.pagenumberentry) && this.state.pagenumberentry !== "") {
            if (pagenumber < 1) {
              pagenumber = 1;
            }
            if (pagenumber > this.state.pagecount) {
              pagenumber = this.state.pagecount;
            }
            this.setState({ pagenumber: pagenumber, pagenumberentry: pagenumber }, () => {
              this.props.handleChangePageNumber(this.state.pagenumber);
              if (callback) {
                callback();
              } else {
                this.getListItems(null, this.state.selectedListItems);
              }
            });
          }
        }, Constants.SEARCH_DELAY);
      }
    });
  };

  handleNextPage = event => {
    this.doHandleNextPage(event);
  };

  doHandleNextPage = (event, callback = null) => {
    this.setState(
      prevState => ({
        pagenumber: Math.min(prevState.pagenumber + 1, this.state.pagecount),
        pagenumberentry: Math.min(prevState.pagenumber + 1, this.state.pagecount),
      }),
      () => {
        this.props.handleChangePageNumber(this.state.pagenumber);
        if (callback) {
          callback();
        } else {
          this.getListItems(null, this.state.selectedListItems);
        }
      }
    );
  };

  handlePrevPage = event => {
    this.doHandlePrevPage(event);
  };

  doHandlePrevPage = (event, callback = null) => {
    this.setState(
      prevState => ({
        pagenumber: Math.max(prevState.pagenumber - 1, 1),
        pagenumberentry: Math.max(prevState.pagenumber - 1, 1),
      }),
      () => {
        this.props.handleChangePageNumber(this.state.pagenumber);
        if (callback) {
          callback();
        } else {
          this.getListItems(null, this.state.selectedListItems);
        }
      }
    );
  };

  handleHome = event => {
    this.doHandleHome(event);
  };

  doHandleHome = (event, callback = null) => {
    if (event.target.id !== "searchkey") {
      this.setState({ pagenumber: 1, pagenumberentry: 1 }, () => {
        this.props.handleChangePageNumber(this.state.pagenumber);
        if (callback) {
          callback();
        } else {
          this.getListItems(null, this.state.selectedListItems);
        }
      });
    }
  };

  handleEnd = event => {
    this.doHandleEnd(event);
  };

  doHandleEnd = (event, callback = null) => {
    if (event.target.id !== "searchkey") {
      this.setState({ pagenumber: this.state.pagecount, pagenumberentry: this.state.pagecount }, () => {
        this.props.handleChangePageNumber(this.state.pagenumber);
        if (callback) {
          callback();
        } else {
          this.getListItems(null, this.state.selectedListItems);
        }
      });
    }
  };

  handleCreatePurchaseFromSelected = (items = []) => {
    if (items.length > 0) {
      items = items.map(item => {
        item.totalcost = item.cost * item.quantity;
        item.totalprice = (item.sellprice - item.discount) * item.quantity;
        item.quantityreceived = 0;
        item.ordertype = Constants.PURCHASE;
        return item;
      });
      let purchaseorder = {
        ...Helper.getBlankOrder(Constants.PURCHASE, this.props.appState?.salesperson),
        orderitems: items,
      };
      this.props.handleEditPurchase(purchaseorder);
    }
  };

  handleApplySort = (sortkey, sortdirection) => {
    this.setState(
      {
        sortkey: sortkey,
        sortdirection: sortdirection,
        pagenumber: 1,
        pagecount: 1,
        pagenumberentry: 1,
      },
      this.getListItems(null, this.state.selectedListItems)
    );
  };

  handleChangeLastRetrieved = event => {
    let localDate;
    localDate = Helper.convertUtcToLocal(this.state.reverblastretrieved, this.props.appState.clientSettings.TIMEZONE);
    localDate = Helper.formatDateTimeForWebService(localDate);
    this.props.showOverlay({
      type: Constants.OVERLAY_DATE_PICKER,
      title: "Change Last Retrieved Date",
      text: "Enter the date you last retrieved orders.",
      date: localDate,
      callback: this.maybeChangeLastRetrieved,
    });
  };

  maybeChangeLastRetrieved = (response, date) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      const previousDate = this.state.reverblastretrieved;
      const localDateTimeString = Helper.formatDateTimeForWebService(date);
      let utcDate = Helper.convertLocalToUtc(localDateTimeString, this.props.appState.clientSettings.TIMEZONE);
      utcDate = Helper.formatDateTimeForWebService(utcDate);

      // Show progress overlay
      this.props.showOverlay({
        type: Constants.OVERLAY_PROGRESS,
        text: "Updating last retrieved date...",
      });
      this.setState({ reverblastretrieved: utcDate }, () => {
        this.putSetting(this.props.appState.thirdparty.reverblastretrieveduuid, utcDate, success => {
          if (success) {
            this.setState({ reverblastretrieved: utcDate }, () => {
              this.props.hideOverlay();
            });
          } else {
            this.setState({ reverblastretrieved: previousDate }, () => {
              this.props.showOverlay({
                text: "There was an error updating the date.",
                type: Constants.OVERLAY_MESSAGE,
              });
            });
          }
        });
      });
    }
  };

  handleChangeStart = event => {
    const date = event.target.value;
    if (this.state.end && this.state.end < date) {
      this.setState({ end: date });
    }
    this.setState({ start: date }, this.getListItems);
  };

  handleChangeEnd = event => {
    const date = event.target.value;
    if (this.state.start && this.state.start > date) {
      this.setState({ start: date });
    }
    this.setState({ end: date }, this.getListItems);
  };

  refreshList = (searchkey, callback = null) => {
    this.setState({ searchkey: searchkey }, () => {
      this.getListItems(callback);
    });
  };

  toggleCollapsed = (event, uuid) => {
    event.preventDefault();
    let expandedListItems = this.state.expandedListItems;
    if (!Helper.inList(expandedListItems, uuid)) {
      expandedListItems.push(uuid);
    } else {
      expandedListItems = expandedListItems.filter(item => item !== uuid);
    }
    this.setState({ expandedListItems: expandedListItems });
  };

  selectListItem = item => {
    if (this.state.preventSelection) {
      return;
    }
    const uuid = item.uuid;
    let selectedListItems;
    if (this.props.single_selection) {
      // If item is already selected, then unselect it
      if (this.state.selectedListItems[0]?.uuid === uuid && this.state.allowDeselect) {
        selectedListItems = [];
      } else {
        selectedListItems = [item];
      }
    } else {
      // Create a new selected item list without the specified item
      selectedListItems = this.state.selectedListItems.filter(item => item.uuid !== uuid);
      // If the list didn't change size, it wasn't in there to delete, so add it
      if (selectedListItems.length === this.state.selectedListItems?.length) {
        selectedListItems.push(item);
      }
    }
    this.setState({ ...this.state, selectedListItems: selectedListItems }, () => {
      if (this.props.appState?.currentView === Constants.MESSAGES && this.props.filtertype?.tab === Constants.TEXTS) {
        this.handleAfterSelectSmsContact(item);
      } else if (this.props.appState?.currentView === Constants.MESSAGES && this.props.filtertype?.tab === Constants.EMAIL) {
        this.handleAfterSelectEmailContact(item);
      }
    });
  };

  handleAfterSelectSmsContact = item => {};
  handleAfterSelectEmailContact = item => {};

  maybeExpandRecords = records => {
    return records;
  };

  maybeSelectItem = (event, uuid) => {
    // If this touch event was handled by the "touch up" event, then ignore the timer event
    if (!this.touchEvent) {
      return;
    }

    // Grab the current touch event and clear it out from this
    const touchEvent = this.touchEvent;
    this.touchEvent = null;

    // If the touch moved a significant distance, then ignore it
    const start = touchEvent.event.changedTouches[0];
    const end = event.changedTouches[0];
    const distance = Math.sqrt(start.clientX - end.clientX + (start.clientY - end.clientY));
    if (isNaN(distance) || distance > 20) {
      return;
    }

    // Select/unselect the list item
    if (touchEvent.item.uuid === uuid) {
      this.selectListItem(touchEvent.item);
    }
  };

  maybeOpenPurchaseOrder = destinationorder => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Success. Open purchase order?",
      callback: this.handleOpenPurchaseOrderResponse,
      key: destinationorder.orderuuid,
      value: destinationorder,
    });
  };

  handleOpenPurchaseOrderResponse = (response, uuid, purchaseorder) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // Open the purchase order for editing
      this.props.handleEditPurchase(purchaseorder);
    } else {
      this.props.hideOverlay();
    }
  };

  handlePickInventoryProduct = (response, item, event) => {
    const index = event.index;
    if (response === Constants.OVERLAY_RESPONSE_CANCEL) {
      localStorage.removeItem(`${Constants.LOCAL_STORAGE_INVENTORY}${index}`);
    } else if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      // Update the inventory product with the selected product details
      const product = item;
      const key = `${Constants.LOCAL_STORAGE_INVENTORY}${index}`;
      const inventoryItem = JSON.parse(localStorage.getItem(key));
      inventoryItem.productuuid = product.productuuid;
      inventoryItem.productname = product.productname;
      inventoryItem.storesku = product.storesku;
      inventoryItem.inventory = product.inventory;
      if (product.affectinventory) {
        inventoryItem.status = Constants.INVENTORY_STATUS_MATCHED;
      } else {
        inventoryItem.status = Constants.INVENTORY_STATUS_NOT_INVENTORIED;
      }
      localStorage.setItem(key, JSON.stringify(inventoryItem));
    }
    // Reload the page
    this.loadListItemsFromLocalStorage(this.state.pagenumber);
  };

  handleClearUnreadMessages = uuid => {
    if (this.state.selectedListItems[0].uuid === uuid) {
      // Get the selected item from state
      const updatedItem = this.state.listItems.find(item => item.uuid === uuid);
      if (updatedItem?.unread_count > 0) {
        const unreadShowing = updatedItem.messages.records.reduce((acc, message) => {
          return acc + (message.unread ? 1 : 0);
        }, 0);
        const unread_count = updatedItem.unread_count - unreadShowing;
        this.setState(prevState => ({
          twilio_unread_count: Math.max(0, prevState.twilio_unread_count - unreadShowing),
          listItems: prevState.listItems.map(item => {
            if (item.uuid === uuid) {
              item.unread_count = unread_count;
              item.messages.records = item.messages.records.map(message => {
                message.unread = false;
                return message;
              });
            }
            return item;
          }),
        }));
        if (this.props.handleReduceTwilioUnreadCount && unreadShowing !== 0) {
          this.props.handleReduceTwilioUnreadCount(unreadShowing);
        }
      }
    }
  };

  handleShowOrderFilter = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_DATE_FILTER,
      callback: this.handleOrderFilter,
      dateFilterType: Constants.FILTER_DATE_ALL,
    });
  };

  // Called when the order filter overlay is dismissed.
  // New values for start and end are found in the props.filtertype.orderdatefilter object
  // No need to set them here, as they are set in the App component
  handleOrderFilter = response => {
    // Only run the search if the user clicked "Apply" or "Remove"
    if ([Constants.OVERLAY_RESPONSE_REMOVE, Constants.OVERLAY_RESPONSE_APPLY].includes(response)) {
      this.props.updateFilterType(this.props.filtertype, () => {
        this.getListItems();
      });
    }
  };

  loadListItemsFromLocalStorage = (page, callback = null) => {
    // Find the last entered inventory item
    let lastItemIndex = 0;
    while (localStorage.getItem(`${Constants.LOCAL_STORAGE_INVENTORY}${lastItemIndex}`)) {
      lastItemIndex++;
    }
    lastItemIndex--;

    const pageSize = this.props.appState.displaySettings.LIST_ROWS;
    const pagecount = Math.ceil(lastItemIndex / pageSize);
    const startIndex = lastItemIndex - pageSize * (page - 1);
    const endIndex = Math.max(startIndex - pageSize + 1, 0);
    // loop through startIndex to endIndex
    let listItems = [];
    for (let i = startIndex; i >= endIndex && i >= 0; i--) {
      const key = `${Constants.LOCAL_STORAGE_INVENTORY}${i}`;
      const item = localStorage.getItem(key);
      if (item) {
        let itemObj = JSON.parse(item);
        itemObj.key = key;
        listItems.push(itemObj);
      }
    }
    this.setState({ listItems: listItems, pagecount: pagecount }, () => {
      if (callback) {
        callback();
      }
    });
  };

  getSpecials = () => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // Go get the vendor specials
    const url = "http://app-test.clerkhound.com/specials/special-api.json";
    Helper.getResourceExternal(url).then(response => {
      if (response.status === 200 && response.body) {
        const vendorid = response.body.vendorid;
        const vendorname = response.body.vendorname;
        let specials = response.body.specials.map(item => {
          item.uuid = vendorid + "-" + item.sku;
          item.type = "item";
          return item;
        });
        specials.splice(0, 0, {
          type: "header",
          vendorname: vendorname,
          vendorid: vendorid,
          uuid: vendorid,
        });
        console.log(Helper.clTimestamp(), "specials", specials);

        // Calculate pagination values
        const pageSize = this.props.appState.displaySettings.LIST_ROWS ? this.props.appState.displaySettings.LIST_ROWS : Constants.PAGE_SIZE;
        const totalrecords = specials.length;
        let pagecount = totalrecords / pageSize;
        // We only need to round when the if there is a remainder
        if (totalrecords % pageSize !== 0) {
          pagecount = Math.round(pagecount + 0.5);
        }
        this.setState({ listItems: specials, pagecount: pagecount, error: null, downloading: false }, () => {
          //Hide overlay after database action is complete
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the specials.",
          });
        });
      }
    });
  };

  buildGetListItemsParams = getParameters => {
    const params = Helper.deepCopy(getParameters);
    if (this.state.sortkey) {
      params.sortkey = encodeURIComponent(this.state.sortkey);
      if (this.state.sortdirection) {
        params.sortdirection = encodeURIComponent(this.state.sortdirection);
      }
    }
    if (this.state.searchkey.trim()) {
      params.searchkey = encodeURIComponent(this.state.searchkey.trim());
    }

    // Only add the active and open flags to the search, if this is not a "I-123"-style search
    const re = Constants.REGEX_ORDER_NUMBER;
    const results = re.exec(params.searchkey);
    if (!results || results.length === 0) {
      // Maybe append the "active" flag
      if (this.props.filtertype.active === Constants.FILTER_ACTIVE) {
        params.active = true;
      } else if (this.props.filtertype.active === Constants.FILTER_INACTIVE) {
        params.active = false;
        // Maybe append the order type filter (i.e. which tab they're on)
      }
      if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
        params.open = true;
      } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
        params.open = false;
      } else if (this.props.filtertype.tab === Constants.TIMESHEETS_CURRENT) {
        params.open = true;
      }
      // If you're on the "Texts" tab, then mark messages as read
      if (this.props.filtertype.tab === Constants.TEXTS) {
        params.markread = true;
      }
      if (
        (this.props.filtertype.tab === Constants.TIMESHEETS_CURRENT || this.props.filtertype.tab === Constants.TIMESHEETS_COMPLETED) &&
        this.state.employee !== "0"
      ) {
        params.useruuid = this.state.employee;
      }
      if (this.props.filtertype.tab === Constants.TAB_SUMMARY) {
        params.disposition = this.props.filtertype.disposition;
        // Override the active flag to TRUE if we're on inventory summary
        params.active = true;
      }
      // Add filtertype into the request
      params.filtertype = this.props.filtertype.tab;
      // Add the filter start and end values if this is an order list with filtering by date
      if (
        this.props.filtertype?.orderDateFilter?.start &&
        this.props.filtertype?.orderDateFilter?.end &&
        this.props.filtertype?.orderDateFilter?.filterOn
      ) {
        params.start = this.props.filtertype.orderDateFilter.start;
        params.end = this.props.filtertype.orderDateFilter.end;
        params.datefilter = this.props.filtertype.orderDateFilter.filterOn;
      }
    }

    // Add the filter start and end values if this is a timesheet list
    if (this.props.appState.currentView === Constants.TIMESHEETS) {
      if (this.state.start) {
        params.start = this.state.start;
      }
      if (this.state.end) {
        params.end = this.state.end;
      }
    }

    // Add the action to the request if this is a recurring billing list
    if (this.props.appState.currentView === Constants.RECURRINGS) {
      if (this.props.filtertype.tab === Constants.TAB_PLANS) {
        params.action = Constants.BILLING_PLAN;
      } else if (this.props.filtertype.tab === Constants.TAB_INVOICES) {
        params.action = Constants.INVOICE;
      } else if (this.props.filtertype.tab === Constants.TAB_CRON) {
        params.action = Constants.CRON;
      } else if (this.props.filtertype.tab === Constants.TAB_CRON_LOG) {
        params.action = Constants.CRON_LOG;
        if (this.state.cronjobid) {
          params.cronjobid = this.state.cronjobid;
        }
      } else {
        params.action = Constants.SUBSCRIPTION;
      }
    }

    // Remove the open flag for repairs because it's not relevant and will mess up the search
    if (this.props.appState.currentView === Constants.REPAIRS) {
      delete params.open;
    }

    // Remove the open/active flags for purchases because it's not relevant and will mess up the search
    if (this.props.appState.currentView === Constants.PURCHASES) {
      delete params.open;
      delete params.active;
    }

    return params;
  };

  getListItems(callback = null, selectedListItems = null) {
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_SPECIALS) {
      this.getSpecials();
      return;
    }
    // Billings are handled by the Maast API
    if (this.props.appState.currentView === Constants.BILLINGS && this.props.filtertype?.tab !== Constants.TAB_PLANS) {
      this.getSubscriptions();
      return;
    } else if (this.props.appState.currentView === Constants.BILLINGS && this.props.filtertype?.tab === Constants.TAB_PLANS) {
      this.getMaastBillingPlans();
      return;
    }
    this.setState(
      {
        downloading: true,
        listItems: [],
      },
      () => {
        let url = this.props.url;
        // The Customer page uses a flattened contact search when there is a sort key
        //   but the other list pages won't have a "sortUrl" property
        if (this.state.sortkey) {
          url = this.props.sortUrl ? this.props.sortUrl : this.props.url;
        }
        let params = this.buildGetListItemsParams(this.props.getParameters);

        // Append the start and limit values
        const pageSize = this.props.appState.displaySettings?.LIST_ROWS ? this.props.appState.displaySettings.LIST_ROWS : Constants.PAGE_SIZE;
        const start = pageSize * (this.state.pagenumber - 1);
        params.searchstart = start;
        params.searchlimit = pageSize;
        // This getData request can be preempted by another request (e.g. search key being types slowly) so we pass in a preemptor key
        // that lets the getData() function know to kill the previous request if it's still running and a new one is submitted
        // This should fix the "undefined undefined" error that happens on list views when the user types too slowly
        Helper.getData(url, params, null, null, Constants.PREEMPT_GET_LIST_ITEMS)
          .then(response => {
            if (response.status === 200 && response.body.records) {
              // Calculate pagination values
              const totalrecords = response.body.count ?? 1;
              let pagecount = totalrecords / pageSize;
              // We only need to round when the if there is a remainder
              if (totalrecords % pageSize !== 0) {
                pagecount = Math.round(pagecount + 0.5);
              }
              let records = this.maybeExpandRecords(response.body.records);
              let totals = response.body.totals || {};
              // If this is the Invoice view, look for online_count to add to state
              if (this.props.appState.currentView === Constants.INVOICES && response.body.online_count) {
                this.setState({ online_count: response.body.online_count });
              }
              // Reverse the records if we're on the Texts tab (newest on bottom)
              if (this.props.filtertype.tab === Constants.TEXTS && response?.body?.records?.length > 0) {
                response?.body?.records[0]?.messages?.records?.reverse();
              }
              // Add editing flag to timesheet entries
              if (this.props.filtertype.tab === Constants.TIMESHEETS_COMPLETED || this.props.filtertype.tab === Constants.TIMESHEETS_CURRENT) {
                records = records.map(record => {
                  record.editingIn = false;
                  record.editingOut = false;
                  return record;
                });
              }
              this.setState(
                {
                  listItems: records,
                  selectedListItems: selectedListItems || [],
                  pagecount: pagecount,
                  totalrecords: totalrecords,
                  error: null,
                  totals: totals,
                },
                () => {
                  // In messages, default the first contact as selected so it's messages get displayed
                  if (this.props.appState.currentView === Constants.MESSAGES && records.length > 0) {
                    this.setState({ selectedListItems: [records[0]] }, () => {
                      // For texts, scroll to the bottom with smooth animation
                      if (this.props.filtertype.tab === Constants.TEXTS) {
                        if (this.textPanelRef.current) {
                          this.textPanelRef.current.scrollTo({
                            top: this.textPanelRef.current.scrollHeight,
                            behavior: "smooth",
                          });
                        }
                        // Clear unread count after 3 seconds
                        setTimeout(() => {
                          this.handleClearUnreadMessages(this.state.selectedListItems[0].uuid);
                        }, Constants.SMS_UNREAD_CLEAR_DELAY);
                      }
                    });
                  }
                  if (callback) {
                    callback();
                  }
                }
              );
            } else if (response.status === 503) {
              this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
              this.props.hideOverlay();
            } else {
              this.setState({ error: "GET" }, () => {
                this.props.showOverlay({
                  type: Constants.OVERLAY_MESSAGE,
                  text: "There was an error loading the " + this.props.title + ".",
                });
              });
            }
            this.setState({
              downloading: false,
            });
          })
          .catch(err => {
            // do nothing if the request was cancelled
            console.log(Helper.clTimestamp(), "getListItems() aborted");
            this.setState({
              listItems: [],
              pagecount: 1,
              pagenumber: 1,
              pagenumberentry: 1,
              error: null,
              totals: {},
            });
          });
      }
    );
  }

  cancelExport = () => {
    this.setState({ exportState: Constants.EXPORT_STATE_CANCELLED }, () => {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Export cancelled.",
      });
    });
  };

  getInventoryStats = () => {
    const url = Constants.URL_PRODUCTS;
    const params = { action: Constants.ACTION_INVENTORY_STATS };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Store the records in state
        this.setState({ inventoryStats: response.body });
      } else {
        this.setState({ inventoryStats: {} });
      }
    });
  };

  // Attempts to retrieve the product from the server. Expects a single product to be returned.
  // If multiple products are returned, then the user is prompted to select the correct one.
  // If no products are returned, then the item is marked as not matched.
  getProduct = (item, lastIndex) => {
    const url = Constants.URL_PRODUCTS;
    const params = { searchkey: item.searchkey, action: Constants.ACTION_INVENTORY };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body?.records?.length === 1) {
        const product = response.body.records[0];
        if (product) {
          // Append new product to product list and save to local storage
          this.setState(
            prevState => ({
              products: [...prevState.products, product],
            }),
            () => {
              localStorage.setItem(Constants.LOCAL_STORAGE_PRODUCTS, JSON.stringify(this.state.products));
            }
          );

          // Save to state
          this.setState(prevState => ({
            listItems: prevState.listItems.map(i => {
              if (i.searchkey === item.searchkey && !i.productuuid) {
                i.productuuid = product.productuuid;
                i.productname = product.productname;
                i.storesku = product.storesku;
                i.inventory = product.inventory;
                if (product.affectinventory) {
                  i.status = Constants.INVENTORY_STATUS_MATCHED;
                } else {
                  i.status = Constants.INVENTORY_STATUS_NOT_INVENTORIED;
                }
              }
              return i;
            }),
          }));
          // Update all matching items in local storage
          for (let i = 0; i <= lastIndex; i++) {
            let value = localStorage.getItem(`${Constants.LOCAL_STORAGE_INVENTORY}${i}`);
            value = JSON.parse(value);
            if (value && value.searchkey === item.searchkey && !value.productuuid) {
              value.productuuid = product.productuuid;
              value.productname = product.productname;
              value.storesku = product.storesku;
              value.inventory = product.inventory;
              if (product.affectinventory) {
                value.status = Constants.INVENTORY_STATUS_MATCHED;
              } else {
                value.status = Constants.INVENTORY_STATUS_NOT_INVENTORIED;
              }
              localStorage.setItem(value.key, JSON.stringify(value));
            }
          }
        }
      } else if (response.status === 200 && response.body?.records?.length > 1) {
        const products = response.body.records.map(product => {
          product.id = product.productuuid;
          product.text = `${product.productname}`;
          if (product.storesku) {
            product.text = `${product.productname} (${product.storesku})`;
          }
          return product;
        });
        const text =
          `${response.body.count} matching products found.\n` +
          `Displaying the first ${response.body.records.length}.\n` +
          `Select the product to inventory or refine your search.`;
        const overlay = {
          type: Constants.OVERLAY_PICKER,
          text: text,
          callback: this.handlePickInventoryProduct,
          items: products,
          key: null,
          event: { index: lastIndex },
        };
        this.props.showOverlay(overlay);
      } else {
        // Mark item as not matched
        this.setState(prevState => ({
          listItems: prevState.listItems.map(i => {
            if (i.searchkey === item.searchkey && !i.productuuid) {
              i.status = Constants.INVENTORY_STATUS_NOT_MATCHED;
            }
            return i;
          }),
        }));
        // Update all matching items in local storage
        for (let i = 0; i <= lastIndex; i++) {
          let value = localStorage.getItem(`${Constants.LOCAL_STORAGE_INVENTORY}${i}`);
          value = JSON.parse(value);
          if (value && value.searchkey === item.searchkey && !value.productuuid) {
            value.status = Constants.INVENTORY_STATUS_NOT_MATCHED;
            localStorage.setItem(value.key, JSON.stringify(value));
          }
        }
      }
    });
  };

  getListItemsForExport = (pageNumber = 0) => {
    // Reset export state
    if (pageNumber === 0) {
      this.setState({ exportState: Constants.EXPORT_STATE_EXPORTING, csv: "" });
    }
    // Check for cancel action
    else if (this.state.exportState === Constants.EXPORT_STATE_CANCELLED) {
      return;
    }
    // TODO: Remove when prospects can be exported
    // Cannot currently export prospects
    if (this.props.appState.currentView === Constants.CUSTOMERS && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Prospects cannot be exported (yet)",
      });
      return;
    }
    // Check for empty list of items
    if (this.state.listItems.length === 0) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Nothing to export.",
      });
      return;
    }
    // Display the progress overlay on the first page
    if (pageNumber === 0) {
      this.setState({ exportState: Constants.EXPORT_STATE_EXPORTING }, () => {
        this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Downloading data...", cancelCallback: this.cancelExport });
      });
    }
    // We always export contacts using /contacts, since companies really doesn't have any additional data
    let url;
    // Both Customer and Supplier pages use the contacts endpoint for exports
    if ([Constants.CUSTOMERS, Constants.SUPPLIERS].includes(this.props.appState.currentView)) {
      url = Constants.URL_CONTACTS;
    } else {
      url = this.props.url;
    }

    let params = this.buildGetListItemsParams(this.props.getParameters);

    // Indicate that we want to download a CSV-formatted dataset
    params.csv_download = true;

    // Append the start and limit values
    const pageSize = Constants.LIST_DOWNLOAD_BLOCK_SIZE;
    const start = pageSize * pageNumber;
    params.searchstart = start;
    params.searchlimit = pageSize;

    Helper.getData(url, params).then(response => {
      if (response.status === 200 && (response.body.csv || response.body.csv === "")) {
        this.setState(
          prevState => ({
            csv: (prevState.csv || "") + (response.body.csv || ""),
            error: null,
          }),
          () => {
            // Check for more data
            if (response.body.count > start + pageSize && this.state.exportState !== Constants.EXPORT_STATE_CANCELLED) {
              // Get the next block of data
              const message = `Downloading ${start + pageSize} of ${response.body.count}...`;
              this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: message, cancelCallback: this.cancelExport });
              this.getListItemsForExport(pageNumber + 1);
            } else {
              // Check for cancel action before trigger download
              if (this.state.exportState !== Constants.EXPORT_STATE_CANCELLED) {
                this.props.hideOverlay();
                let filename = this.props.title;
                if (this.props.filtertype?.tab && this.props.filtertype?.tab !== Constants.TAB_NONE) {
                  filename += "-" + this.props.filtertype?.tab;
                }
                if (this.state.searchkey.trim()) {
                  filename += "-" + this.state.searchkey.trim();
                }
                // Trigger download
                const element = document.createElement("a");
                const file = new Blob([this.state.csv], { type: "text/csv" });
                element.href = URL.createObjectURL(file);
                element.download = filename + ".csv";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                const text =
                  "Export complete." +
                  (this.props.appState.inventory && this.props.appState.currentView === Constants.PRODUCTS
                    ? "\nReturn to the Product Summary tab to complete inventory reconciliation when ready."
                    : "");
                this.props.showOverlay({
                  type: Constants.OVERLAY_MESSAGE,
                  text: text,
                });
              }
            }
          }
        );
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error exporting the " + this.props.title + ".",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getSubscriptions = () => {
    this.setState(
      {
        downloading: true,
        listItems: [],
      },
      () => {
        let url = Constants.URL_BILLING;
        const params = { action: Constants.BILLING_GET_SUBSCRIPTION };
        params.filter = [];
        if (this.props.filtertype?.tab === Constants.TAB_ACTIVE) {
          params.filter.push("status,IN,[A,P,S]");
        } else if (this.props.filtertype?.tab === Constants.TAB_INACTIVE) {
          params.filter.push("status,NOT_IN,[A,P,S]");
        } else if (this.props.filtertype?.tab === Constants.TAB_OWING) {
          // Only show subscriptions with an overdue amount
          params.filter.push("amt_overdue_total,GREATER,0");
          // Do not show cancelled subscriptions even if they have an owing balance
          params.filter.push("status,NOT_IN,[C]");
        }

        // Search using the standard searchkey (all fields except custom fields)
        if (this.state.searchkey.trim()) {
          // Maast does not support more than 12 subfilters, which is why we split the searchkey into two parts (at most)
          let searchkey = this.state.searchkey.trim().replace("\t", " ").split(" ");
          if (searchkey.length > 2) {
            searchkey = [searchkey[0], searchkey.slice(1).join(" ")];
          }
          const filterString = searchkey
            .map(word => {
              let filterfields = ["custom_fields", "plan_desc", "customer_email"];
              // if the word does not contain alpha characters, then include phone number
              if (!word.trim().match(/[a-zA-Z]/i)) {
                filterfields.push("customer_phone");
              }
              // If the field contains alpha characters, then include first and last name
              if (word.trim().match(/[a-zA-Z]/i)) {
                filterfields.push("customer_first_name", "customer_last_name");
              }
              return filterfields
                .map(field => {
                  if (field === "customer_phone") {
                    return field + ",CONTAINS," + Helper.formatPhoneNumber(word);
                  }
                  return field + ",CONTAINS," + word.trim();
                })
                .join("|");
            })
            .join("|");

          params.filter.push(encodeURIComponent(filterString));
        }

        // Search the custom fields, which are stored in the ClerkHound customfields table
        // which is linked to the Maast subscriptions by the subscription_id
        else if (this.state.searchkeyCustomFields.trim()) {
          url = Constants.URL_RECURRINGS;
          params.action = Constants.CUSTOM_FIELD;
          params.searchkey = this.state.searchkeyCustomFields.trim();
          params.searchstart = 0;
          params.searchlimit = Constants.PAGE_SIZE;
        }

        // Append the start and limit values
        const pageSize = this.props.appState.displaySettings?.LIST_ROWS ? this.props.appState.displaySettings.LIST_ROWS : Constants.PAGE_SIZE;
        // Page number is zero-based on the Maast side
        params.page = this.state.pagenumber - 1;
        params.count = pageSize;

        // Order by
        if (this.state.sortkey) {
          params.order_on = this.state.sortkey;
          params.order_by = this.state.sortdirection === "A" ? "asc" : "desc";
        } else {
          params.order_on = "customer_first_name";
          params.order_by = "asc";
        }

        Helper.getData(url, params).then(response => {
          if (response.status === 200 && response.body.code === 0 && response.body.data) {
            const subscriptions = response.body.data.map(subscription => {
              subscription.uuid = subscription.subscription_id;
              subscription.is_individual = subscription.plan_id === 0;
              subscription.plan_desc = subscription.plan_desc || subscription.plan_name;
              subscription.type = Constants.BILLING_SUBSCRIPTION;
              return subscription;
            });

            this.setState({
              listItems: subscriptions,
              pagecount: response.body.totalPages,
              error: null,
            });
          } else if (response.status === 503) {
            this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
            this.props.hideOverlay();
          } else {
            this.setState({ error: "GET" }, () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "There was an error loading the " + this.props.title + ".",
              });
            });
          }
          this.setState({
            downloading: false,
          });
        });
      }
    );
  };

  getMaastBillingPlans = () => {
    this.setState(
      {
        downloading: true,
        listItems: [],
      },
      () => {
        const params = { action: Constants.BILLING_GET_PLANS };

        params.filter = [];

        // Append the start and limit values
        const pageSize = this.props.appState.displaySettings?.LIST_ROWS ? this.props.appState.displaySettings.LIST_ROWS : Constants.PAGE_SIZE;
        // Page number is zero-based on the Maast side
        params.page = this.state.pagenumber - 1;
        params.count = pageSize;

        if (this.state.sortkey) {
          params.order_on = this.state.sortkey;
          params.order_by = this.state.sortdirection === "A" ? "asc" : "desc";
        } else {
          params.order_on = "plan_desc";
          params.order_by = "asc";
        }

        // Handle filtering
        if (this.props.filtertype.active === Constants.FILTER_ACTIVE) {
          params.filter.push("status,IS,E");
        } else if (this.props.filtertype.active === Constants.FILTER_INACTIVE) {
          params.filter.push("status,IS_NOT,E");
        } else {
          params.filter.push("status,IS,E");
        }

        if (this.state.searchkey.trim()) {
          const filterStrings = this.state.searchkey
            .trim()
            .split(" ")
            .map(s => {
              let f = "plan_desc,CONTAINS," + s;
              // If s is numeric, add a filter for amt_tran
              if (!isNaN(s)) {
                f += "|amt_tran,IS," + s;
              }
              const freq = Helper.getMaastFrequency(s);
              if (freq !== null) {
                f += "|plan_frequency,IS," + freq;
              }
              return f;
            });
          const filterString = encodeURIComponent(filterStrings.join("|"));
          params.filter.push(filterString);
        }

        const url = Constants.URL_BILLING;
        Helper.getData(url, params).then(response => {
          if (response.status === 200 && response.body.code === 0 && response.body.data) {
            const plans = response.body.data.map(plan => {
              return Helper.normalizePlan(plan);
            });
            this.setState({
              listItems: plans,
              pagecount: response.body.totalPages,
              error: null,
            });
          } else if (response.status === 503) {
            this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
            this.props.hideOverlay();
          } else {
            this.setState({ error: "GET" }, () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "There was an error loading the " + this.props.title + ".",
              });
            });
          }
          this.setState({
            downloading: false,
          });
        });
      }
    );
  };

  getEmployees = () => {
    const url = Constants.URL_USERS;
    const params = {};
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        let employees = [{ uuid: 0, fullname: "Select Employee" }].concat(response.body.records);
        this.setState({
          employees: employees,
        });
      } else {
        this.setState({
          employees: [{ uuid: 0, name: "Unable to load employee list" }],
        });
      }
    });
  };

  getOnlineOrders = () => {
    // Show overlay progress bar with message "Retrieving online orders..."
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Retrieving online orders..." });
    const url = Constants.URL_REVERB;
    const params = {
      action: Constants.ACTION_GET_ORDERS,
    };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        if (response.body.lastretrieved) {
          this.setState({ reverblastretrieved: response.body.lastretrieved });
        }
        if (response.body.message) {
          if (response.body.count) {
            console.log(Helper.clTimestamp(), "Online orders updated: ", response.body.count);
          }
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        } else {
          this.props.hideOverlay();
        }
      } else if (response.status === 403) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Unable to retrieve orders. Please check your Reverb API key\nand ensure it has the 'read_orders' and 'write_orders' scopes assigned.",
        });
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error retrieving the online orders.",
        });
      }
    });
  };

  // Create a new order record in the database with a copy of the all the order items (itemuuids)
  postPurchaseOrder = itemuuids => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDERS;
    const data = { ordertype: Constants.PURCHASE };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        const destinationorder = response.body;
        // If there are no line items, offer to go straight to the Purchase
        if (itemuuids.length === 0) {
          this.maybeOpenPurchaseOrder(destinationorder);
        } else {
          // POST the product line items
          this.postPurchaseOrderItems(destinationorder, itemuuids);
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the order.",
          });
        });
      }
    });
  };

  // Writes all ordered items to the database and updates the order,
  //  finally storing the order (with orderitems) in the state
  postPurchaseOrderItems = (destinationorder, itemuuids) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDER_ITEMS;
    const data = {
      ordertype: this.getOrderType(),
      orderuuid: destinationorder.orderuuid,
      orderitems: itemuuids,
      lineitemstatus: Constants.ORDER_STATUS_OPEN,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body && response.body.records) {
        // Update the items in the order and save to state
        const purchaseorder = response.body;
        this.setState(
          {
            downloading: false,
            error: null,
          },
          () => {
            this.maybeOpenPurchaseOrder(purchaseorder);
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the order.",
          });
        });
      }
    });
  };

  // Update product details
  putProduct = (productuuid, fieldname, value, callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // Perform any required data conversions
    if (fieldname === "onhand" || fieldname === "inventory" || fieldname === "sellprice" || fieldname === "cost") {
      value = numeral(value).value();
    }

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    const data = {
      productuuid: productuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Format the max discount as a percentage
        if (response.body.maxdiscount) {
          response.body.maxdiscount = Helper.formatPercent(response.body.maxdiscount);
        }
        // Format the product dimensions
        if (response.body.length) {
          response.body.length = numeral(response.body.length).format(Constants.DECIMAL_VALUE);
        }
        if (response.body.width) {
          response.body.width = numeral(response.body.width).format(Constants.DECIMAL_VALUE);
        }
        if (response.body.height) {
          response.body.height = numeral(response.body.height).format(Constants.DECIMAL_VALUE);
        }
        if (response.body.weight) {
          response.body.weight = numeral(response.body.weight).format(Constants.DECIMAL_VALUE);
        }
        this.setState(prevState => ({
          listItems: prevState.listItems.map(i => {
            if (i.uuid === productuuid) {
              // Overlay the new values on top of the existing item
              i = { ...i, ...response.body };
            }
            return i;
          }),
          downloading: false,
        }));
        //Hide overlay after database action is complete
        this.props.hideOverlay();
        if (callback) {
          callback();
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the product.",
          });
        });
      }
    });
  };

  putSetting = (uuid, value, callback = null) => {
    // If nothing to do, return
    if (!uuid || !value) {
      return;
    }

    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SETTINGS;
    let params = { settinguuid: uuid, value: value };
    Helper.putData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Good response
        this.setState({ downloading: false }, () => {
          if (callback) {
            callback(true);
          }
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false }, () => {
          if (callback) {
            callback(false);
          }
        });
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          if (callback) {
            callback(false);
          }
        });
      }
    });
  };

  renderPaginationOrTotals() {
    let totals = "";
    let pagination = "";
    // Only show totals on the order list view and if there are totals to show (except TODAY's invoices, which always shows totals)
    const isSubscriptionInvoiceList =
      this.props.appState.currentView === Constants.RECURRINGS &&
      [Constants.TAB_INVOICES, Constants.TAB_ACTIVE, Constants.TAB_OWING].includes(this.props.filtertype.tab);
    let showTotals =
      (Helper.isOrderListView(this.props.appState.currentView) || isSubscriptionInvoiceList) &&
      this.state.totals &&
      (Object.keys(this.state.totals).length !== 0 || this.props.filtertype.tab === Constants.ORDER_STATUS_TODAY);

    if (showTotals) {
      totals = (
        <div className="invoiceListTotalsGrid">
          {this.renderPriceOrCost()}
          {this.renderTotalTax()}
          {this.renderGrossOrNet()}
          {this.renderTotalPaymentsApplied()}
          {this.renderTotalOrders()}
          {this.renderSubscriptionTotals()}
        </div>
      );
    }
    if (this.state.showpagination) {
      pagination = (
        <Pagination
          pagenumber={this.state.pagenumber}
          pagecount={this.state.pagecount}
          pagenumberentry={this.state.pagenumberentry}
          handleChangePageNumber={this.handleChangePageNumber}
          handleNextPage={this.handleNextPage}
          handlePrevPage={this.handlePrevPage}
        />
      );
    }
    if (showTotals || this.state.showpagination) {
      return (
        <React.Fragment>
          {totals}
          {pagination}
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  renderSubscriptionTotals = () => {
    if (
      this.props.appState.currentView === Constants.RECURRINGS &&
      [Constants.TAB_ACTIVE, Constants.TAB_OWING, Constants.TAB_INVOICES].includes(this.props.filtertype.tab)
    ) {
      let totals = "";
      if (this.props.filtertype.tab === Constants.TAB_ACTIVE) {
        totals = this.state.totals.map
          ? this.state.totals
              // The amount owed is under a special label "OVERDUE_TOTAL" so filter it out to use later (below)
              .filter(total => total.label !== Constants.OVERDUE_TOTAL && total.label !== Constants.ORDER_COUNT)
              .map(total => {
                return (
                  <React.Fragment key={total.label}>
                    <div data-testid="Total Recurring Amount Label" className="subscriptionListTotalsLabel">
                      {total.label} Subscriptions
                    </div>
                    <div data-testid={"Total Recurring Amount " + total.label} className="subscriptionListTotalsValue">
                      {numeral(total.amount).format(Constants.CURRENCY_WITH_SYMBOL)}
                    </div>
                  </React.Fragment>
                );
              })
          : "";
      }
      // The amount owed is under a special label "OVERDUE_TOTAL" in the totals array
      // The totals returned by Invoices is not an array, so we need to check if it is an array before using it
      let amountOwed = null;
      let owed = "";
      if (this.state.totals.find) {
        amountOwed = this.state.totals.find(total => total.label === Constants.OVERDUE_TOTAL);
        owed = amountOwed?.label ? (
          <React.Fragment>
            <div data-testid="Total Owing Amount Label" className="subscriptionListTotalsLabel">
              Total Overdue Balance
            </div>
            <div data-testid="Total Owing Amount" className="subscriptionListTotalsValue">
              {numeral(amountOwed.amount).format(Constants.CURRENCY_WITH_SYMBOL)}
            </div>
          </React.Fragment>
        ) : (
          ""
        );
      }
      const orderCount = this.state.totalrecords ? (
        <React.Fragment>
          <div data-testid="Total Order Count Label" className="subscriptionListTotalsLabel">
            Number of Subscriptions
          </div>
          <div data-testid="Total Order Count Amount" className="subscriptionListTotalsValue">
            {numeral(this.state.totalrecords).format(Constants.INTEGER_WITH_COMMA)}
          </div>
        </React.Fragment>
      ) : (
        ""
      );

      return (
        <React.Fragment>
          {totals}
          {owed}
          {orderCount}
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  renderTotalOrders() {
    if (Constants.TOTALS_VIEW_SHOW_COUNT.includes(this.props.appState.currentView)) {
      return (
        <React.Fragment>
          <div data-testid="Total Records Label" className="invoiceListTotalsLabel">
            Number of {this.props.appState.currentView}
          </div>
          <div data-testid="Total Records" className="invoiceListTotalsValue">
            {numeral(this.state.totalrecords ?? 0).format(Constants.INTEGER_WITH_COMMA)}
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  renderTotalPaymentsApplied() {
    const isSubscriptionInvoiceList =
      this.props.appState.currentView === Constants.RECURRINGS &&
      this.props.filtertype.tab === Constants.TAB_INVOICES &&
      [Constants.FILTER_ACTIVE, Constants.FILTER_ALL].includes(this.props.filtertype.active);
    if (Constants.TOTALS_VIEW_SHOW_PAYMENTS.includes(this.props.appState.currentView) || isSubscriptionInvoiceList) {
      const label = this.props.appState.currentView === Constants.INVOICES || isSubscriptionInvoiceList ? "Payments" : "Deposits";
      return (
        <React.Fragment>
          <div data-testid="Total Payments Applied Label" className="invoiceListTotalsLabel">
            Total {label} Applied
          </div>
          <div data-testid="Total Payments Applied Amount" className="invoiceListTotalsValue">
            {numeral(this.state.totals?.totalpayments).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  renderTotalTax() {
    if (Constants.TOTALS_VIEW_SHOW_TAXES.includes(this.props.appState.currentView)) {
      const label = this.props.appState.currentView === Constants.INVOICES ? "Total Tax" : "Total Estimated Tax";
      return (
        <React.Fragment>
          <div data-testid="Total Tax Label" className="invoiceListTotalsLabel">
            {label}
          </div>
          <div data-testid="Total Tax Amount" className="invoiceListTotalsValue">
            {numeral(this.state.totals?.totaltax).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  renderPriceOrCost() {
    if (this.state.showProfit || this.props.appState.currentView === Constants.PURCHASES) {
      return (
        <React.Fragment>
          <div data-testid="Total Cost Label" className="invoiceListTotalsLabel" onClick={() => this.setState({ showProfit: false })}>
            Total Cost
          </div>
          <div data-testid="Total Cost Amount" className="invoiceListTotalsValue">
            {numeral(this.state.totals?.totalcost).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    } else if (this.props.appState.currentView === Constants.RECURRINGS && this.props.filtertype.tab !== Constants.TAB_INVOICES) {
      return "";
    } else {
      let label = "Total Price";
      if (this.props.appState.currentView === Constants.RECURRINGS && this.props.filtertype.tab === Constants.TAB_INVOICES) {
        label = "Invoices Total";
      }
      return (
        <React.Fragment>
          <div data-testid="Total Price Label" className="invoiceListTotalsLabel" onClick={() => this.setState({ showProfit: true })}>
            {label}
          </div>
          <div data-testid="Total Price Amount" className="invoiceListTotalsValue">
            {numeral(this.state.totals?.totalprice).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
        </React.Fragment>
      );
    }
  }

  renderGrossOrNet() {
    if (Constants.TOTALS_VIEW_SHOW_GROSS_OR_NET.includes(this.props.appState.currentView)) {
      if (this.state.showProfit) {
        const label = this.props.appState.currentView === Constants.INVOICES ? "Net Profit" : "Price - Cost";
        return (
          <React.Fragment>
            <div data-testid="Total Net Profit Label" className="invoiceListTotalsLabel" onClick={() => this.setState({ showProfit: false })}>
              {label}
            </div>
            <div data-testid="Total Net Profit Amount" className="invoiceListTotalsValue">
              {numeral(this.state.totals?.netprofit).format(Constants.CURRENCY_WITH_SYMBOL)}
            </div>
          </React.Fragment>
        );
      } else if (this.props.appState.currentView !== Constants.ORDERS) {
        const label = this.props.appState.currentView === Constants.INVOICES ? "Gross Sales" : "Price + Estimated Tax";
        return (
          <React.Fragment>
            <div data-testid="Total Gross Sales Label" className="invoiceListTotalsLabel" onClick={() => this.setState({ showProfit: true })}>
              {label}
            </div>
            <div data-testid="Total Gross Sales Amount" className="invoiceListTotalsValue">
              {numeral(this.state.totals?.grosssales).format(Constants.CURRENCY_WITH_SYMBOL)}
            </div>
          </React.Fragment>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  renderCalendarFilter = () => {
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_TODAY || this.props.filtertype.tab === Constants.TAB_PROSPECTS) {
      return <span></span>;
    }
    return (
      <span
        title="Filter List Button"
        className={"hoverPointer" + (this.props.filtertype?.orderDateFilter?.filterOn ? " highlight" : "")}
        onClick={this.handleShowOrderFilter}
      >
        <FilterFunnel />
      </span>
    );
  };
}

export default BaseListViewComponent;
