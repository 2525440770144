import React from "react";

// Style
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="paginationContainer">
        <span className="desktop-inline"></span>
        <span className="pageLeft" id="page-left" onClick={this.props.handlePrevPage}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </span>
        <span>
          Page &nbsp;
          <input
            type="text"
            name="pagenumber"
            id="pagenumber"
            maxLength={5}
            className="pageNumber"
            autoComplete="off"
            value={this.props.pagenumberentry}
            onChange={this.props.handleChangePageNumber}
            // Block propogation of HOME/END/PGUP/PGDN keys to prevent pagination from being triggered
            onKeyDown={event => {
              if (["Home", "End"].includes(event.code)) {
                event.stopPropagation();
              }
            }}
          />
          &nbsp;of {this.props.pagecount || 1}
        </span>
        <span className="pageRight" id="page-right" onClick={this.props.handleNextPage}>
          <FontAwesomeIcon icon={faCaretRight} />
        </span>
      </div>
    );
  };
}

export default Pagination;
