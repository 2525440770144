import React from "react";

import SearchBar from "./SearchBar.jsx";
import * as Constants from "./Constants";
import * as Helper from "./Helper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudBolt, faAngleDown, faTriangleExclamation, faPencil } from "@fortawesome/free-solid-svg-icons";
import EmployeeSearchBar from "./EmployeeSearchBar.jsx";

class ListControls extends React.Component {
  constructor(props) {
    super(props);
    this.filterlistRef = React.createRef();

    this.state = {
      showFilterOptions: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render = () => {
    const topButtons = this.props.renderControlButtons ? this.props.renderControlButtons() : <div></div>;
    return (
      <React.Fragment>
        <h1 className="component-title">
          {this.props.title}
          {this.renderCloudIcon()}
          {this.renderErrorIcon()}
        </h1>
        <div className="listControlsContainer">
          {this.renderListControlButtons()}
          {this.renderFilterComponent()}
          {this.renderSearchBar()}
          {this.renderCustomDataSearchBar()}
        </div>
        <div className="new-po-wrapper">{this.props.renderCreatePurchaseButton("mobile")} </div>
        <div className="listControlsContainer">
          {topButtons}
          {this.props.renderControlButtons ? "" : <div></div>}
        </div>
      </React.Fragment>
    );
  };

  renderSearchBar = () => {
    if (this.props.showsearch) {
      return (
        <SearchBar
          appState={this.props.appState}
          disabled={this.props.disabled}
          handleSearchChange={this.props.handleSearchChange}
          searchkey={this.props.searchkey}
          handleClearSearch={this.props.handleClearSearch}
          handleSearchKeyDown={this.props.handleSearchKeyDown}
          searchPlaceholder={this.props.searchPlaceholder}
          fieldref={this.props.searchRef}
        />
      );
    } else if (this.props.showemployeesearch) {
      return (
        <EmployeeSearchBar
          appState={this.props.appState}
          handleChange={this.props.handleChangeEmployee}
          searchPlaceholder={this.props.searchPlaceholder}
          fieldref={this.props.searchRef}
          employee={this.props.employee}
          employees={this.props.employees}
        />
      );
    } else {
      return "";
    }
  };

  renderCustomDataSearchBar = () => {
    if (this.props.showsearch && this.props.appState.currentView === Constants.BILLINGS && this.props.filtertype?.tab !== Constants.TAB_PLANS) {
      return (
        <React.Fragment>
          {/* This area is a 3 column grid where the first 2 columns are blank */}
          <div></div>
          <div></div>
          <SearchBar
            id="searchkeyCustomFields"
            appState={this.props.appState}
            disabled={this.props.disabled}
            handleSearchChange={this.props.handleSearchChangeCustomFields}
            searchkey={this.props.searchkeyCustomFields}
            handleClearSearch={this.props.handleClearSearchCustomFields}
            handleSearchKeyDown={this.props.handleSearchKeyDown}
            searchPlaceholder="Search Custom Data"
            fieldref={this.props.searchRef2}
          />
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  renderListControlButtons = () => {
    let newButton = "";
    let cancelInventoryButton = "";
    let pauseInventoryButton = "";
    let completeInventoryButton = "";
    let showNew =
      !this.props.hideNew &&
      !(
        this.props.appState?.currentView === Constants.PRODUCTS &&
        [Constants.TAB_INVENTORY, Constants.TAB_SUMMARY].includes(this.props.filtertype?.tab)
      ) &&
      !(this.props.appState?.currentView === Constants.RECURRINGS && this.props.filtertype?.tab === Constants.TAB_CRON);

    if (showNew) {
      let extra = "";
      let handleClick = () => {
        this.props.handleNew(null);
      };
      let label = "New";
      if (this.props.appState.currentView === Constants.TIMESHEETS) {
        label = "Clock in";
      } else if (this.props.appState.currentView === Constants.INVOICES && this.props.filtertype?.tab === Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
        label = "Retrieve Orders";
        handleClick = () => {
          this.props.handleGetOnlineOrders();
        };
        // Add the "Last Fetched" date/time
        let lastRetrieved = Helper.convertUtcToLocal(this.props.reverblastretrieved, this.props.appState.clientSettings.TIMEZONE);
        lastRetrieved = Helper.formatDateTimeForWebService(lastRetrieved);
        lastRetrieved = Helper.formatDateTime(lastRetrieved, false, false);
        extra = (
          <span className="alignSelfCenter ghost" onClick={this.props.handleChangeLastRetrieved}>
            Last Retrieved: {lastRetrieved}{" "}
            <span className="ghost">
              <FontAwesomeIcon icon={faPencil} />
            </span>
          </span>
        );
      } else if (this.props.appState.currentView === Constants.BILLINGS) {
        if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
          label = "New Plan";
        } else {
          label = "New Subscription";
        }
      }
      newButton = (
        <React.Fragment>
          <span id="list-new-button" className="action-button green-button" onClick={handleClick} data-testid="New">
            {this.props.newicon}
            {label}
          </span>
          {extra}
        </React.Fragment>
      );
    } else if (this.props.appState?.currentView === Constants.PRODUCTS && this.props.filtertype?.tab === Constants.TAB_SUMMARY) {
      cancelInventoryButton = (
        <span
          id="list-inventory-button"
          className="action-button red-button"
          onClick={() => {
            this.props.showOverlay({
              type: Constants.OVERLAY_QUESTION,
              text: "Abandon inventory reconciliation and lose changes?",
              callback: response => {
                if (response === Constants.OVERLAY_RESPONSE_YES) {
                  this.props.handleInventory(Constants.ACTION_INVENTORY_CANCEL);
                }
              },
            });
          }}
        >
          Abandon
        </span>
      );
      pauseInventoryButton = (
        <span
          id="list-inventory-button"
          className="action-button brown-button"
          onClick={() => {
            this.props.showOverlay({
              type: Constants.OVERLAY_QUESTION,
              text: "Pause inventory reconciliation?",
              callback: response => {
                if (response === Constants.OVERLAY_RESPONSE_YES) {
                  this.props.handleInventory(Constants.ACTION_INVENTORY_PAUSE);
                }
              },
            });
          }}
        >
          Pause
        </span>
      );
      const pending = this.props.inventoryStats?.dispositionpending;
      completeInventoryButton = (
        <span
          id="list-inventory-button"
          className="action-button green-button"
          onClick={() => {
            // If there are no pending items, just complete the inventory
            if (pending === 0) {
              this.props.handleInventory(Constants.ACTION_INVENTORY_END, false);
            } else {
              // If there are pending items, ask the user if they want to zero their inventory counts
              const confirmationText = `Set ${pending} uncounted products to ZERO`;
              const prompt =
                `There are ${pending} products that have not yet been inventoried.\n` +
                `Do you want to adjust the inventory count to ZERO for those products?\n`;
              this.props.showOverlay({
                type: Constants.OVERLAY_QUESTION,
                title: "IMPORTANT!",
                text: prompt,
                bullets:
                  `To keep the inventory count unchanged for all uncounted items, select No.\n` +
                  `To zero the inventory counts, type "${confirmationText}" below and select Yes.\n`,
                confirmationText: confirmationText,
                placeholder: `Type "${confirmationText}"`,
                maxLength: confirmationText.length,
                input: "",
                callback: response => {
                  const updateuninventoried = response === Constants.OVERLAY_RESPONSE_YES;
                  this.props.handleInventory(Constants.ACTION_INVENTORY_END, updateuninventoried);
                },
              });
            }
          }}
        >
          Complete
        </span>
      );
    }

    return (
      <div className="listControlButtons">
        {newButton}
        {cancelInventoryButton}
        {pauseInventoryButton}
        {completeInventoryButton}
      </div>
    );
  };

  renderCloudIcon = () => {
    if (this.props.downloading) {
      return (
        <span className="cloud-icon">
          <FontAwesomeIcon icon={faCloudBolt} />
        </span>
      );
    } else {
      return "";
    }
  };

  renderErrorIcon = () => {
    if (this.props.error === "GET") {
      return (
        <span className="cloud-icon cloud-error">
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </span>
      );
    } else {
      return "";
    }
  };

  renderFilterComponent = () => {
    if (this.props.hideFilter && this.props.hideFilterDates && this.props.hideFilterDisposition) {
      return <div className="desktop"></div>;
    } else if (!this.props.hideFilter) {
      if (this.props.appState.currentView === Constants.CUSTOMERS || this.props.appState.currentView === Constants.PRODUCTS) {
        return this.renderFilterComponentCustomer();
      } else {
        return this.renderFilterComponentRecurring();
      }
    } else if (!this.props.hideFilterDates) {
      return (
        <div className="desktop filter-by">
          <div className="dateFilter">
            <div className="timesheetStartDate">
              <label htmlFor="start">Begin Date: </label>
              <input type="date" id="start" onChange={this.props.handleChangeStart} value={this.props.start} />
            </div>
            <div className="timesheetEndDate">
              <label htmlFor="end">End Date: </label>
              <input type="date" id="end" onChange={this.props.handleChangeEnd} value={this.props.end} />
            </div>{" "}
          </div>
        </div>
      );
    } else if (!this.props.hideFilterDisposition) {
      let filterLabel = <span className="dispositionFilterItem">{Helper.renderDisposition(this.props.filtertype?.disposition)}</span>;
      const filterListClasses = this.state.showFilterOptions ? " open " : "";
      return (
        <div className="desktop filter-by disposition">
          Filter by:
          <span className="filter-button" onClick={this.handleShowFilterOptions}>
            {filterLabel} <FontAwesomeIcon icon={faAngleDown} />
            <div id="filter-list" ref={this.filterlistRef} className={filterListClasses}>
              <div onClick={() => this.handleFilterSelection({ disposition: Constants.DISPOSITION_ALL })}>
                {Helper.renderDisposition(Constants.DISPOSITION_ALL)}
              </div>
              <div onClick={() => this.handleFilterSelection({ disposition: Constants.DISPOSITION_INVENTORIED })}>
                {Helper.renderDisposition(Constants.DISPOSITION_INVENTORIED)}
              </div>
              <div onClick={() => this.handleFilterSelection({ disposition: Constants.DISPOSITION_PENDING })}>
                {Helper.renderDisposition(Constants.DISPOSITION_PENDING)}
              </div>
              <div onClick={() => this.handleFilterSelection({ disposition: Constants.DISPOSITION_MATCHED })}>
                {Helper.renderDisposition(Constants.DISPOSITION_MATCHED)}
              </div>
              <div onClick={() => this.handleFilterSelection({ disposition: Constants.DISPOSITION_MISSING })}>
                {Helper.renderDisposition(Constants.DISPOSITION_MISSING)}
              </div>
              <div onClick={() => this.handleFilterSelection({ disposition: Constants.DISPOSITION_OVERAGE })}>
                {Helper.renderDisposition(Constants.DISPOSITION_OVERAGE)}
              </div>
            </div>
          </span>
        </div>
      );
    }
  };

  renderFilterComponentRecurring = () => {
    // Open or Processed filter
    let filterLabel = <span>Open</span>;
    if (this.props.filtertype.active === Constants.FILTER_INACTIVE) {
      filterLabel = <span>Processed</span>;
    } else if (this.props.filtertype.active === Constants.FILTER_ALL) {
      filterLabel = <span>All</span>;
    }
    const filterListClasses = this.state.showFilterOptions ? " open " : "";
    return (
      <div className="desktop filter-by">
        Filter by:
        <span title="Filter List Items" className="filter-button" onClick={this.handleShowFilterOptions}>
          {filterLabel} <FontAwesomeIcon icon={faAngleDown} />
          <div id="filter-list" ref={this.filterlistRef} className={filterListClasses}>
            <div data-testid="All" onClick={() => this.handleFilterSelection({ active: Constants.FILTER_ALL })}>
              All
            </div>
            <div data-testid="Active" onClick={() => this.handleFilterSelection({ active: Constants.FILTER_ACTIVE })}>
              Open
            </div>
            <div data-testid="Inactive" onClick={() => this.handleFilterSelection({ active: Constants.FILTER_INACTIVE })}>
              Processed
            </div>
          </div>
        </span>
      </div>
    );
  };

  renderFilterComponentCustomer = () => {
    const activeFilterLabel = this.props.filtertype?.tab === Constants.TAB_PROSPECTS ? "Open" : "Active";
    const inactiveFilterLabel = this.props.filtertype?.tab === Constants.TAB_PROSPECTS ? "Processed" : "Inactive";
    let filterLabel = activeFilterLabel;
    if (this.props.filtertype.active === Constants.FILTER_INACTIVE) {
      filterLabel = inactiveFilterLabel;
    }
    const filterListClasses = this.state.showFilterOptions ? " open " : "";
    return (
      <div className="desktop filter-by">
        Filter by:
        <span title="Filter List Items" className="filter-button" onClick={this.handleShowFilterOptions}>
          {filterLabel} <FontAwesomeIcon icon={faAngleDown} />
          <div id="filter-list" ref={this.filterlistRef} className={filterListClasses}>
            <div data-testid="Active" onClick={() => this.handleFilterSelection({ active: Constants.FILTER_ACTIVE })}>
              {activeFilterLabel}
            </div>
            <div data-testid="Inactive" onClick={() => this.handleFilterSelection({ active: Constants.FILTER_INACTIVE })}>
              {inactiveFilterLabel}
            </div>
          </div>
        </span>
      </div>
    );
  };

  handleFilterSelection = filtertype => {
    this.props.handleFilterList(filtertype);
  };

  handleShowFilterOptions = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };

  handleClickOutside = event => {
    if (this.filterlistRef && this.filterlistRef.current && !this.filterlistRef.current.contains(event.target)) {
      this.setState({ showFilterOptions: false });
    }
  };
}
export default ListControls;
