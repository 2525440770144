// Constants
import { v4 as uuidv4 } from "uuid";
import * as ApiBase from "./ApiBase";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import he from "he";
import numeral from "numeral";
import React from "react";

import BillingsIcon from "./img/BillingsIcon.js";
// import CalendarIcon from "./img/CalendarIcon.js";
import CustomersIcon from "./img/CustomersIcon.js";
import DashboardIcon from "./img/DashboardIcon.js";
import InvoicesIcon from "./img/InvoicesIcon.js";
import MessagesIcon from "./img/MessagesIcon.js";
import MegaphoneIcon from "./img/MegaphoneIcon.js";
import OrdersIcon from "./img/OrdersIcon.js";
import ProductsIcon from "./img/ProductsIcon.js";
import PurchasesIcon from "./img/PurchasesIcon.js";
import QuotesIcon from "./img/QuotesIcon.js";
import RepairsIcon from "./img/RepairsIcon.js";
import ReportsIcon from "./img/ReportsIcon.js";
import SuppliersIcon from "./img/SuppliersIcon.js";
import TimesheetsIcon from "./img/TimesheetsIcon.js";
import { addDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faCopy, faInfinity, faPencil } from "@fortawesome/free-solid-svg-icons";
import BillingCustomFields from "./BillingCustomFields.jsx";

// The fetch() function is ansynchronous, so we use the "await" keyword to wait for it to finish before continuing.
// The "response" from fetch() has a status, which is immediately available.
// However, the json() function is a Promise, so we have to call it and wait for it to return -- using .then() --
// before returning the status/body combined values from this function.

let abortControllers = {};

export async function getData(url = "", params = {}, baseUrl = null, headers = null, requestor = null) {
  const startTime = new Date();
  if (![Constants.URL_SESSIONS, Constants.URL_SESSIONS_RO].includes(url)) {
    console.log(clTimestamp() + "\n", "GET[" + (baseUrl ?? "") + url + "]");
  }
  // Convert the params JSON object into a URL encoded query string
  if (!baseUrl) {
    params = addSessionTokenToParams(params);
  }
  const queryString = Object.keys(params)
    .map(key => {
      // If params[key] is an array, the render it as multiple query string parameters
      if (Array.isArray(params[key])) {
        return params[key]
          .map(value => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(value);
          })
          .join("&");
      }
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
  const full_url = (baseUrl ?? ApiBase.URL) + url + "?" + queryString;
  const full_headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };
  let request = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: full_headers,
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };
  try {
    let abortController = null;
    if (requestor) {
      // If an inflight request exists for this requestor, then abort it
      abortGetData(requestor);

      // Create a new AbortController for this requestor's new message and add it to the list of inflight requests
      abortController = new AbortController();
      request.signal = abortController.signal;
      abortControllers[requestor].push(abortController);
    }
    const response = await fetch(full_url, request);
    return response.json().then(body => {
      const endTime = new Date();
      const timeDifference = endTime - startTime;

      if (![Constants.URL_SESSIONS, Constants.URL_SESSIONS_RO].includes(url)) {
        console.log(clTimestamp() + "\n", "GET[" + (baseUrl ?? "") + url + `] in ${timeDifference} ms\n`, sanitize(params), "\n", sanitize(body));
      } else {
        // console.log(clTimestamp() + ` Session validated in ${timeDifference} ms`);
      }
      // Remove the abort controller for this completed request
      if (abortController) {
        if (!abortControllers[requestor]) {
          abortControllers[requestor] = [];
        }
        // Remove the abort Controller from the list of inflight requests
        abortControllers[requestor] = abortControllers[requestor].filter(ac => ac !== abortController);
      }
      return { status: response.status, body: body };
    });
  } catch (error) {
    // TODO: Investigate enhancing this code to handle network errors better
    return { status: 503 };
  }
}

export function abortGetData(requestor) {
  // If an inflight request exists for this requestor, then abort it
  if (abortControllers[requestor]?.length > 0) {
    abortControllers[requestor].forEach(abortController => {
      abortController.abort();
    });
  }
  abortControllers[requestor] = [];
}

export async function postData(url = "", data = {}, baseUrl = null, headers = null) {
  const startTime = new Date();
  if (!baseUrl) {
    data = addSessionTokenToBody(data);
  }
  const full_headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };
  const request = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: full_headers,
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch((baseUrl ?? ApiBase.URL) + url, request);
    return response.json().then(body => {
      const endTime = new Date();
      const timeDifference = endTime - startTime;

      if (![Constants.URL_SESSIONS, Constants.URL_SESSIONS_RO].includes(url)) {
        console.log(clTimestamp() + "\n", "POST[" + (baseUrl ?? "") + url + `] in ${timeDifference} ms\n`, sanitize(data), "\n", sanitize(body));
      } else {
        console.log(clTimestamp() + ` Session created in ${timeDifference} ms`);
      }
      return { status: response.status, body: body };
    });
  } catch (error) {
    console.log(clTimestamp() + "\n", "POST[" + (baseUrl ?? "") + url + "]\n", sanitize(data), "\n", sanitize(request));
    return { status: 503 };
  }
}

export async function putData(url = "", data = {}, baseUrl = null, headers = null) {
  const startTime = new Date();
  if (!baseUrl) {
    data = addSessionTokenToBody(data);
  }
  const full_headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };
  const request = {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: full_headers,
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch((baseUrl ?? ApiBase.URL) + url, request);
    return response.json().then(body => {
      const endTime = new Date();
      const timeDifference = endTime - startTime;

      if (![Constants.URL_SESSIONS, Constants.URL_SESSIONS_RO].includes(url)) {
        console.log(clTimestamp() + "\n", "PUT[" + (baseUrl ?? "") + url + `] in ${timeDifference} ms\n`, sanitize(data), "\n", sanitize(body));
      }
      return { status: response.status, body: body };
    });
  } catch (error) {
    console.log(clTimestamp() + "\n", "PUT[" + (baseUrl ?? "") + url + "]\n", sanitize(data), "\n", sanitize(request));
    return { status: 503 };
  }
}

export async function deleteData(url = "", params = {}, baseUrl = null, headers = null) {
  // Convert the params JSON object into a URL encoded query string
  const startTime = new Date();
  if (!baseUrl) {
    params = addSessionTokenToParams(params);
  }
  const queryString = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&");
  const full_url = (baseUrl ?? ApiBase.URL) + url + "?" + queryString;
  const full_headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };
  const request = {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: full_headers,
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };
  try {
    const response = await fetch(full_url, request);
    return response.json().then(body => {
      const endTime = new Date();
      const timeDifference = endTime - startTime;

      console.log(clTimestamp() + "\n", "DELETE[" + (baseUrl ?? "") + url + `] in ${timeDifference} ms\n`, sanitize(params), "\n", sanitize(body));
      return { status: response.status, body: sanitize(body) };
    });
  } catch (error) {
    console.log(clTimestamp() + "\n", "DELETE[" + (baseUrl ?? "") + url + "]\n", sanitize(params), "\n", sanitize(request));
    return { status: 503 };
  }
}

function addSessionTokenToParams(data) {
  const token = localStorage.getItem(Constants.LOCAL_STORAGE_SESSION);
  if (token) {
    data.session = token;
  }
  data.debug = 1;
  return data;
}

function addSessionTokenToBody(data) {
  const token = localStorage.getItem(Constants.LOCAL_STORAGE_SESSION);
  if (token) {
    data.session = token;
  }
  data.debug = 1;
  return data;
}

export async function getResource(url = "") {
  try {
    const response = await fetch(ApiBase.S3_BASE_URL + url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.text().then(body => {
      return { status: response.status, body: body };
    });
  } catch (error) {
    return { status: 503 };
  }
}

export async function getResourceExternal(url = "") {
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json().then(body => {
      return { status: response.status, body: body };
    });
  } catch (error) {
    return { status: 503 };
  }
}

export function formatNull(s) {
  if (s === null) {
    return "";
  } else {
    return s;
  }
}

export function formatPercent(p) {
  if (p === null || p === undefined || p === "") {
    return "";
  } else {
    // return numeral(p).format("0.00%");
    return numeral(p ?? 0).format("0%");
  }
}

export function newOrderRecalculate(order) {
  return {
    ...order,
    ...newOrderRecalculateItems(order.orderitems),
  };
}

export function newOrderRecalculateItems(items) {
  let totalprice = numeral(0);
  let totalcost = numeral(0);
  const orderitems = items.map(item => {
    // Parse the input
    let cost = numeral(item.cost ? item.cost : 0);
    let quantity = numeral(item.quantity ? item.quantity : 0);
    let sellprice = numeral(item.sellprice ? item.sellprice : 0);
    let discount = numeral(item.discount ? item.discount : 0);

    // Check for invalid inputs such as "-" and "A"
    cost = cost.value() ? cost : numeral(0);
    quantity = quantity.value() ? quantity : numeral(0);
    sellprice = sellprice.value() ? sellprice : numeral(0);
    discount = discount.value() ? discount : numeral(0);

    // Update this item
    item.totalcost = cost.multiply(quantity.value()).format(Constants.CURRENCY);
    item.totalprice = sellprice.subtract(discount.value()).multiply(quantity.value()).format(Constants.CURRENCY);

    // Add item totals to order totals
    totalprice.add(numeral(item.totalprice ?? 0).value());
    totalcost.add(numeral(item.totalcost ?? 0).value());
    return item;
  });
  return {
    orderitems: orderitems,
    totalcost: totalcost.format(Constants.CURRENCY),
    totalprice: totalprice.format(Constants.CURRENCY),
  };
}

export function calculateOrderTotals(order) {
  order.totalcost = 0;
  order.totalprice = 0;
  order.totalpayments = 0;
  order.totaltax = 0;
  if (order.orderitems && order.orderitems.length > 0) {
    order.totalcost = order.orderitems.reduce(
      (total, item) =>
        numeral(total)
          .add(
            numeral(item.cost ?? 0)
              .multiply(numeral(item.quantity ?? 1).value())
              .value()
          )
          .value(),
      0
    );
    order.totalprice = order.orderitems.reduce(
      (total, item) =>
        numeral(total)
          .add(
            numeral(item.sellprice ?? 0)
              .subtract(numeral(item.discount ?? 0).value())
              .multiply(numeral(item.quantity ?? 1).value())
              .value()
          )
          .value(),
      0
    );
  }
  if (order.payments && order.payments.length > 0) {
    order.totalpayments = order.payments.reduce(
      (total, item) =>
        numeral(total)
          .add(numeral(item.amount ?? 0).value())
          .value(),
      0
    );
  }
  return order;
}

export function formatOrder(order) {
  order = maybeAddDefaultRepairItem(order);

  return {
    ...order,
    totalprice: numeral(order.totalprice).format(Constants.CURRENCY),
    totalcost: numeral(order.totalcost).format(Constants.CURRENCY),
    totaltax: numeral(order.totaltax).format(Constants.CURRENCY),
    totalpayments: numeral(order.totalpayments).format(Constants.CURRENCY),
    balancedue: numeral(order.balancedue).format(Constants.CURRENCY),
    totalwithtax: numeral(order.totalwithtax).format(Constants.CURRENCY),
    orderitems: formatOrderItems(order.orderitems),
    taxes: formatTaxes(order.taxes),
  };
}

export function maybeAddDefaultRepairItem(order) {
  if (order.ordertype === Constants.REPAIR) {
    if (!order.repairitems) {
      order.repairitems = [getBlankRepairItem(order.orderuuid)];
    } else if (order.repairitems.length === 0) {
      order.repairitems.push(getBlankRepairItem(order.orderuuid));
    }
  }
  return order;
}

export function formatOrderItems(items) {
  return items.map(item => {
    item.cost = numeral(item.cost).format(Constants.CURRENCY);
    item.discount = numeral(item.discount).format(Constants.CURRENCY);
    item.quantity = numeral(item.quantity).format(Constants.DECIMAL_VALUE);
    item.sellprice = numeral(item.sellprice).format(Constants.CURRENCY);
    item.totalcost = numeral(item.totalcost).format(Constants.CURRENCY);
    item.totalprice = numeral(item.totalprice).format(Constants.CURRENCY);
    if (item.quantityreceived) {
      item.quantityreceived = numeral(item.quantityreceived).format(Constants.DECIMAL_VALUE);
    }
    return item;
  });
}

export function formatTaxes(taxes) {
  if (taxes) {
    return taxes.map(item => {
      item.amount = numeral(item.amount).format(Constants.CURRENCY);
      return item;
    });
  } else {
    return [];
  }
}

// Format an string value
export function formatString(value, withnull = false) {
  if ((value === "" || value === null) && withnull) {
    return null;
  }
  if (value === "" || value === null) {
    return "";
  }
  return value.trim();
}

export function suppressTrailingZeros(fieldname) {
  return inList(["inventory", "quantity", "quantityreceived", "length", "height", "width", "weight"], fieldname);
}

export function inList(list, value) {
  return list.indexOf(value) !== -1;
}

export function inString(str, value) {
  return str.indexOf(value) !== -1;
}

export function getTargetValue(event) {
  let value = event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX ? event.target.checked : event.target.value;
  return value;
}

export function renderTotalPricePlusTax(totalpriceplustax) {
  // Format the total price plus tax as a span with a data-testid attribute
  let totalPricePlusTax = numeral(totalpriceplustax).format(Constants.CURRENCY);
  return <span data-testid="Total Price Plus Tax">{totalPricePlusTax}</span>;
}

export function renderPhone(mobilephone, otherphone) {
  // Phone number is the mobile phone number if present, otherwise the other phone number if present, otherwise "-"
  let phone = mobilephone ? mobilephone : otherphone ? otherphone : "-";
  return <span data-testid="Customer Phone">{phone}</span>;
}

export function renderProductName(prodname, itemcount) {
  // Product name is the product name, and if there are more than 1 item, then append the number of additional items
  let productname = prodname;
  if (itemcount === 2) {
    productname += " (and 1 more item)";
  } else if (itemcount > 2) {
    productname += " (and " + (itemcount - 1) + " more items)";
  }
  return <span data-testid="Product Name">{productname}</span>;
}

export function renderCustomerName(contactname, companyname) {
  // Customer name is the contact name and company name if both are present
  let customername = contactname;
  if (companyname && contactname) {
    customername = contactname + " (" + companyname + ")";
  }
  return <span data-testid="Customer Name">{customername}</span>;
}

export function getOrderNumber(ordertype, ordersubtype, ordernumber, externalid, filtertype) {
  // If the order type is return, then add an "R" to the beginning to look like "RI"
  // If the order type is repair, then replace the "R" with "S" for Service
  if (ordersubtype === Constants.RETURN) {
    ordertype = "R" + ordertype.substr(0, 1);
  } else if (ordersubtype === Constants.SUBSCRIPTION) {
    ordertype = "S" + ordertype.substr(0, 1);
  } else if (ordertype === Constants.REPAIR) {
    ordertype = "S";
  } else {
    ordertype = ordertype.substr(0, 1);
  }
  // Order number label is the first character of the order type + the order number
  let orderNumber = ordertype + "-" + ordernumber;
  // If the order has an external ID (online order), then append it to the order number
  if (externalid && filtertype.tab !== Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
    orderNumber += "/" + externalid;
  }

  return orderNumber;
}

export function renderOrderNumber(ordertype, ordersubtype, ordernumber, externalid, filtertype) {
  return <span data-testid="Order Number">{getOrderNumber(ordertype, ordersubtype, ordernumber, externalid, filtertype)}</span>;
}

export function renderStatus(status, ordersubtype = null, additionalText = "") {
  let description = "";
  let className = "statusbadge " + status;

  if (status === Constants.ORDER_STATUS_ON_HOLD) {
    description = "On Hold";
  } else if (status === Constants.ORDER_STATUS_BACKORDERED) {
    description = "Backordered";
  } else if (status === Constants.ORDER_STATUS_OPEN) {
    description = "Open";
  } else if (status === Constants.ORDER_STATUS_PROCESSED) {
    description = "Processed";
  } else if (status === Constants.ORDER_STATUS_ORDERED) {
    description = "Ordered";
  } else if (status === Constants.ORDER_STATUS_FULLY_RECEIVED) {
    description = "Received";
  } else if (status === Constants.ORDER_STATUS_PARTIALLY_RECEIVED) {
    description = "Partially Received";
  } else if (status === Constants.ORDER_STATUS_CANCELLED) {
    description = "Cancelled";
  } else if (status === Constants.ORDER_STATUS_CANCELLED_RETURN) {
    description = "Cancelled/Return";
  } else if (status === Constants.ORDER_STATUS_CONTACTED) {
    description = "Contacted";
  } else if (status === Constants.ORDER_STATUS_INVOICED) {
    description = "Invoiced";
  } else if (status === Constants.ORDER_STATUS_DIAGNOSED) {
    description = "Diagnosed";
  } else if (status === Constants.ORDER_STATUS_PAID_IN_FULL) {
    if (ordersubtype === Constants.RETURN) {
      description = "Paid Refund";
      className = "statusbadge paidrefund";
    } else {
      description = "Paid";
    }
  } else if (status === Constants.ORDER_STATUS_PARTIAL_PAYMENT) {
    description = "Partially Paid";
  } else if (status === Constants.ORDER_STATUS_REFUNDED) {
    description = "Refunded";
  } else if (status === Constants.ORDER_STATUS_PARTIALLY_REFUNDED) {
    description = "Partially Refunded";
  } else if (status === Constants.ORDER_STATUS_PARTS_ORDERED) {
    description = "Parts Ordered";
  } else if (status === Constants.ORDER_STATUS_SENT) {
    description = "Sent";
  } else if (status === Constants.ORDER_STATUS_ON_BENCH) {
    description = "On Bench";
  } else if (status === Constants.ORDER_STATUS_PURCHASE_CREATED) {
    description = "PO Generated";
  } else if (status === Constants.ORDER_STATUS_PARTIALLY_ORDERED) {
    description = "Partially Ordered";
  } else if (status === Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
    description = "Not Shipped";
  } else if (status === Constants.ORDER_STATUS_ONLINE_SHIPPED) {
    description = "Shipped";
  } else if (status === Constants.ORDER_STATUS_ONLINE_PICKED_UP) {
    description = "Picked Up";
  } else if (status === Constants.ORDER_STATUS_ONLINE_PARTIALLY_SHIPPED) {
    description = "Partially Shipped";
  } else if ([Constants.SUBSCRIPTION_NEW, Constants.CH_SUBSCRIPTION_NEW].includes(status)) {
    description = "New";
  } else if ([Constants.SUBSCRIPTION_ACTIVE, Constants.CH_SUBSCRIPTION_ACTIVE].includes(status)) {
    description = "Active";
  } else if ([Constants.SUBSCRIPTION_CANCELLED, Constants.CH_SUBSCRIPTION_CANCELLED].includes(status)) {
    description = "Cancelled";
  } else if ([Constants.SUBSCRIPTION_COMPLETE, Constants.CH_SUBSCRIPTION_COMPLETE].includes(status)) {
    description = "Complete";
  } else if ([Constants.SUBSCRIPTION_PAUSED, Constants.CH_SUBSCRIPTION_PAUSED].includes(status)) {
    description = "Paused";
  } else if ([Constants.SUBSCRIPTION_SUSPENDED, Constants.CH_SUBSCRIPTION_SUSPENDED].includes(status)) {
    description = "Suspended";
  } else if ([Constants.SUBSCRIPTION_VAULTED, Constants.CH_SUBSCRIPTION_VAULTED].includes(status)) {
    description = "Vaulted";
  } else if ([Constants.SUBSCRIPTION_LOADING, Constants.CH_SUBSCRIPTION_LOADING].includes(status)) {
    description = "Loading...";
  } else if (status === Constants.PROSPECT_STATUS_CANCELLED) {
    description = "Cancelled";
  } else if (status === Constants.PROSPECT_STATUS_NEW) {
    description = "New";
  } else if (status === Constants.PROSPECT_STATUS_PENDING) {
    description = "Pending";
  } else if (status === Constants.PROSPECT_STATUS_PREMATCHED) {
    description = "Not Completed";
  } else if (status === Constants.PROSPECT_STATUS_PROCESSED_CREATED) {
    description = "Customer Created";
  } else if (status === Constants.PROSPECT_STATUS_PROCESSED_MATCHED) {
    description = "Matched";
  } else if (status === Constants.CAMPAIGN_STATUS_ARCHIVED) {
    description = "Archived";
  } else if (status === Constants.CAMPAIGN_STATUS_DRAFT) {
    description = "Draft";
  } else if (status === Constants.CAMPAIGN_STATUS_PUBLISHED) {
    description = "Published";
  } else if (status === Constants.AUTH_APPROVED) {
    description = "Approved";
  } else if (status === Constants.AUTH_CANCELLED) {
    description = "Cancelled";
  } else if (status === Constants.AUTH_DECLINED) {
    description = "Declined";
  } else if (status === Constants.AUTH_FAILED) {
    description = "Failed";
  } else if (status === Constants.AUTH_NEW) {
    description = "New";
  } else if (status === Constants.AUTH_PARTIAL_APPROVAL) {
    description = "Partial Approval";
  } else if (status === Constants.AUTH_PENDING) {
    description = "Pending";
  } else {
    description = status;
  }
  if (additionalText) {
    description += " " + additionalText;
  }

  return (
    <div className={className}>
      <span data-testid="Order Status">{description}</span>
    </div>
  );
}

export function renderTransactionStatus(status) {
  if (status === Constants.TRANSACTION_APPROVED) {
    return "Approved";
  } else if (status === Constants.TRANSACTION_CANCELLED) {
    return "Cancelled";
  } else if (status === Constants.TRANSACTION_CAPTURED) {
    return "Captured";
  } else if (status === Constants.TRANSACTION_DECLINED_BY_ISSUER) {
    return "Declined by Issuer";
  } else if (status === Constants.TRANSACTION_DEPOSIT_SENT) {
    return "Deposit Sent";
  } else if (status === Constants.TRANSACTION_FAILURE_OTHER) {
    return "Failed";
  } else if (status === Constants.TRANSACTION_FUNDED_BY_ISSUER) {
    return "Funded by Issuer";
  } else if (status === Constants.TRANSACTION_HELD) {
    return "Held";
  } else if (status === Constants.TRANSACTION_REJECTED) {
    return "Rejected";
  } else if (status === Constants.TRANSACTION_SETTLED) {
    return "Settled";
  } else if (status === Constants.TRANSACTION_VOIDED_BY_MERCHANT) {
    return "Voided by Merchant";
  } else if (status === Constants.TRANSACTION_VOIDED_BY_SYSTEM) {
    return "Voided by System";
  }
  return "Unknown";
}

export function renderSubscriptionStatus(status) {
  if (status === Constants.SUBSCRIPTION_ACTIVE) {
    status = "Active";
  } else if (status === Constants.SUBSCRIPTION_CANCELLED) {
    status = "Cancelled";
  } else if (status === Constants.SUBSCRIPTION_COMPLETE) {
    status = "Complete";
  } else if (status === Constants.SUBSCRIPTION_PAUSED) {
    status = "Paused";
  } else if (status === Constants.SUBSCRIPTION_SUSPENDED) {
    status = "Suspended";
  } else if (status === Constants.SUBSCRIPTION_LOADING) {
    status = "Loading...";
  }
  return status;
}

export function renderMaastPlanStatus(status) {
  let description = "";
  if (status === "A") {
    description = "Archived";
  } else if (status === "E") {
    description = "Active";
  } else if (status === "D") {
    description = "Deleted";
  }
  return description;
}

export function renderMaastFrequency(frequency) {
  let description = "";
  if (frequency === 0) {
    description = "Weekly";
  } else if (frequency === 1) {
    description = "Bi-weekly";
  } else if (frequency === 3) {
    description = "Monthly";
  } else if (frequency === 4) {
    description = "Quarterly";
  } else if (frequency === 5) {
    description = "Bi-annually";
  } else if (frequency === 6) {
    description = "Annually";
  } else if (frequency === 7) {
    description = "Daily";
  }
  return description;
}

export function getMaastFrequency(description) {
  let frequency = null;
  const d = description.toLowerCase();
  if (d === "weekly") {
    frequency = 0;
  } else if (d === "bi-weekly" || d === "biweekly") {
    frequency = 1;
  } else if (d === "monthly") {
    frequency = 3;
  } else if (d === "quarterly") {
    frequency = 4;
  } else if (d === "bi-annually" || d === "biannually") {
    frequency = 5;
  } else if (d === "annually") {
    frequency = 6;
  } else if (d === "daily") {
    frequency = 7;
  }
  return frequency;
}

export function renderPaymentType(paymenttype, subtype, refund) {
  let description = "";
  const refundLabel = refund ? " Refund" : "";

  if (paymenttype === Constants.CREDIT) {
    description = "Credit";
    if (subtype) {
      description += " - " + subtype;
    }
  } else if (paymenttype === Constants.CREDIT_REFUND) {
    description = "Credit Refund";
    if (subtype) {
      description += " - " + subtype;
    }
  } else if (paymenttype === Constants.STORECREDIT && !refund) {
    description = "Store Credit";
  } else if (paymenttype === Constants.STORECREDIT && refund) {
    description = "Store Credit Issued";
  } else if (paymenttype === Constants.CHANGE) {
    description = "Change";
  }
  // The following payment types may have "Refund" appended to them
  else if (paymenttype === Constants.CASH) {
    description = "Cash" + refundLabel;
  } else if (paymenttype === Constants.CHECK) {
    description = "Check" + refundLabel;
  } else if (paymenttype === Constants.GIFTCARD) {
    description = "Gift Card" + refundLabel;
  } else if (paymenttype === Constants.OTHER) {
    description = "Other" + refundLabel;
  } else if (paymenttype === Constants.PAYPAL) {
    description = "PayPal" + refundLabel;
  } else if (paymenttype === Constants.DEPOSIT_APPLIED) {
    description = "Deposit" + refundLabel;
  } else {
    description = paymenttype + refundLabel;
  }

  return description;
}

export function renderShortAddress(contact) {
  let displayaddress = contact.address1;
  if (!displayaddress) {
    displayaddress = contact.address2;
  }
  if (!displayaddress) {
    displayaddress = contact.city + ", " + contact.state;
  }
  if (displayaddress === ", ") {
    displayaddress = "";
  }
  displayaddress = displayaddress ? displayaddress : "-";
  return <span className="minaddress"> {displayaddress} </span>;
}

export function renderFullAddress(customerinfo) {
  const copyIcon = (
    <span
      onClick={event => {
        let value = `${customerinfo.address1}, ${customerinfo.address2}, ${customerinfo.city}, ${customerinfo.state} ${customerinfo.postalcode}`;
        value = value.replace(/, , /g, ", ");
        event.stopPropagation();
        Helper.copyToClipboard(value);
      }}
    >
      &nbsp;
      <FontAwesomeIcon icon={faCopy} />
    </span>
  );
  if (customerinfo.address1 && customerinfo.address2 && (customerinfo.city || customerinfo.state || customerinfo.postalcode)) {
    return (
      <div>
        {formatNull(customerinfo.address1)} {copyIcon}
        <br />
        {formatNull(customerinfo.address2)}
        <br />
        {formatNull(customerinfo.city)}&nbsp;
        {formatNull(customerinfo.state)}&nbsp;
        {formatNull(customerinfo.postalcode)}
      </div>
    );
  } else if (customerinfo.address1 && (customerinfo.city || customerinfo.state || customerinfo.postalcode)) {
    return (
      <div>
        {formatNull(customerinfo.address1)} {copyIcon}
        <br />
        {formatNull(customerinfo.city)}&nbsp;
        {formatNull(customerinfo.state)}&nbsp;
        {formatNull(customerinfo.postalcode)}
      </div>
    );
  } else if (customerinfo.address2 && (customerinfo.city || customerinfo.state || customerinfo.postalcode)) {
    return (
      <div>
        {formatNull(customerinfo.address2)} {copyIcon}
        <br />
        {formatNull(customerinfo.city)} &nbsp;
        {formatNull(customerinfo.state)}&nbsp;
        {formatNull(customerinfo.postalcode)}
      </div>
    );
  } else if (customerinfo.address1) {
    return (
      <div>
        {formatNull(customerinfo.address1)} {copyIcon}
      </div>
    );
  } else if (customerinfo.address2) {
    return (
      <div>
        {formatNull(customerinfo.address2)} {copyIcon}
      </div>
    );
  } else if (customerinfo.city || customerinfo.state || customerinfo.postalcode) {
    return (
      <div>
        {formatNull(customerinfo.city)}&nbsp;
        {formatNull(customerinfo.state)}&nbsp;
        {formatNull(customerinfo.postalcode)} {copyIcon}
      </div>
    );
  } else if (!customerinfo.address1 && !customerinfo.address2 && !customerinfo.city && !customerinfo.state && !customerinfo.postalcode) {
    return "";
  } else {
    return (
      <div>
        {formatNull(customerinfo.address1)} &nbsp;
        {formatNull(customerinfo.address2)} &nbsp;
        {formatNull(customerinfo.city)} &nbsp;
        {formatNull(customerinfo.state)} &nbsp;
        {formatNull(customerinfo.postalcode)} {copyIcon}
      </div>
    );
  }
}

export function today() {
  return new Date().toLocaleString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
}

// Returns the date for tomorrow as a string in the format of "yyyy-mm-dd"
export function tomorrow() {
  let tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow = addDays(tomorrow, 1);
  return dateAsString(tomorrow);
}

// Returns the date for tomorrow as a string in the format of "yyyy-mm-dd"
// ensuring the day of month does not exceed 28
export function tomorrowForBilling(plan_frequency) {
  let tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow = addDays(tomorrow, 1);
  if (plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY) {
    while (tomorrow.getUTCDate() > 28) {
      tomorrow = addDays(tomorrow, 1);
    }
  }
  return tomorrow.getFullYear() + "-" + String(1 + tomorrow.getMonth()).padStart(2, "0") + "-" + String(tomorrow.getUTCDate()).padStart(2, "0");
}

export function clTimestamp() {
  return "[" + dateTimeAsString() + "]";
}

export function dateAsString(datevalue) {
  if (!datevalue) {
    datevalue = new Date();
  }
  return datevalue.getFullYear() + "-" + String(1 + datevalue.getMonth()).padStart(2, "0") + "-" + String(datevalue.getDate()).padStart(2, "0");
}

export function convertUtcToLocal(utcDateTimeString, localTimeZone) {
  const utcDate = new Date(utcDateTimeString);
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000).toLocaleString("en-US", { timeZone: localTimeZone });
  return localDate;
}

export function convertLocalToUtc(localDateTimeString, localTimeZone) {
  const localDate = new Date(localDateTimeString);
  const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000).toLocaleString("en-US", { timeZone: localTimeZone });
  return utcDate;
}

export function getHumanDateString() {
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  return new Date().toLocaleDateString(undefined, options);
}

export function dateTimeAsString(datevalue) {
  if (!datevalue) {
    datevalue = new Date();
  }
  return (
    datevalue.getFullYear() +
    "-" +
    String(1 + datevalue.getMonth()).padStart(2, "0") +
    "-" +
    String(datevalue.getDate()).padStart(2, "0") +
    " " +
    String(datevalue.getHours()).padStart(2, "0") +
    ":" +
    String(datevalue.getMinutes()).padStart(2, "0") +
    ":" +
    String(datevalue.getSeconds()).padStart(2, "0")
  );
}

export function formatDateUTC(datevalue) {
  // Return the UTC date value in the format mm/dd/yy
  return datevalue.toLocaleString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });
}

export function formatDate(datevalue, shortformat = false, includeTime = false) {
  let result = "";
  if (datevalue) {
    // If the datevalue param is a Date object, directly use it
    let dateObject = null;
    if (datevalue instanceof Date) {
      dateObject = datevalue;
    } else {
      // If the date is in the format of "2020-01-01 00:00:00", then we need to remove the space and replace it with a "T"
      // so that the date is in the format of "2020-01-01T00:00:00" which is the format that the Date object expects.
      if (datevalue.indexOf(" ") !== -1) {
        datevalue = datevalue.replace(" ", "T");
      }
      // If the date is in the format of "2020-01-01", then we need to add the time of "00:00:00"
      if (datevalue.length === 10) {
        datevalue += "T00:00:00";
      }
      // If we're not including the time, then we need to remove the time from the date.
      // This will ensure the date does not cross timezones to a different date when parsed.
      if (!includeTime) {
        datevalue = datevalue.split("T")[0] + "T00:00:00";
      }
      dateObject = new Date(datevalue);
    }
    // Compose the correct month, date, and year string
    if (!shortformat && !includeTime) {
      result = dateObject.toLocaleString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
    } else if (!shortformat && includeTime) {
      result = dateObject.toLocaleString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (shortformat) {
      result = dateObject.toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
      });
    }
  } else {
    result = "";
  }
  return result;
}

export function formatDateForWebService(datevalue) {
  datevalue = new Date(datevalue);
  let month = datevalue.getMonth() + 1;
  month = String(month).padStart(2, "0");
  let day = datevalue.getDate();
  day = String(day).padStart(2, "0");
  let year = datevalue.getFullYear();
  return year + "-" + month + "-" + day;
}

export function formatDateTimeForWebService(datevalue) {
  datevalue = new Date(datevalue);
  let month = datevalue.getMonth() + 1;
  month = String(month).padStart(2, "0");
  let day = datevalue.getDate();
  day = String(day).padStart(2, "0");
  let year = datevalue.getFullYear();
  let hour = String(datevalue.getHours()).padStart(2, "0");
  let minute = String(datevalue.getMinutes()).padStart(2, "0");
  let second = String(datevalue.getSeconds()).padStart(2, "0");

  return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
}

export function formatTime(datevalue) {
  datevalue = new Date(datevalue);
  let hour = String(datevalue.getHours()).padStart(2, "0");
  let minute = String(datevalue.getMinutes()).padStart(2, "0");
  let ampm = "AM";
  if (hour > 12) {
    hour = hour - 12;
    ampm = "PM";
  }

  return hour + ":" + minute + " " + ampm;
}

// Expects a date string in the format of "hh:mm" (military time) into a formatted time string with AM/PM.
export function formatTimeString(datevalue) {
  if (!datevalue || datevalue.length !== 5) {
    return datevalue;
  }
  let hour = datevalue.slice(0, 2);
  let minute = datevalue.slice(3, 5);
  let ampm = "AM";
  if (hour.startsWith("0")) {
    hour = hour.slice(1);
  } else if (hour === "00") {
    hour = "12";
  } else if (hour > 12) {
    hour = hour - 12;
    ampm = "PM";
  }
  return hour + ":" + minute + " " + ampm;
}

export function formatDateTime(datevalue, shortformat = false, timeonly = false) {
  let result = "";
  if (!datevalue) {
    return "";
  }
  if (datevalue.indexOf(" ") !== -1) {
    datevalue = datevalue.replace(" ", "T");
  }
  if (datevalue && timeonly) {
    result = new Date(datevalue).toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (datevalue && !shortformat) {
    result = new Date(datevalue).toLocaleString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (datevalue && shortformat) {
    if (inString(datevalue, " ")) {
      datevalue = datevalue.split(" ")[0];
    }
    result = new Date(datevalue).toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
  } else {
    result = "";
  }
  return result;
}

export function formatSubscriptionForWebService(subscription) {
  subscription.amt_tran = numeral(subscription.amt_tran).value() ?? 0.0;
  return subscription;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function moveContactToFront(contactuuid, company) {
  if (contactuuid !== "new" && company && company.contacts) {
    const contact = company.contacts.filter(contact => contact.contactuuid === contactuuid)[0];

    if (contact) {
      // if the selected contact is not the first one already, put the contact first in the list.
      if (contactuuid !== company.contacts[0].contactuuid) {
        company.contacts.splice(company.contacts.indexOf(contact), 1);
        company.contacts.splice(0, 0, contact);
      }
    }
  }
  return company;
}

export function moveSubscriptionToFront(subscriptions, subscriptionuuid) {
  // Find the index of the sub object in the list by matching the "uuid"
  const index = subscriptions.findIndex(item => item.subscriptionuuid === subscriptionuuid);

  // If the sub object is found and not already the first item
  if (index > 0) {
    // Remove the sub object from its current position
    const [foundSub] = subscriptions.splice(index, 1);
    // Add the sub object to the beginning of the list
    subscriptions.unshift(foundSub);
  }

  return subscriptions;
}

// Handle Live Editing: Grabbing Initial Value.
// Date input's should pass in overwrite = false to prevent overwriting the initial value when the date picker widget is used.
export function handleFocus(event, overwrite = true) {
  const target = event.target;
  if (overwrite || !target.getAttribute(Constants.ATTR_DATA_VALUE)) {
    target.setAttribute(Constants.ATTR_DATA_VALUE, target.value);
  }
}

export function handleFocusSelect(event) {
  handleFocus(event);
  event.target.select();
}

export function getContactByUUID(company, contactuuid) {
  const filteredlist = company.contacts.filter(contact => contact.contactuuid === contactuuid);
  if (filteredlist.length > 0) {
    return filteredlist[0];
  } else {
    return null;
  }
}

export function stripNonNumerics(str) {
  return str.replace(/\D/g, "");
}

export function renderPhoneLink(phone) {
  const dial = "tel:" + stripNonNumerics(phone);
  return (
    <a className="dialer" href={dial}>
      {phone}
    </a>
  );
}

export function formatPhoneNumber(phoneNumber, suppressLeadingOne = false) {
  // Bail if there is no phone number
  if (!phoneNumber) {
    return "";
  }
  // Remove all non-digit characters
  let cleaned = phoneNumber.replace(/\D/g, "");

  // 11 digits ==> 1(912) 555-1212
  if (cleaned.length === 11) {
    cleaned = cleaned.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1($2) $3-$4");
  } else if (cleaned.length === 10) {
    // 10 digits ==> (912) 555-1212
    cleaned = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } else if (cleaned.length === 7) {
    // 7 digits ==> 555-1212
    cleaned = cleaned.replace(/(\d{3})(\d{4})/, "$1-$2");
  }
  if (suppressLeadingOne) {
    cleaned = cleaned.replace(/^1/, "");
  }
  return cleaned;
}

export function formatPhoneNumberForTwilio(phoneNumber) {
  // Bail if there is no phone number
  if (!phoneNumber) {
    return "";
  }
  // Remove all non-digit characters
  let cleaned = phoneNumber.replace(/\D/g, "");

  // 11 digits ==> 1(912) 555-1212
  if (cleaned.length === 11) {
    cleaned = "+" + cleaned;
  } else if (cleaned.length === 10) {
    cleaned = "+1" + cleaned;
  }
  return cleaned;
}

function verifyEanCheckDigit13(value) {
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    let digit = parseInt(value[i]);
    sum += i % 2 === 0 ? digit * 1 : digit * 3;
  }
  let checkDigit = (10 - (sum % 10)) % 10;
  return checkDigit === parseInt(value[12]);
}

function verifyEanCheckDigit8(value) {
  let sum = 0;
  for (let i = 0; i < 7; i++) {
    let digit = parseInt(value[i]);
    sum += i % 2 === 0 ? digit * 3 : digit * 1;
  }
  let checkDigit = (10 - (sum % 10)) % 10;
  return checkDigit === parseInt(value[7]);
}

// Validates a date string in the format of "YYYY-MM-DD"
export function isValidDate(d) {
  // Check if the format is "YYYY-MM-DD"
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  if (!datePattern.test(d)) return false;

  // Parse components and validate
  const [year, month, day] = d.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  // Ensure the date components match (e.g., rejecting invalid dates like 2022-02-30)
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}

export function isValidEan(value) {
  if (!value) {
    return false;
  }
  // Regex to match value as all numbers
  let pattern = /^\d+$/g;
  let result = value.match(pattern);
  if (!result) {
    return false;
  }
  // Must be 13 digits or 8 digits
  if (value.length !== 13 && value.length !== 8) {
    return false;
  }

  // Check the check digit
  if (value.length === 13 && !verifyEanCheckDigit13(value)) {
    return false;
  }
  if (value.length === 8 && !verifyEanCheckDigit8(value)) {
    return false;
  }
  return true;
}

export function verifyUpcCheckDigit(upc) {
  let odd = 0;
  let even = 0;
  for (let i = 0; i < 11; i++) {
    let digit = parseInt(upc[i]);
    if (i % 2 === 0) {
      odd += digit;
    } else {
      even += digit;
    }
  }
  const sum = odd * 3 + even;
  let check = String(10 - (sum % 10));
  if (sum % 10 === 0) {
    check = "0";
  }
  return upc[11] === check;
}

export function isValidUpc(value) {
  if (!value) {
    return false;
  }
  // Regex to match value as all numbers
  let pattern = /^\d+$/g;
  let result = value.match(pattern);
  if (!result) {
    return false;
  }
  // Must be 12 digits
  if (value.length !== 12) {
    return false;
  }
  if (!verifyUpcCheckDigit(value)) {
    return false;
  }
  return true;
}

export function isValidValue(id, value, prev, required, ordertype = null, datatype = null) {
  // If this is a required field and the value was removed and there was previously a value,
  //     chastise the user!
  if (required && !value && prev) {
    return false;
  } else {
    // Validate store SKU for system SKU's on non-protected products
    if (id === "storesku" && Constants.SYSTEM_STORE_SKUS.includes(value)) {
      return false;
    }

    // Validate numeric order items fields
    if (ordertype && inList(["quantity", "sellprice", "discount", "cost", "quantityreceived"], id)) {
      // Check for negative values
      if (value < 0) {
        // Negative numbers not allowed on order, repair, purchase, quote
        if (inList([Constants.ORDER, Constants.REPAIR, Constants.PURCHASE], ordertype)) {
          return false;
        }
      }
    }

    // If a discount is provided, check that discount is an actual numeric value (not NaN, null, or undefined)
    if (
      ["discount", "maxdiscount"].includes(id) &&
      value &&
      (isNaN(numeral(value).value()) || numeral(value).value() === null || numeral(value).value() === undefined)
    ) {
      return false;
    }

    // If a discount is provided, then percent discount cannot exceed 100%
    // Actual sell price comparison to discount amount cannot be evaulated here (we don't know the sell price)
    if (["discount", "maxdiscount"].includes(id) && value && value.endsWith("%") && numeral(value).value() > 1) {
      return false;
    } else if (["discount", "maxdiscount"].includes(id) && datatype === "percent" && value && numeral(value).divide(100).value() > 1) {
      return false;
    }

    // Validate email address
    if (id === "email") {
      if (!value) {
        return true;
      }
      let pattern = /^.+@.+\..+$/g;
      let result = value.match(pattern);
      if (result) {
        return true;
      } else return false;
    }
    //Validate website
    if (id === "website") {
      if (!value) {
        return true;
      }
      let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/g;
      let result = value.match(pattern);
      if (result) {
        return true;
      } else return false;
    }
    //Validate phone numbers
    if (id === "mobilephone" || id === "otherphone") {
      if (!value) {
        return true;
      }
      // Check that there are, at most, 15 numbers in the field
      return value.replace(/\D/g, "").length <= 15;
    }
    // TODO: Validate address via USPS(?)
    if (id === "address1") {
      if (!value) {
        return true;
      }
    }
    if (id === "address2") {
      if (!value) {
        return true;
      }
    }
    if (id === "city") {
      if (!value) {
        return true;
      }
    }
    if (id === "state") {
      if (!value) {
        return true;
      }
    }
    //Validate zip code
    if (id === "postalcode") {
      if (!value) {
        return true;
      }
      //   let pattern = /^(\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)|(^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d)$/g;
      //   let result = value.match(pattern);
      //   if (result) {
      //     return true;
      //   } else return false;
    }

    // Subscription fields
    if (validateSubscriptionChange(id, value, required) !== null) {
      return false;
    }

    return true;
  }
}

export function validateSubscriptionChange(id, value, required) {
  if (id === "plan_name") {
    if (required && !value) {
      return "Plan name is required.";
    }
  }
  if (id === "recur_amt") {
    if (required && !value) {
      return "Recurring amount is required.";
    }
    if (isNaN(numeral(value).value())) {
      return "Recurring amount must be a number.";
    }
    if (numeral(value).value() <= 0) {
      return "Recurring amount must be greater than zero.";
    }
  }
  if (id === "plan_duration") {
    if (required && !value) {
      return "Plan duration is required.";
    }
    if (isNaN(numeral(value).value())) {
      return "Plan duration must be a number.";
    }
    if (numeral(value).value() === 0) {
      return "Plan duration cannot be zero.";
    }
    if (numeral(value).value() < -1) {
      return "Plan duration cannot be negative.";
    }
  }
  if (id === "plan_frequency") {
    if (required && !value) {
      return "Plan frequency is required.";
    }
    if (!Constants.CH_RECURRING_PLAN_FREQUENCIES.includes(value)) {
      return "Invalid plan frequency.";
    }
  }
  if (id === "date_next") {
    if (required && !value) {
      return "Next billing date is required.";
    }
    if (!isValidDate(value)) {
      return "Invalid date.";
    }
    // Must be a future date
    if (new Date(value).toISOString() <= new Date().toISOString()) {
      return "Date must be in the future.";
    }
  }
  return null;
}

export function isValidPercent(value) {
  if (!value) {
    return false;
  }
  let pattern = /^(\d\d|\d)\.?\d?\d?%?$/g;
  let result = value.match(pattern);
  if (!result) {
    return false;
  }
  // Check for greater than 100%
  let v = numeral(value.replace("%", "")).value();
  if (v >= 100 || v < 0) {
    return false;
  }

  return true;
}

export function isValidEmail(email) {
  if (!email) {
    return null;
  } else {
    const re = new RegExp("^.+@.+[.].+$");
    if (email.match(re)) {
      return true;
    }
    return false;
  }
}

export function orderContainsUnreceivedItems(order) {
  return (
    order?.orderitems.filter(item => {
      return isQuantityReceivedLessThanQuantity(item);
    }).length !== 0
  );
}

export function isQuantityReceivedLessThanQuantity(item) {
  let quantityreceived = numeral(item.quantityreceived).value();
  let quantity = numeral(item.quantity).value();
  if (!quantityreceived || isNaN(quantityreceived)) {
    quantityreceived = 0;
  }
  if (!quantity || isNaN(quantity)) {
    quantity = 0;
  }
  return quantityreceived < quantity;
}

export function replaceItemInList(listitems, replacewith) {
  return listitems.map(item => {
    if (item.uuid === replacewith.uuid) {
      return replacewith;
    } else {
      return item;
    }
  });
}

export function getBlankBillingPlan() {
  return {
    billingplanuuid: "",
    plan_name: "",
    plan_frequency: Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY,
    plan_duration: -1,
    recur_amt: "1.00",
    tran_currency: "840",
  };
}

// REVISIT: This function will get removed when the Maast subscriptions are retired
export function getBlankMaastBillingPlan() {
  return {
    plan_code: "new",
    plan_name: "",
    plan_desc: "",
    plan_frequency: 3,
    interval: 1,
    plan_duration: -1,
    plan_duration_unlimited: true,
    amt_tran: "1.00",
    tran_currency: "840",
    plan_type: "I",
  };
}

export function getBlankContact(salesperson, companytype) {
  return {
    active: true,
    address1: "",
    address2: "",
    city: "",
    companyname: "",
    companytype: companytype,
    companyuuid: "",
    contactuuid: "new",
    creationdatetime: today(),
    discount: "0%", // Used for new companies
    editmode: true,
    email: "",
    firstname: "",
    hasshippingaddress: false,
    lastname: "",
    lastupdated: "",
    mobilephone: "",
    otherphone: "",
    postalcode: "",
    salesperson: salesperson,
    shippingaddress: getBlankShippingAddress(),
    state: "",
    taxable: true, // Used for new companies
    title: "",
    uuid: "new",
    website: "",
  };
}

export function getBlankShippingAddress() {
  return {
    addressuuid: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalcode: "",
  };
}

export function getBlankCompany(companytype, contacts, salesperson, taxable = false) {
  return {
    companyuuid: null,
    companyname: "",
    companytype: companytype,
    active: true,
    salesperson: salesperson,
    taxable: taxable,
    discount: "0%",
    contacts: contacts,
    creationdatetime: today(),
    tags: [],
    vaulted: false,
  };
}

export function getBlankProspectCompany(prospect) {
  return {
    companyuuid: null,
    companyname: "",
    companytype: Constants.PROSPECT,
    active: true,
    salesperson: prospect.salesperson,
    taxable: false,
    discount: "0%",
    contacts: [prospect],
    creationdatetime: prospect.creationdatetime,
    tags: [],
  };
}

export function getBlankSupplier(productuuid) {
  return {
    supplieruuid: "new",
    productuuid: productuuid,
    companyname: "",
    companyuuid: "",
    sku: "",
    cost: "",
  };
}

export function getBlankProduct(salesperson) {
  return {
    active: true,
    affectinventory: true,
    altupc: "",
    commission: "",
    companyname: "",
    companyuuid: "",
    cost: "",
    creationdatetime: today(),
    details: "",
    ean: "",
    family: "",
    inventory: "",
    location: "",
    longdescription: "",
    make: "",
    maxdiscount: "",
    model: "",
    photos: [],
    productname: "",
    productnumber: "",
    productuuid: null,
    salesperson: salesperson,
    sellprice: "",
    sku: "",
    storesku: "",
    suppliers: [],
    taxable: true,
    upc: "",
  };
}

export function getBlankSubscriptionPaymentProduct(salesperson) {
  let product = getBlankProduct(salesperson);
  product.productname = "Subscription Payment";
  product.longdescription = "System-generated product for collecting billing subscription payments.";
  product.sellprice = 0.0;
  product.altupc = Constants.ALTUPC_SUBSCRIPTION_PAYMENT;
  product.taxable = false;
  product.affectinventory = false;
  return product;
}

export function getBlankOrder(ordertype, salesperson, ordersubtype = null, company = null, products = null) {
  let order = {
    orderuuid: null,
    ordertype: ordertype,
    ordersubtype: ordersubtype,
    ordernumber: "",
    ponumber: "",
    orderstatus: Constants.ORDER_STATUS_OPEN,
    trackingnumber: "",
    contactuuid: null,
    contactname: "",
    totalprice: 0.0,
    totalcost: 0.0,
    totaltax: 0.0,
    salesperson: salesperson,
    creationdatetime: dateTimeAsString(),
    duedatetime: "",
    productname: "",
    companyname: "",
    company: null,
    orderitems: products || [],
  };
  if (company) {
    order.company = company;
    order.companyname = company.companyname;
    if (company.contacts.length > 0) {
      order.contactname = company.contacts[0].firstname + " " + company.contacts[0].lastname;
      order.contactuuid = company.contacts[0].contactuuid;
    }
  }
  return order;
}

export function getBlankRepairItem(orderuuid) {
  return {
    repairitemuuid: "new",
    uuid: "new",
    orderuuid: orderuuid,
    serialnumber: "",
    description: "",
    model: "",
    technician: "",
    repairitemstatus: Constants.ORDER_STATUS_OPEN,
    family: "",
  };
}

export function getBlankTag(reftype, refuuid, reflabel) {
  return {
    isEditing: true,
    isNew: true,
    reftype: reftype,
    refuuid: refuuid,
    reflabel: reflabel,
    tagname: "",
    taguuid: "",
    uuid: "",
    tagcolor: Constants.TAG_COLOR_DEFAULT,
  };
}

export function getBlankWidget(type) {
  let widget = {
    widgettype: type,
    uuid: "new",
    dashboardwidgetuuid: "new",
    widgettitle: "",
    widgetcolor: "#acb4bd",
  };
  if (type === Constants.WIDGET_TYPE_TAG_LIST) {
    widget.widgetconfig = { tag: "", reftype: "", sortorder: Constants.SORT_OLDEST_FIRST };
  } else if (type === Constants.WIDGET_TYPE_CLOCK) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    widget.widgetconfig = { timezone: timezone };
  } else {
    widget.widgetconfig = {};
  }
  return widget;
}

export function getBlankInventoryItem(searchkey) {
  const uuid = Helper.uuid();
  return {
    searchkey: searchkey,
    enteredDateTime: new Date(),
    searchuuid: uuid,
    status: Constants.INVENTORY_STATUS_NEW,
    productname: "",
    tags: [],
    productuuid: "",
    uuid: uuid,
    quantity: 1,
    inventory: "?",
    recordnumber: 0,
  };
}

export function getCustomerName(state) {
  let customerName = "";
  if (state.order?.company?.companyname) {
    customerName = state.order?.company.companyname;
  } else if (state.order?.contactname) {
    customerName = state.order?.contactname;
  } else if (state.company?.companyname) {
    customerName = state.company.companyname;
  } else if (
    state.company?.contacts &&
    state.company.contacts.length > 0 &&
    (state.company.contacts[0].firstname || state.company.contacts[0].lastname)
  ) {
    customerName = state.company.contacts[0].firstname + " " + state.company.contacts[0].lastname;
  }
  return customerName;
}

export function copyInvoiceDataToProduct(invoices, product) {
  return invoices.map(invoice => {
    const orderitem = deepCopy(product);
    const amount = numeral(invoice.amt_balance).format(Constants.CURRENCY);
    orderitem.lineitemstatus = Constants.ORDER_STATUS_OPEN;
    orderitem.uniqueidentifier = Constants.SUBSCRIPTION_PAYMENT + "|" + invoice.invoice_id + "|" + amount;
    orderitem.quantity = 1;
    orderitem.sellprice = amount;
    orderitem.totalprice = amount;
    orderitem.totalcost = numeral(orderitem.cost).format(Constants.CURRENCY);
    orderitem.discount = 0;
    orderitem.rootorderitemuuid = null;
    orderitem.productname +=
      " for " + invoice.subscription_description + " - Invoice ID #" + invoice.invoice_id + " for $" + amount + " due " + invoice.date_due;
    return orderitem;
  });
}

export function getPurchaseItemFromOrderItem(ordertype, salesperson, orderitem) {
  return {
    orderstatus: Constants.ORDER_STATUS_OPEN,
    creationdatetime: today(),
    ordertype: ordertype,
    salesperson: salesperson,
    orderitemuuid: null,
    orderuuid: null,
    lineitemstatus: Constants.ORDER_STATUS_PURCHASE_CREATED,

    // Supplier info (to be set by the user)
    companyname: orderitem.companyname,
    companyuuid: orderitem.companyuuid,

    // Copy item attributes
    uuid: orderitem.productuuid,
    productuuid: orderitem.productuuid,
    productname: orderitem.productname,
    taxable: orderitem.taxable,
    sku: orderitem.sku,
    commission: orderitem.commission,
    associate: orderitem.associate,
    cost: numeral(orderitem.cost).format(Constants.CURRENCY),
    discount: numeral(orderitem.discount).format(Constants.CURRENCY),
    quantity: numeral(orderitem.quantity).format(Constants.DECIMAL_VALUE),
    quantityreceived: numeral(orderitem.quantityreceived).format(Constants.DECIMAL_VALUE),
    sellprice: numeral(orderitem.sellprice).format(Constants.CURRENCY),
    totalprice: numeral(orderitem.totalprice).format(Constants.CURRENCY),
    totalcost: numeral(orderitem.totalcost).format(Constants.CURRENCY),

    // Add blank parent/root values (only used for non-purchases, but must be there anyway)
    parentorderitemuuid: null,
    rootorderitemuuid: null,

    // Customer info for Purchase
    parent_data: orderitem.orderitemuuid
      ? [
          {
            orderitemuuid: orderitem.orderitemuuid,
            contactname: orderitem.contactname,
            contactuuid: orderitem.contactuuid,
            customercompanyname: orderitem.customercompanyname ?? "",
            mobilephone: orderitem.mobilephone ?? "",
            otherphone: orderitem.otherphone ?? "",
            parentorderitemuuid: orderitem.orderitemuuid,
            rootorderitemuuid: orderitem.rootorderitemuuid,
          },
        ]
      : [],
  };
}

export function getSettingFromRecord(records, category, description) {
  let value = null;
  let record = records.filter(item => item.category === category && item.description === description);
  if (record.length > 0) {
    value = record[0].value;
  }
  return value;
}

export function getSettingUuidFromRecord(records, category, description) {
  let uuid = null;
  let record = records.filter(item => item.category === category && item.description === description);
  if (record.length > 0) {
    uuid = record[0].settinguuid;
  }
  return uuid;
}

export function isRequiredFieldOrderItem(view, fieldname) {
  if (view === Constants.PURCHASE) {
    return inList(["productname", "cost", "quantity"], fieldname);
  } else if (view === Constants.ORDER) {
    return inList(["productname", "sellprice", "quantity"], fieldname);
  } else if (view === Constants.REPAIR) {
    return inList(["productname", "sellprice", "quantity"], fieldname);
  } else if (view === Constants.QUOTE) {
    return inList(["productname", "sellprice", "quantity"], fieldname);
  } else if (view === Constants.INVOICE) {
    return inList(["productname", "sellprice", "quantity"], fieldname);
  }
}

export function isReturn(order) {
  return order && order.ordersubtype === Constants.RETURN;
}

export function isTradeIn(order) {
  return order && order.ordersubtype !== "Return" && order.totalprice < 0;
}

export function containsTradeIn(order) {
  return order.orderitems.filter(item => item.sellprice < 0).length > 0;
}

export function containsGiftCard(order) {
  return order.orderitems.filter(item => item.isgiftcard).length > 0;
}

export function hasReturnEligibleItems(order) {
  const items = order.orderitems.filter(item => {
    if (numeral(item.quantity).value() > numeral(item.quantityreceived).value()) {
      return item;
    } else {
      return null;
    }
  });
  return items.length > 0;
}

export function isOrderView(view) {
  return inList([Constants.ORDER, Constants.QUOTE, Constants.REPAIR, Constants.INVOICE, Constants.PURCHASE, Constants.PAY], view);
}

export function isPayLinkEligible(order) {
  return (
    order &&
    [Constants.ORDER, Constants.QUOTE, Constants.REPAIR, Constants.INVOICE].includes(order.ordertype) &&
    !isReturn(order) &&
    numeral(order.balancedue ?? 0).value() > 0 &&
    !Constants.CLOSED_INVOICE_ORDER_STATUSES.includes(order.orderstatus)
  );
}

export function isTaggedView(view, tab) {
  if (view === Constants.CUSTOMER && tab === Constants.TAB_PROSPECTS) {
    return false;
  }
  return inList(
    [
      Constants.ORDER,
      Constants.QUOTE,
      Constants.REPAIR,
      Constants.INVOICE,
      Constants.PURCHASE,
      Constants.PAY,
      Constants.CUSTOMER,
      Constants.PRODUCT,
      Constants.SUPPLIER,
    ],
    view
  );
}

export function isOrderListView(view) {
  return inList([Constants.ORDERS, Constants.QUOTES, Constants.REPAIRS, Constants.INVOICES, Constants.PURCHASES], view);
}

export function sanitize(obj) {
  //return obj;
  obj = removeKeys(deepCopy(obj), Constants.SENSITIVE_CLIENT);
  return obj;
}

// https://stackoverflow.com/questions/68678126/search-for-keys-in-nested-object-and-delete-them
export function removeKeys(obj, keys) {
  if (Array.isArray(obj)) return obj.map(item => removeKeys(item, keys));

  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce((previousValue, key) => {
      return keys.includes(key) ? previousValue : { ...previousValue, [key]: removeKeys(obj[key], keys) };
    }, {});
  }

  return obj;
}

export function checkImageUrl(url) {
  url = url.toLowerCase();
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return true;
  }
  return false;
}

export function isTrue(value) {
  if (value === true || ["true", "1", "yes"].includes(String(value).toLowerCase())) {
    return true;
  }
  return false;
}

export function isFalse(value) {
  return !isTrue(value);
}

export function uuid(size) {
  return uuidv4().replaceAll("-", "").substring(0, size);
}

export function normalizePlan(plan) {
  if (plan) {
    plan.uuid = plan.plan_id;
    plan.plan_duration_unlimited = plan.plan_duration === -1;
    plan.plan_type = plan.plan_code.substring(0, 1);
    plan.is_individual = plan.plan_type === Constants.PLAN_TYPE_INDIVIDUAL;
    plan.type = Constants.BILLING_PLAN;
  }
  return plan;
}

export function clearLocalStorage() {
  // Get the all keys from local storage, filter for the ones we want to remove, and remove them
  let keys = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (
      [
        Constants.LOCAL_STORAGE_STOCK_ORDER_CUSTOMER,
        Constants.LOCAL_STORAGE_WALKIN_CUSTOMER,
        Constants.LOCAL_STORAGE_UPC_LIST,
        Constants.LOCAL_STORAGE_UPC_CONFIG,
      ].includes(key)
    ) {
      keys.push(key);
    }
  }
  keys.forEach(key => {
    localStorage.setItem(key, "");
    localStorage.removeItem(key);
  });
  clearLocalStorageInventory();
}

export function clearLocalStorageInventory() {
  // Iterate through every key in localStorage backwards, since we're deleting keys
  for (let i = localStorage.length - 1; i >= 0; i--) {
    // get key name
    let key = localStorage.key(i);
    if (key.startsWith(Constants.LOCAL_STORAGE_INVENTORY) || key === Constants.LOCAL_STORAGE_PRODUCTS) {
      localStorage.removeItem(key);
    }
  }
}

export function getEmptyState() {
  return {
    breadcrumbs: [],
    clientSettings: {},
    clientuuid: "",
    contactSearchKey: "",
    contactuuid: "",
    currentMenu: Constants.LOGIN,
    currentView: Constants.LOGIN,
    displaySettings: {},
    downloading: false,
    error: null,
    families: [],
    features: [],
    filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.TAB_NONE },
    handpoint: {},
    import: null,
    isLoggedIn: false,
    loggedInError: false,
    maast: {},
    overlay: null,
    page: 1,
    peers: [],
    productSearchKey: "",
    productuuid: null,
    salesperson: "",
    searchkey: "",
    selectedItem: null,
    showTestLabel: true,
    technicians: [],
    thirdparty: {},
    twilio: {},
    uploadsUrl: "",
    username: "",
    useruuid: "",
    usertype: null,
    valor: {},
    waiting: false,
  };
}

export function getMenuItems(appState, handleMenuClick, index = 0) {
  let features = appState?.features;
  let usertype = appState?.usertype;
  let menuItems = [];
  // Dynamically build this list from the enabled features list
  menuItems = menuItems.concat([
    {
      key: "menu-" + Constants.DASHBOARD + index,
      menu: Constants.DASHBOARD,
      svg: <DashboardIcon key={Constants.DASHBOARD + index} />,
      handler: () => {
        handleMenuClick(Constants.DASHBOARD);
      },
      submenu: Constants.DASHBOARD,
    },
    {
      key: "menu-" + Constants.CUSTOMERS + index,
      menu: Constants.CUSTOMERS,
      svg: <CustomersIcon key={Constants.CUSTOMERS + index} />,
      handler: () => {
        handleMenuClick(Constants.CUSTOMERS);
      },
      notification: appState?.pendingprospectcount,
      notificationtitle: "Prospects in need of review",
      submenu: Constants.CUSTOMER,
    },
  ]);
  if (features?.includes(Constants.FEATURE_PRODUCTS)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.PRODUCTS + index,
        menu: Constants.PRODUCTS,
        svg: <ProductsIcon key={Constants.PRODUCTS + index} />,
        handler: () => {
          handleMenuClick(Constants.PRODUCTS);
        },
        submenu: Constants.PRODUCT,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_INVOICES)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.INVOICES + index,
        menu: Constants.INVOICES,
        svg: <InvoicesIcon key={Constants.INVOICES + index} />,
        handler: () => {
          handleMenuClick(Constants.INVOICES);
        },
        submenu: Constants.INVOICE,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_ORDERS)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.ORDERS + index,
        menu: Constants.ORDERS,
        svg: <OrdersIcon key={Constants.ORDERS + index} />,
        handler: () => {
          handleMenuClick(Constants.ORDERS);
        },
        submenu: Constants.ORDER,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_REPAIRS)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.REPAIRS + index,
        menu: Constants.REPAIRS,
        svg: <RepairsIcon key={Constants.REPAIRS + index} />,
        handler: () => {
          handleMenuClick(Constants.REPAIRS);
        },
        submenu: Constants.REPAIR,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_QUOTES)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.QUOTES + index,
        menu: Constants.QUOTES,
        svg: <QuotesIcon key={Constants.QUOTES + index} />,
        handler: () => {
          handleMenuClick(Constants.QUOTES);
        },
        submenu: Constants.QUOTE,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_SUPPLIERS)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.SUPPLIERS + index,
        menu: Constants.SUPPLIERS,
        svg: <SuppliersIcon key={Constants.SUPPLIERS + index} />,
        handler: () => {
          handleMenuClick(Constants.SUPPLIERS);
        },
        submenu: Constants.SUPPLIER,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_PURCHASES)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.PURCHASES + index,
        menu: Constants.PURCHASES,
        svg: <PurchasesIcon key={Constants.PURCHASES + index} />,
        handler: () => {
          handleMenuClick(Constants.PURCHASES);
        },
        submenu: Constants.PURCHASE,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_EMAIL) || features?.includes(Constants.FEATURE_TEXT)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.MESSAGES + index,
        menu: Constants.MESSAGES,
        svg: <MessagesIcon key={Constants.MESSAGES + index} />,
        handler: () => {
          handleMenuClick(Constants.MESSAGES);
        },
        notification: appState?.twilio?.unread_count,
        notificationtitle: "Unread text messages",
        submenu: Constants.MESSAGE,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_TIMESHEETS)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.TIMESHEETS + index,
        menu: Constants.TIMESHEETS,
        svg: <TimesheetsIcon key={Constants.TIMESHEETS + index} />,
        handler: () => {
          handleMenuClick(Constants.TIMESHEETS);
        },
        submenu: Constants.TIMESHEET,
      },
    ]);
  }
  menuItems = menuItems.concat([
    {
      key: "menu-" + Constants.REPORTS + index,
      menu: Constants.REPORTS,
      svg: <ReportsIcon key={Constants.REPORTS + index} />,
      handler: () => {
        handleMenuClick(Constants.REPORTS);
      },
      submenu: Constants.REPORT,
    },
  ]);
  if (features?.includes(Constants.FEATURE_BILLING) && Helper.authorize(Constants.ACTION_VIEW_BILLING, usertype)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.BILLINGS + index,
        menu: Constants.BILLINGS,
        svg: <BillingsIcon key={Constants.BILLINGS + index} />,
        handler: () => {
          handleMenuClick(Constants.BILLINGS);
        },
        submenu: Constants.BILLING,
      },
    ]);
  }
  if (features?.includes(Constants.FEATURE_RECURRINGS) && Helper.authorize(Constants.ACTION_VIEW_BILLING, usertype)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.RECURRINGS + index,
        menu: Constants.RECURRINGS,
        svg: <BillingsIcon key={Constants.RECURRINGS + index} />,
        handler: () => {
          handleMenuClick(Constants.RECURRINGS);
        },
        submenu: Constants.RECURRINGS,
      },
    ]);
  }
  if (Helper.authorize(Constants.ACTION_EDIT_CAMPAIGNS, usertype)) {
    menuItems = menuItems.concat([
      {
        key: "menu-" + Constants.CAMPAIGNS + index,
        menu: Constants.CAMPAIGNS,
        svg: <MegaphoneIcon key={Constants.CAMPAIGNS + index} />,
        handler: () => {
          handleMenuClick(Constants.CAMPAIGNS);
        },
        submenu: Constants.CAMPAIGN,
      },
    ]);
  }
  // if (features?.includes(Constants.FEATURE_ROSTERHOUND)) {
  //   menuItems = menuItems.concat([
  //     {
  //       key: "menu-" + Constants.ROSTERHOUND + index,
  //       menu: Constants.ROSTERHOUND,
  //       svg: <CalendarIcon key={Constants.ROSTERHOUND + index} />,
  //       handler: () => {
  //         // Open Rosterhound in a new tab
  //         let url = ApiBase.ROSTERHOUND_URL + "?clientuuid=" + appState.clientuuid;
  //         // Concatenate the user's clientuuid
  //         window.open(url, "_blank");
  //       },
  //       submenu: Constants.ROSTERHOUND,
  //     },
  //   ]);
  // }
  return menuItems;
}

export function detectCreditCardNumbers(text) {
  /**
   * Detect credit card numbers inside a string and validate using Mod10 check.
   *
   * @param {string} text - The input text to search for credit card numbers.
   * @return {Array} - An array of detected and valid credit card numbers.
   */
  // Define the regular expression pattern to match credit card numbers
  const pattern = /\b(?:\d[ -]*?){13,16}\b/g;

  // Search for credit card numbers in the input text using the regular expression
  const matches = text.match(pattern) || [];

  // Validate credit card numbers using Mod10 check
  const validCreditCardNumbers = [];
  for (const creditCardNumber of matches) {
    // Remove spaces and hyphens from the credit card number
    const creditCardNumberNoSpaces = creditCardNumber.replace(/ /g, "").replace(/-/g, "");

    // Perform Mod10 check
    let sum = 0;
    const numDigits = creditCardNumberNoSpaces.length;
    for (let i = 0; i < numDigits; i++) {
      let digit = parseInt(creditCardNumberNoSpaces[numDigits - 1 - i], 10);
      if ((i + 1) % 2 === 0) {
        let doubleDigit = digit * 2;
        if (doubleDigit > 9) {
          doubleDigit = doubleDigit - 9;
        }
        sum += doubleDigit;
      } else {
        sum += digit;
      }
    }

    if (sum % 10 === 0) {
      validCreditCardNumbers.push(creditCardNumberNoSpaces);
    }
  }

  // Return the array of detected and valid credit card numbers
  return validCreditCardNumbers;
}

export function action(noun, verb) {
  // Remove all action logging
  // Iterate through every key in localStorage backwards, since we're deleting keys
  for (let i = localStorage.length - 1; i >= 0; i--) {
    // Get the key name
    let key = localStorage.key(i);
    // If the key is an action log, remove it
    if (key.startsWith("actions-")) {
      localStorage.removeItem(key);
    }
  }

  // const das = dateAsString(new Date());
  // const index_name = "actions-index";
  // const log_name = "actions-" + das;

  // // Check for expired log file to delete over seven days old
  // const entries = localStorage.getItem(index_name) || "";
  // const log_dates = entries.split("\n");
  // const sevenDaysAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  // log_dates.forEach(log_date => {
  //   // Parse the date from the log name
  //   const d = new Date(log_date);

  //   if (d < sevenDaysAgo) {
  //     // Delete the log file
  //     localStorage.removeItem("actions-" + log_date);

  //     // Remove the log name from the index
  //     const index = localStorage.getItem(index_name) || "";
  //     localStorage.setItem(index_name, index.replace(log_date + "\n", ""));
  //   }
  // });

  // // Record the log name in the index if it's not already there
  // var index = localStorage.getItem(index_name) || "";
  // if (index.indexOf(das) === -1) {
  //   localStorage.setItem(index_name, index + das + "\n");
  // }

  // // Append to the log file
  // var old = localStorage.getItem(log_name) || "";
  // localStorage.setItem(log_name, old + noun + verb);
}

export function sortSettings(settings) {
  settings.sort((a, b) => {
    // First, compare the categories
    const categoryComparison = (a.category || "").localeCompare(b.category || "");

    // If the categories are the same, compare the descriptions or values
    if (categoryComparison === 0) {
      if (a.category === Constants.SETTINGS_CATEGORY_REPAIR && a.description === Constants.SETTINGS_TECHNICIAN) {
        return (a.fullname || "").localeCompare(b.fullname || "");
      }
      if (a.category === Constants.SETTINGS_CATEGORY_CUSTOM_FIELDS) {
        return parseInt(a.value || 0) - parseInt(b.value || 0);
      }
      const descriptionComparison = a.description.localeCompare(b.description);
      if (descriptionComparison === 0) {
        return (a.value || "").localeCompare(b.value || "");
      }
      return descriptionComparison;
    }

    // If the categories are different, return the category comparison result
    return categoryComparison;
  });
  return settings;
}

export function renderMarkup(str) {
  if (str.startsWith("~") && str.endsWith("~")) {
    return <span className="highlight">{str.slice(1, -1)}</span>;
  }
  return str;
}

export function handleViewMessage(message) {
  const top =
    "<b>To:</b> " +
    message.emailto +
    "<br />" +
    "<b>From:</b> " +
    he.encode(message.emailfrom) +
    "<br />" +
    "<b>Subject:</b> " +
    message.subject +
    "<br />" +
    "<b>Date:</b> " +
    formatDateTime(message.creationdatetime) +
    "<br />" +
    "<hr /><br />";
  let html;
  if (message.body_html.indexOf("<!-- EmailHeader -->") === -1) {
    html =
      "<html lang='en'>" +
      "<head>" +
      "<link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Poppins' />" +
      "</head>" +
      "<body style='margin: 4%; font-size: 12px; font-family: Poppins, Arial, sans-serif'>" +
      "<div>" +
      top +
      message.body_html +
      "</div></body></html>";
  } else {
    html = message.body_html.replace("<!-- EmailHeader -->", top);
  }
  var newTab = window.open();
  newTab.document.open();
  newTab.document.write(html);
  newTab.document.close();
}

export function handleViewHtml(message) {
  // let html;
  // html =
  //   "<html lang='en'>" +
  //   "<head>" +
  //   "<link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Poppins' />" +
  //   "</head>" +
  //   "<body style='margin: 4%; font-size: 12px; font-family: Poppins, Arial, sans-serif'>" +
  //   "<div>" +
  //   message +
  //   "</div></body></html>";
  var newTab = window.open();
  if (newTab) {
    newTab.document.open();
    newTab.document.write(message);
    newTab.document.close();
  }
  return newTab;
}

export function getImportFieldList(type, updateExisting) {
  let fields = [];
  if (type === Constants.CUSTOMERS) {
    if (updateExisting) {
      fields = [
        // Company-level fields
        { uuid: "Company-level-fields", label: "Company-level fields", heading: true },
        { uuid: "companyname", name: "companyname", label: "Company Name", match: "", required: false },
        { uuid: "discount", name: "discount", label: "Discount", match: "", required: false, percent: true },
        { uuid: "note", name: "note", label: "New Note", match: "", required: false },
        { uuid: "tag", name: "tag", label: "New Tag", match: "", required: false, commaSeparated: true }, // Not really comma-separated, but it formats the sample as a tag
        { uuid: "tags", name: "tags", label: "New Tags (comma-separated)", match: "", required: false, commaSeparated: true },
        { uuid: "taxable", name: "taxable", label: "Taxable", match: "", required: false, boolean: true },
        // Contact-level fields
        { uuid: "Contact-level-fields", label: "Contact-level fields", heading: true },
        { uuid: "active", name: "active", label: "Active", match: "", required: false, boolean: true },
        { uuid: "address1", name: "address1", label: "Address 1", match: "", required: false },
        { uuid: "address2", name: "address2", label: "Address 2", match: "", required: false },
        { uuid: "city", name: "city", label: "City", match: "", required: false },
        { uuid: "email", name: "email", label: "Email", match: "", required: false },
        { uuid: "firstname", name: "firstname", label: "First Name", match: "", required: false },
        { uuid: "lastname", name: "lastname", label: "Last Name", match: "", required: false },
        { uuid: "mobilephone", name: "mobilephone", label: "Mobile Phone", match: "", required: false },
        { uuid: "otherphone", name: "otherphone", label: "Other Phone", match: "", required: false },
        { uuid: "postalcode", name: "postalcode", label: "Postal Code", match: "", required: false },
        { uuid: "state", name: "state", label: "State", match: "", required: false },
        { uuid: "title", name: "title", label: "Title", match: "", required: false },
        { uuid: "website", name: "website", label: "Website", match: "", required: false },
      ];
    } else {
      fields = [
        // Company-level fields
        { uuid: "Company-level-fields", label: "Company-level fields", heading: true },
        { uuid: "companyname", name: "companyname", label: "Company Name", match: "", required: false },
        { uuid: "discount", name: "discount", label: "Discount", match: "", required: false, percent: true },
        { uuid: "note", name: "note", label: "Note", match: "", required: false },
        { uuid: "tag", name: "tag", label: "Tag", match: "", required: false, commaSeparated: true }, // Not really comma-separated, but it formats the sample as a tag
        { uuid: "tags", name: "tags", label: "Tags (comma-separated)", match: "", required: false, commaSeparated: true },
        { uuid: "taxable", name: "taxable", label: "Taxable", match: "", required: false, boolean: true },
        // Contact-level fields
        { uuid: "Contact-level-fields", label: "Contact-level fields", heading: true },
        { uuid: "firstname", name: "firstname", label: "First Name", match: "", required: true },
        { uuid: "lastname", name: "lastname", label: "Last Name", match: "", required: true },
        { uuid: "active", name: "active", label: "Active", match: "", required: false, boolean: true },
        { uuid: "address1", name: "address1", label: "Address 1", match: "", required: false },
        { uuid: "address2", name: "address2", label: "Address 2", match: "", required: false },
        { uuid: "city", name: "city", label: "City", match: "", required: false },
        { uuid: "email", name: "email", label: "Email", match: "", required: false },
        { uuid: "mobilephone", name: "mobilephone", label: "Mobile Phone", match: "", required: false },
        { uuid: "otherphone", name: "otherphone", label: "Other Phone", match: "", required: false },
        { uuid: "postalcode", name: "postalcode", label: "Postal Code", match: "", required: false },
        { uuid: "state", name: "state", label: "State", match: "", required: false },
        { uuid: "title", name: "title", label: "Title", match: "", required: false },
        { uuid: "website", name: "website", label: "Website", match: "", required: false },
      ];
    }
  } else if (type === Constants.SUPPLIERS) {
    if (updateExisting) {
      fields = [
        // Company-level fields
        { uuid: "Company-level-fields", label: "Company-level fields", heading: true },
        { uuid: "companyname", name: "companyname", label: "Company Name", match: "", required: false },
        { uuid: "note", name: "note", label: "New Note", match: "", required: false },
        { uuid: "tag", name: "tag", label: "New Tag", match: "", required: false, commaSeparated: true }, // Not really comma-separated, but it formats the sample as a tag
        { uuid: "tags", name: "tags", label: "New Tags (comma-separated)", match: "", required: false, commaSeparated: true },
        // Contact-level fields
        { uuid: "Contact-level-fields", label: "Contact-level fields", heading: true },
        { uuid: "active", name: "active", label: "Active", match: "", required: false, boolean: true },
        { uuid: "address1", name: "address1", label: "Address 1", match: "", required: false },
        { uuid: "address2", name: "address2", label: "Address 2", match: "", required: false },
        { uuid: "city", name: "city", label: "City", match: "", required: false },
        { uuid: "email", name: "email", label: "Email", match: "", required: false, multirequired: false },
        { uuid: "firstname", name: "firstname", label: "First Name", match: "", required: false, multirequired: false },
        { uuid: "lastname", name: "lastname", label: "Last Name", match: "", required: false, multirequired: false },
        { uuid: "mobilephone", name: "mobilephone", label: "Mobile Phone", match: "", required: false, multirequired: false },
        { uuid: "otherphone", name: "otherphone", label: "Other Phone", match: "", required: false, multirequired: false },
        { uuid: "postalcode", name: "postalcode", label: "Postal Code", match: "", required: false },
        { uuid: "state", name: "state", label: "State", match: "", required: false },
        { uuid: "title", name: "title", label: "Title", match: "", required: false },
        { uuid: "website", name: "website", label: "Website", match: "", required: false, multirequired: false },
      ];
    } else {
      fields = [
        // Company-level fields
        { uuid: "Company-level-fields", label: "Company-level fields", heading: true },
        { uuid: "companyname", name: "companyname", label: "Company Name", match: "", required: true },
        { uuid: "note", name: "note", label: "Note", match: "", required: false },
        { uuid: "tag", name: "tag", label: "Tag", match: "", required: false, commaSeparated: true }, // Not really comma-separated, but it formats the sample as a tag
        { uuid: "tags", name: "tags", label: "Tags (comma-separated)", match: "", required: false, commaSeparated: true },
        // Contact-level fields
        { uuid: "Contact-level-fields", label: "Contact-level fields", heading: true },
        { uuid: "active", name: "active", label: "Active", match: "", required: false, boolean: true },
        { uuid: "address1", name: "address1", label: "Address 1", match: "", required: false },
        { uuid: "address2", name: "address2", label: "Address 2", match: "", required: false },
        { uuid: "city", name: "city", label: "City", match: "", required: false },
        { uuid: "email", name: "email", label: "Email", match: "", required: false, multirequired: true },
        { uuid: "firstname", name: "firstname", label: "First Name", match: "", required: false, multirequired: true },
        { uuid: "lastname", name: "lastname", label: "Last Name", match: "", required: false, multirequired: true },
        { uuid: "mobilephone", name: "mobilephone", label: "Mobile Phone", match: "", required: false, multirequired: true },
        { uuid: "otherphone", name: "otherphone", label: "Other Phone", match: "", required: false, multirequired: true },
        { uuid: "postalcode", name: "postalcode", label: "Postal Code", match: "", required: false },
        { uuid: "state", name: "state", label: "State", match: "", required: false },
        { uuid: "title", name: "title", label: "Title", match: "", required: false },
        { uuid: "website", name: "website", label: "Website", match: "", required: false, multirequired: true },
      ];
    }
  } else if (type === Constants.PRODUCTS) {
    if (updateExisting) {
      fields = [
        { uuid: "active", name: "active", label: "Active", match: "", required: false, boolean: true },
        { uuid: "affectinventory", name: "affectinventory", label: "Affects Inventory", match: "", required: false, boolean: true },
        { uuid: "commission", name: "commission", label: "Commission", match: "", required: false },
        { uuid: "cost", name: "cost", label: "Cost", match: "", required: false, numeric: true },
        { uuid: "ean", name: "ean", label: "EAN", match: "", required: false },
        { uuid: "family", name: "family", label: "Category", match: "", required: false },
        { uuid: "inventory", name: "inventory", label: "Inventory", match: "", required: false, numeric: true },
        { uuid: "location", name: "location", label: "Location", match: "", required: false },
        { uuid: "longdescription", name: "longdescription", label: "Long Description", match: "", required: false },
        { uuid: "make", name: "make", label: "Brand/Make", match: "", required: false },
        { uuid: "maxdiscount", name: "maxdiscount", label: "Max Discount", match: "", required: false, percent: true },
        { uuid: "model", name: "model", label: "Model", match: "", required: false },
        { uuid: "note", name: "note", label: "New Note", match: "", required: false },
        { uuid: "productname", name: "productname", label: "Product Name", match: "", required: false },
        { uuid: "sellprice", name: "sellprice", label: "Sell Price", match: "", required: true, numeric: true },
        { uuid: "sku", name: "sku", label: "SKU", match: "", required: false },
        { uuid: "storesku", name: "storesku", label: "Store SKU", match: "", required: false },
        // TODO: Modify product.py to accept a companyname on PUT and look up the companyid by name using select_company_by_name()
        // { uuid: "companyname", name: "companyname", label: "Supplier Name", match: "", required: false },
        { uuid: "tag", name: "tag", label: "New Tag", match: "", required: false, commaSeparated: true }, // Not really comma-separated, but it formats the sample as a tag
        { uuid: "tags", name: "tags", label: "New Tags (comma-separated)", match: "", required: false, commaSeparated: true },
        { uuid: "taxable", name: "taxable", label: "Taxable", match: "", required: false, boolean: true },
        { uuid: "upc", name: "upc", label: "UPC", match: "", required: false },
      ];
    } else {
      fields = [
        { uuid: "productname", name: "productname", label: "Product Name", match: "", required: true },
        { uuid: "sellprice", name: "sellprice", label: "Sell Price", match: "", required: true, numeric: true },
        { uuid: "active", name: "active", label: "Active", match: "", required: false, boolean: true },
        { uuid: "affectinventory", name: "affectinventory", label: "Affects Inventory", match: "", required: false, boolean: true },
        { uuid: "commission", name: "commission", label: "Commission", match: "", required: false },
        { uuid: "cost", name: "cost", label: "Cost", match: "", required: false, numeric: true },
        { uuid: "ean", name: "ean", label: "EAN", match: "", required: false },
        { uuid: "family", name: "family", label: "Category", match: "", required: false },
        { uuid: "inventory", name: "inventory", label: "Inventory", match: "", required: false, numeric: true },
        { uuid: "location", name: "location", label: "Location", match: "", required: false },
        { uuid: "longdescription", name: "longdescription", label: "Long Description", match: "", required: false },
        { uuid: "make", name: "make", label: "Brand/Make", match: "", required: false },
        { uuid: "maxdiscount", name: "maxdiscount", label: "Max Discount", match: "", required: false, percent: true },
        { uuid: "model", name: "model", label: "Model", match: "", required: false },
        { uuid: "note", name: "note", label: "Note", match: "", required: false },
        { uuid: "sku", name: "sku", label: "SKU", match: "", required: false },
        { uuid: "storesku", name: "storesku", label: "Store SKU", match: "", required: false },
        { uuid: "companyname", name: "companyname", label: "Supplier Name", match: "", required: false },
        { uuid: "tag", name: "tag", label: "Tag", match: "", required: false, commaSeparated: true }, // Not really comma-separated, but it formats the sample as a tag
        { uuid: "tags", name: "tags", label: "Tags (comma-separated)", match: "", required: false, commaSeparated: true },
        { uuid: "taxable", name: "taxable", label: "Taxable", match: "", required: false, boolean: true },
        { uuid: "upc", name: "upc", label: "UPC", match: "", required: false },
      ];
    }
  }
  return fields;
}

export function isProtectedCustomer(customer, appState) {
  if (customer?.contactuuid === appState?.clientSettings?.STOCK_ORDER_CUSTOMER) {
    return true;
  }
  if (customer?.contactuuid === appState?.clientSettings?.WALKIN_CUSTOMER) {
    return true;
  }
  return false;
}

// Only call this function for actions that are prohibited to SOME users
export function authorize(action, usertype) {
  // Managers can do anything listed here
  if (
    usertype >= Constants.USER_TYPE_MANAGER &&
    [
      Constants.ACTION_DELETE_PAYMENT,
      Constants.ACTION_EDIT_CAMPAIGNS,
      Constants.ACTION_EDIT_COMMISSION,
      Constants.ACTION_EDIT_ORDER_DATE,
      Constants.ACTION_EDIT_TIMESHEET,
      Constants.ACTION_INVENTORY,
      Constants.ACTION_MARK_AS_PAID,
      Constants.ACTION_PAY_BY_VAULT,
      Constants.ACTION_UPDATE_PRODUCT,
      Constants.ACTION_VIEW_BILLING, // Front-end only
    ].includes(action)
  ) {
    return true;
  }

  // Owners can do anything listed below
  else if (
    usertype >= Constants.USER_TYPE_OWNER &&
    [
      Constants.ACTION_DELETE_CLOSED_ORDER,
      Constants.ACTION_EDIT_SETTINGS,
      Constants.ACTION_IMPORT_EXPORT,
      Constants.ACTION_SWITCH_STORE,
      Constants.ACTION_UPDATE_ORDER_STATUS,
      Constants.ACTION_VIEW_MERCHANT_MANAGER, // Front-end only
    ].includes(action)
  ) {
    return true;
  }

  // Admins can do anything!!!!!
  if (usertype === Constants.USER_TYPE_ADMIN) {
    return true;
  }

  // All else if prohibited
  return false;
}

export function copyToClipboard(text) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  }
}

export function canClip() {
  if (window.location.hostname === "localhost") {
    return true;
  }
  if (window.location.protocol === "http:") {
    return false;
  }
  return navigator.clipboard && window.location.protocol === "https:";
}

export function renderInventoryStatusLabel(status) {
  let label = "Unknown";
  if (status === Constants.INVENTORY_STATUS_PENDING) {
    label = "Pending";
  } else if (status === Constants.INVENTORY_STATUS_DELETED) {
    label = "Deleted";
  } else if (status === Constants.INVENTORY_STATUS_NEW) {
    label = "New";
  } else if (status === Constants.INVENTORY_STATUS_MATCHED) {
    label = "Product found";
  } else if (status === Constants.INVENTORY_STATUS_NOT_INVENTORIED) {
    label = "Item not counted in inventory";
  } else if (status === Constants.INVENTORY_STATUS_NOT_MATCHED) {
    label = "Product not found";
  } else if (status === Constants.INVENTORY_STATUS_SAVED) {
    label = "Saved";
  }
  return label;
}

export function renderFilterLabel(dateFilterType) {
  let filterLabel = "Unknown";
  if (dateFilterType === Constants.FILTER_DATE_CUSTOM) {
    filterLabel = "Custom";
  } else if (dateFilterType === Constants.FILTER_DATE_ALL) {
    filterLabel = "All";
  } else if (dateFilterType === Constants.FILTER_DATE_TODAY) {
    filterLabel = "Today";
  } else if (dateFilterType === Constants.FILTER_DATE_YESTERDAY) {
    filterLabel = "Yesterday";
  } else if (dateFilterType === Constants.FILTER_DATE_THIS_WEEK) {
    filterLabel = "This Week";
  } else if (dateFilterType === Constants.FILTER_DATE_THIS_MONTH) {
    filterLabel = "This Month";
  } else if (dateFilterType === Constants.FILTER_DATE_THIS_QUARTER) {
    filterLabel = "This Quarter";
  } else if (dateFilterType === Constants.FILTER_DATE_THIS_YEAR) {
    filterLabel = "This Year";
  } else if (dateFilterType === Constants.FILTER_DATE_LAST_WEEK) {
    filterLabel = "Last Week";
  } else if (dateFilterType === Constants.FILTER_DATE_LAST_MONTH) {
    filterLabel = "Last Month";
  } else if (dateFilterType === Constants.FILTER_DATE_LAST_QUARTER) {
    filterLabel = "Last Quarter";
  } else if (dateFilterType === Constants.FILTER_DATE_LAST_YEAR) {
    filterLabel = "Last Year";
  }
  return filterLabel;
}

export function renderDisposition(disposition) {
  let label = "";
  if (disposition === Constants.DISPOSITION_ALL) {
    return "All";
  } else if (disposition === Constants.DISPOSITION_INVENTORIED) {
    return "Counted Inventory";
  } else if (disposition === Constants.DISPOSITION_MATCHED) {
    return "Matched Inventory";
  } else if (disposition === Constants.DISPOSITION_MISSING) {
    return "Missing Inventory";
  } else if (disposition === Constants.DISPOSITION_OVERAGE) {
    return "Excess Inventory";
  } else if (disposition === Constants.DISPOSITION_PENDING) {
    return "Uncounted Inventory";
  }
  return label;
}

export function renderSmsContactName(item) {
  // Format the phone number and remove the leading "1"
  let label = formatPhoneNumber(item.phonenumber).substring(1);
  if (item.firstname || item.lastname) {
    label = item.firstname + " " + item.lastname;
    label = label.trim();
    if (item.companyname) {
      label += " (" + item.companyname + ")";
    }
  } else if (item.companyname) {
    label = item.companyname;
  }
  return label;
}

export function getUniqueRef(authResponse) {
  if (authResponse.processor === Constants.MAAST) {
    return authResponse.pg_id;
  } else if (authResponse.processor === Constants.HANDPOINT) {
    return authResponse.transactionID;
  } else if (authResponse.processor === Constants.VALOR) {
    return {
      CODE: authResponse.CODE,
      TXN_ID: authResponse.TXN_ID,
      RRN: authResponse.RRN,
      EPI: authResponse.epi,
      STAN_ID: authResponse.STAN_ID,
      TRAN_NO: authResponse.TRAN_NO,
    };
  } else {
    return null;
  }
}

export function copyPrimitives(obj) {
  const copy = {};
  Object.keys(obj).forEach(key => {
    if (["string", "number", "boolean"].includes(typeof obj[key])) {
      copy[key] = obj[key];
    }
  });
  return copy;
}

export function getFileType(filename) {
  const ext = filename.split(".").pop();
  if (inList(["jpg", "jpeg"], ext)) {
    return "image/jpeg";
  } else if (ext === "png") {
    return "image/png";
  } else if (ext === "webp") {
    return "image/webp";
  } else if (ext === "bmp") {
    return "image/bmp";
    // AWS is not supporting HEIC yet. It sees it as an octet stream
    // } else if (ext === "heic") {
    //   return "image/heic";
  } else {
    return "";
  }
}

export function findParent(element, className) {
  while (element) {
    if (element.classList.contains(className)) {
      return element;
    }
    element = element.parentElement;
  }
  return null;
}

export function getMaastEmbeddedStyle(source, colorMode, fontSize = null) {
  const bodyColor = colorMode === Constants.COLOR_MODE_DARK ? "#353d4a" : "#efeff1";
  const textColor = colorMode === Constants.COLOR_MODE_DARK ? "#acb4bd" : "#707070";
  const backgroundColor = colorMode === Constants.COLOR_MODE_DARK ? "#282e38" : "#ffffff";
  if ([Constants.CREATE_ACCOUNT, Constants.VAULT].includes(source)) {
    return (
      `body { background-color: ${bodyColor}; } ` +
      ".form-control { padding-left: 0.5em; box-shadow: none; }" +
      ".form-control:focus { box-shadow: none; } " +
      `input[type='text']::placeholder { color: ${textColor}; opacity:0.4 } ` +
      `input[type='text']:focus { color: ${textColor}; background-color: ${backgroundColor}; } ` +
      `input[type='text']:hover { background-color: ${backgroundColor}; } ` +
      `input[type='text'] { color: ${textColor}; font-size: ${
        fontSize || "4.5vw"
      }; background-color: ${backgroundColor}; border: none; outline: none; resize: none; border-radius: .3em; padding: .5em;} ` +
      "input.ng-invalid.ng-dirty { border: 1px solid red; } "
    );
  } else if (source === Constants.PAY) {
    return (
      "#root { min-width: 500px; } " +
      `body { background-color: ${bodyColor}; } ` +
      `.qp-form { background-color: ${bodyColor}; } ` +
      `.form-control {box-shadow: none;  font-size: ${fontSize || "17px"}; } ` +
      ".form-control:focus {box-shadow: none; } " +
      `input[type='text'] { background-color: ${backgroundColor}; color: ${textColor}; border: none; outline: none; resize: none; border-radius: .3em; } ` +
      `input[type='text']:focus { color: ${textColor} !important; } ` +
      `input[type='text']::placeholder { color: ${backgroundColor}; } ` +
      `.qp-form input:hover { background-color: ${backgroundColor}; } ` +
      `.qp-form input:focus { background-color: ${backgroundColor}; } ` +
      "input.ng-invalid.ng-dirty { border: 1px solid red; } " +
      ".card-number .input-group .card-type-icon: {display: none; }"
    );
  } else {
    return "";
  }
}

export function getDatesForFilter(dateFilterType) {
  let start = new Date();
  let end = new Date();
  let currentMonth = start.getMonth();
  let startMonth;
  let endMonth;
  switch (dateFilterType) {
    case Constants.FILTER_DATE_CUSTOM:
      start = end = Helper.dateAsString(start);
      break;
    case Constants.FILTER_DATE_ALL:
      start = Helper.dateAsString(new Date(2023, 0, 1));
      end = Helper.dateAsString(new Date());
      break;
    case Constants.FILTER_DATE_YESTERDAY:
      start = end = Helper.dateAsString(new Date(start.getTime() - Constants.ONE_DAY));
      break;
    case Constants.FILTER_DATE_THIS_WEEK:
      start = new Date(start.getTime() - Constants.ONE_DAY * start.getDay());
      end = Helper.dateAsString(new Date(start.getTime() + Constants.ONE_DAY * 6));
      start = Helper.dateAsString(start);
      break;
    case Constants.FILTER_DATE_THIS_MONTH:
      start = Helper.dateAsString(new Date(start.getFullYear(), start.getMonth(), 1));
      end = Helper.dateAsString(new Date(end.getFullYear(), end.getMonth() + 1, 0));
      break;
    case Constants.FILTER_DATE_THIS_QUARTER:
      currentMonth = start.getMonth();
      if (currentMonth >= 0 && currentMonth <= 2) {
        startMonth = 0;
        endMonth = 3;
      } else if (currentMonth >= 3 && currentMonth <= 5) {
        startMonth = 3;
        endMonth = 6;
      } else if (currentMonth >= 6 && currentMonth <= 8) {
        startMonth = 6;
        endMonth = 9;
      } else {
        startMonth = 9;
        endMonth = 12;
      }
      start = new Date(start.getFullYear(), startMonth, 1);
      end = Helper.dateAsString(new Date(start.getFullYear(), endMonth, 0));
      start = Helper.dateAsString(start);
      break;
    case Constants.FILTER_DATE_THIS_YEAR:
      start = Helper.dateAsString(new Date(start.getFullYear(), 0, 1));
      end = Helper.dateAsString(new Date(end.getFullYear(), 12, 0));
      break;
    case Constants.FILTER_DATE_LAST_WEEK:
      start = new Date(start.getTime() - Constants.ONE_DAY * (start.getDay() + 7));
      end = Helper.dateAsString(new Date(start.getTime() + Constants.ONE_DAY * 6));
      start = Helper.dateAsString(start);
      break;
    case Constants.FILTER_DATE_LAST_MONTH:
      start = Helper.dateAsString(new Date(start.getFullYear(), start.getMonth() - 1, 1));
      end = Helper.dateAsString(new Date(end.getFullYear(), end.getMonth(), 0));
      break;
    case Constants.FILTER_DATE_LAST_QUARTER:
      // Get the start and end months for the THIS quarter
      if (currentMonth >= 0 && currentMonth <= 2) {
        startMonth = 0;
        endMonth = 3;
      } else if (currentMonth >= 3 && currentMonth <= 5) {
        startMonth = 3;
        endMonth = 6;
      } else if (currentMonth >= 6 && currentMonth <= 8) {
        startMonth = 6;
        endMonth = 9;
      } else {
        startMonth = 9;
        endMonth = 12;
      }
      // Set the start date to 3 months earlier than the start of THIS quarter (which gives you the start of the previous quarter)
      start = new Date(start.getFullYear(), startMonth - 3, 1);
      // Sets the end date to start + 3 months minus 1 day (last day of the previous quarter)
      end = new Date(start);
      end.setMonth(end.getMonth() + 3);
      end.setDate(end.getDate() - 1);
      // Convert dates to strings for the UI
      start = Helper.dateAsString(start);
      end = Helper.dateAsString(end);
      break;
    case Constants.FILTER_DATE_LAST_YEAR:
      start = Helper.dateAsString(new Date(start.getFullYear() - 1, 0, 1));
      end = Helper.dateAsString(new Date(end.getFullYear() - 1, 12, 0));
      break;
    default:
    case Constants.FILTER_DATE_TODAY:
      start = end = Helper.dateAsString(start);
      break;
  }
  return { start, end };
}

// Custom error class
export class AppError extends Error {
  constructor(message, code) {
    super(message);
    this.name = "AppError";
    this.code = code;
  }
}

export function getFontSize(id) {
  const div = document.getElementById(id);
  if (!div) {
    return null;
  }
  const computedStyle = window.getComputedStyle(div);
  if (!computedStyle) {
    return null;
  }
  const fontSize = computedStyle.getPropertyValue("font-size");
  return fontSize;
}

export function ln2br(str, useSpans = true) {
  // If the string has line breaks, split it and return a div with each line as a span
  if (str.split("\n").length > 1) {
    if (useSpans) {
      const lines = str.split("\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      });
      return <div>{lines}</div>;
    } else {
      return str.replace(/\n/g, "<br />");
    }
  }
  // No line breaks, return the string as is
  return str;
}

export function containsString(fragmentContent, searchString) {
  // Check if the fragment is a string
  if (typeof fragmentContent === "string") {
    return fragmentContent.toLowerCase().includes(searchString);
  }
  if (typeof fragmentContent?.props?.children === "string" && containsString(fragmentContent?.props?.children, searchString)) {
    return true;
  }
  if (typeof fragmentContent?.props?.children === "object" && fragmentContent?.props?.children?.length > 0) {
    const matches = fragmentContent?.props?.children?.filter(child => containsString(child, searchString));
    return matches.length > 0;
  }
  if (!fragmentContent || fragmentContent === undefined || fragmentContent.childNodes === undefined) {
    return false;
  }
  return Array.from(fragmentContent.childNodes).some(node => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent.toLowerCase().includes(searchString);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      return node.textContent.toLowerCase().includes(searchString) || node.querySelector(`:contains("${searchString}")`);
    }
    return false;
  });
}

export function isDifferentProduct(a, b) {
  return (
    a.productuuid !== b.productuuid ||
    a.productname !== b.productname ||
    a.sellprice !== b.sellprice ||
    a.location !== b.location ||
    a.taxable !== b.taxable ||
    a.upc !== b.upc ||
    a.ean !== b.ean ||
    a.altupc !== b.altupc
  );
}

export function isQaAdmin(state) {
  const start = state?.clientSettings?.NAME?.slice(0, 11) ?? "No state";
  const hasName = Constants.QA_COMPANIES.includes(start);
  const isAdmin = state?.usertype === Constants.USER_TYPE_ADMIN;
  return hasName && isAdmin;
}

export function renderAdditionalDataPrompt(prompt, setter, getter) {
  let additionaldataprompt = "";
  const fieldNames = [];
  if (prompt) {
    // Go through each line in the prompt
    additionaldataprompt = prompt.split("\n").map((line, index) => {
      // Split each line into field definitions and text fragments
      const parts = splitLineWithFieldDefinitions(line, index);
      // Empty line returns an empty list -> return a div with a space
      if (parts.length === 0) {
        return <div key={"prompt-line-" + index}>&nbsp;</div>;
      }
      const formattedLine = parts.map((fragment, index) => {
        // If the element is a field definition, render the field
        if (fragment.startsWith("{{") && fragment.endsWith("}}")) {
          const { element, fieldName } = renderField(fragment, index, setter, getter);
          fieldNames.push(fieldName);
          return element;
        } else {
          // If the element is a text fragment, just return the text
          return <span key={"prompt-line-" + index}>{renderTextAsHtml(fragment)}</span>;
        }
      });
      return <div key={"prompt-line-" + index}>{formattedLine}</div>;
    });
    additionaldataprompt = (
      <React.Fragment>
        <h3>Additional Information</h3>
        <div className="campaign">{additionaldataprompt}</div>
      </React.Fragment>
    );
  }
  return { additionaldataprompt: additionaldataprompt, fields: fieldNames };
}

export function getAdditionalDataPromptFieldNames(prompt, fieldNameToID = true) {
  let fields = [];
  if (prompt) {
    // Go through each line in the prompt
    prompt.split("\n").forEach(line => {
      // Go through each fragment in the line
      splitLineWithFieldDefinitions(line).forEach(fragment => {
        // If the fragment is a field definition, add the field name to the list
        if (fragment.startsWith("{{") && fragment.endsWith("}}")) {
          fragment = fragment.replace("{{", "").replace("}}", "");
          const parts = fragment.split("|");
          const fieldName = fieldNameToID ? Helper.fieldNameToID(parts[1]) : parts[1];
          fields.push(fieldName);
        }
      });
    });
  }
  return fields;
}

export function getAdditionalDataPromptFields(prompt, fieldNameToID = true) {
  let fields = [];
  if (prompt) {
    // Go through each line in the prompt
    prompt.split("\n").forEach(line => {
      // Go through each fragment in the line
      splitLineWithFieldDefinitions(line).forEach(fragment => {
        // If the fragment is a field definition, add the field name to the list
        if (fragment.startsWith("{{") && fragment.endsWith("}}")) {
          fragment = fragment.replace("{{", "").replace("}}", "");
          const parts = fragment.split("|");
          const fieldName = fieldNameToID ? Helper.fieldNameToID(parts[1]) : parts[1];
          let required = false;
          // Check for three/four part field definitions
          if (
            ["radio", "text", "checkbox", "select", "textarea"].includes(parts[0].toLowerCase()) &&
            parts.length === 4 &&
            parts[3].toLowerCase() === "required"
          ) {
            required = true;
          }
          // Check for two/three part field definitions
          if (["date", "time", "number"].includes(parts[0].toLowerCase()) && parts.length === 3 && parts[2].toLowerCase() === "required") {
            required = true;
          }
          fields.push({ fieldName: fieldName, fieldType: parts[0], required: required });
        }
      });
    });
  }
  return fields;
}

export function getAdditionalDataPromptsByType(prompt, type, fieldNameToID = true) {
  let fields = [];
  if (prompt) {
    // Go through each line in the prompt
    prompt.split("\n").forEach(line => {
      // Go through each fragment in the line
      splitLineWithFieldDefinitions(line).forEach(fragment => {
        // If the fragment is a field definition, add the field name to the list
        if (fragment.startsWith("{{") && fragment.endsWith("}}")) {
          fragment = fragment.replace("{{", "").replace("}}", "");
          const parts = fragment.split("|");
          if (parts[0].toLowerCase() === type) {
            const fieldName = fieldNameToID ? Helper.fieldNameToID(parts[1]) : parts[1];
            fields.push(fieldName);
          }
        }
      });
    });
  }
  return fields;
}

export function splitLineWithFieldDefinitions(line, lineIndex) {
  // Break the line into:
  //     field definitions  {{.*?}} (e.g. {{radio|tag|tag1,tag2,tag3}})
  //     strings            .* (i.e. not {})
  const regex = /(\{\{.*?\}\})|([^{}]+)/g;
  const result = [];
  let match;

  while ((match = regex.exec(line)) !== null) {
    if (match[1]) {
      result.push(match[1]); // Field definitions
    } else if (match[2]) {
      result.push(match[2]); // Text fragments
    }
  }
  return result;
}

function checkParts(parts) {
  // Every field definition must have at least 2 parts
  if (parts.length < 2) {
    return false;
  }
  // Check for three/four part field definitions
  if (["radio", "text", "checkbox", "select", "textarea"].includes(parts[0].toLowerCase()) && ![3, 4].includes(parts.length)) {
    return false;
  }
  // Check for two/three part field definitions
  if (["date", "time", "number"].includes(parts[0].toLowerCase()) && ![2, 3].includes(parts.length)) {
    return false;
  }
  // Check for empty parts
  if (parts.some(part => part === "")) {
    return false;
  }
  return true;
}

export function renderField(fieldDefinition, lineIndex, setter, getter) {
  const fragment = fieldDefinition.replace("{{", "").replace("}}", "");
  const parts = fragment.split("|");

  if (checkParts(parts) && parts[0].toLowerCase() === "radio") {
    const fieldName = Helper.fieldNameToID(parts[1]);
    const options = parts[2].split(",");
    const radioButtons = options.map((option, index) => {
      option = option.trim();
      return (
        <React.Fragment key={"prompt-" + lineIndex + "-field-" + index}>
          <input
            type="radio"
            id={option}
            data-testid={fieldName}
            title={fieldName}
            name={fieldName}
            checked={getter(fieldName) === option}
            value={option}
            onChange={event => {
              setter(fieldName, option);
            }}
          />
          <label htmlFor={option}>{option}</label>
          <br />
        </React.Fragment>
      );
    });
    return { element: radioButtons, fieldName: fieldName };
  } else if (checkParts(parts) && parts[0].toLowerCase() === "select") {
    const fieldName = Helper.fieldNameToID(parts[1]);
    const options = parts[2].split(",");
    const pleaseSelect = <option value="">Please select...</option>;
    const optionList = options.map((option, index) => {
      option = option.trim();
      return (
        <option value={option} key={option}>
          {option}
        </option>
      );
    });
    const element = (
      <select
        id={fieldName}
        data-testid={fieldName}
        title={fieldName}
        name={fieldName}
        key={"prompt-" + lineIndex + "-field-"}
        value={getter(fieldName)}
        onChange={event => {
          setter(fieldName, event.target.value);
        }}
      >
        {pleaseSelect}
        {optionList}
      </select>
    );
    return { element: element, fieldName: fieldName };
  } else if (checkParts(parts) && parts[0].toLowerCase() === "text") {
    const fieldName = Helper.fieldNameToID(parts[1]);
    const placeholder = parts[2];
    const input = (
      <input
        type="text"
        id={fieldName}
        data-testid={fieldName}
        title={fieldName}
        name={fieldName}
        placeholder={placeholder}
        key={"prompt-" + lineIndex + "-field-"}
        value={getter(fieldName)}
        onChange={event => {
          setter(fieldName, event.target.value);
        }}
      />
    );
    return { element: input, fieldName: fieldName };
  } else if (checkParts(parts) && parts[0].toLowerCase() === "checkbox") {
    const fieldName = Helper.fieldNameToID(parts[1]);
    const label = parts[2];
    const input = (
      <div className="campaignCheckboxDiv" key={"prompt-" + lineIndex + "-checkbox"}>
        <input
          type="checkbox"
          id={fieldName}
          data-testid={fieldName}
          title={fieldName}
          name={fieldName}
          key={"prompt-" + lineIndex + "-field-"}
          checked={getter(fieldName)}
          onChange={event => {
            setter(fieldName, event.target.checked);
          }}
        />
        <label htmlFor={fieldName}>{label}</label>
      </div>
    );
    return { element: input, fieldName: fieldName };
  } else if (checkParts(parts) && parts[0].toLowerCase() === "date") {
    const fieldName = Helper.fieldNameToID(parts[1]);
    const input = (
      <input
        type="date"
        id={fieldName}
        data-testid={fieldName}
        title={fieldName}
        name={fieldName}
        key={"prompt-" + lineIndex + "-field-"}
        value={getter(fieldName)}
        onChange={event => {
          setter(fieldName, event.target.value);
        }}
      />
    );
    return { element: input, fieldName: fieldName };
  } else if (checkParts(parts) && parts[0].toLowerCase() === "time") {
    const fieldName = Helper.fieldNameToID(parts[1]);
    const input = (
      <input
        type="time"
        id={fieldName}
        data-testid={fieldName}
        title={fieldName}
        name={fieldName}
        key={"prompt-" + lineIndex + "-field-"}
        value={getter(fieldName)}
        onChange={event => {
          setter(fieldName, event.target.value);
        }}
      />
    );
    return { element: input, fieldName: fieldName };
  } else if (checkParts(parts) && parts[0].toLowerCase() === "textarea") {
    const fieldName = Helper.fieldNameToID(parts[1]);
    const placeholder = parts[2];
    const input = (
      <textarea
        id={fieldName}
        data-testid={fieldName}
        title={fieldName}
        name={fieldName}
        placeholder={placeholder}
        rows={3}
        key={"prompt-" + lineIndex + "-field-"}
        value={getter(fieldName)}
        onChange={event => {
          setter(fieldName, event.target.value);
        }}
      />
    );
    return { element: input, fieldName: fieldName };
  } else if (checkParts(parts) && parts[0].toLowerCase() === "number") {
    const fieldName = Helper.fieldNameToID(parts[1]);
    const input = (
      <input
        type="number"
        id={fieldName}
        data-testid={fieldName}
        title={fieldName}
        name={fieldName}
        key={"prompt-" + lineIndex + "-field-"}
        value={getter(fieldName)}
        onChange={event => {
          setter(fieldName, event.target.value);
        }}
      />
    );
    return { element: input, fieldName: fieldName };
  }
  const element = <span>{fieldDefinition}</span>;
  return { element: element, fieldName: null };
}

// Replace non-letter/number characters and spaces with underscores
export function fieldNameToID(text) {
  let s = text.replace(/[^a-zA-Z0-9]/g, "_");
  return s;
}

export function splitLineWithBoldMarkup(line) {
  // Break the line into:
  //     text strings
  //     **bold text**
  const regexBold = /(\*\*.*?\*\*)|(\*)|([^**]+)/g;
  let match;
  const result = [];

  while ((match = regexBold.exec(line)) !== null) {
    if (match[1]) {
      result.push(match[1]); // Bold text
    } else if (match[2]) {
      result.push(match[2]); // Single asterisk
    } else if (match[3]) {
      result.push(match[3]); // Non-bold text
    }
  }
  return result;
}

export function splitLineWithItalicMarkup(line) {
  // Break the line into:
  //     text strings
  //     ^^italic text^^
  // const regexItalic = /(\^\^.*?\^\^)|([^\^\^]+)/g;
  const regexItalic = /(\^\^.*?\^\^)|([^^]?[\^][^^]?)|([^^^]+)/g;
  let match;
  const result = [];

  while ((match = regexItalic.exec(line)) !== null) {
    if (match[1]) {
      result.push(match[1]); // Italic text
    } else if (match[2]) {
      result.push(match[2]); // Single caret
    } else if (match[3]) {
      result.push(match[3]); // Non-italic text
    }
  }
  return result;
}

export function renderTextAsHtml(text) {
  return text.split("\n").map((item, key) => {
    if (item && item.trim() !== "") {
      // Check for bold text in the format **text**
      const partsBold = splitLineWithBoldMarkup(item);
      const line = partsBold.map((partBold, index) => {
        if (partBold.startsWith("**") && partBold.endsWith("**")) {
          const val = partBold.replace(/\*\*/g, "");
          return (
            <span key={"line-" + key + "-part-" + index} style={{ fontWeight: "bold" }}>
              {val}
            </span>
          );
        } else {
          const partsItalic = splitLineWithItalicMarkup(partBold);
          const line = partsItalic.map((partItalic, index) => {
            if (partItalic.startsWith("^^") && partItalic.endsWith("^^")) {
              const val = partItalic.replace(/\^\^/g, "");
              return (
                <span key={"line-" + key + "-part-" + index} style={{ fontStyle: "italic" }}>
                  {val}
                </span>
              );
            } else {
              return partItalic;
            }
          });
          return line;
        }
      });
      return <div key={"line-" + key}>{line}</div>;
    } else {
      return <div key={"line-" + key}>&nbsp;</div>;
    }
  });
}

export function getBlankCampaign(current_user_fullname) {
  return {
    campaignuuid: null,
    campaignname: "",
    description: "",
    campaignstatus: Constants.CAMPAIGN_STATUS_DRAFT,
    additionaldataprompt: "",
    agreementtext: "",
    requiremobilephone: false,
    requireotherphone: false,
    requireaddress: false,
    requirecard: false,
    requirenameinvitation: false,
    requiremobilephoneinvitation: false,
    salesperson: current_user_fullname,
    creationdatetime: "",
    lastupdated: "",
  };
}

export function getCampaignLink(preview, subdomain, campaignuuid) {
  let url = "";
  if (["localhost", "app-test.clerkhound.com"].includes(window.location.hostname)) {
    let hostname = window.location.hostname;
    if (hostname === "localhost") {
      hostname = "localhost:3000";
    }
    url = `http://${hostname}?subdomain=${subdomain}&campaignuuid=${campaignuuid}`;
  } else {
    url = `https://${subdomain}.billinghound.com?campaignuuid=${campaignuuid}`;
  }
  if (preview) {
    url += "&preview=true";
  }
  return url;
}

export function isCartloomField(field) {
  return Constants.CARTLOOM_FIELDS.includes(field);
}

export function renderDayOfCycle(subscription) {
  if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY) {
    if (subscription.day_of_cycle >= 0 && subscription.day_of_cycle <= 6) {
      return Constants.WEEKDAYS[subscription.day_of_cycle];
    } else {
      return "Unknown";
    }
  } else if ([Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY, Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY].includes(subscription.plan_frequency)) {
    return subscription.day_of_cycle + getNumericSuffix(subscription.day_of_cycle);
  }
  return "";
}

export function getNumericSuffix(number) {
  let suffix;
  // Handle special cases for numbers ending in 11, 12, or 13
  if (number % 100 >= 11 && number % 100 <= 13) {
    suffix = "th";
  } else {
    // Determine suffix based on the last digit of the number
    const lastDigit = number % 10;
    switch (lastDigit) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
      default:
        suffix = "th";
    }
  }
  return suffix;
}

export function renderMonthOfCycle(subscription, handler) {
  return (
    <select
      data-testid="Edit Month of Cycle"
      className="monthOfCycle"
      id="month_of_cycle"
      name="month_of_cycle"
      value={subscription.month_of_cycle}
      onChange={handler}
    >
      <option value="0">January</option>
      <option value="1">February</option>
      <option value="2">March</option>
      <option value="3">April</option>
      <option value="4">May</option>
      <option value="5">June</option>
      <option value="6">July</option>
      <option value="7">August</option>
      <option value="8">September</option>
      <option value="9">October</option>
      <option value="10">November</option>
      <option value="11">December</option>
    </select>
  );
}

export function renderDayOfCycleSelect(frequency, day, handleChange, monthOfCycle = null) {
  let dayOfCycleElement = "";
  switch (frequency) {
    case Constants.CH_RECURRING_PLAN_FREQUENCY_DAILY:
      dayOfCycleElement = "Every Day";
      break;
    case Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY:
      dayOfCycleElement = (
        <select data-testid="Edit Day of Cycle" id="day_of_cycle" name="day_of_cycle" value={day} onChange={event => handleChange(event)}>
          {Constants.WEEKDAYS.map((day, index) => {
            return (
              <option key={index} value={index}>
                {day}
              </option>
            );
          })}
        </select>
      );
      break;
    case Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY:
      dayOfCycleElement = (
        <select data-testid="Edit Day of Cycle" id="day_of_cycle" name="day_of_cycle" value={day} onChange={event => handleChange(event)}>
          {Array.from({ length: 28 }, (_, i) => i + 1).map((day, index) => {
            return (
              <option key={index} value={day}>
                {`${day}${Helper.getNumericSuffix(day)}`}
              </option>
            );
          })}
        </select>
      );
      break;
    case Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY:
      const days = daysInMonth(monthOfCycle);
      dayOfCycleElement = (
        <select
          data-testid="Edit Day of Cycle"
          className="annualDayOfCycle"
          id="day_of_cycle"
          name="day_of_cycle"
          value={day}
          onChange={event => handleChange(event)}
        >
          {Array.from({ length: days }, (_, i) => i + 1).map((day, index) => {
            return (
              <option key={index} value={day}>
                {`${day}${Helper.getNumericSuffix(day)}`}
              </option>
            );
          })}
        </select>
      );
      break;
    default:
      console.warn("Unsupported plan frequency");
      dayOfCycleElement = "";
  }
  return dayOfCycleElement;
}

// This function works everything in UTC time to avoid timezone issues
export function getDayOfCycle(date, frequency) {
  const d = new Date(date + "T00:00:00Z");
  switch (frequency) {
    case Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY:
      return d.getUTCDay();
    case Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY:
      // Cap at 28 to ensure consistency across months
      const dayOfMonth = Math.min(d.getUTCDate(), 28);
      return dayOfMonth;
    case Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY:
      return d.getUTCDate();
    default:
      // Returning 0 if frequency doesn't match; consider throwing an error or logging for unsupported frequencies
      console.warn("Unsupported frequency type, defaulting to 0.");
      return 0;
  }
}

// This function works everything in UTC time to avoid timezone issues
export function getMonthOfCycle(date) {
  const d = new Date(date + "T00:00:00Z");
  // Get the month of the year as a number from the date passed in
  return d.getUTCMonth();
}

export function getDateFromDateString(date) {
  const d = new Date(date);
  return d.getUTCDate();
}

export function getMonthFromDateString(date) {
  const d = new Date(date);
  return d.getUTCMonth();
}

export function renderSubscriptionToColumnsReadonly(
  subscription,
  index,
  hideInactiveSubscriptions,
  handleChangeCustomField,
  handleBlurCustomField,
  renderCustomFieldsPrint,
  appState,
  maastCustomer,
  subscriptions,
  handleToggleEditMode,
  isMaastOnlyCustomer,
  handleExpandSubscription,
  renderPencilAndExpanderFlag = true,
  doubleClickEdits = true
) {
  if (hideInactiveSubscriptions && Constants.CH_SUBSCRIPTION_INACTIVE_STATUSES.includes(subscription.status)) {
    return [];
  }

  // The doubleClickEdits prop is used to determine whether or not to handle double clicks on the subscription fields
  const handleDoubleClick = doubleClickEdits
    ? event => {
        // Stop propagation
        event.stopPropagation();
        handleToggleEditMode(subscription, index);
      }
    : () => {};

  const subscriptionNumber = (
    <div data-testid="Subscription Number" className="subscriptionLabel" title={subscription.subscriptionnumber} onDoubleClick={handleDoubleClick}>
      {subscription.subscriptionnumber}
    </div>
  );

  const subscriptionName = (
    <div data-testid="Subscription Name" className="subscriptionLabel" title={subscription.plan_name} onDoubleClick={handleDoubleClick}>
      {subscription.plan_name}
    </div>
  );

  const planFrenquency = (
    <div data-testid="Subscription Frequency" className="subscriptionLabel" onDoubleClick={handleDoubleClick}>
      {subscription.plan_frequency}
    </div>
  );

  const recurAmount = (
    <div data-testid="Subscription Amount" className="subscriptionLabel" onDoubleClick={handleDoubleClick}>
      {numeral(subscription.recur_amt).format(Constants.CURRENCY)}
    </div>
  );

  let overdueClasses = subscription.overdue_balance > 0 ? "subscriptionLabel highlight" : "subscriptionLabel";
  const overdueAmount = (
    <div data-testid="Subscription Overdue Amount" className={overdueClasses} onDoubleClick={handleDoubleClick}>
      {numeral(subscription.overdue_balance).format(Constants.CURRENCY)}
    </div>
  );

  const startDate = (
    <div data-testid="Subscription Start Date" className="subscriptionLabel" onDoubleClick={handleDoubleClick}>
      {Helper.formatDate(subscription.date_start)}
    </div>
  );

  const dateNext = (
    <div data-testid="Subscription Date Next" className="subscriptionLabel" onDoubleClick={handleDoubleClick}>
      {Helper.formatDate(subscription.date_next) || "-"}
    </div>
  );

  const dateEnd = <div onDoubleClick={handleDoubleClick}>{renderDateEndOrInfinity(subscription, "subscriptionLabel")}</div>;

  const subscriptionStatus = (
    <div data-testid="Subscription Status" className="subscriptionLabel" onDoubleClick={handleDoubleClick}>
      {Helper.renderStatus(subscription.status)}
    </div>
  );

  let payment = (
    <div data-testid="Payment Method" className="subscriptionLabel centerAligned">
      {" "}
      -{" "}
    </div>
  );
  if ([Constants.CH_SUBSCRIPTION_ACTIVE, Constants.CH_SUBSCRIPTION_SUSPENDED].includes(subscription.status)) {
    payment = (
      <div data-testid="Payment Method" className="subscriptionLabel" onDoubleClick={handleDoubleClick}>
        {subscription.masked_card_number || "Card not found"}
      </div>
    );
  }
  const pencilAndExpander = renderPencilAndExpanderFlag
    ? renderPencilAndExpander(
        subscription,
        subscriptions,
        index,
        handleToggleEditMode,
        subscription.customFields,
        isMaastOnlyCustomer,
        handleExpandSubscription
      )
    : "";

  const customFieldsRow = subscription.expanded ? (
    <BillingCustomFields
      subscription={subscription}
      appState={appState}
      handleChangeCustomField={handleChangeCustomField}
      handleBlurCustomField={handleBlurCustomField}
      className=""
    />
  ) : (
    ""
  );
  const customFieldsRowPrint = renderCustomFieldsPrint(subscription, "printOnly");
  let columns = [
    { rowvalue: subscriptionNumber, classes: "subscriptionNumber firstLeft" },
    { rowvalue: subscriptionName, classes: "subscriptionName" },
    { rowvalue: planFrenquency, classes: "planFrenquency" },
    { rowvalue: recurAmount, classes: "right-aligned recurAmount" },
    { rowvalue: overdueAmount, classes: "right-aligned recurOverdueAmount" },
    { rowvalue: startDate, classes: "centerAligned startDate" },
    { rowvalue: dateNext, classes: "centerAligned dateNext" },
    { rowvalue: dateEnd, classes: "centerAligned" },
    { rowvalue: subscriptionStatus, classes: "centerAligned" },
    { rowvalue: payment },
    { rowvalue: pencilAndExpander, classes: "lastRight" },
  ];
  if (customFieldsRow !== "") {
    columns.push({ rowvalue: customFieldsRow, classes: "span11 noPrint customFieldsContainer" });
  }
  columns.push({ rowvalue: customFieldsRowPrint, classes: "span11 printOnly leftMarginSmall customFieldsContainerPrint" });
  return columns;
}

export function renderDateEndOrInfinity(subscription, endDateClass = "") {
  let date_end_field = "";
  if (Constants.CH_SUBSCRIPTION_INACTIVE_STATUSES.includes(subscription.status) && subscription.closedate) {
    date_end_field = <div data-testid="Subscription End Date">{formatDate(subscription.closedate)}</div>;
  } else if (subscription.plan_duration !== -1) {
    date_end_field = <div data-testid="Subscription End Date">{formatDate(subscription.date_end)}</div>;
  } else if (!subscription.isNew) {
    date_end_field = (
      <div data-testid="Infinity Symbol">
        <FontAwesomeIcon icon={faInfinity} />
      </div>
    );
  }
  const dateEnd = (
    <div data-testid="Subscription End Date Container" className={endDateClass}>
      {date_end_field}
    </div>
  );
  return dateEnd;
}

export function renderPaymentOrNoCard(subscription, maastCustomer = null, paymentClass = "") {
  let payment = "";
  const cards = maastCustomer?.billing_cards ?? [];
  const card = cards.find(card => card.card_id === subscription.card_id);
  if (cards.length === 0) {
    payment = (
      <div data-testid="No card on file" className={paymentClass}>
        No card on file
      </div>
    );
  } else if (card) {
    const name = card.billing_first_name + " " + card.billing_last_name;
    const desc = name + " " + card.card_number.slice(-6);
    payment = (
      <div data-testid="Payment Method" className={paymentClass}>
        {desc}
      </div>
    );
  } else {
    payment = (
      <div data-testid="Card not found" className={paymentClass}>
        Card not found
      </div>
    );
  }
  return payment;
}

export function renderPencilAndExpander(
  subscription,
  subscriptions,
  index,
  handleToggleEditMode,
  customFields,
  isMaastOnlyCustomer,
  handleExpandSubscription
) {
  let editButton = (
    <span
      data-testid="Edit Subscription Button"
      className="noPrint"
      title="Edit Subscription"
      onClick={() => {
        handleToggleEditMode(subscription, index);
      }}
    >
      <FontAwesomeIcon icon={faPencil} />
    </span>
  );
  if (subscriptions.filter(sub => sub.editMode).length > 0) {
    // If any subscription is in edit mode, don't show edit button
    editButton = <span>&nbsp; &nbsp;</span>;
  }

  let expandButton = renderSubscriptionExpandButton(subscription, customFields, isMaastOnlyCustomer, handleExpandSubscription);

  return (
    <div className="subscriptionLabel">
      {editButton} &nbsp; &nbsp; {expandButton}
    </div>
  );
}

export function renderSubscriptionExpandButton(subscription, customFields, isMaastOnlyCustomer, handleExpandSubscription) {
  // If there are no custom fields defined in settings and no orphaned custom field values, then disable the expand button
  let expandIcon = subscription.expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />;
  const noCustomFields =
    ((customFields?.length === 0 || customFields === undefined) &&
      (subscription.custom_fields?.length === 0 || subscription.custom_fields === undefined)) ||
    isMaastOnlyCustomer;
  let className = noCustomFields ? "save-disabled" : "";
  let title = noCustomFields ? "No custom field templates defined" : "View Custom Fields";
  title = isMaastOnlyCustomer ? "Custom fields are not available for Maast-only customers" : title;
  let handleClick = noCustomFields
    ? () => {}
    : () => {
        handleExpandSubscription(subscription);
      };

  return (
    <span data-testid="Expand Icon" className={"noPrint " + className} title={title} onClick={handleClick}>
      {expandIcon}
    </span>
  );
}

export function isBillingFeatureEnabled(features) {
  return features.includes(Constants.FEATURE_BILLING) || features.includes(Constants.FEATURE_RECURRINGS);
}

export function isDateNextOffCycle(subscription) {
  if (subscription.status !== Constants.CH_SUBSCRIPTION_ACTIVE) {
    return false;
  }
  if (subscription.date_next === null) {
    return false;
  }
  if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_DAILY) {
    return false;
  }
  if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY) {
    return subscription.day_of_cycle !== new Date(subscription.date_next).getUTCDay();
  }
  if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY) {
    return subscription.day_of_cycle !== new Date(subscription.date_next).getUTCDate();
  }
  if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY) {
    return (
      subscription.day_of_cycle !== new Date(subscription.date_next).getUTCDate() ||
      subscription.month_of_cycle !== new Date(subscription.date_next).getUTCMonth()
    );
  }
  return false;
}

export function daysInMonth(month) {
  if ([3, 5, 8, 10].includes(month)) {
    return 30;
  } else if (month === 1) {
    return 28;
  } else {
    return 31;
  }
}

export function renderCronLogMessage(type, message) {
  // Parse the JSON
  let log = message;
  try {
    log = JSON.parse(message);
  } catch (e) {
    // console.log(e);
  }
  if (type === Constants.CRON_LOG_TYPE_EMAIL_FAILED) {
    return "Client: " + log.clientid + " - Invoice #: " + log.invoice?.ordernumber;
  } else if (type === Constants.CRON_LOG_TYPE_EMAIL_SENT) {
    return "Client: " + log.clientid + " - Invoice #: " + log.invoice?.ordernumber + " - " + log.email_contact_response_body?.emailto;
  } else if (type === Constants.CRON_LOG_TYPE_GET_SUBSCRIPTIONS_SUCCESS) {
    return "Client: " + log.clientid + " - Subscription Count: " + log.subscription_count;
  } else if (type === Constants.CRON_LOG_TYPE_GET_SUBSCRIPTIONS_FAILED) {
    return "Client: " + log.clientid;
  } else if (type === Constants.CRON_LOG_TYPE_INVOICE_CREATED) {
    return "Client: " + log.clientid + " - Invoice #: " + log.invoice?.ordernumber;
  } else if (type === Constants.CRON_LOG_TYPE_INVOICE_FAILED) {
    return "Client: " + log.clientid + " - Subscription #: " + log.subscription?.subscriptionnumber;
  } else if (type === Constants.CRON_LOG_TYPE_MISSING_PAYMENT_PRODUCT) {
    return "Client: " + log.clientid;
  } else if (type === Constants.CRON_LOG_TYPE_NETWORK_ERROR) {
    return (
      "Client: " +
      log.clientid +
      " - Invoice #: " +
      log.invoice?.ordernumber +
      " - $" +
      numeral(log.invoice?.balancedue ?? 0).format(Constants.CURRENCY)
    );
  } else if (type === Constants.CRON_LOG_TYPE_NO_BALANCE_DUE) {
    return "Client: " + log.clientid + " - Invoice #: " + log.invoice?.ordernumber;
  } else if (type === Constants.CRON_LOG_TYPE_NO_SUBSCRIPTIONS) {
    return "Client: " + log.clientid;
  } else if (type === Constants.CRON_LOG_TYPE_NO_VAULTED_PAYMENT) {
    return "Client: " + log.clientid + " - Subscription #: " + log.subscription?.subscriptionnumber;
  } else if (type === Constants.CRON_LOG_TYPE_PAYMENT_ERROR) {
    return (
      "Client: " +
      log.clientid +
      " - Invoice #: " +
      log.invoice?.ordernumber +
      " - $" +
      numeral(log.invoice?.balancedue ?? 0).format(Constants.CURRENCY) +
      " - " +
      log.exception
    );
  } else if (type === Constants.CRON_LOG_TYPE_PAYMENT_FAILED) {
    return (
      "Client: " +
      log.clientid +
      " - Invoice #: " +
      log.invoice?.ordernumber +
      " - $" +
      numeral(log.invoice?.balancedue ?? 0).format(Constants.CURRENCY)
    );
  } else if (type === Constants.CRON_LOG_TYPE_PAYMENT_SUCCESS) {
    return (
      "Client: " +
      log.clientid +
      " - Invoice #: " +
      log.invoice?.ordernumber +
      " - $" +
      numeral(log.maast_response?.order?.totalpayments ?? 0).format(Constants.CURRENCY)
    );
  } else if (type === Constants.CRON_LOG_TYPE_PROCESS_SUBSCRIPTION_END) {
    return "Client: " + log.clientid + " - Subscription #: " + log.subscription?.subscriptionnumber;
  } else if (type === Constants.CRON_LOG_TYPE_PROCESS_SUBSCRIPTION_EXCEPTION) {
    return "Client: " + log.clientid + " - Subscription #: " + log.subscription?.subscriptionnumber + " - " + log.exception;
  } else if (type === Constants.CRON_LOG_TYPE_PROCESS_SUBSCRIPTION_START) {
    return "Client: " + log.clientid + " - Subscription #: " + log.subscription?.subscriptionnumber;
  } else if (type === Constants.CRON_LOG_TYPE_SUSPEND_FAILED) {
    return "Client: " + log.clientid + " - Subscription #: " + log.subscription?.subscriptionnumber;
  } else if (type === Constants.CRON_LOG_TYPE_SUSPEND_SUCCESS) {
    return "Client: " + log.clientid + " - Subscription #: " + log.subscription?.subscriptionnumber;
  } else if (type === Constants.CRON_LOG_TYPE_SUSPENDED) {
    return "Client: " + log.clientid + " - Subscription #: " + log.subscription?.subscriptionnumber;
  }
}
